import React from "react";
import Text from "../text/Text";
import styles from "./RoundCheckbox.module.css";

interface ICheckBoxProps {
  checkId: string;
  label: string;
  onClick: () => void;
  checked: boolean;
}

export default function RoundCheckbox({
  checkId,
  label,
  checked,
  onClick,
}: ICheckBoxProps) {
  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        id={checkId}
        className={styles.checkboxround}
        onChange={onClick}
        checked={checked}
      />
      <Text
        label={label}
        variant={
          checked
            ? `Body2-14 ${styles.labelStyle} bold`
            : `Body2-14 ${styles.labelStyle}`
        }
      />
    </div>
  );
}
