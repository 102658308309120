import React, {
  useState,
  useEffect,
  FormEvent,
  useCallback,
  useMemo,
} from "react";
import {
  addDays,
  format,
  startOfWeek,
  subMonths,
  startOfMonth,
} from "date-fns";
import { getAllOrdersByStatus } from "../../../../services/orders";
import { labels } from "../../../../labels";
import { menuDates, menuItems } from "../../../../constants";
import uploadProducts from "../../../../assets/icons/icons-upload-products.svg";
import NoOrders from "../../NoOrders";
import OutlinedInput from "../../../../components/outlinedInput/OutlinedInput";
import SelectComponent, {
  SelectOption,
} from "../../../../components/selectComponent/SelectComponent";
import OrderComponent from "../../../../components/orderComponent/OrderHistoryComponent";
import useDisclosure from "../../../../hooks/useDisclosure";
import recordStyles from "./Record.module.css";
import ScrollComponent from "../../../../components/ScrollComponent";

const PAGE_CHUNK = 300;
const DELIVERED = 4;
const REJECTEDBYLOCAL = -1;
const REJECTEDBYCUSTOMER = -2;

interface TimeFormats {
  [key: string]: Date | undefined;
}
const timeDatesFrom: TimeFormats = {
  "0": undefined,
  "4": new Date(),
  "1": addDays(startOfWeek(new Date()), 1),
  "2": startOfMonth(new Date()),
  "3": subMonths(new Date(), 3),
};
export default function Record() {
  const currentUser = localStorage.getItem("currentUser");

  const user = currentUser && JSON.parse(currentUser);
  const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal;
  const {
    open: loading,
    handleOpen: handleOpenLoading,
    handleClose: handleCloseLoading,
  } = useDisclosure();
  const [data, setData] = useState<TypeHistory[]>([]);

  const [filterByOrder, setFilterByOrder] = useState<string>("");
  const [filterByStatus, setFilterByStatus] = useState<SelectOption>(
    menuItems[0]
  );
  const [datesOption, setDatesOption] = useState<SelectOption>(menuDates[0]);

  const handleChangeOrderFilter = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setFilterByOrder(newValue);
  };

  const filterData = useMemo(() => {
    if (filterByOrder.length > 2) {
      return data.filter((element) =>
        element.orderHeader.id
          .toString()
          .toLocaleLowerCase()
          .includes(filterByOrder)
      );
    }
    return [];
  }, [data, filterByOrder]);

  const handleGetData = useCallback(
    async (status: number, isFilter?: boolean) => {
      const dateTo = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");

      const from = timeDatesFrom[datesOption.value];
      const dateFrom = from ? format(from, "yyyy-MM-dd'T'HH:mm:ss") : undefined;
      return getAllOrdersByStatus(
        commerceId,
        status,
        PAGE_CHUNK,
        1,
        dateFrom,
        dateTo
      ).then((response) => {
        setData((prevState) => {
          if (isFilter) {
            return response;
          }
          return [...prevState, ...response];
        });
      });
    },
    [datesOption]
  );

  const handleGetHistoryData = async () => {
    handleOpenLoading();
    setData([]);
    if (filterByStatus.value === +menuItems[0].value) {
      await handleGetData(DELIVERED).then(async () => {
        await handleGetData(REJECTEDBYLOCAL).then(async () => {
          await handleGetData(REJECTEDBYCUSTOMER).then(() => {
            handleCloseLoading();
          });
        });
      });
    } else {
      handleGetData(filterByStatus.value, true).then(() => {
        handleCloseLoading();
      });
    }
  };

  const handleChangeTimeFilter = async (newTime: SelectOption) => {
    setDatesOption(newTime);
  };

  const handleFilterByStatus = (newStatus: SelectOption) => {
    setFilterByStatus(newStatus);
  };

  useEffect(() => {
    handleGetHistoryData();
  }, [filterByStatus, datesOption]);
  return (
    <>
      <div className={recordStyles.recordContainer}>
        <div className="history-search-container">
          <div className="search-input-style">
            <OutlinedInput
              floatingLabel={labels.ordersSearchInput}
              placeholder=""
              formId="commerceCode"
              onChange={handleChangeOrderFilter}
            />
          </div>
          <div className="order-select-container">
            <SelectComponent item={menuItems} onChange={handleFilterByStatus} />
            <div className="selector-divider-style" />
            <SelectComponent
              item={menuDates}
              onChange={handleChangeTimeFilter}
            />
          </div>
        </div>
        {data.length !== 0 && !loading && (
          <div className="record-orders-container">
            <ScrollComponent>
              {filterData.length === 0 && filterByOrder.length > 2 && (
                <NoOrders
                  icon={uploadProducts}
                  label={labels.noOrdersHistory}
                />
              )}
              {filterData.length === 0 && filterByOrder.length <= 2 && (
                <>
                  {data.map((item: TypeHistory) => (
                    <div
                      key={`${item.orderHeader.id}-${item.orderHeader.statusID}`}
                      className="bottom-margin-history"
                    >
                      <OrderComponent
                        orderTypeID={item.orderHeader.orderTypeID}
                        statusID={item.orderHeader.statusID}
                        id={item.id}
                        client={`${item.consumer.firstName} ${item.consumer.lastName}`}
                        orderNumber={item.orderHeader.id}
                        dateCreated={item.orderHeader.creationDate}
                        ammount={item.orderHeader.finalAmount}
                      />
                    </div>
                  ))}
                </>
              )}
              {filterData.length !== 0 && filterByOrder.length > 2 && (
                <>
                  {filterData.map((item: TypeHistory) => (
                    <div
                      key={`${item.orderHeader.id}-${item.orderHeader.statusID}`}
                      className="bottom-margin-history"
                    >
                      <OrderComponent
                        orderTypeID={item.orderHeader.orderTypeID}
                        statusID={item.orderHeader.statusID}
                        id={item.id}
                        client={`${item.consumer.firstName} ${item.consumer.lastName}`}
                        orderNumber={item.orderHeader.id}
                        dateCreated={item.orderHeader.creationDate}
                        ammount={item.orderHeader.finalAmount}
                      />
                    </div>
                  ))}
                </>
              )}
            </ScrollComponent>
          </div>
        )}
      </div>
      {loading && (
        <div className={recordStyles.spinnerContainer}>
          <div className={recordStyles.loader} />
        </div>
      )}
      {data?.length === 0 && !loading && (
        <NoOrders icon={uploadProducts} label={labels.ordersNoHistory} />
      )}
    </>
  );
}
