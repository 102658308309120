import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from "react";
import { Token, getInfoTokens } from "../services/commerce";

export const TokenContext = createContext<Token[]>([]);

export const useTokens = (): Token[] => {
  const [tokens, setTokens] = useState<Token[]>([]);

  const getTokens = async () => {
    await getInfoTokens().then((res) => {
      if (res.status === 200) {
        setTokens(res.responseJson);
      } else {
        setTokens([]);
      }
    });
  };
  useEffect(() => {
    getTokens();
  }, []);

  return tokens;
};
interface ITokensProviderProps {
  children: ReactNode;
}

export const useTokensContext = (): Token[] | null => {
  const context = useContext<Token[]>(TokenContext);
  return context;
};

export default function TokensProvider({ children }: ITokensProviderProps) {
  const context = useTokens();
  return (
    <TokenContext.Provider value={context}>{children}</TokenContext.Provider>
  );
}
