import React from "react";
import { labels } from "../../../labels";
import Text from "../../text/Text";
import styles from "./DeleteProductModal.module.css";
import iconAlert from "../../../assets/icons/icons-alert.svg";

interface DeleteProductProps {
  onClick: () => void;
  onBack: () => void;
}

export default function DeleteProductModal(props: DeleteProductProps) {
  return (
    <div className={styles.containerModal}>
      <div className={styles.labels}>
        <img src={iconAlert} />
        <Text label={labels.deleteProductText} variant="Body1-16" />
      </div>
      <div className={styles.buttons}>
        <button
          className={`${styles.modalButtonBack}  ${styles.button}`}
          onClick={props.onClick}
          type="button"
        >
          {labels.deleteProductButtonText}
        </button>
        <button
          className={`${styles.modalButtonBack} ${styles.buttonBack}`}
          onClick={props.onBack}
          type="button"
        >
          {labels.dontDeleteProductButtonText}
        </button>
      </div>
    </div>
  );
}
