import React, { useRef, useState, useLayoutEffect, ChangeEvent } from "react";
import "../outlinedInput/OutlinedInput.css";
interface IOutlinedSelectProps {
  options: Option[];
  formId?: string;
  error?: string;
  helperText?: string;
  floatingLabel?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
}

function OutlinedSelect({
  options,
  error,
  floatingLabel,
  formId,
  helperText,
  onChange,
  defaultValue,
}: IOutlinedSelectProps) {
  const [focused, setFocused] = useState<boolean>(true);
  const [valid, setValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(
    error || null
  );

  const inputField = useRef<HTMLSelectElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const floatingLabelRef = useRef<HTMLLabelElement>(null);

  const focusField = () => {
    if (!inputField.current) {
      return;
    }
    inputField.current.focus();
  };

  const setLabelWidthStyleProperty = () => {
    if (floatingLabelRef.current) {
      const dims = floatingLabelRef.current.getBoundingClientRect();
      container.current?.style.setProperty(
        "--label-active-width",
        `${dims.width}px`
      );
    } else {
      container.current?.style.setProperty("--label-active-width", "0");
    }
  };

  const onFocus = () => {
    setFocused(true);
    setLabelWidthStyleProperty();
  };

  const getStyleClasses = () => {
    const classes = [];
    if (floatingLabel) {
      classes.push("_floating-label");
    }
    if (!valid) {
      classes.push("_invalid");
    }
    if (focused) {
      classes.push("_focused");
    }
    return classes.join(" ");
  };

  const showHintContainer = errorMessage || helperText;

  const setError = (errorMessage: string) => {
    if (!inputField.current) {
      return;
    }
    inputField.current.setCustomValidity(errorMessage);
    setValid(false);
    setErrorMessage(errorMessage);
  };

  useLayoutEffect(() => {
    if (error) {
      setError(error);
    } else {
      setError("");
      setValid(true);
    }
  }, [error]);

  return (
    <div className="wrapper">
      <div className="outline-input" ref={container}>
        <div
          className={`field-wrapper ${getStyleClasses()}`}
          onClick={focusField}
        >
          {floatingLabel && <label className="floating">{floatingLabel}</label>}
          {floatingLabel && (
            <label
              ref={floatingLabelRef}
              className="floating reference floatinglabel"
            >
              {floatingLabel}
            </label>
          )}
          <select
            ref={inputField}
            className="select"
            id={formId}
            name={formId}
            onClick={onFocus}
            onChange={onChange}
          >
            {options.map(({ id, label }) => (
              <option key={id} selected={id === defaultValue} id={id}>
                {label}
              </option>
            ))}
          </select>
          <div className="borders">
            <div className="border left" />
            <div className="middle">
              <div className="top-borders">
                <div className="border start" />
                <div className="border end" />
              </div>
              <div className="border bottom" />
            </div>
            <div className="border right" />
          </div>
        </div>
        {showHintContainer && (
          <div className="hints">
            <div className="messages">
              {(errorMessage || helperText) && (
                <>
                  {!valid && errorMessage && (
                    <div className="errorInput">{errorMessage}</div>
                  )}
                  {valid && helperText && (
                    <div className="helper">{helperText}</div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OutlinedSelect;
