import React from "react";
import { labels } from "../../labels";
import Text from "../text/Text";
import "./CardProduct.css";

interface ICardProduct {
  productObject: IProductObject;
  liquidate: (item: IProductObject) => void;
  stopLiquidating: (item: IProduct) => void;
  isLast: boolean;
  onUpdateClick: (item: IProductObject) => void;
}
export const lastId = "lastProduct";
const CardProduct = ({
  productObject,
  liquidate,
  stopLiquidating,
  isLast,
  onUpdateClick,
}: ICardProduct) => {
  return (
    <div className="container-card-product" id={isLast ? lastId : ""}>
      <div className="container-card-info-product">
        <div className="div-image">
          <img
            src={productObject.product.photoURL}
            width={"80px"}
            height={"78px"}
          />
        </div>
        <div className="div-texts-info">
          <Text
            label={productObject.product.productName}
            variant="text-card-producto-info"
          />
          {productObject.product.weight && (
            <Text
              label={`${productObject.product.weight} ${productObject.product.productMeasureUnit}`}
              variant="text-card-producto-info"
            />
          )}
          <Text
            label={`Precio: ${productObject.productPrices[0].saleAmount} /
                        ${
                          productObject.product.settlement
                            ? `${labels.productCardOfferPrice}: ` +
                              productObject.productPrices[0].saleAmount
                            : ""
                        }`}
            variant="text-card-producto-precio-stock"
          />
          <Text
            label={`${labels.stock}: ${productObject.commerceStock.stock}`}
            variant="text-card-producto-precio-stock"
          />
          {!productObject.product.settlement ? (
            <div className="twoButtonsContainer">
              <button
                className="button-liquidation"
                onClick={() => liquidate(productObject)}
              >
                {labels.CardProductLiquidate}
              </button>
              <button
                className="button-edit"
                onClick={() => onUpdateClick(productObject)}
              >
                {labels.editProduct}
              </button>
            </div>
          ) : (
            <button
              className="button-stop-liquidation"
              onClick={() => stopLiquidating(productObject.product)}
            >
              {labels.stopLiquidaqtion}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
