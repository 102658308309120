import React, {
    useState,
    useEffect,
    ChangeEvent,
    FormEvent,
    useRef,
    useContext,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ModalComponent, {
    VARIANTS,
    VariantsType,
} from '../../components/modal/ModalComponent'
import moment from 'moment'
import Calendar from '../../components/calendar/Calendar'
import ErrorModal from '../../components/modal/modalVariants/ErrorModal'
import SuccessModal from '../../components/modal/modalVariants/SuccessModal'
import ComuneModal from '../../components/modal/ComuneModal'
import SwitchComponent from '../../components/Switch/SwitchComponent'
import Text from '../../components/text/Text'
import SquareCheckBox from '../../components/checkBox/SquareCheckBox'
import RegionModal from '../../components/modal/RegionModal'

import { getCommerceInfo } from '../../services/commerce'
import {
    closeStore,
    putCommerceInfo,
    setComunesToRegion,
} from '../../services/OnlineStore'
import useDate from '../../hooks/useDate'
import useDisclosure from '../../hooks/useDisclosure'

import arrowBack from '../../assets/icons/icons-arrow-back.svg'
import info from '../../assets/icons/icon-info-circle.svg'
import location from '../../assets/icons/icon-location.svg'
import Trash from '../../assets/icons/icons-trash.svg'
import plus from '../../assets/icons/icons-plus-yellow.svg'
import timeIcon from '../../assets/icons/icon-time.svg'
import alert from '../../assets/icons/icon-error-circle.svg'
import calendar from '../../assets/icons/icon-calendar.svg'
import editPencil from '../../assets/icons/icon-edit-pencil.svg'
import storeIcon from '../../assets/icons/icon-noInfo-comerce.svg'
import ecoFriendlyIcon from '../../assets/icons/icons-eco-friend.svg'

import routes from '../../router/routes'
import { labels, onlineConfig } from '../../labels'
import './StoreConfigurationScreen.css'
import { FeatureContext } from '../../context/useFeatureFlagContext'

interface Day {
    dayLetter: string
    day: string
    setDaySelected: () => void
    isSelected: boolean
    dateDesde: string
    dateHasta: string
    setDateDesde: (value: string) => void
    setDateHasta: (value: string) => void
}
interface DayReception {
    dayLetter: string
    day: string
    setDaySelected: () => void
    isSelected: boolean
    dateDesde: string
    dateHasta: string
}

export default function StoreConfigurationScreen() {
    const { features } = useContext(FeatureContext)
    const navigation = useNavigate()
    const state = useLocation()
    const ref = useRef<HTMLInputElement>(null)
    const { open, handleClose, handleOpen } = useDisclosure()
    const [commerceSchedule, setCommerceSchedule] = useState<boolean>(false)
    const currentUser = localStorage.getItem('currentUser')
    const user = currentUser && JSON.parse(currentUser)
    const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal
    const internalCommerceID = user.sEstablecimiento + user.cLocal[0].sLocal
    const [regionSelected, setRegionSelected] = useState<RegionType>()
    const [comuneSelected, setComuneSelected] = useState<
        ComuneType | undefined
    >()
    const [regionID, setRegionID] = useState<string>('')
    const [logo, setLogo] = useState<string>('')
    const [storeCategory, setStoreCategory] = useState<string>('')
    const [storeDescription, setStoreDescription] = useState<string>('')
    const [descriptionDisabled, setDescriptionDisabled] =
        useState<boolean>(true)
    const [sPhoneNumber, setSPhoneNumber] = useState<string>('')
    const [phoneDisabled, setPhoneDisabled] = useState<boolean>(true)
    const [hasOnlineCommerce, setHasOnlineCommerce] = useState<boolean>(false)
    const [urlAmimarket, setUrlAmimarket] = useState<string>('')
    const [allDayDel, setAllDayDel] = useState<boolean>(false)
    const [emailDisabled, setEmailDisabled] = useState<boolean>(true)
    const [sEmailContacto, setSEmailContacto] = useState<string>(
        user?.sEmailContacto ? user?.sEmailContacto : ''
    )
    const [emailError, setEmailError] = useState<string>('')
    const [hasWithdrawal, setHasWithdrawal] = useState<boolean>(false)
    const [hasDelivery, setHasDelivery] = useState<boolean>(false)
    const [ammount, setAmmount] = useState<string>('')
    const [minAmmount, setMinAmount] = useState<string>('')
    const [image, setImage] = useState<File | null>(null)
    const [base64Img, setBase64Img] = useState<ArrayBuffer | string | null>()

    const [showRegionModal, setshowRegionModal] = useState<boolean>(false)
    const [selectedList, setSelectedList] = useState<RegionType[]>([])
    const [comunaList, setComunaList] = useState([] as any)
    const [hasConsumerStore, setHasConsumerStore] = useState<boolean>(false)
    const [lunAttentionHour, setLunAttentionHour] = useState<boolean>(false)
    const [lunDesde, setLunDesde] = useState<string>('')
    const [lunHasta, setLunHasta] = useState<string>('')
    const [marAttentionHour, setMarAttentionHour] = useState<boolean>(false)
    const [marDesde, setMarDesde] = useState<string>('')
    const [marHasta, setMarHasta] = useState<string>('')
    const [mierAttentionHour, setMierAttentionHour] = useState<boolean>(false)
    const [mierDesde, setMierDesde] = useState<string>('')
    const [mierHasta, setMierHasta] = useState<string>('')
    const [jueAttentionHour, setJueAttentionHour] = useState<boolean>(false)
    const [jueDesde, setJueDesde] = useState<string>('')
    const [jueHasta, setJueHasta] = useState<string>('')
    const [vieAttentionHour, setVieAttentionHour] = useState<boolean>(false)
    const [vierDesde, setVierDesde] = useState<string>('')
    const [vierHasta, setVierHasta] = useState<string>('')
    const [sabAttentionHour, setSabAttentionHour] = useState<boolean>(false)
    const [sabDesde, setSabDesde] = useState<string>('')
    const [sabHasta, setSabHasta] = useState<string>('')
    const [domAttentionHour, setDomAttentionHour] = useState<boolean>(false)
    const [domDesde, setDomDesde] = useState<string>('')
    const [domHasta, setDomHasta] = useState<string>('')
    const [time, setTime] = useState<string>('')
    const [lunReceptionHour, setLunReceptionHour] = useState<boolean>(false)
    const [receptionDesde, setReceptionDesde] = useState<string>('')
    const [receptionHasta, setReceptionHasta] = useState<string>('')
    const [marReceptionHour, setMarReceptionHour] = useState<boolean>(false)
    const [mierReceptionHour, setMierReceptionHour] = useState<boolean>(false)
    const [jueReceptionHour, setJueReceptionHour] = useState<boolean>(false)
    const [vieReceptionHour, setVieReceptionHour] = useState<boolean>(false)
    const [sabReceptionHour, setSabReceptionHour] = useState<boolean>(false)
    const [domReceptionHour, setDomReceptionHour] = useState<boolean>(false)
    const [modalContentType, setModalContentType] =
        React.useState<VariantsType>(null)
    const [updateStore, setUpdateStore] = useState<boolean>(false)
    const [errorUpdateStore, setErrorUpdateStore] = useState<boolean>(false)
    const [deactive, setDeactive] = useState<boolean>(false)
    const [hasError, setHasError] = useState<boolean>(false)
    const [hasLogoError, setHasLogoError] = useState<boolean>(false)
    const [hasNoPhoneError, setHasNoPhoneError] = useState<boolean>(false)
    const [hasPhoneError, setHasPhoneError] = useState<boolean>(false)
    const [errorComerceData, setErrorComerceData] = useState<boolean>(false)
    const [hasNoComune, setHasNoComune] = useState<boolean>(false)
    const [hourInputError, setHourInputError] = useState<boolean>(false)
    const dateSelection: Day[] = [
        {
            dayLetter: onlineConfig.daySelectL,
            day: onlineConfig.daySelectMonday,
            setDaySelected: () => setLunAttentionHour(!lunAttentionHour),
            isSelected: lunAttentionHour,
            dateDesde: lunDesde,
            dateHasta: lunHasta,
            setDateDesde: (value: string) => setLunDesde(value),
            setDateHasta: (value: string) => setLunHasta(value),
        },
        {
            dayLetter: onlineConfig.daySelectM,
            day: onlineConfig.daySelectTuesday,
            setDaySelected: () => setMarAttentionHour(!marAttentionHour),
            isSelected: marAttentionHour,
            dateDesde: marDesde,
            dateHasta: marHasta,
            setDateDesde: (value: string) => setMarDesde(value),
            setDateHasta: (value: string) => setMarHasta(value),
        },
        {
            dayLetter: onlineConfig.daySelectM,
            day: onlineConfig.daySelectWednesday,
            setDaySelected: () => setMierAttentionHour(!mierAttentionHour),
            isSelected: mierAttentionHour,
            dateDesde: mierDesde,
            dateHasta: mierHasta,
            setDateDesde: (value: string) => setMierDesde(value),
            setDateHasta: (value: string) => setMierHasta(value),
        },
        {
            dayLetter: onlineConfig.daySelectJ,
            day: onlineConfig.daySelectThursday,
            setDaySelected: () => setJueAttentionHour(!jueAttentionHour),
            isSelected: jueAttentionHour,
            dateDesde: jueDesde,
            dateHasta: jueHasta,
            setDateDesde: (value: string) => setJueDesde(value),
            setDateHasta: (value: string) => setJueHasta(value),
        },
        {
            dayLetter: onlineConfig.daySelectV,
            day: onlineConfig.dayselectFriday,
            setDaySelected: () => setVieAttentionHour(!vieAttentionHour),
            isSelected: vieAttentionHour,
            dateDesde: vierDesde,
            dateHasta: vierHasta,
            setDateDesde: (value: string) => setVierDesde(value),
            setDateHasta: (value: string) => setVierHasta(value),
        },
        {
            dayLetter: onlineConfig.daySelectS,
            day: onlineConfig.daySelectSaturday,
            setDaySelected: () => setSabAttentionHour(!sabAttentionHour),
            isSelected: sabAttentionHour,
            dateDesde: sabDesde,
            dateHasta: sabHasta,
            setDateDesde: (value: string) => setSabDesde(value),
            setDateHasta: (value: string) => setSabHasta(value),
        },
        {
            dayLetter: onlineConfig.daySelectD,
            day: onlineConfig.daySelectSunday,
            setDaySelected: () => setDomAttentionHour(!domAttentionHour),
            isSelected: domAttentionHour,
            dateDesde: domDesde,
            dateHasta: domHasta,
            setDateDesde: (value: string) => setDomDesde(value),
            setDateHasta: (value: string) => setDomHasta(value),
        },
    ]
    const dateReceptionSelection: DayReception[] = [
        {
            dayLetter: 'L',
            day: 'Lunes',
            setDaySelected: () => setLunReceptionHour(!lunReceptionHour),
            isSelected: lunReceptionHour,
            dateDesde: receptionDesde,
            dateHasta: receptionHasta,
        },
        {
            dayLetter: 'M',
            day: 'Martes',
            setDaySelected: () => setMarReceptionHour(!marReceptionHour),
            isSelected: marReceptionHour,
            dateDesde: receptionDesde,
            dateHasta: receptionHasta,
        },
        {
            dayLetter: 'M',
            day: 'Miércoles',
            setDaySelected: () => setMierReceptionHour(!mierReceptionHour),
            isSelected: mierReceptionHour,
            dateDesde: receptionDesde,
            dateHasta: receptionHasta,
        },
        {
            dayLetter: 'J',
            day: 'Jueves',
            setDaySelected: () => setJueReceptionHour(!jueReceptionHour),
            isSelected: jueReceptionHour,
            dateDesde: receptionDesde,
            dateHasta: receptionHasta,
        },
        {
            dayLetter: 'V',
            day: 'Viernes',
            setDaySelected: () => setVieReceptionHour(!vieReceptionHour),
            isSelected: vieReceptionHour,
            dateDesde: receptionDesde,
            dateHasta: receptionHasta,
        },
        {
            dayLetter: 'S',
            day: 'Sábado',
            setDaySelected: () => setSabReceptionHour(!sabReceptionHour),
            isSelected: sabReceptionHour,
            dateDesde: receptionDesde,
            dateHasta: receptionHasta,
        },
        {
            dayLetter: 'D',
            day: 'Domingo',
            setDaySelected: () => setDomReceptionHour(!domReceptionHour),
            isSelected: domReceptionHour,
            dateDesde: receptionDesde,
            dateHasta: receptionHasta,
        },
    ]
    const dateConfig = (title: string, dateData: Day[]) => {
        return (
            <div>
                <Text label={title} variant="Subtitle2 grey900" />

                <div className="daysContainer-sConfig">
                    {dateData.map((day: Day) => (
                        <div
                            key={day.day}
                            onClick={day.setDaySelected}
                            className={
                                day.isSelected
                                    ? 'dayContainerBlue-sConfig'
                                    : 'dayContainerGrey-sConfig'
                            }
                        >
                            <Text label={day.dayLetter} variant="Subtitle2" />
                        </div>
                    ))}
                </div>
                {dateData.map(
                    (day: Day) =>
                        day.isSelected && (
                            <div key={day.day}>
                                <Text label={day.day} variant="Small-3" />
                                <div className="commonContainer-sConfig spaceBetween-sConfig">
                                    <div className="commonContainer-sConfig centered-sConfig spaceBetween-sConfig commonWidth-sConfig">
                                        <Text
                                            label={labels.sConfigDesde}
                                            variant="Small-2 grey900 "
                                        />
                                        <input
                                            placeholder={''}
                                            value={day.dateDesde}
                                            onChange={(
                                                value: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                day.setDateDesde(
                                                    value.target.value
                                                )
                                            }
                                            className={
                                                'Body1-16 grey900 timeInputContainer-sConfig'
                                            }
                                            type="time"
                                        />
                                    </div>

                                    <div className="commonContainer-sConfig centered-sConfig spaceBetween-sConfig commonWidth-sConfig">
                                        <Text
                                            label={labels.sConfigHasta}
                                            variant="Small-2 grey900"
                                        />
                                        <input
                                            placeholder={''}
                                            type="time"
                                            value={day.dateHasta}
                                            onChange={(
                                                value: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                day.setDateHasta(
                                                    value.target.value
                                                )
                                            }
                                            className={
                                                'Body1-16 grey900 timeInputContainer-sConfig'
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="commonContainer-sConfig spaceBetween-sConfig ">
                                    <div className="divider-sConfig" />
                                    <div className="divider-sConfig" />
                                </div>
                            </div>
                        )
                )}
            </div>
        )
    }
    const dateReception = (title: string, dateData: DayReception[]) => {
        return (
            <>
                <div>
                    <Text label={title} variant="Subtitle2 grey900" />

                    <div className="daysContainer-sConfig">
                        {dateData.map((day: DayReception) => (
                            <div
                                key={day.day}
                                onClick={day.setDaySelected}
                                className={
                                    day.isSelected
                                        ? 'dayContainerBlue-sConfig'
                                        : 'dayContainerGrey-sConfig'
                                }
                            >
                                <Text
                                    label={day.dayLetter}
                                    variant="Subtitle2"
                                />
                            </div>
                        ))}
                    </div>
                    {lunReceptionHour ||
                    marReceptionHour ||
                    mierReceptionHour ||
                    jueReceptionHour ||
                    vieReceptionHour ||
                    sabReceptionHour ||
                    domReceptionHour ? (
                        <>
                            <div>
                                <div className="commonContainer-sConfig spaceBetween-sConfig">
                                    <div className="commonContainer-sConfig centered-sConfig spaceBetween-sConfig commonWidth-sConfig">
                                        <Text
                                            label={labels.sConfigDesde}
                                            variant="Small-2 grey900 "
                                        />
                                        <input
                                            placeholder={''}
                                            type="time"
                                            value={receptionDesde}
                                            onChange={(
                                                value: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                setReceptionDesde(
                                                    value.target.value
                                                )
                                            }
                                            className={
                                                'Body1-16 grey900 timeInputContainer-sConfig'
                                            }
                                        />
                                    </div>

                                    <div className="commonContainer-sConfig centered-sConfig spaceBetween-sConfig commonWidth-sConfig">
                                        <Text
                                            label={labels.sConfigHasta}
                                            variant="Small-2 grey900"
                                        />
                                        <input
                                            placeholder={''}
                                            type="time"
                                            value={receptionHasta}
                                            onChange={(
                                                value: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                setReceptionHasta(
                                                    value.target.value
                                                )
                                            }
                                            className={
                                                'Body1-16 grey900 timeInputContainer-sConfig'
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="commonContainer-sConfig spaceBetween-sConfig ">
                                    <div className="divider-sConfig" />
                                    <div className="divider-sConfig" />
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </>
        )
    }
    const renderDay = (daysSelection: DaySelectionType[]) => {
        const daysSelected =
            daysSelection.filter((ds: DaySelectionType) => ds.isSelected) || []
        let days = ''
        daysSelected.forEach((dss: DaySelectionType, index: number) => {
            days += dss.day
            if (index !== daysSelected.length - 1) days += ', '
        })

        return (
            <Text
                label={days}
                variant="Body2-14 grey900 communeTextAlign-sConfig"
            />
        )
    }

    const returnLocationInfoCard = () => {
        return selectedList.map((reg) => (
            <div className="regionSelectedContainer-sConfig " key={reg.regID}>
                <div className="regionTitleStyle-sConfig ">
                    <img
                        src={Trash}
                        className="trashIconContainer-sConfig"
                        onClick={() => {
                            const filteredList = selectedList.filter(
                                (sl: RegionType) => sl.ID !== reg.ID
                            )
                            setSelectedList(filteredList)
                            const comunaListTmp = comunaList.filter(
                                (c: any) => c.regionID !== reg.ID
                            )
                            setComunaList(comunaListTmp)
                        }}
                    />
                    <Text
                        label={
                            reg.NOMBRE ? reg.NOMBRE.toLowerCase() : reg.NOMBRE
                        }
                        variant="ButtonLg-16"
                    />
                </div>
                <div key={reg.regID} className="containerCommune-sConfig ">
                    {reg.comunas.map((co: ComuneType) =>
                        co.comunaList.map((com: CommuneTypeDTO) => {
                            return (
                                !com.isDeleted && (
                                    <div
                                        key={com.ID}
                                        className="communesContainer"
                                    >
                                        <div>
                                            <div className="commonContainer-sConfig spaceBetween-sConfig">
                                                <Text
                                                    label={com.NOMBRE.toLowerCase()}
                                                    variant="Subtitle2"
                                                />
                                                <img
                                                    src={editPencil}
                                                    className="trashIconContainer-sConfig"
                                                    onClick={() =>
                                                        handleOpenEditComuneModal(
                                                            reg,
                                                            co
                                                        )
                                                    }
                                                />
                                            </div>
                                            <Text
                                                label={labels.sConfigCost}
                                                variant="Subtitle2 grey900"
                                            />
                                            <Text
                                                label={`$ ${co.costValue}`}
                                                variant="Body2-14 grey900 communeTextAlign-sConfig"
                                            />
                                            {co.specificDays && (
                                                <div>
                                                    <Text
                                                        label={
                                                            labels.sConfigDeliveryDays
                                                        }
                                                        variant="Subtitle2 grey900"
                                                    />

                                                    {renderDay(
                                                        co.daysSelection
                                                    )}

                                                    <Text
                                                        label={
                                                            labels.sConfigReceptionDays
                                                        }
                                                        variant="Subtitle2 grey900"
                                                    />
                                                    <Text
                                                        label={
                                                            co.pickerValueReception
                                                        }
                                                        variant="Body2-14 grey900 communeTextAlign-sConfig"
                                                    />

                                                    <Text
                                                        label={
                                                            labels.sConfigMaxHour
                                                        }
                                                        variant="Subtitle2 grey900"
                                                    />
                                                    <Text
                                                        label={
                                                            Number(
                                                                co.deliveryTime
                                                            ) >= 12
                                                                ? co.deliveryTime +
                                                                  ' PM'
                                                                : co.deliveryTime +
                                                                  ' AM'
                                                        }
                                                        variant="Body2-14 grey900 communeTextAlign-sConfig"
                                                    />
                                                </div>
                                            )}

                                            {co.withinTheSameDayPickerValue && (
                                                <>
                                                    <Text
                                                        label={
                                                            labels.sConfigAproxDeliverTime
                                                        }
                                                        variant="Subtitle2 grey900"
                                                    />
                                                    <Text
                                                        label={
                                                            co.withinTheSameDayPickerValue
                                                        }
                                                        variant="Body2-14 grey900 communeTextAlign-sConfig"
                                                    />
                                                </>
                                            )}

                                            {co.optionSelected?.title && (
                                                <>
                                                    <Text
                                                        label={
                                                            labels.sConfigTimeSlog
                                                        }
                                                        variant="Subtitle2 grey900"
                                                    />
                                                    <Text
                                                        label={
                                                            co.optionSelected
                                                                ?.title
                                                        }
                                                        variant="Body2-14 grey900 communeTextAlign-sConfig"
                                                    />
                                                    <Text
                                                        label={
                                                            labels.sConfigMaxHour
                                                        }
                                                        variant="Subtitle2 grey900"
                                                    />
                                                    <Text
                                                        label={
                                                            Number(
                                                                co.deliveryTime
                                                            ) >= 12
                                                                ? co.deliveryTime +
                                                                  ' PM'
                                                                : co.deliveryTime +
                                                                  ' AM'
                                                        }
                                                        variant="Body2-14 grey900 communeTextAlign-sConfig"
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div className="lineCommuneStyle-sConfig" />
                                    </div>
                                )
                            )
                        })
                    )}
                </div>

                <div className="comunneButtonContainer">
                    <button
                        type="button"
                        className={'comuneButton-sConfig'}
                        onClick={() => handleOpenComuneModal(reg)}
                    >
                        <img src={location} className="iconContainer-sConfig" />
                        <Text
                            label={labels.sConfigAddComune}
                            variant="Subtitle2 comuneButtonTextStyle-sConfig"
                        />
                    </button>
                </div>
            </div>
        ))
    }

    const getECommerce = async () => {
        try {
            await getCommerceInfo(commerceId).then((resp) => {
                if (resp.minimumAmount) {
                    const minammount = resp.minimumAmount.toString()
                    setAmmount(minammount)
                }
                if (resp.minimalAmountFreeDelivery) {
                    const minAmountDelivery =
                        resp.minimalAmountFreeDelivery.toString()
                    setMinAmount(minAmountDelivery)
                }
                setStoreCategory(resp.classification)

                const logoRefresh = `${resp.logo}?time=${new Date()}`
                setLogo(logoRefresh)

                setStoreDescription(resp.description)

                setSPhoneNumber('+' + resp.phone)
                setHasConsumerStore(resp.hasConsumeStore)
                setHasWithdrawal(resp.hasWithdrawal)
                setHasDelivery(resp.hasDelivery)
                setUrlAmimarket(resp.urlAmimarket)
                setAllDayDel(resp.allDay)
                setSEmailContacto(resp.email)
                setDeactive(resp.deactivateStoreTemp)
                setHasOnlineCommerce(resp.hasEcommerce)
                setCommerceSchedule(
                    state?.state?.commerceScheduled || resp.scheduledSale
                )
                if (resp.regionsDeliveries)
                    mapRegionsDeliveries(resp.regionsDeliveries)

                setLunAttentionHour(resp.attentionHours.lunes)
                setLunDesde(
                    resp.attentionHours.lunesInicio === ''
                        ? '00:00'
                        : resp.attentionHours.lunesInicio
                )
                setLunHasta(
                    resp.attentionHours.lunesCierre === ''
                        ? '00:00'
                        : resp.attentionHours.lunesCierre
                )
                setMarAttentionHour(resp.attentionHours.martes)
                setMarDesde(
                    resp.attentionHours.martesInicio === ''
                        ? '00:00'
                        : resp.attentionHours.martesInicio
                )
                setMarHasta(
                    resp.attentionHours.martesCierre === ''
                        ? '00:00'
                        : resp.attentionHours.martesCierre
                )
                setMierAttentionHour(resp.attentionHours.miercoles)
                setMierDesde(
                    resp.attentionHours.miercolesInicio === ''
                        ? '00:00'
                        : resp.attentionHours.miercolesInicio
                )
                setMierHasta(
                    resp.attentionHours.miercolesCierre === ''
                        ? '00:00'
                        : resp.attentionHours.miercolesCierre
                )
                setJueAttentionHour(resp.attentionHours.jueves)
                setJueDesde(
                    resp.attentionHours.juevesInicio === ''
                        ? '00:00'
                        : resp.attentionHours.juevesInicio
                )
                setJueHasta(
                    resp.attentionHours.juevesCierre === ''
                        ? '00:00'
                        : resp.attentionHours.juevesCierre
                )
                setVieAttentionHour(resp.attentionHours.viernes)
                setVierDesde(
                    resp.attentionHours.viernesInicio === ''
                        ? '00:00'
                        : resp.attentionHours.viernesInicio
                )
                setVierHasta(
                    resp.attentionHours.viernesCierre === ''
                        ? '00:00'
                        : resp.attentionHours.viernesCierre
                )
                setSabAttentionHour(resp.attentionHours.sabado)
                setSabDesde(
                    resp.attentionHours.sabadoInicio === ''
                        ? '00:00'
                        : resp.attentionHours.sabadoInicio
                )
                setSabHasta(
                    resp.attentionHours.sabadoCierre === ''
                        ? '00:00'
                        : resp.attentionHours.sabadoCierre
                )
                setDomAttentionHour(resp.attentionHours.domingo)
                setDomDesde(
                    resp.attentionHours.domingoInicio === ''
                        ? '00:00'
                        : resp.attentionHours.domingoInicio
                )
                setDomHasta(
                    resp.attentionHours.domingoCierre === ''
                        ? '00:00'
                        : resp.attentionHours.domingoCierre
                )

                if (resp.receptionHours) {
                    setLunReceptionHour(resp.receptionHours.lunes)
                    setMarReceptionHour(resp.receptionHours.martes)
                    setMierReceptionHour(resp.receptionHours.miercoles)
                    setJueReceptionHour(resp.receptionHours.jueves)
                    setVieReceptionHour(resp.receptionHours.viernes)
                    setSabReceptionHour(resp.receptionHours.sabado)
                    setDomReceptionHour(resp.receptionHours.domingo)

                    let tmpReceptionDesde = ''
                    let tmpReceptionHasta = ''
                    if (resp.receptionHours.lunesInicio) {
                        tmpReceptionDesde = resp.receptionHours.lunesInicio
                        tmpReceptionHasta = resp.receptionHours.lunesCierre
                    } else if (resp.receptionHours.martesInicio) {
                        tmpReceptionDesde = resp.receptionHours.martesInicio
                        tmpReceptionHasta = resp.receptionHours.martesCierre
                    } else if (resp.receptionHours.miercolesInicio) {
                        tmpReceptionDesde = resp.receptionHours.miercolesInicio
                        tmpReceptionHasta = resp.receptionHours.miercolesCierre
                    } else if (resp.receptionHours.juevesInicio) {
                        tmpReceptionDesde = resp.receptionHours.juevesInicio
                        tmpReceptionHasta = resp.receptionHours.juevesCierre
                    } else if (resp.receptionHours.viernesInicio) {
                        tmpReceptionDesde = resp.receptionHours.viernesInicio
                        tmpReceptionHasta = resp.receptionHours.viernesCierre
                    } else if (resp.receptionHours.sabadoInicio) {
                        tmpReceptionDesde = resp.receptionHours.sabadoInicio
                        tmpReceptionHasta = resp.receptionHours.sabadoCierre
                    } else if (resp.receptionHours.domingoInicio) {
                        tmpReceptionDesde = resp.receptionHours.domingoInicio
                        tmpReceptionHasta = resp.receptionHours.domingoCierre
                    }
                    setReceptionDesde(tmpReceptionDesde)
                    setReceptionHasta(tmpReceptionHasta)
                }
            })
        } catch (err) {
            setErrorComerceData(true)
            modalOpenScrollUp()
        }
    }
    const getTimeSlotOption = (hourRange: any) => {
        switch (hourRange) {
            case 24:
                return {
                    title: 'Máximo 24 horas (Entrega al día siguiente)',
                    setOption: 0,
                    hourRange: 24,
                }
            case 48:
                return {
                    title: 'Máximo 48 horas (Entrega en 2 días\ndespués de la compra)',
                    setOption: 1,
                    hourRange: 48,
                }
            case 72:
                return {
                    title: 'Máximo 72 horas (Entrega en 3 días\ndespués de la compra)',
                    setOption: 2,
                    hourRange: 72,
                }
            case 96:
                return {
                    title: 'Máximo 96 horas (Entrega en 4 días\ndespués de la compra)',
                    setOption: 3,
                    hourRange: 96,
                }
            case 120:
                return {
                    title: 'Máximo 120 horas (Entrega en 5 días\ndespués de la compra)',
                    setOption: 4,
                    hourRange: 120,
                }
        }
    }

    const getPickerValueReception = (dayReception: any) => {
        switch (dayReception) {
            case 1:
                return '1 día antes'
            case 2:
                return '2 días antes'
            case 0:
                return 'Mismo día'
            default:
                return null
        }
    }
    const mapRegionsDeliveries = (data: RegionType[]) => {
        const dataTmp = data
        const tempSelectedList: any = []
        const tempComunaList: any = []
        const tmp = dataTmp.map((item: any) => {
            tempSelectedList.push({
                NOMBRE: item.name,
                ID: item.name,
                regID: item.regionID,
                isDeleted: false,
                comunas: [],
            })
            const comunesTmp = item.communes.map((co: any) => {
                const timeSlotOption = getTimeSlotOption(co.hourRange)
                const pickerValueReception = getPickerValueReception(
                    co.dayReception
                )
                return {
                    comunaList: [
                        {
                            NOMBRE: co.communeName,
                            ID: co.communeID,
                            ID_REGION: item.name,
                            regID: item.regionID,
                            isDeleted: false,
                        },
                    ],
                    pickerValue: co.communeReference,
                    deliveryTime: co.hourLimit,
                    pickerValueReception: pickerValueReception,
                    costValue: co.communeDeliveryPrice,
                    optionSelected: timeSlotOption,
                    withinTheSameDayPickerValue: co?.typeDelivery
                        ? null
                        : co.communeDeliveryTime,
                    moreThanTwentyFourHr:
                        co.typeDelivery === 1 || co.typeDelivery === 2,
                    specificDays: co.typeDelivery === 2,
                    intheDayDelivery: co.sameDayDelivery,
                    selectedReceptionDays: co.dayReception,
                    daysSelection: [
                        {
                            dayLetter: 'L',
                            day: 'Lunes',
                            setDaySelected: 'daySelected()',
                            isSelected: co.monday,
                        },
                        {
                            dayLetter: 'M',
                            day: 'Martes',
                            setDaySelected: 'daySelected()',
                            isSelected: co.tuesday,
                        },
                        {
                            dayLetter: 'M',
                            day: 'Miércoles',
                            setDaySelected: 'daySelected()',
                            isSelected: co.wednesday,
                        },
                        {
                            dayLetter: 'J',
                            day: 'Jueves',
                            setDaySelected: 'daySelected()',
                            isSelected: co.thursday,
                        },
                        {
                            dayLetter: 'V',
                            day: 'Viernes',
                            setDaySelected: 'daySelected()',
                            isSelected: co.friday,
                        },
                        {
                            dayLetter: 'S',
                            day: 'Sábado',
                            setDaySelected: 'daySelected()',
                            isSelected: co.saturday,
                        },
                        {
                            dayLetter: 'D',
                            day: 'Domingo',
                            setDaySelected: 'daySelected()',
                            isSelected: co.sunday,
                        },
                    ],
                }
            })
            tempComunaList.push({
                ID: item.name,
                NOMBRE: item.name,
                isDeleted: false,
                regID: item.regionID,
                comunas: comunesTmp,
            })
        })
        setSelectedList(tempComunaList)
    }
    const handleDeactivateStore = () => {
        const dateTmp = moment(selectedDate).format('YYYY-MM-DD')
        const data = {
            openDate: dateTmp,
            openDateHour: time,
        }
        try {
            closeStore(internalCommerceID, data).then((resp: any) => {
                if (resp[1] === 200) {
                    setModalContentType(VARIANTS.SUCCESS)
                    setDeactive(true)
                } else {
                    setModalContentType(VARIANTS.ERROR)
                }
            })
        } catch (err) {
            setModalContentType(VARIANTS.ERROR)
        }
    }
    const handleNoHourDeactivateStore = () => {
        const data = {
            openDate: null,
            openDateHour: time,
        }
        try {
            closeStore(internalCommerceID, data).then((resp: any) => {
                if (resp[1] === 200) {
                    setModalContentType(VARIANTS.SUCCESS)
                    setDeactive(true)
                } else {
                    setModalContentType(VARIANTS.ERROR)
                }
            })
        } catch (err) {
            setModalContentType(VARIANTS.ERROR)
        }
    }
    const handleCloseRegionModal = () => {
        setshowRegionModal(false)
    }

    const handleCloseErrorModal = () => {
        setModalContentType(null)
        enableScroll()
    }
    const handleFile = (e: FormEvent<HTMLInputElement>) => {
        const file = e?.currentTarget?.files?.[0] || null
        setImage(file)
        const reader = new FileReader()
        reader.readAsDataURL(file as Blob)
        reader.onload = () => {
            if (file && file.type.includes('image/png')) {
                const baseURL = reader.result
                const tmp = baseURL?.slice(22)
                setBase64Img(tmp)
            } else {
                const baseURL = reader.result
                const tmp = baseURL?.slice(23)
                setBase64Img(tmp)
            }
        }
        if (file) {
            const objectUrl = URL.createObjectURL(file)
            setLogo(objectUrl)
        }
    }
    const handleClick = () => {
        if (ref.current != null) {
            ref?.current?.click()
        }
    }
    const { handleDate, selectedDate } = useDate(new Date())
    const modalOpenScrollUp = () => {
        const myDiv = document.getElementById('innerContainer-SConfiguration')
        if (myDiv) {
            myDiv.scrollIntoView({ behavior: 'smooth' })
            document.body.style.overflow = 'hidden'
        }
    }
    const enableScroll = () => {
        document.body.style.overflow = 'scroll'
    }
    const handleOpenComuneModal = (region: RegionType) => {
        const tmp = region.ID
        setRegionSelected(region)
        setRegionID(tmp)
        handleOpen()
    }
    const handleOpenCalendar = () => {
        if (!deactive) {
            setModalContentType(VARIANTS.CALENDAR)
            modalOpenScrollUp()
        } else {
            setDeactive(false)
        }
    }
    const handleCloseModal = () => {
        setModalContentType(null)
        enableScroll()
    }
    const handleCloseUpdateModal = () => {
        setUpdateStore(false)
        enableScroll()
    }
    const handleCloseUpdateErrorModal = () => {
        setErrorUpdateStore(false)
        enableScroll()
    }
    const commonAlert = (
        error: boolean,
        text: string,
        handleClose: () => void
    ) => {
        return (
            <ModalComponent
                show={error}
                handleClose={handleClose}
                icon={true}
                modalStyle="alertModal-sConfig "
            >
                <Text label={text} variant="grey900 centerTextAlign-sConfig" />
            </ModalComponent>
        )
    }
    const putCommerceInformation = async (regionsDeliveries: any) => {
        const phone = sPhoneNumber.replace('+', '')
        const data = {
            nameLocal: user?.cLocal[0]?.sNombreLocal,
            classification: storeCategory,
            logo: base64Img ? base64Img : null,
            description: storeDescription,
            phone: phone,
            attentionHours: {
                lunes: lunAttentionHour,
                martes: marAttentionHour,
                miercoles: mierAttentionHour,
                jueves: jueAttentionHour,
                viernes: vieAttentionHour,
                sabado: sabAttentionHour,
                domingo: domAttentionHour,
                lunesInicio: lunDesde,
                lunesCierre: lunHasta,
                martesInicio: marDesde,
                martesCierre: marHasta,
                miercolesInicio: mierDesde,
                miercolesCierre: mierHasta,
                juevesInicio: jueDesde,
                juevesCierre: jueHasta,
                viernesInicio: vierDesde,
                viernesCierre: vierHasta,
                sabadoInicio: sabDesde,
                sabadoCierre: sabHasta,
                domingoInicio: domDesde,
                domingoCierre: domHasta,
            },
            receptionHours: {
                lunes: lunReceptionHour,
                martes: marReceptionHour,
                miercoles: mierReceptionHour,
                jueves: jueReceptionHour,
                viernes: vieReceptionHour,
                sabado: sabReceptionHour,
                domingo: domReceptionHour,
                lunesInicio: receptionDesde,
                lunesCierre: receptionHasta,
                martesInicio: receptionDesde,
                martesCierre: receptionHasta,
                miercolesInicio: receptionDesde,
                miercolesCierre: receptionHasta,
                juevesInicio: receptionDesde,
                juevesCierre: receptionHasta,
                viernesInicio: receptionDesde,
                viernesCierre: receptionHasta,
                sabadoInicio: receptionDesde,
                sabadoCierre: receptionHasta,
                domingoInicio: receptionDesde,
                domingoCierre: receptionHasta,
            },
            hasConsumeStore: hasConsumerStore,
            hasWithdrawal: hasWithdrawal,
            scheduledSale: commerceSchedule,
            hasDelivery: hasDelivery,
            email: sEmailContacto,
            hasAutomaticOrder: false,
            deactivateStoreTemp: deactive,
            minimumAmount: ammount === '' ? 0 : ammount,
            minimalAmountFreeDelivery: minAmmount === '' ? 0 : minAmmount,
            hasEcommerce: hasOnlineCommerce,
            allDay: allDayDel,
            regionsDeliveries: regionsDeliveries,
        }
        try {
            await putCommerceInfo(data, commerceId).then((resp) => {
                if (resp === 200) {
                    setUpdateStore(true)
                    modalOpenScrollUp()
                } else {
                    setErrorUpdateStore(true)
                    modalOpenScrollUp()
                }
            })
        } catch (err) {
            console.error(err)
            setErrorUpdateStore(true)
            modalOpenScrollUp()
        }
    }
    const updateInformation = () => {
        let allRegionsHasComune = true
        const testing = selectedList
        testing.forEach((element) => {
            const hasComune = element.comunas[0].comunaList.find(
                (e: any) => e.regID == element.regID
            )
            if (!hasComune) {
                allRegionsHasComune = false
            }
        })
        if (!allRegionsHasComune) {
            setHasNoComune(true)
            modalOpenScrollUp()
            return
        }

        const regionsDeliveries = selectedList.map((r: RegionType) => {
            const testComunes = r.comunas.reduce((acc, element) => {
                const communes = element.comunaList.reduce(
                    (acum, item): any => {
                        if (item.isDeleted) {
                            return acum
                        }
                        const formatted = {
                            communeID: item.ID,
                            communeName: item.NOMBRE,
                            communeDeliveryPrice: element.costValue,
                            communeDeliveryTime: element.moreThanTwentyFourHr
                                ? 'Más de 24 horas'
                                : element.withinTheSameDayPickerValue
                                ? element.withinTheSameDayPickerValue
                                : 'Más de 24 horas',
                            dayReception:
                                element.selectedReceptionDays !== ''
                                    ? element.selectedReceptionDays
                                    : null,
                            hasAllDay: false,
                            typeDelivery: element.intheDayDelivery
                                ? null
                                : element.moreThanTwentyFourHr &&
                                  !element.specificDays
                                ? 1
                                : element.specificDays
                                ? 2
                                : null,
                            hourLimit:
                                element.deliveryTime &&
                                element.deliveryTime !== ''
                                    ? element.deliveryTime
                                    : null,
                            sameDayDelivery: element.intheDayDelivery,
                            hourRange: element.optionSelected?.hourRange,
                            communeReference:
                                element.pickerValue &&
                                element.pickerValue !== ''
                                    ? element.pickerValue
                                    : null,
                            monday: !!element.daysSelection?.find(
                                (dsst) =>
                                    dsst.day === 'Lunes' && dsst.isSelected
                            ),
                            tuesday: !!element.daysSelection?.find(
                                (dsst) =>
                                    dsst.day === 'Martes' && dsst.isSelected
                            ),
                            wednesday: !!element.daysSelection?.find(
                                (dsst) =>
                                    dsst.day === 'Miércoles' && dsst.isSelected
                            ),
                            thursday: !!element.daysSelection?.find(
                                (dsst) =>
                                    dsst.day === 'Jueves' && dsst.isSelected
                            ),
                            friday: !!element.daysSelection?.find(
                                (dsst) =>
                                    dsst.day === 'Viernes' && dsst.isSelected
                            ),
                            saturday: !!element.daysSelection?.find(
                                (dsst) =>
                                    dsst.day === 'Sábado' && dsst.isSelected
                            ),
                            sunday: !!element.daysSelection?.find(
                                (dsst) =>
                                    dsst.day === 'Domingo' && dsst.isSelected
                            ),
                        }
                        return [...acum, formatted]
                    },
                    []
                )
                return [...acc, ...communes]
            }, [] as any[])

            return {
                name: r.NOMBRE,
                regionID: r.regID,
                communes: testComunes,
            }
        })
        if (!logo) {
            setHasLogoError(true)
            modalOpenScrollUp()
        } else if (!storeDescription) {
            setHasError(true)
            modalOpenScrollUp()
        } else if (!sPhoneNumber) {
            setHasNoPhoneError(true)
            modalOpenScrollUp()
        } else if (sPhoneNumber.length < 10) {
            setHasPhoneError(true)
            modalOpenScrollUp()
        } else if (
            lunDesde > lunHasta ||
            marDesde > marHasta ||
            mierDesde > mierHasta ||
            jueDesde > jueHasta ||
            vierDesde > vierHasta ||
            sabDesde > sabHasta ||
            domDesde > domHasta ||
            receptionDesde > receptionHasta
        ) {
            setHourInputError(true)
            modalOpenScrollUp()
        } else {
            putCommerceInformation(regionsDeliveries)
        }
    }

    const handleOpenEditComuneModal = (
        region: RegionType,
        comune: ComuneType
    ) => {
        const tmp = region.ID
        setRegionSelected(region)
        setComuneSelected(comune)
        setRegionID(tmp)
        handleOpen()
    }
    const handleCloseComuneModal = () => {
        setRegionSelected(undefined)
        setComuneSelected(undefined)
        handleClose()
    }
    const addComuneToRegion = (comune: ComuneType[]) => {
        setSelectedList(setComunesToRegion(selectedList, comune))
    }
    const addRegion = (region: RegionType) => {
        setSelectedList((prevState) => {
            const tmp = prevState
            const foundedIndex = tmp.find(
                (element) => element.regID === region.regID
            )

            if (foundedIndex) {
                return [...prevState]
            } else {
                return [...prevState, { ...region, comunas: [] }]
            }
        })
    }
    const handleOnChangePhone = (value: ChangeEvent<HTMLInputElement>) => {
        if (value.currentTarget.value.length < 3) {
            setSPhoneNumber('+56')
            return
        }
        const phoneTmp = '+56' + value.currentTarget.value.replace('+56', '')
        setSPhoneNumber(phoneTmp)
    }
    const navigateBack = () => {
        navigation(`/${routes.configurations}`)
    }
    const start_and_end = (str: string) => {
        if (str.length > 35) {
            return (
                str.substr(0, 15) +
                '...' +
                str.substr(str.length - 20, str.length)
            )
        }
        return str
    }
    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email)
    }
    const handleChangeEmail = (value: any) => {
        if (!isValidEmail(value.currentTarget.value)) {
            setEmailError('Email is invalid')
        } else {
            setEmailError('')
        }
        setSEmailContacto(value.currentTarget.value)
    }
    const handleDescriptionInput = (value: any) => {
        setStoreDescription(value.target.value)
    }
    const handleNavigateToOnlineStorOB = () => {
        navigation(`/${routes.onlineStoreInformationScreen}`)
    }
    useEffect(() => {
        if (commerceId) {
            getECommerce()
        }
    }, [])

    return (
        <div className="background-sConfig">
            <div>
                <div
                    className="innerContainer-sConfig"
                    id="innerContainer-SConfiguration"
                >
                    <div className="commonContainer-sConfig">
                        <img
                            src={arrowBack}
                            className="iconContainer-sConfig "
                            onClick={navigateBack}
                        />
                        <Text label={labels.sConfigTitle} variant="H5" />
                    </div>
                    <div className="storeNameContainer-sConfig">
                        <div>
                            <img src={logo} className="logo-img-sConfig" />
                            <div
                                className="plusIconContainer-sConfig"
                                onClick={handleClick}
                            >
                                <img src={plus} />
                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    ref={ref}
                                    onChange={handleFile}
                                />
                            </div>
                        </div>

                        <div className="textContainer-sConfig">
                            <Text
                                label={user?.cLocal[0]?.sNombreLocal}
                                variant="subtitle1 textStyle-sConfig "
                            />
                            <Text
                                label={storeCategory}
                                variant="Small-2 storeCategoryTextStyle-sConfig"
                            />
                            {features.drawer.config.eco_friendly && (
                                <div>
                                    <img
                                        src={ecoFriendlyIcon}
                                        style={{ height: 18, width: 18 }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="editableInfoContainer-sConfig">
                        <Text
                            label={labels.sConfigDescription}
                            variant="subtitle2 grey900"
                        />
                        <div className="lineTitleStyle-sConfig" />
                        <div
                            onClick={() => {
                                setDescriptionDisabled(!descriptionDisabled)
                            }}
                        >
                            <Text
                                label={labels.editProduct}
                                variant="Small-2 grey900 hasPointer-sConfig"
                            />
                        </div>
                    </div>
                    <textarea
                        placeholder={''}
                        value={storeDescription}
                        onChange={handleDescriptionInput}
                        className={'inputStyle-sConfig'}
                        disabled={descriptionDisabled}
                    />
                    <div className="editableInfoContainer-sConfig">
                        <Text
                            label={labels.sConfigPhone}
                            variant="subtitle2 grey900 telephoneTitleWidth-sConfig "
                        />

                        <input
                            maxLength={12}
                            placeholder={''}
                            value={sPhoneNumber}
                            onChange={handleOnChangePhone}
                            className={'inputPhoneStyle-sConfig'}
                            disabled={phoneDisabled}
                        />
                        <div className="lineTitleStyle-sConfig" />
                        <div
                            onClick={() => {
                                setPhoneDisabled(!phoneDisabled)
                            }}
                        >
                            <Text
                                label={labels.editProduct}
                                variant="Small-2 grey900 hasPointer-sConfig"
                            />
                        </div>
                    </div>
                    <div className="storeColumn-sConfig">
                        <div className="commonColumnContainer-sq commonMarginLeft">
                            <Text
                                label={labels.sConfigFisicalStore}
                                variant="subtitle1 grey900 "
                            />
                            {dateConfig('Horario de atención', dateSelection)}
                            <div className="commonHeight" />

                            <SquareCheckBox
                                checkId="consumerStore-id"
                                labelClassName="Body3-12 "
                                label={'Consumo en local'}
                                subtitle="Los clientes pueden comer en mis instalaciones."
                                onClick={() =>
                                    setHasConsumerStore(!hasConsumerStore)
                                }
                                checked={hasConsumerStore}
                            />
                        </div>
                        {features.amimarket && (
                            <div className="onlineContainer-sq">
                                <Text
                                    label={labels.sConfigOnlineStore}
                                    variant="subtitle1 grey900 "
                                />
                                {!hasOnlineCommerce ? (
                                    <>
                                        <div className="noOnlineStoreContainer-sConfig">
                                            <img
                                                src={info}
                                                className="infoIcon-sConfig"
                                            />
                                            <Text
                                                label={
                                                    labels.sConfigNoOnlineStore
                                                }
                                                variant="Subtitle2 grey900"
                                            />
                                        </div>
                                        <div
                                            className="greenButton-sConfig"
                                            onClick={
                                                handleNavigateToOnlineStorOB
                                            }
                                        >
                                            <Text
                                                label={
                                                    labels.sConfigUpdateStoreButton
                                                }
                                                variant="Subtitle2"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        {!allDayDel &&
                                            dateReception(
                                                'Horarios de recepción de pedidos',
                                                dateReceptionSelection
                                            )}
                                        {/* <Text variant="body1 bold" label="Enlace de tienda online" />
                  <div>
                    <Text
                      variant="body1"
                      label={start_and_end(urlAmimarket)}
                      as="link"
                      to={urlAmimarket}
                    />
                  </div> */}
                                        <div className="commonHeight" />
                                        <SquareCheckBox
                                            checkId="reception24-7"
                                            labelClassName="Body3-12 "
                                            label={'Recepción de pedidos 24/7 '}
                                            onClick={() =>
                                                setAllDayDel(!allDayDel)
                                            }
                                            checked={allDayDel}
                                        />
                                        <div className="editableInfoContainer-sConfig ">
                                            <Text
                                                label={labels.sConfigEmail}
                                                variant="subtitle3 grey900"
                                            />
                                            <textarea
                                                placeholder={''}
                                                value={sEmailContacto}
                                                onChange={handleChangeEmail}
                                                className={
                                                    'inputEmailStyle-sConfig '
                                                }
                                                disabled={emailDisabled}
                                            />

                                            <div
                                                onClick={() => {
                                                    setEmailDisabled(
                                                        !emailDisabled
                                                    )
                                                }}
                                            >
                                                <Text
                                                    label={labels.editProduct}
                                                    variant="Small-2 grey900 hasPointer-sConfig"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {features.amimarket && hasOnlineCommerce && (
                        <div>
                            <div className="editableInfoContainer-sConfig ">
                                <Text
                                    label={labels.sConfigDeliveryType}
                                    variant="Subtitle2 grey900 commonTitleWidth-sConfig"
                                />
                                <div className="lineTitleStyle-sConfig" />
                            </div>
                            <div className="storeColumn-sConfig">
                                <div className="commonColumnContainer-sq ">
                                    <SquareCheckBox
                                        checkId="reception24-7"
                                        labelClassName="Body3-12 "
                                        label={'Retiro en local'}
                                        subtitle="Los clientes pueden retirar pedidos en mis instalaciones."
                                        onClick={() =>
                                            setHasWithdrawal(!hasWithdrawal)
                                        }
                                        checked={hasWithdrawal}
                                    />
                                    <div className="minAmountRowContainer-sConfig  spaceBetween-sConfig ">
                                        <Text
                                            label={labels.sConfigMinAmount}
                                            variant="Subtitle2 grey900"
                                        />
                                        <input
                                            placeholder={''}
                                            value={ammount}
                                            onChange={(value) =>
                                                setAmmount(value.target.value)
                                            }
                                            className={'amountInput-sConfig'}
                                        />
                                    </div>
                                </div>
                                <div className="onlineContainer-sq ">
                                    <SquareCheckBox
                                        checkId="reception24-7"
                                        labelClassName="Body3-12 "
                                        label={'Despacho a domicilio'}
                                        subtitle="Los clientes pueden recibir mis productos en su domicilio."
                                        onClick={() =>
                                            setHasDelivery(!hasDelivery)
                                        }
                                        checked={hasDelivery}
                                    />
                                    <div className="storeNameContainer-sConfig spaceBetween-sConfig minAmountContainer ">
                                        <Text
                                            label={labels.sConfigFreeDeliver}
                                            variant="Subtitle2 grey900"
                                        />
                                        <input
                                            placeholder={''}
                                            value={minAmmount}
                                            onChange={(value) =>
                                                setMinAmount(value.target.value)
                                            }
                                            className={'amountInput-sConfig'}
                                        />
                                    </div>
                                </div>
                            </div>
                            {features.amimarket && (
                                <>
                                    <div className="editableInfoContainer-sConfig">
                                        <Text
                                            label={labels.sConfigDeliverPlaces}
                                            variant="Subtitle2 grey900 deliverWidth-sConfig "
                                        />
                                        <div className="lineTitleStyle-sConfig" />
                                    </div>
                                    <button
                                        type="button"
                                        className={'regionButton-sConfig'}
                                        onClick={() => setshowRegionModal(true)}
                                    >
                                        <img
                                            src={location}
                                            className="iconContainer-sConfig"
                                        />
                                        <Text
                                            label={labels.sConfigAddRegion}
                                            variant="Subtitle2"
                                        />
                                    </button>
                                    {returnLocationInfoCard()}
                                </>
                            )}
                            {features.amimarket && (
                                <div className="storeColumn-sConfig spaceBetween-sConfig ">
                                    <div className="commonColumnContainer-sq commonTopMargin-sConfig">
                                        <div className="deactiveStore-sConfig daysContainer-sConfig">
                                            <Text
                                                label={
                                                    labels.sConfigDeactiveStore
                                                }
                                                variant="Subtitle3 grey900 comunneButtonContainer"
                                            />

                                            <SwitchComponent
                                                onClick={handleOpenCalendar}
                                                isSelected={deactive}
                                                id={'calendar-switch'}
                                            />
                                        </div>
                                    </div>
                                    <div className="programmedContainer">
                                        <Text
                                            label={`Recibe pedidos programados separados por tramos de una hora dependiendo de la hora en que se realice la compra \nEjemplo (11:00 AM - 12:00 PM, 12:00 PM - 1:00 PM)`}
                                            variant="Body1-16"
                                        />
                                        {!commerceSchedule ? (
                                            <div
                                                className="programmedContainer-sConfig"
                                                onClick={() => {
                                                    navigation(
                                                        `/${routes.scheduled}`
                                                    )
                                                }}
                                            >
                                                <img
                                                    src={calendar}
                                                    className="iconContainer-sConfig "
                                                />
                                                <Text
                                                    label={
                                                        labels.sConfigProgrammed
                                                    }
                                                    variant="Subtitle3"
                                                />
                                            </div>
                                        ) : (
                                            <div className="commonColumnContainer-sq commonTopMargin-sConfig">
                                                <div className="deactiveStore-sConfig daysContainer-sConfig">
                                                    <Text
                                                        label={
                                                            labels.sConfigProgramed2
                                                        }
                                                        variant="Subtitle3 grey900 comunneButtonContainer"
                                                    />

                                                    <SwitchComponent
                                                        onClick={() =>
                                                            setCommerceSchedule(
                                                                !commerceSchedule
                                                            )
                                                        }
                                                        isSelected={
                                                            commerceSchedule
                                                        }
                                                        id={'scheduled-switch'}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <button
                    onClick={updateInformation}
                    type="button"
                    className={'ContinueButton-sConfig commonTopMargin-sConfig'}
                >
                    <Text label={labels.saveChanges} variant="Subtitle2" />
                </button>
            </div>
            <RegionModal
                show={!!showRegionModal}
                handleClose={handleCloseRegionModal}
                onSelect={addRegion}
                accept={() => setshowRegionModal(false)}
            />
            {regionSelected && (
                <ComuneModal
                    show={open}
                    handleClose={handleCloseComuneModal}
                    onSelect={addComuneToRegion}
                    accept={handleCloseComuneModal}
                    regionSelected={regionID}
                    itemRegion={regionSelected}
                    comuneData={comuneSelected}
                />
            )}

            <ModalComponent
                show={modalContentType === VARIANTS.CALENDAR}
                handleClose={handleCloseModal}
                icon={false}
                modalStyle="container-body-calendar-sConfig "
            >
                <>
                    <div className="commonContainer-sConfig">
                        <img
                            src={arrowBack}
                            className="iconContainer-sConfig"
                            onClick={handleCloseModal}
                        />
                        <Text label={labels.sConfigDeactive} variant="H6" />
                    </div>
                    <Text
                        label={labels.sConfigDeactiveTitle}
                        variant="Subtitle2"
                    />
                    <div className="">
                        <Calendar onChange={handleDate} minDate={new Date()} />
                    </div>
                    <div className="commonContainer-sConfig editableInfoContainer-sConfig ">
                        <Text
                            label={labels.sConfigDeactiveHour}
                            variant="Subtitle2"
                        />
                        <div className="commonWidth-sConfig">
                            <div className="disableStoreTimeContainer">
                                <input
                                    placeholder={''}
                                    value={time}
                                    onChange={(
                                        value: ChangeEvent<HTMLInputElement>
                                    ) => setTime(value.target.value)}
                                    className={
                                        'Body1-16 grey900 timeInputContainer-sConfig'
                                    }
                                    type="time"
                                />
                                <img
                                    src={timeIcon}
                                    className="timeIconContainer"
                                />
                            </div>

                            <div className="divider-sConfig divider-width" />
                        </div>
                    </div>
                    <div className="noOnlineStoreContainer-sConfig">
                        <img src={alert} />
                        <Text
                            label={`Recuerda que tus clientes no podrán\ncomprar en tu tienda hasta que se vuelva a activar.`}
                            variant="Body2-14 calendarText-sConfig"
                        />
                    </div>
                    <div className="buttonsContainer-sConfig">
                        <div
                            className="redCalendarButton-sConfig"
                            onClick={handleDeactivateStore}
                        >
                            <Text
                                label={labels.sConfigButtonDeactive}
                                variant="ButtonLg-16"
                            />
                        </div>
                        <div onClick={handleNoHourDeactivateStore}>
                            <Text
                                label={labels.sConfigButtonDeactiveNoDate}
                                variant="Subtitle2 greyButtonCalendar"
                            />
                        </div>
                    </div>
                </>
            </ModalComponent>
            <ModalComponent
                show={
                    modalContentType === VARIANTS.ERROR ||
                    modalContentType === VARIANTS.SUCCESS
                }
                handleClose={handleCloseErrorModal}
                icon={false}
                modalStyle={
                    modalContentType === VARIANTS.ERROR
                        ? 'modalsmall-sConfig'
                        : 'successModalSmaill-sConfig'
                }
            >
                {modalContentType === VARIANTS.ERROR && (
                    <ErrorModal
                        text={
                            'No pudimos desactivar la tienda online. Por favor, comunícate con en el equipo amiPASS.'
                        }
                        buttonText={labels.continue}
                        whatsappButton={false}
                        closeModal={handleCloseErrorModal}
                    />
                )}
                {modalContentType === VARIANTS.SUCCESS && (
                    <SuccessModal
                        text={
                            'La tienda online se ha desactivado correctamente. '
                        }
                        buttonText={labels.accept}
                        handleOnPress={handleCloseErrorModal}
                    />
                )}
            </ModalComponent>
            <ModalComponent
                show={updateStore}
                handleClose={handleCloseUpdateModal}
                icon={true}
                modalStyle="container-updateStore"
            >
                <div className="innerUpdateStoreContainer">
                    <img src={storeIcon} className="alertIcon-sConfig" />
                    <Text
                        label={labels.sConfigStoreUpdated}
                        variant="Body1-16 grey900 centerTextAlign-sConfig "
                    />

                    <button
                        onClick={() => {
                            setUpdateStore(false)
                            enableScroll()
                            setTimeout(() => {
                                window.location.reload()
                                window.history.replaceState(
                                    { state: { commerceScheduled: null } },
                                    document.title
                                )
                            }, 300)
                        }}
                        type="button"
                        className={'save-sConfig'}
                    >
                        <Text label={labels.accept} variant="Subtitle2" />
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                show={errorUpdateStore}
                handleClose={handleCloseUpdateErrorModal}
                icon={true}
                modalStyle="container-updateStore"
            >
                <div className="innerUpdateStoreContainer">
                    <img src={storeIcon} className="alertIcon-sConfig" />
                    <Text
                        label={labels.sConfigErrorUpdate}
                        variant="Body1-16 grey900 centerTextAlign-sConfig "
                    />

                    <button
                        onClick={() => {
                            setErrorUpdateStore(false)
                            enableScroll()
                        }}
                        type="button"
                        className={'save-sConfig'}
                    >
                        <Text label={labels.accept} variant="Subtitle2" />
                    </button>
                </div>
            </ModalComponent>
            {hasLogoError &&
                commonAlert(
                    hasLogoError,
                    'Campo obligatorio, inserte Logotipo.',
                    () => {
                        setHasLogoError(false)
                        enableScroll()
                    }
                )}
            {hasNoPhoneError &&
                commonAlert(
                    hasNoPhoneError,
                    'Complete todos los campos obligatorios.',
                    () => {
                        setHasNoPhoneError(false)
                        enableScroll()
                    }
                )}
            {hasError &&
                commonAlert(
                    hasError,
                    'Complete todos los campos obligatorios.',
                    () => {
                        setHasError(false)
                        enableScroll()
                    }
                )}
            {hasPhoneError &&
                commonAlert(
                    hasPhoneError,
                    'El numero de teléfono es inválido.',
                    () => {
                        setHasPhoneError(false)
                        enableScroll()
                    }
                )}
            {errorComerceData &&
                commonAlert(
                    errorComerceData,
                    'Error al cargar datos del comercio.',
                    () => {
                        setErrorComerceData(false)
                        enableScroll()
                    }
                )}
            {hasNoComune &&
                commonAlert(
                    hasNoComune,
                    'Todas las regiones deben contener al menos una comuna',
                    () => {
                        setHasNoComune(false)
                        enableScroll()
                    }
                )}
            {hourInputError &&
                commonAlert(
                    hourInputError,
                    'Error en horario de atención, Ingrese un horario correcto',
                    () => {
                        setHourInputError(false)
                        enableScroll()
                    }
                )}
        </div>
    )
}
