import React from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import routes from "../../router/routes";
import { labels } from "../../labels";
import "./OnlineStoreActivatedScreen.css";
import cart from "../../assets/icons/icons-cart.svg";
import StepIndicator from "../../components/stepIndicator/StepIndicator";
import { stateKey } from "./configurationsUtils";
const viewStep = 5;
export default function OnlineStoreActivatedScreen() {
  const navigation = useNavigate();

  const clearStateKey = () => {
    localStorage.removeItem(stateKey);
  };
  const navigateBack = () => {
    navigation(`/${routes.termsAndConditions}`);
  };
  const navigateToTerms = () => {
    clearStateKey();
    navigation(`/${routes.storeConfigurations}`);
  };
  const navigateToCreateProducts = () => {
    clearStateKey();
    navigation(`/${routes.products}`);
  };
  return (
    <div className="background-OSA">
      <div className="innerContainer-OSA">
        <div className="commonContainer-OSA">
          <img
            src={arrowBack}
            className="iconContainer-OSA "
            onClick={navigateBack}
          />
        </div>
        <StepIndicator view={viewStep} />
        <div className="centeredCardContainer-OSA">
          <div className="cardContainer-OSA">
            <div className="innerCardContainer-OSA">
              <div className="cartIconContainer-OSA">
                <img src={cart} className="iconSize-OSA" />
              </div>

              <Text label={labels.storeActivated} variant="H4 titleStyle-OSA" />
              <Text
                label={labels.startCreatingProducts}
                variant="ButtonLg-16 subtitleStyle-OSA"
              />
            </div>
          </div>
        </div>
        <div className="greyButtonContainer-OSA">
          <button
            className={" greyButton-OSA"}
            onClick={navigateToCreateProducts}
          >
            <a className={"ButtonMd-14"}>{labels.createFirstProduct}</a>
          </button>
        </div>
      </div>

      <button className={"buttonContinue-OSA "} onClick={navigateToTerms}>
        <a className={"ButtonMd-14"}>{labels.continue}</a>
      </button>
    </div>
  );
}
