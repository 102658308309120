import React, { createContext, useReducer, useCallback } from 'react'

export const init_feature_context_value: IFeatureFlag = {
    reports: false,
    amimarket: false,
    bottomBar: {
        orders: false,
        inventory: false,
        sales: false,
    },
    drawer: {
        promotions: false,
        userBankData: false,
        chat: false,
        config: {
            eco_friendly: false,
        },
        billingPayments: false,
    },
}

export const FeatureContext = createContext({
    features: init_feature_context_value,
    updateFeature: (feature: IFeatureFlag): void => {
        console.log(feature)
    },
    resetFeature: (): void => {
        console.log
    },
})

function statusReducer(
    features: IFeatureFlag,
    action: { type: string; payload?: IFeatureFlag }
) {
    switch (action.type) {
        case 'update':
            return {
                ...features,
                ...action.payload,
            }
        case 'reset':
            return init_feature_context_value
        default:
            return features
    }
}

export function FeatureProvider({
    children,
}: {
    children: JSX.Element[] | JSX.Element
}) {
    const [features, dispatch] = useReducer(
        statusReducer,
        init_feature_context_value
    )

    const updateFeature = useCallback((feature: IFeatureFlag) => {
        dispatch({
            type: 'update',
            payload: feature,
        })
    }, [])

    const resetFeature = useCallback(() => {
        dispatch({ type: 'reset' })
    }, [])

    return (
        <FeatureContext.Provider
            value={{ features, updateFeature, resetFeature }}
        >
            {children}
        </FeatureContext.Provider>
    )
}
