import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import Text from "../text/Text";
import RejectOrderModal from "./RejectOrderModal";
import {
  getOrderDetail,
  modifyOrderStatus,
  getOrderComanda,
} from "../../services/orders";
import ModalComponent, {
  VARIANTS,
  VariantsType,
} from "../../components/modal/ModalComponent";
import SuccessModal from "../../components/modal/modalVariants/SuccessModal";
import ErrorModal from "../../components/modal/modalVariants/ErrorModal";
import { useLoadingContext } from "../../context/spinnerContext";
import "./OrderDetailModal.css";
import iconX from "../../assets/icons/icons-x.svg";
import downloadPdf from "../../assets/icons/icons-download-pdf.svg";
import approvedIcon from "../../assets/icons/icons-green-approved.svg";
import rejectedIcon from "../../assets/icons/icons-red-rejected.svg";
import deliveryAmipass from "../../assets/icons/icons-delivery-amipass.svg";
import purchase from "../../assets/icons/icons-purchase.svg";
import { labels, clevertapEvents } from "../../labels";
import { track } from "../../ClevertapWrapper";
import routes from "../../router/routes";

interface ModalProps {
  id: string;
  show: boolean;
  hasScheduledPurchase?: boolean;
  hasDeliveryUber?: boolean;
  handleClose?: () => void;
  rejectOrder?: () => void;
}

export default function OrderDetailModal({
  id,
  hasDeliveryUber,
  hasScheduledPurchase,
  handleClose,
  rejectOrder,
  show,
}: ModalProps) {
  const navigation = useNavigate();
  const loadingContext = useLoadingContext();

  const [orderId, setOrderId] = useState<number>(0);
  const [date, setDate] = useState<Date>();
  const [phone, setPhone] = useState<string>();
  const [orderStatus, setOrderStatus] = useState<number>(0);
  const [adress, setAdress] = useState<string>("");
  const [consumerName, setConsumerName] = useState<string>("");
  const [orderType, setOrderType] = useState<number>();
  const [discount, setDiscount] = useState<string>("0");
  const [deliveryAmount, setDeliveryAmount] = useState<number>(0);
  const [totalPay, setTotalPay] = useState<number>(0);

  const [orderComment, setOrderComment] = useState<string>("");
  const [orderDelivery, setOrderDelivery] = useState<
    OrderDeliveryType[] | undefined
  >([]);
  const [statusReason, setStatusReason] = useState<StatusReasonType | null>();
  const [ingredients, setIngredients] = useState<IngredientsType[]>([]);
  const [orderSchedule, setOrderSchedule] = useState<ScheduledType | null>();
  const [orderUberId, setOrderUberId] = useState<string>("");
  const [showRejectModal, setshowRejectModal] = useState<boolean>(false);

  const [deliverTime, setDeliverTime] = useState<string>("");
  const [hasBackUp, setHasBackUp] = useState<boolean>(false);
  const [voucher, setVoucher] = useState<string>("");
  const [place, setPlace] = useState<string>("");

  useEffect(() => {
    if (show) {
      getDetails();
    }
  }, [show]);
  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);

  const getDetails = async () => {
    try {
      await getOrderDetail(id).then(async (response) => {
        setOrderStatus(response.orderHeader.statusID);
        setOrderId(response.id);
        setDate(response.orderHeader.creationDate);
        setPhone(response.consumer.mobileNumber);
        setConsumerName(
          response.consumer.firstName + " " + response.consumer.lastName
        );
        setOrderType(response.orderHeader.orderTypeID);
        setDiscount(response.orderHeader.discountAmount.toString());
        setDeliveryAmount(response.orderHeader.deliveryAmount);
        setTotalPay(response.orderHeader.finalAmount);
        setAdress(response.consumer.address);
        setOrderDelivery(response.orderDelivery);
        if (response.statusReasonHeader) {
          setStatusReason(response.statusReasonHeader);
        }
        setOrderComment(response.consumer.addressComment);
        setIngredients(response.orderDetailsDTO);
        setOrderSchedule(response?.orderSchedule);
        setDeliverTime(response?.lastStatusDate);
        if (response.orderDelivery) {
          setOrderUberId(response.orderDelivery.id);
        }
        setHasBackUp(response.orderHeader.hasBackup);
        setVoucher(response.orderHeader.urlVoucher);
        setPlace(response.orderHeader.urlPlace);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const renderIngredients = () => {
    return ingredients.map((item: IngredientsType) => (
      <div key={item.id}>
        <div className={"common-text-style"}>
          <Text
            variant={"Subtitle2 common-no-margin"}
            label={item.totalItems + " x " + item.productName}
          />

          <Text
            variant={"Subtitle2 common-no-margin"}
            label={"$" + item.finalAmount}
          />
        </div>

        {item.ingredientTypes.map((i: IngType) => (
          <div key={i.id}>
            <Text
              variant={"Caption3-13 ingredient-type-style common-no-margin"}
              label={i.name}
            />

            {i.ingredients.map((j: SubIngredientsType) => {
              return (
                <div key={j.id} className="common-text-style">
                  <Text
                    variant={"Body2-14 common-no-margin"}
                    label={" · " + j.totalItems + " x " + j.name}
                  />
                  <Text
                    variant={"Body3-12 common-no-margin"}
                    label={j.price === 0 ? "" : `$${j.price}`}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    ));
  };
  const handleCloseRejectModal = () => {
    setshowRejectModal(false);
  };
  const handleOpenRejectModal = () => {
    setshowRejectModal(true);
  };

  const handleModifyOrderStatus = async (statusId: number) => {
    const data = {
      statusID: statusId,
      statusReason: null,
    };

    try {
      loadingContext?.onOpen();
      await modifyOrderStatus(id, data).then((res) => {
        loadingContext?.onClose();
        if (res === 200) {
          setModalContentType(VARIANTS.SUCCESS);
        } else {
          setModalContentType(VARIANTS.ERROR);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const onSelectProcessOrderStatus = () => {
    track(clevertapEvents.detailAcceptOrder);
    handleModifyOrderStatus(2);
  };
  const onSelectToDeliverOrderStatus = () => {
    if (orderType === 2) {
      track(clevertapEvents.detailReadyDelivery);
    } else {
      track(clevertapEvents.detailReadyPickUp);
    }
    handleModifyOrderStatus(3);
  };
  const onSelectDeliveredOrderStatus = () => {
    if (orderType === 2) {
      track(clevertapEvents.detaildelivered);
    } else {
      track(clevertapEvents.detailPickedUpByClient);
    }
    handleModifyOrderStatus(4);
  };
  const handleOnReject = () => {
    rejectOrder?.();
  };
  const showFile = (data: string) => {
    window.open(data)?.print();
  };
  const handleGetComanda = async () => {
    try {
      loadingContext?.onOpen();
      await getOrderComanda(orderId).then((resp) => {
        loadingContext?.onClose();
        showFile(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseErrorModal = () => {
    setModalContentType(null);
  };
  const handleAcceptModal = () => {
    setModalContentType(null);
    window.location.reload();
  };
  const navigateToVoucher = () => {
    navigation(`/${routes.voucherScreen}`, {
      state: {
        orderId: orderId,
        // backUp: hasBackUp,
        // voucher: voucher,
        // place: place,
      },
    });
  };
  const navigateToHasVoucher = () => {
    navigation(`/${routes.hasVoucherScreen}`, {
      state: {
        orderId: orderId,
        backUp: hasBackUp,
        voucher: voucher,
        place: place,
      },
    });
  };

  return show ? (
    <div className="backdrop-dm">
      <div className={"modal display-block-dm"}>
        <div className="modal-main-dm">
          <div className="IconContainer-dm">
            <div onClick={handleClose} className={"xIconContainer-dm"}>
              <img src={iconX} />
            </div>
          </div>
          <div className="scroll-container-dm">
            <Text
              label={labels.orderDetail}
              variant={"H4 detail-title-style"}
            />
            <div className="common-text-style">
              <Text label={"Nº: " + orderId} variant={"H5 common-no-margin"} />
              <Text
                label={Moment(date).format("DD/MM/YYYY")}
                variant={"H5 common-no-margin"}
              />
            </div>
            <div className="common-text-style">
              <Text
                label={consumerName}
                variant={"Subtitle2 common-no-margin"}
              />
              <Text
                label={Moment(date).format("HH:mm")}
                variant={"Subtitle2 common-no-margin"}
              />
            </div>
            <div className="common-text-style">
              <Text
                label={phone?.startsWith("+56") ? phone : `+569${phone}`}
                variant={"Subtitle2 common-no-margin"}
              />
              <Text
                label={Moment(date).fromNow()}
                variant={"Subtitle2 common-no-margin"}
              />
            </div>
            <div className="description-text-style">
              <Text
                label={labels.orderDetailPickUpForm}
                variant={"Subtitle2 detail-title-style"}
              />
              <div className="common-space-odm" />
              <Text
                label={
                  orderType === 2
                    ? labels.orderDetailStoreDelivery
                    : labels.orderDetailStorePickUp
                }
                variant={"Body2-14 detail-title-style"}
              />
            </div>
            {hasScheduledPurchase && (
              <div className="has-schedule-container">
                <div className="has-purchase-text-container">
                  <Text
                    label={labels.orderDetailScheduled}
                    variant={"subtitle1 common-white-text-style"}
                  />
                  <div className="common-row-style">
                    <Text
                      label={labels.orderDetailScheduledDate1}
                      variant={"Subtitle2 common-white-text-style"}
                    />
                    <div className="common-space-odm" />
                    <Text
                      label={
                        Moment(date).format("DD-MM-YYYY") +
                        " / " +
                        Moment(date).format("hh:mm")
                      }
                      variant={"Body2-14 common-white-text-style"}
                    />
                  </div>
                  <div className="common-row-style">
                    <Text
                      label={labels.orderDetailScheduledDate2}
                      variant={"Subtitle2 common-white-text-style"}
                    />
                    <div className="common-space-odm" />
                    <Text
                      label={
                        orderSchedule?.orderDate +
                        " / " +
                        orderSchedule?.orderTime
                      }
                      variant={"Body2-14 common-white-text-style"}
                    />
                  </div>
                  <div className="common-row-style">
                    <Text
                      label={labels.orderDetailScheduledDate3}
                      variant={"Subtitle2 common-white-text-style"}
                    />
                    <div className="common-space-odm" />
                    <Text
                      label={orderSchedule?.communesDeliveryTime || ""}
                      variant={"Body2-14 common-white-text-style"}
                    />
                  </div>
                </div>
                <div className="icon-background-odm">
                  <img src={purchase} />
                </div>
              </div>
            )}
            {hasDeliveryUber && (
              <div className="has-uber-container">
                <div className="text-icon-uber-container">
                  <img src={deliveryAmipass} />
                </div>
                <div className="text-icon-uber-container">
                  <Text
                    label={
                      orderUberId !== null
                        ? labels.orderDetailID + orderUberId
                        : labels.orderDetailNumber + orderId
                    }
                    variant={"Caption-Italic common-white-text-style"}
                  />
                </div>
              </div>
            )}
            <div className="description-text-style">
              <Text
                label={labels.orderDetailAddress}
                variant={"Subtitle2 detail-title-style"}
              />
              <div className="common-space-odm" />
              <Text label={adress} variant={"address detail-title-style"} />
            </div>
            {renderIngredients()}
            <div className="common-text-style">
              <Text label={labels.orderDetailTotal} variant={"H5 "} />
              <Text label={"$" + totalPay.toString()} variant={"H5"} />
            </div>
            <Text
              label={labels.orderDetailComment}
              variant={"Subtitle2 common-text-style"}
            />
            <Text
              label={
                orderComment !== null
                  ? `"${orderComment}"`
                  : `${labels.orderDetailNoComment}`
              }
              variant={"Small common-text-style comment-text-style"}
            />
            {orderStatus === 1 && (
              <div className="centered-od">
                <div className="blue-line-divider" />
                <div
                  className="download-pdf-container"
                  onClick={handleGetComanda}
                >
                  <img src={downloadPdf} />
                  <div className="common-space-odm" />
                  <Text
                    label={labels.orderDetailprint}
                    variant="subtitle1 print-text-style-odm"
                  />
                </div>
                <div className="blue-line-divider" />
              </div>
            )}
            {orderStatus === 1 && (
              <div className="button-container">
                <div className="reject-button" onClick={handleOpenRejectModal}>
                  <Text
                    label={labels.reject}
                    variant={"ButtonLg-16 common-button-text-style"}
                  />
                </div>
                <div
                  className="accept-button"
                  onClick={onSelectProcessOrderStatus}
                >
                  <Text
                    label={labels.accept}
                    variant={"ButtonLg-16 common-button-text-style"}
                  />
                </div>
              </div>
            )}
            {orderStatus === 2 && (
              <div>
                <div className="button-container">
                  <div
                    className="reject-button"
                    onClick={handleOpenRejectModal}
                  >
                    <Text
                      label={labels.reject}
                      variant={"ButtonLg-16 common-button-text-style"}
                    />
                  </div>
                  {orderType === 2 ? (
                    <>
                      {orderDelivery !== null && <div style={{ height: 25 }} />}
                      <div onClick={onSelectToDeliverOrderStatus}>
                        <Text
                          label={labels.orderComponentReadyDelivery}
                          variant={
                            "ButtonLg-16 common-margin blue-button-text-style blue-button-style"
                          }
                        />
                      </div>
                    </>
                  ) : (
                    orderType !== 2 && (
                      <>
                        {orderDelivery !== null && (
                          <div style={{ height: 25 }} />
                        )}
                        <div onClick={onSelectToDeliverOrderStatus}>
                          <Text
                            label={labels.orderComponentReadyPickUp}
                            variant={
                              "ButtonLg-16 common-margin blue-button-text-style blue-button-style"
                            }
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            )}
            {orderStatus === -1 && (
              <>
                <div className="icon-text-container-ODM">
                  <img src={rejectedIcon} />
                  <Text
                    label={labels.orderComponentRejectedStore}
                    variant={"H5 detail-title-style rejected-text-style-ODM"}
                  />
                </div>
                <Text
                  label={labels.orderDetailRejectMotive}
                  variant={"H6 detail-title-style centered-text-style-ODM"}
                />
                {statusReason ? (
                  <Text
                    label={statusReason?.description || ""}
                    variant={"Body1-16  detail-title-style comment-text-style"}
                  />
                ) : (
                  <Text label={"*"} variant={"Body1-16  detail-title-style "} />
                )}
              </>
            )}
            {orderStatus === -2 && (
              <>
                <div className="icon-text-container-ODM">
                  <img src={rejectedIcon} />
                  <Text
                    label={labels.orderComponentRejectedClient}
                    variant={"H5 detail-title-style rejected-text-style-ODM"}
                  />
                </div>
                <Text
                  label={labels.orderDetailRejectMotive}
                  variant={"H6 detail-title-style centered-text-style-ODM"}
                />
                {statusReason ? (
                  <Text
                    label={statusReason?.description || ""}
                    variant={"Body1-16  detail-title-style comment-text-style"}
                  />
                ) : (
                  <Text label={"*"} variant={"Body1-16  detail-title-style "} />
                )}
              </>
            )}
            {orderType !== 2 && orderStatus === 3 && (
              <div
                className="blue-button-common-margin"
                onClick={onSelectDeliveredOrderStatus}
              >
                <Text
                  label={labels.orderDetailPickedUpByClient}
                  variant={
                    "ButtonLg-16 blue-button-text-style blue-button-style"
                  }
                />
              </div>
            )}
            {orderStatus === 3 && orderType === 2 && (
              <div
                className="blue-button-common-margin"
                onClick={onSelectDeliveredOrderStatus}
              >
                <Text
                  label={labels.orderDetailDelivered}
                  variant={
                    "ButtonLg-16 blue-button-text-style blue-button-style"
                  }
                />
              </div>
            )}
            {orderStatus === 4 && (
              <div className="vouchercontainer">
                <div className="icon-text-container-ODM">
                  <img src={approvedIcon} />
                  <Text
                    label={labels.orderComponentDelivered}
                    variant={
                      "H5 detail-title-style delivered-text-style-ODM detail-line-height"
                    }
                  />
                </div>
                <Text
                  label={
                    labels.orderDelivered1 +
                    Moment(deliverTime).format("DD/MM/YY")
                  }
                  variant={"Body1-16 detail-title-style detail-line-height"}
                />
                <Text
                  label={
                    labels.ordersProgramedAtTime +
                    Moment(deliverTime).format("HH:MM")
                  }
                  variant={"Body1-16 detail-title-style detail-line-height"}
                />
                {orderType === 2 &&
                  (!hasBackUp ? (
                    <div className="voucherButton" onClick={navigateToVoucher}>
                      <Text
                        label={labels.attachDeliveryVoucher}
                        variant="subtitle1 voucherTextStyle"
                      />
                    </div>
                  ) : (
                    <div
                      className="hasVoucherButton"
                      onClick={navigateToHasVoucher}
                    >
                      <Text
                        label={labels.showDeliveryVoucher}
                        variant="subtitle1 voucherTextStyle"
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <RejectOrderModal
        id={id}
        show={!!showRejectModal}
        handleClose={handleCloseRejectModal}
        status={orderStatus}
        rejectOrder={handleOnReject}
        orderType={orderType}
      />
      <ModalComponent
        show={!!modalContentType}
        handleClose={handleCloseErrorModal}
        icon={false}
        modalStyle={VARIANTS.SUCCESS ? "modalSuccess-ODM" : "modalError-ODM"}
      >
        {modalContentType === VARIANTS.ERROR && (
          <ErrorModal
            text={labels.modalErrorMessage}
            buttonText={labels.continue}
            whatsappButton={false}
            closeModal={handleCloseErrorModal}
          />
        )}
        {modalContentType === VARIANTS.SUCCESS && orderStatus === 1 && (
          <SuccessModal
            text={labels.ordersNumber + orderId + labels.ordersAccepted}
            buttonText={labels.continue}
            handleOnPress={handleAcceptModal}
          />
        )}
        {modalContentType === VARIANTS.SUCCESS &&
          orderStatus === 2 &&
          orderType === 2 && (
            <SuccessModal
              text={labels.ordersNumber + orderId + labels.readyForDelivery}
              buttonText={labels.continue}
              handleOnPress={handleAcceptModal}
            />
          )}
        {modalContentType === VARIANTS.SUCCESS &&
          orderStatus === 2 &&
          orderType === 1 && (
            <SuccessModal
              text={labels.ordersNumber + orderId + labels.readyForPickUp}
              buttonText={labels.continue}
              handleOnPress={handleAcceptModal}
            />
          )}
        {modalContentType === VARIANTS.SUCCESS &&
          orderType !== 2 &&
          orderStatus === 3 && (
            <SuccessModal
              text={labels.ordersNumber + orderId + labels.pickedUpByClient}
              buttonText={labels.continue}
              handleOnPress={handleAcceptModal}
            />
          )}
        {modalContentType === VARIANTS.SUCCESS &&
          orderType === 2 &&
          orderStatus === 3 && (
            <SuccessModal
              text={labels.ordersNumber + orderId + labels.delivered}
              buttonText={labels.continue}
              handleOnPress={handleAcceptModal}
            />
          )}
      </ModalComponent>
    </div>
  ) : null;
}
