import React, { FormEvent, useEffect, useState } from "react";
import Text from "../text/Text";
import OutlinedInput from "../outlinedInput/OutlinedInput";
import iconX from "../../assets/icons/icons-x.svg";
import circleAlert from "../../assets/icons/icon-info-circle-white.svg";
import location from "../../assets/icons/icon-location-grey.svg";

import { getRegionForOnlineStore } from "../../services/OnlineStore";
import "./RegionModal.css";

import { labels } from "../../labels";

interface ModalProps {
  show: boolean;
  handleClose?: () => void;
  accept?: () => void;
  onSelect: (RegionType: RegionType) => void;
}

export default function RegionModal({
  handleClose,
  accept,
  show,
  onSelect,
}: ModalProps) {
  const [region, setRegion] = useState<string>("");
  const [regionList, setRegionList] = useState<RegionTypeDTO[]>([]);
  const [listFiltered, setListFiltered] = React.useState<RegionTypeDTO[]>([]);
  const [addedItems, setAddedItems] = React.useState<RegionType>();

  const getRegion = async () => {
    try {
      await getRegionForOnlineStore().then((response: RegionTypeDTO[]) => {
        const resp = response;
        setRegionList(resp);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleOnChangeText = (text: FormEvent<HTMLInputElement>) => {
    setRegion(text.currentTarget.value);
    if (text.currentTarget.value === "") {
      setListFiltered([]);
      return;
    }
    if (text.currentTarget.value.length >= 3) {
      const listFilteredTmp = [
        ...regionList.filter((it: RegionTypeDTO) =>
          it.name
            .toString()
            .toLocaleLowerCase()
            .includes(text.currentTarget.value.toLocaleLowerCase())
        ),
      ];

      setListFiltered(listFilteredTmp);
    }
  };
  const regionTypeDTOToRegionType = (region: RegionTypeDTO) => {
    return {
      comunas: [],
      regID: region.id,
      ID: region.code,
      isDeleted: false,
      NOMBRE: region.name,
    };
  };
  const ShowList = (listData: RegionTypeDTO[]) => {
    return listData.map((item: RegionTypeDTO) => {
      return (
        <div
          key={item.id}
          className="iconRegionContainer"
          onClick={() => {
            setAddedItems(regionTypeDTOToRegionType(item));
            setListFiltered([]);
          }}
        >
          <img src={location} className="iconLocationContainer-reg" />
          <Text label={item.name} variant="Small-2" />
        </div>
      );
    });
  };
  const onAccept = () => {
    if (addedItems) {
      onSelect(addedItems);
      accept?.();
      setAddedItems(undefined);
    }
  };
  const onDelete = () => {
    setAddedItems(undefined);
  };
  useEffect(() => {
    getRegion();
  }, []);

  if (!show) {
    return null;
  } else {
    return (
      <div className="backdrop-reg">
        <div className={"modal display-block-reg"}>
          <div className="modal-main-reg">
            <div className="IconContainer-reg">
              <div onClick={handleClose} className={"xIconContainer-reg"}>
                <img src={iconX} />
              </div>
            </div>
            <div className="alertTextContainer-reg">
              <img src={circleAlert} className="iconAlertStyle-reg" />
              <Text
                variant="Subtitle3 textAlertStyle-reg"
                label={labels.regionModalAlert}
              />
            </div>{" "}
            <div className="searchInputStyle-reg">
              <OutlinedInput
                formId={"search-name"}
                floatingLabel={""}
                placeholder={labels.regionModalInput}
                charCounter
                trailingIcon="search"
                onChange={handleOnChangeText}
                disabled={!!addedItems}
              />
              {listFiltered.length >= 1 && (
                <div className="listContainer-reg">
                  {ShowList(listFiltered)}
                </div>
              )}
              {addedItems && (
                <div className="selectedItemContainer-reg">
                  <div className="innerSelectedItemContainer-reg">
                    <Text label={addedItems.NOMBRE} variant="Body3-12" />
                    <img
                      src={iconX}
                      className="iconXStyle-reg"
                      onClick={onDelete}
                    />
                  </div>
                </div>
              )}
            </div>
            <button
              type="button"
              className={
                addedItems ? "confirmButton-reg " : "confirmButtonDeactive-reg "
              }
              onClick={onAccept}
              disabled={addedItems ? false : true}
            >
              <Text label={labels.confirm} variant="Subtitle2" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
