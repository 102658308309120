import { FormEvent, useEffect, useRef, useState } from "react";
import { MEASURES } from "../../constants";
import { useLoadingContext } from "../../context/spinnerContext";
import {
  brandsToOption,
  categoriesToOption,
  formIds,
  imageToBase64,
} from "../../formatters";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import useDate from "../../hooks/useDate";
import useDisclosure from "../../hooks/useDisclosure";
import { labels } from "../../labels";
import {
  addStock,
  addPrice,
  liquidateFromCreate,
  localBrands,
  localCategories,
  uploadImage,
} from "../../services/products";

const MB_SIZE = 1024 * 1024;
const MAX_MB_IMAGE = 2;
const initState: ILiquidateCreate = {
  category: "",
  date: new Date(),
  description: "",
  isFood: true,
  liquidatePrice: "",
  name: "",
  price: "",
  productBrand: "",
  productImage: "",
  quantity: "",
  shortDescription: "",
  stock: "",
  unit: MEASURES[0].label,
};

export default function useLiquidate() {
  const {
    open: openRespnseOK,
    handleClose: handleCloseResponseOk,
    handleOpen: handleOpenResponseOK,
  } = useDisclosure();
  const {
    open: openResponseError,
    handleClose: handleCloseResponseError,
    handleOpen: handleOpenResponseError,
  } = useDisclosure();
  const loadingContext = useLoadingContext();
  const [errors, setError] = useState<IFormErrors | null>(null);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageBase64, setImageBase64] = useState<string>("");
  const { handleDate, selectedDate } = useDate(new Date());
  const { handleOptionSelect: handleSelectBrand, optionID: brandID } =
    useAsyncSelect();
  const { handleOptionSelect: handleSelectCategory, optionID: categoryID } =
    useAsyncSelect();
  const [isFood, setIsFood] = useState<boolean>(false);
  const brands = brandsToOption(localBrands());
  const categories = categoriesToOption(localCategories());
  const formRef = useRef<HTMLFormElement>(null);
  const [form, setForm] = useState<ILiquidateCreate>(initState);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleFood = (value: boolean) => {
    setIsFood(value);
  };

  const handleError = (key: string, label: string) => {
    setError((prevState) => ({ ...prevState, [key]: label }));
    setIsValidForm(false);
  };

  const validateImage = () => {
    let isValid = true;
    if (image && image?.size / MB_SIZE > MAX_MB_IMAGE) {
      handleError(formIds.productImage, labels.imageSizeError);
      isValid = false;
    }
    return isValid;
  };

  const validatePrice = () => {
    let isValid = true;
    const normalPrice = Number(form.price);
    const liquidatePrice = Number(form.liquidatePrice);

    if (normalPrice <= liquidatePrice) {
      handleError(formIds.liquidatePrice, labels.liquidatePriceError);
      isValid = false;
    }
    return isValid;
  };

  const validateIds = () => {
    let isValid = true;
    if (!brandID) {
      handleError(formIds.productBrand, labels.required);
      isValid = false;
    } else if (!brands.find((element) => element.id === brandID)) {
      handleError(formIds.productBrand, labels.noValidOption);
      isValid = false;
    }
    if (!categoryID) {
      handleError(formIds.classification, labels.required);
      isValid = false;
    } else if (!categories.find((element) => element.id === categoryID)) {
      handleError(formIds.classification, labels.noValidOption);
      isValid = false;
    }
    return isValid;
  };

  const validFullForm = () => {
    let isvalid = true;
    let formKey: keyof ILiquidateCreate;
    for (formKey in form) {
      if (
        ![
          formIds.category,
          formIds.productBrand,
          formIds.productImage,
          formIds.unit,
        ].includes(formKey) &&
        !(form[formKey] as string)
      ) {
        handleError(formKey as string, labels.required);
        isvalid = false;
      }
    }

    if (form.stock === "0") {
      handleError(formIds.stock, labels.required);
      isvalid = false;
    }

    const isValidIds = validateIds();
    const isValidImage = validateImage();
    const isValidPrice = validatePrice();
    return isValidIds && isValidImage && isValidPrice && isvalid;
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const validate = () => {
    const isValid = validFullForm();
    if (!isValid) {
      setShowErrors(true);
    }
    return isValid;
  };

  const handleImage = (base64: string) => {
    setImageBase64(base64);
  };

  const handleSubmit = async () => {
    const formatedData = {
      ...form,
      date: selectedDate,
      productBrand: brandID,
      productImage: imageBase64,
      category: categoryID,
      isFood: isFood,
    };

    if (formatedData.productImage) {
      loadingContext?.onOpen();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      try {
        await uploadImage(imageBase64).then(async (res) => {
          liquidateFromCreate({
            ...formatedData,
            quantity: formatedData.quantity?.replace(",", ".") || "0",
            productImage: res,
          }).then(async (res: ILiquidateCreated) => {
            addStock(
              formatedData.stock,
              res.id,
              formatedData.quantity,
              formatedData.unit
            )
              .then(async () => {
                addPrice(
                  formatedData.liquidatePrice || "",
                  formatedData.price,
                  res.id,
                  4
                )
                  .then(() => {
                    loadingContext?.onClose();
                    handleOpenResponseOK();
                  })
                  .catch(() => {
                    loadingContext?.onClose();
                    handleOpenResponseError();
                  });
              })
              .catch(() => {
                loadingContext?.onClose();
                handleOpenResponseError();
              });
          });
        });
      } catch (e) {
        loadingContext?.onClose();
        handleOpenResponseError();
      }
    }
  };

  const handleFile = (e: FormEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0] || null;
    setImage(file);
    setError(null);
    validFullForm();
  };

  const handleCloseResponse = () => {
    handleCloseResponseOk();
  };

  useEffect(() => {
    setError(null);
    const isValid = validFullForm();
    setIsValidForm(isValid);
    if (isValid) {
      setShowErrors(false);
    }
  }, [form, image, brandID, categoryID]);

  useEffect(() => {
    imageToBase64(image, handleImage);
  }, [image]);
  return {
    form,
    formRef,
    errors,
    isValidForm,
    openRespnseOK,
    openResponseError,
    showErrors,
    handleDate,
    handleSelectBrand,
    handleSelectCategory,
    handleChange,
    handleSubmit,
    handleFile,
    validate,
    handleCloseResponseOk: handleCloseResponse,
    handleCloseResponseError,
    handleFood,
  };
}
