import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./qrPayment.module.css";
import "../../components/text/Text.css";
import Text from "../../components/text/Text";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import routes from "../../router/routes";
import { useLoadingContext } from "../../context/spinnerContext";
import VerificationInput from "react-verification-input";
import { processPaymentOrder } from "../../services/payment";
import ModalComponent from "../../components/modal/ModalComponent";
import ErrorModal from "../../components/modal/modalVariants/ErrorModal";
import { VARIANTS, VariantsType } from "../../components/modal/ModalComponent";
import { labels } from "../../labels";
import AlertModal from "../../components/modal/modalVariants/AlertModal";

export default function QrPayment() {
  const navigation = useNavigate();
  const state = useLocation();
  const loadingContext = useLoadingContext();

  const [qrCode, setQrCode] = useState<string>("");
  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errorMessage1, setErrorMessage1] = useState<boolean>(false);
  const [errorMessage2, setErrorMessage2] = useState<boolean>(false);
  const [usedQR, setUsedQR] = useState<boolean>(false);
  const [invalidQR, setInvalidQR] = useState<boolean>(false);
  const [smallAmountQR, setSmallAmountQR] = useState<boolean>(false);
  const navigateBack = () => {
    navigation(`/${routes.payment}`);
  };

  const handleQRInput = (value: string) => {
    if (value.length === 0) {
      setQrCode("");
      setDisabled(true);
    } else {
      setQrCode(value);
      setDisabled(false);
    }
  };
  const handlePayment = async () => {
    const idLocal = user?.cLocal[0].idLocal;
    const token = currentUser && JSON.parse(currentUser).token;
    const price = state.state.price;
    const sLocal = user?.cLocal[0].sLocal;
    const establishment = user?.sEstablecimiento;
    const idPayment = state.state.data.nId;
    const paymentOrderData = {
      sQR: qrCode,
      sEstablecimiento: establishment,
      sLocal: sLocal,
      nMonto: price,
      codPromocion: "0",
    };
    loadingContext?.onOpen();
    try {
      await processPaymentOrder(
        idLocal,
        idPayment,
        paymentOrderData,
        token
      ).then(async (resp: any) => {
        loadingContext?.onClose();
        if (resp[1] === 200) {
          navigation(`/${routes.successPayment}`, { state: { data: resp } });
          return;
        }
        const codRespuesta = parseInt(resp[0].CodRespuesta);

        setModalContentType(null);
        setErrorMessage2(false);
        setUsedQR(false);
        setErrorMessage1(false);
        setSmallAmountQR(false);
        setInvalidQR(false);

        if (codRespuesta > 9 && codRespuesta < 18) {
          setErrorMessage2(true);
          setModalContentType(VARIANTS.ERROR);
        } else if (codRespuesta === 52) {
          setInvalidQR(true);
          setModalContentType(VARIANTS.ALERT);
        } else if (codRespuesta === 55) {
          setModalContentType(VARIANTS.ALERT);
          setUsedQR(true);
        } else if (codRespuesta === 56) {
          setSmallAmountQR(true);
          setModalContentType(VARIANTS.ALERT);
        } else {
          setErrorMessage1(true);
          setModalContentType(VARIANTS.ERROR);
        }
      });
    } catch (error) {
      loadingContext?.onClose();
      setErrorMessage1(true);
      setModalContentType(VARIANTS.ERROR);
    }
  };
  const handleCloseErrorModal = () => {
    setModalContentType(null);
  };
  return (
    <div className={styles.containertop}>
      <div className={styles.container}>
        <div className={styles.backIconContainer} onClick={navigateBack}>
          <img src={arrowBack} className={styles.arrowStyle} />
          <Text label={labels.back} variant="md-medium titleStyle" />
        </div>
        <div className={styles.commonMargin}>
          <div className={styles.qrCodeContainer}>
            <Text
              label={labels.paymentQRdigits}
              variant="subtitle1 titleStyle"
            />
            <VerificationInput
              length={8}
              classNames={{
                container: "container",
                character: styles.character,
                characterInactive: "character--inactive",
                characterSelected: "character--selected",
              }}
              placeholder="__"
              onChange={handleQRInput}
            />
            <div
              className={
                qrCode.length < 8
                  ? styles.buttonContainerDisabled
                  : styles.buttonContainer
              }
              onClick={handlePayment}
              aria-disabled={disabled ? true : false}
            >
              <Text
                label={labels.paymentQRdigitsButton}
                variant={`ButtonLg-16 ${styles.buttonTextStyle}`}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={!!modalContentType}
        handleClose={handleCloseErrorModal}
        modalStyle={styles.modalStyle}
      >
        {modalContentType === VARIANTS.ERROR && errorMessage1 && (
          <ErrorModal
            text={labels.paymentErrorMessage}
            buttonText={labels.paymentContinue}
            subtitle={labels.paymentRetry}
            whatsappButton={false}
            closeModal={handleCloseErrorModal}
          />
        )}
        {modalContentType === VARIANTS.ERROR && errorMessage2 && (
          <ErrorModal
            text={labels.paymentErrorMessage}
            buttonText={labels.paymentContinue}
            subtitle={labels.paymentErrorMoreDetails}
            whatsappButton={false}
            closeModal={handleCloseErrorModal}
          />
        )}
        {modalContentType === VARIANTS.ALERT && smallAmountQR && (
          <AlertModal
            text={labels.paymentErrorMessage}
            buttonText={labels.retry}
            subtitle={labels.paymentErrorSmallAmount}
            closeModal={handleCloseErrorModal}
          />
        )}
        {modalContentType === VARIANTS.ALERT && invalidQR && (
          <AlertModal
            text={labels.paymentErrorInvalidQR}
            buttonText={labels.continue}
            subtitle={labels.paymentErrorNewQR}
            closeModal={handleCloseErrorModal}
          />
        )}
        {modalContentType === VARIANTS.ALERT && usedQR && (
          <AlertModal
            text={labels.paymentErrorQRUsed}
            buttonText={labels.continue}
            subtitle={labels.paymentErrorNewQR2}
            closeModal={handleCloseErrorModal}
          />
        )}
      </ModalComponent>
    </div>
  );
}
