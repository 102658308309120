import { StepConfiguration } from "../views/configurations/configurationsUtils";

const useConfigurationStep = (viewStep: number) => {
  const handleIsSelected = (step: StepConfiguration) => step.value === viewStep;
  const handleIsComplete = (step: StepConfiguration) => step.value < viewStep;
  const getClass = (step: StepConfiguration) => {
    const isSelected = handleIsSelected(step);
    const isComplete = handleIsComplete(step);
    if (isSelected) return "stepIndicatorCircle-OSConfig";

    if (isComplete) return "stepIndicatorCompleted-OSConfig";
    return "stepIndicatorUnselectedCircle-OSConfig";
  };
  return {
    handleIsSelected,
    handleIsComplete,
    getClass,
  };
};

export default useConfigurationStep;
