export const initWorkers = () => {
  console.log("Requesting permission...");
  // Notification.requestPermission().then((permission) => {
  //   if (permission === 'granted')
  //     console.log('Notification permission granted.');
  // })

  // if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('./firebase-messaging-sw.js')
  // }
  // navigator.serviceWorker.getRegistrations().then(registrations => {
  //     console.log(registrations);
  // });
};
