import React from "react";
import { labels } from "../../../labels";
import Button from "../../buttons/ButtonUI";
import Text from "../../text/Text";
import styles from "./IncorrectCredentials.module.css";
interface IInconrrectCredentialsProps {
  text: string;
  onClick: () => void;
}
export default function IncorrectCredentials(
  props: IInconrrectCredentialsProps
) {
  return (
    <div className={styles.incorrectCredentials}>
      <Text label={labels.incorrectCredentials} variant="H5-20 colorGray" />
      <Text label={props.text} variant="Body1-16" />
      <div className={`centered ${styles.margin}`}>
        <Button
          onClick={props.onClick}
          label={labels.accept}
          variant="error"
          type="button"
          textVariant="sm13"
        />
      </div>
    </div>
  );
}
