import React, { useState, useEffect } from "react";
import Text from "../text/Text";
import { labels, clevertapEvents } from "../../labels";
import "./OrdersHeaderComponent.modules.css";
import { track } from "../../ClevertapWrapper";
import { format } from "date-fns";

interface OrderHeaderTypeProps {
  onChangeTab: (value: number) => void;
  activeTab: number;
}

export default function OrdersHeaderComponent({
  onChangeTab,
  activeTab,
}: OrderHeaderTypeProps) {
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  const handleOnChangeTab = (tab: number) => {
    onChangeTab(tab);
  };
  const onSelectNewTab = () => {
    track(clevertapEvents.orderHeaderNew, clevertapData);
    handleOnChangeTab(0);
  };
  const onSelectProcessTab = () => {
    track(clevertapEvents.orderHeaderProgress, clevertapData);
    handleOnChangeTab(1);
  };
  const onSelectDeliverTab = () => {
    track(clevertapEvents.orderHeaderDelivery, clevertapData);
    handleOnChangeTab(2);
  };
  const onSelectHistoryTab = () => {
    track(clevertapEvents.orderHeaderHistory, clevertapData);
    handleOnChangeTab(3);
  };
  useEffect(() => {
    getLocation();
  }, [lat, lng]);

  return (
    <div className="orders-header-tab-container">
      <div
        className={
          activeTab === 0
            ? "order-header-text-container-selected"
            : "order-header-text-container"
        }
        onClick={onSelectNewTab}
      >
        <Text label={labels.orderHeaderNew} variant="order-header-text-style" />
      </div>
      <div
        className={
          activeTab === 1
            ? "order-header-text-container-selected"
            : "order-header-text-container"
        }
        onClick={onSelectProcessTab}
      >
        <Text
          label={labels.orderHeaderProgress}
          variant="order-header-text-style"
        />
      </div>
      <div
        className={
          activeTab === 2
            ? "order-header-text-container-selected"
            : "order-header-text-container"
        }
        onClick={onSelectDeliverTab}
      >
        <Text
          label={labels.orderHeaderToDeliver}
          variant="order-header-text-style"
        />
      </div>
      <div
        className={
          activeTab === 3
            ? "order-header-text-container-selected"
            : "order-header-text-container"
        }
        onClick={onSelectHistoryTab}
      >
        <Text
          label={labels.orderHeaderHistory}
          variant="order-header-text-style"
        />
      </div>
    </div>
  );
}
