import React, { useState } from "react";
import Text from "../text/Text";
import { modifyOrderStatus } from "../../services/orders";
import iconX from "../../assets/icons/icons-x.svg";
import "./RejectOrderModal.css";
import alert from "../../assets/icons/icons-alert.svg";
import ModalComponent from "../../components/modal/ModalComponent";
import SuccessModal from "../../components/modal/modalVariants/SuccessModal";
import ErrorModal from "../../components/modal/modalVariants/ErrorModal";
import { useLoadingContext } from "../../context/spinnerContext";

import { VARIANTS, VariantsType } from "../../components/modal/ModalComponent";
import { labels } from "../../labels";

interface ModalProps {
  id: string;
  show: boolean;
  handleClose?: () => void;
  accept?: () => void;
  rejectOrder?: () => void;
  status?: number;
  orderType?: number;
}

export default function RejectOrderModal({
  handleClose,
  accept,
  show,
  id,
  rejectOrder,
  status,
  orderType,
}: ModalProps) {
  const loadingContext = useLoadingContext();

  const [reason, setReason] = useState<number>(0);
  const [descriptionReason, setDescriptionReason] = useState<string>("");
  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);

  const reasonsArray: ReasonType[] = [
    {
      idReason: 1,
      description: labels.reason1,
    },
    {
      idReason: 2,
      description: labels.reason2,
    },
    {
      idReason: 3,
      description: labels.reason3,
    },
    {
      idReason: 4,
      description: labels.reason4,
    },
  ];
  const reasonsArrayPickUp: ReasonType[] = [
    {
      idReason: 1,
      description: labels.reason1,
    },
    {
      idReason: 2,
      description: labels.reason2,
    },
    {
      idReason: 4,
      description: labels.reason4,
    },
  ];
  const renderOptions = (list: any) => {
    return list.map((item: ReasonType, index: number) => (
      <div key={index}>
        <div
          className={
            reason == item.idReason
              ? "reject-option-button-selected-rm"
              : "reject-option-button-rm"
          }
          onClick={() => OptionIdSelection(item.idReason, item.description)}
        >
          <Text
            label={item.description}
            variant={
              reason == item.idReason
                ? "Body2-14 reject-option-text-style-rm common-text-color-selected"
                : "Body2-14 reject-option-text-style-rm title-text-style-rm"
            }
          />
        </div>
      </div>
    ));
  };

  const OptionIdSelection = (id: number, description: string) => {
    setReason(id);
    setDescriptionReason(description);
  };

  const handleRejectOrder = async () => {
    const data = {
      statusID: -1,
      statusReason: reason,
    };

    try {
      loadingContext?.onOpen();
      await modifyOrderStatus(id, data).then((res) => {
        loadingContext?.onClose();
        if (res === 200) {
          setModalContentType(VARIANTS.SUCCESS);
        } else {
          setModalContentType(VARIANTS.ERROR);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleCloseErrorModal = () => {
    setModalContentType(null);
  };
  const handleAccept = () => {
    if (status === 2) {
      rejectOrder?.();
    }
    accept?.();
  };
  return show ? (
    <div className="backdrop-rm">
      <div className={"modal display-block-rm"}>
        <div className="modal-main-rm">
          <div className="IconContainer-rm">
            <div onClick={handleClose} className={"xIconContainer-rm"}>
              <img src={iconX} />
            </div>
          </div>
          <div className="main-container-rm">
            <Text
              label={labels.rejectOrder}
              variant={"H4 title-text-style-rm"}
            />
            <div className="alert-icon-container-rm">
              <img src={alert} />
            </div>
            <Text
              label={labels.rejectOrderTitle}
              variant={"Body1-16 common-text-color"}
            />
            <Text
              label={labels.rejectOrderSubtitle + " " + id + "?"}
              variant={"Body1-16 common-text-color"}
            />
            <Text
              label={labels.rejectMotive}
              variant={"subtitle2 subtitle-text-style-rm"}
            />
            {orderType === 2
              ? renderOptions(reasonsArray)
              : renderOptions(reasonsArrayPickUp)}
            <div>
              <div
                className={
                  reason === 0
                    ? "reject-button-disabled-rm"
                    : "reject-button-rm"
                }
                onClick={handleRejectOrder}
                aria-disabled={reason === 0 ? true : false}
              >
                <Text label={labels.rejectOrder} variant={"ButtonLg-16"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={!!modalContentType}
        handleClose={handleCloseErrorModal}
        icon={false}
        modalStyle={
          modalContentType === VARIANTS.SUCCESS
            ? "modalSuccess-rm"
            : "modalError-rm"
        }
      >
        {modalContentType === VARIANTS.ERROR && (
          <ErrorModal
            text={labels.modalErrorMessage}
            buttonText={labels.continue}
            whatsappButton={false}
            closeModal={handleCloseErrorModal}
          />
        )}
        {modalContentType === VARIANTS.SUCCESS && (
          <SuccessModal
            text={labels.ordersNumber + id + labels.ordersRejected}
            buttonText={labels.continue}
            handleOnPress={handleAccept}
          />
        )}
      </ModalComponent>
    </div>
  ) : null;
}
