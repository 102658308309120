import React from "react";
import {
  StepConfiguration,
  stepContent,
} from "../../views/configurations/configurationsUtils";
import check from "../../assets/icons/icon-check.svg";

import useConfigurationStep from "../../hooks/useConfigurationStep";
import Text from "../text/Text";
interface IStepIndicatorProps {
  view: number;
}
const StepIndicator = ({ view }: IStepIndicatorProps) => {
  const { getClass, handleIsComplete, handleIsSelected } =
    useConfigurationStep(view);
  return (
    <div className="SIContainer-OSConfig">
      <div className="stepIndicatorContainer-OSConfig">
        {stepContent.map((item: StepConfiguration, index: number) => (
          <div
            key={item.value}
            className={
              index < stepContent.length - 1
                ? "stepInnerContainer-OSConfig"
                : "stepInnerContainer-OSConfig-last"
            }
          >
            <div
              className={
                index < stepContent.length - 1
                  ? "stepIndicatorCircleContainer-OSConfig"
                  : "stepIndicatorCircleContainer-OSConfig-last"
              }
            >
              <div className={getClass(item)}>
                {handleIsComplete(item) ? (
                  <img src={check} />
                ) : (
                  <Text
                    label={item.value.toString()}
                    variant={
                      handleIsSelected(item)
                        ? "Small white-OSConfig"
                        : "Small grey900-OSConfig"
                    }
                  />
                )}
              </div>
              {item.value !== 4 && (
                <div className="dividerStepIndicator-OSConfig" />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="stepIndicatorTextContainer-OSConfig">
        {stepContent.map((item) => (
          <div key={item.value}>
            <Text
              label={item.label}
              variant="Small stepIndicatorLabelContainer-OSConfig"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepIndicator;
