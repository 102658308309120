import React, { useState, useEffect } from "react";
import "./OrderComponent.css";
import Text from "../text/Text";
import OrderDetailModal from "../modal/OrderDetailModal";
import RejectOrderModal from "../modal/RejectOrderModal";
import deliveryAmipass from "../../assets/icons/icons-delivery-amipass.svg";
import purchase from "../../assets/icons/icons-purchase.svg";
import purchaseWeb from "../../assets/icons/icons-purchase-web.svg";
import clientIcon from "../../assets/icons/icons-client-small.svg";
import { modifyOrderStatus } from "../../services/orders";
import ModalComponent from "../modal/ModalComponent";
import SuccessModal from "../modal/modalVariants/SuccessModal";
import ErrorModal from "../modal/modalVariants/ErrorModal";
import { VARIANTS, VariantsType } from "../modal/ModalComponent";
import { clevertapEvents, labels } from "../../labels";
import { useLoadingContext } from "../../context/spinnerContext";

import moment from "moment";
import "moment/locale/es";
import { track } from "../../ClevertapWrapper";
import { format } from "date-fns";

interface OProps {
  statusID: number;
  orderTypeID: number;
  id: string;
  client: string;
  orderNumber: string;
  dateCreated: string;
  ammount: string;
  hasDelivery?: any[];
  hasScheduledPurchase?: ScheduledType;
  acceptOrder?: () => void;
  rejectOrder?: () => void;
  delivered?: () => void;
  acceptPreparationOrder?: () => void;
}

export default function OrderComponent(props: OProps): React.ReactElement {
  const loadingContext = useLoadingContext();

  const time = moment(props.dateCreated).fromNow();
  const [minWidth, setMinWidth] = useState<boolean>(
    window.matchMedia("(min-width: 642px)").matches
  );
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [showRejectModal, setshowRejectModal] = useState<boolean>(false);
  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);
  const [scheduled, setScheduled] = useState<ScheduledType | null>(
    props.hasScheduledPurchase ? props.hasScheduledPurchase : null
  );
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };

  useEffect(() => {
    window
      .matchMedia("(min-width: 642px)")
      .addEventListener("change", (e) => setMinWidth(e.matches));
  }, []);

  const handleCloseDetailModal = () => {
    track(clevertapEvents.closeDetailModal, clevertapData);
    setShowDetail(false);
  };
  const handleOpenDetailModal = () => {
    setShowDetail(true);
  };
  const handleCloseRejectModal = () => {
    setshowRejectModal(false);
  };
  const handleOpenRejectModal = () => {
    setshowRejectModal(true);
  };

  const handleModifyOrderStatus = async (statusId: number) => {
    const data = {
      statusID: statusId,
      statusReason: null,
    };

    try {
      loadingContext?.onOpen();
      await modifyOrderStatus(props.id, data).then((res) => {
        loadingContext?.onClose();
        if (res === 200) {
          setModalContentType(VARIANTS.SUCCESS);
        } else {
          setModalContentType(VARIANTS.ERROR);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const onSelectProcessOrderStatus = () => {
    handleModifyOrderStatus(2);
  };
  const onSelectToDeliverOrderStatus = () => {
    handleModifyOrderStatus(3);
  };

  const handleCloseErrorModal = () => {
    setModalContentType(null);
  };
  const handleAccept = () => {
    props.acceptOrder?.();
  };
  const handleOnReject = () => {
    props.rejectOrder?.();
  };

  function handleStyle() {
    if (props.hasDelivery && !props.hasScheduledPurchase) {
      return "order-delivery-container-OC";
    }
    if (!props.hasDelivery && props.hasScheduledPurchase) {
      return "order-purchase-container-OC";
    }
    if (props.hasDelivery && props.hasScheduledPurchase) {
      return "delivery-purchase-container-OC";
    } else {
      return "order-container-OC";
    }
  }
  useEffect(() => {
    getLocation();
  }, [lat, lng]);

  return (
    <>
      <div className={handleStyle()}>
        {props.hasDelivery && !props.hasScheduledPurchase && (
          <div className="delivery-header-OC">
            <img src={deliveryAmipass} className="delivery-icon-style-OC" />
          </div>
        )}
        {props.hasScheduledPurchase && !props.hasDelivery && (
          <div className="purchase-header-OC">
            <Text
              label={
                labels.ordersProgramedForDay +
                scheduled?.orderDate +
                labels.ordersProgramedAtTime +
                scheduled?.orderTime
              }
              variant="Subtitle3 purchase-title-style-OC"
            />
            {!minWidth && (
              <img src={purchase} className="purchase-icon-style-OC" />
            )}
            {minWidth && <img src={purchaseWeb} />}
          </div>
        )}

        {props.hasDelivery && props.hasScheduledPurchase && (
          <>
            <div className="delivery-header-OC">
              <img src={deliveryAmipass} className="delivery-icon-style-OC" />
            </div>

            <div className="purchase-header-OC">
              <Text
                label={
                  labels.ordersProgramedForDay +
                  scheduled?.orderDate +
                  labels.ordersProgramedAtTime +
                  scheduled?.orderTime
                }
                variant="Subtitle3 purchase-title-style-OC"
              />
              {minWidth && (
                <img src={purchase} className="purchase-icon-style-OC" />
              )}
              {!minWidth && <img src={purchaseWeb} />}
            </div>
          </>
        )}
        <div className="first-row-container-OC">
          {minWidth && (
            <div className="title-container-OC">
              <div className="data-container-OC">
                <Text
                  label={labels.orderComponentClient}
                  variant="Small text-style-OC"
                />
                <Text
                  label={props.client}
                  variant="Caption3-13 text-style-OC"
                />
              </div>
              <div className="data-container-OC">
                <Text
                  label={labels.orderComponentOrderID}
                  variant="Small text-style-OC"
                />
                <Text
                  label={props.orderNumber}
                  variant="Caption3-13 text-style-OC"
                />
              </div>
              <div className="data-container-OC">
                <Text
                  label={labels.orderComponentTime}
                  variant="Small text-style-OC"
                />
                <Text label={time} variant="Caption3-13 text-style-OC" />
              </div>
              <div className="data-container-OC">
                <Text
                  label={labels.orderComponentAmount}
                  variant="Small text-style-OC"
                />
                <Text
                  label={"$" + props.ammount}
                  variant="Caption3-13 text-style-OC"
                />
              </div>
            </div>
          )}
          {!minWidth && (
            <div className="mobile-data-container-OC">
              <div className="mobile-data-column-container-OC">
                <Text
                  label={props.client}
                  variant="Caption3-13 text-style-OC"
                />
                <Text label={"N°" + props.orderNumber} variant="Caption3-13 " />
                <Text label={time} variant="Small " />
              </div>
            </div>
          )}
          {props.statusID === 1 && (
            <div className="process-buttons-container-OC">
              {!minWidth && (
                <Text
                  label={"$" + props.ammount}
                  variant="Caption1-14 amount-text-style-OC process-text-common-margin"
                />
              )}
              <div className="buttons-container-OC">
                <div
                  className="accept-button-OC"
                  onClick={onSelectProcessOrderStatus}
                >
                  <Text label={labels.accept} variant="Subtitle3" />
                </div>
                <div
                  className="reject-button-OC"
                  onClick={handleOpenRejectModal}
                >
                  <Text
                    label={labels.reject}
                    variant="Subtitle3 common-text-color-OC"
                  />
                </div>
              </div>
            </div>
          )}
          {props.statusID === 2 && props.orderTypeID === 2 && (
            <div className="process-buttons-container-OC">
              {!minWidth && (
                <Text
                  label={"$" + props.ammount}
                  variant="Caption1-14 amount-text-style-OC process-text-common-margin"
                />
              )}
              <div
                className="blue-button-style-OC process-text-common-margin"
                onClick={onSelectToDeliverOrderStatus}
              >
                <Text
                  label={labels.orderComponentReadyDelivery}
                  variant="Subtitle3 blue-button-text-style-OC "
                />
              </div>
            </div>
          )}
          {props.statusID === 2 && props.orderTypeID === 1 && (
            <div className="process-buttons-container-OC">
              {!minWidth && (
                <Text
                  label={"$" + props.ammount}
                  variant="Caption1-14 amount-text-style-OC"
                />
              )}
              <div
                className="blue-button-style-OC process-text-common-margin"
                onClick={onSelectToDeliverOrderStatus}
              >
                <Text
                  label={labels.orderComponentReadyPickUp}
                  variant="Subtitle3 blue-button-text-style-OC"
                />
              </div>
            </div>
          )}
          {props.statusID === 3 && props.orderTypeID === 1 && (
            <div className="process-buttons-container-OC">
              {!minWidth && (
                <Text
                  label={"$" + props.ammount}
                  variant="Caption1-14 amount-text-style-OC process-text-common-margin"
                />
              )}
              <div className="buttons-container-OC">
                <div className="icon-text-container-OC">
                  <img src={clientIcon} />
                  <Text
                    label={labels.orderComponentToDeliverClient}
                    variant="Subtitle3 blue-text-style-OC delivery-text-common-margin"
                  />
                </div>
              </div>
            </div>
          )}
          {props.statusID === 3 && props.orderTypeID === 2 && (
            <div className="process-buttons-container-OC">
              {!minWidth && (
                <Text
                  label={"$" + props.ammount}
                  variant="Caption1-14 amount-text-style-OC process-text-common-margin"
                />
              )}
              <div className="buttons-container-OC">
                <div className="icon-text-container-OC">
                  <img src={clientIcon} />
                  <Text
                    label={labels.orderComponentReadyForPickUp}
                    variant="Subtitle3 blue-text-style-OC delivery-text-common-margin"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="detail-container-OC" onClick={handleOpenDetailModal}>
          <Text
            label={labels.orderComponentDetail}
            variant={
              minWidth
                ? "subtitle1 text-style-big-OC "
                : "Subtitle3 text-style-OC"
            }
          />
        </div>
      </div>
      <OrderDetailModal
        hasScheduledPurchase={props.hasScheduledPurchase ? true : false}
        hasDeliveryUber={props.hasDelivery ? true : false}
        id={props.id}
        show={showDetail}
        handleClose={handleCloseDetailModal}
        rejectOrder={handleOnReject}
      />
      <RejectOrderModal
        id={props.id}
        show={!!showRejectModal}
        handleClose={handleCloseRejectModal}
        accept={props.acceptOrder}
      />
      <ModalComponent
        show={!!modalContentType}
        handleClose={handleCloseErrorModal}
        icon={false}
        modalStyle={VARIANTS.SUCCESS ? "modalSuccess-OC" : "modalError-OC"}
      >
        {modalContentType === VARIANTS.ERROR && (
          <ErrorModal
            text={labels.modalErrorMessage}
            buttonText={labels.continue}
            whatsappButton={false}
            closeModal={handleCloseErrorModal}
          />
        )}

        {modalContentType === VARIANTS.SUCCESS && props.statusID === 1 && (
          <SuccessModal
            text={
              labels.ordersNumber + props.orderNumber + labels.ordersAccepted
            }
            buttonText={labels.continue}
            handleOnPress={handleAccept}
          />
        )}
        {modalContentType === VARIANTS.SUCCESS &&
          props.statusID === 2 &&
          props.orderTypeID === 2 && (
            <SuccessModal
              text={
                labels.ordersNumber +
                props.orderNumber +
                labels.readyForDelivery
              }
              buttonText={labels.continue}
              handleOnPress={handleAccept}
            />
          )}
        {modalContentType === VARIANTS.SUCCESS &&
          props.statusID === 2 &&
          props.orderTypeID === 1 && (
            <SuccessModal
              text={
                labels.ordersNumber + props.orderNumber + labels.readyForPickUp
              }
              buttonText={labels.continue}
              handleOnPress={handleAccept}
            />
          )}
        {modalContentType === VARIANTS.SUCCESS &&
          props.statusID === 3 &&
          props.orderTypeID === 2 && (
            <SuccessModal
              text={
                labels.ordersNumber +
                props.orderNumber +
                labels.pickedUpByClient
              }
              buttonText={labels.continue}
              handleOnPress={handleAccept}
            />
          )}
      </ModalComponent>
    </>
  );
}
