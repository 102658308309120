import React, { ChangeEvent } from "react";
import { labels } from "../../labels";
import SvgIcon, { iconNames } from "../svgIcon/SvgIcon";
import styles from "./fileUploader.module.css";
import UploadLine from "./UploadLine";

interface IFileUploaderProps {
  title: string;
  handleFile: (e: ChangeEvent<HTMLInputElement>) => void;
  formId: string;
  errorMessage: string;
  accept: string;
  percentage: number;
  error?: boolean;
}

export default function FileUploader({
  errorMessage,
  title,
  handleFile,
  formId,
  accept,
  percentage,
  error,
}: IFileUploaderProps) {
  return (
    <div className={styles.container}>
      <p className="Body2-14">{title}</p>
      <UploadLine error={error} percentage={percentage} />
      <input
        accept={accept}
        type="file"
        id={formId}
        hidden
        onChange={handleFile}
      />
      {error && (
        <div className={styles.error}>
          <div className={styles.errorIcon}>
            <SvgIcon iconName={iconNames.warningRed} />
          </div>
          <p>{errorMessage || labels.fileError}</p>
        </div>
      )}
      <label htmlFor={formId} className={styles.button}>
        {labels.chooseFile}
      </label>
    </div>
  );
}
