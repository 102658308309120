import React from "react";
import "./Text.css";
export const link = "link";
export type asType = "link";
interface ITextProps {
  label: string;
  variant: string;
  as?: asType;
  to?: string;
}

export default function Text(props: ITextProps) {
  if (props.as === link && props.to) {
    return (
      <a href={props.to} className={props.variant}>
        {props.label}
      </a>
    );
  }
  return <p className={props.variant}>{props.label}</p>;
}
