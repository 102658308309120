import { format } from 'date-fns'

const init = async () => {
    console.log('Clevertap was initialized')
}

const getDataStructure = () => {
    const currentUser = localStorage.getItem('currentUser')

    const user = currentUser && JSON.parse(currentUser)
    const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal

    return {
        'Región (DB Comercio - sRegion)': user.cLocal[0].sRegion,
        'Comuna (DB Comercio - sComuna)': user.cLocal[0].sComuna,
        'Código de comercio': user.sEstablecimiento,
        'Código de local': user.cLocal[0].sLocal,
        Fecha: format(new Date(), 'yyyy/MM/dd'),
        Hora: format(new Date(), 'kk:mm:ss'),
        'ID o Cookie de usuario': commerceId,
    }
}

const logIn = (payload = {}) => {
    window.clevertap.onUserLogin.push(payload)
    window.clevertap.profile.push(payload)
}

const track = (name, payload) => {
    if (!payload) {
        payload = getDataStructure()
    }

    window.clevertap.event.push(name, payload)
}

export { init, logIn, track }
