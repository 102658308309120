export const buttonVariants = {
  form: "formButton",
  success: "successButton",
  successDark: "successDark",
  error: "error",
  errorLight: "errorLight",
  green: "green",
  accepted: "accepted",
  secondary: "secondary",
};

export const buttonTextVariants = {
  md14: "ButtonMd-14",
  sm13: "ButtonSm-13",
};
