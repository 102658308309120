import { IOptions } from "../../../components/ingredients/Ingredients";
import {
  brandsToOption,
  categoriesToOption,
  createProductFormIds,
  imageToBase64,
} from "../../../formatters";
import { useLoadingContext } from "../../../context/spinnerContext";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import useDisclosure from "../../../hooks/useDisclosure";
import useAsyncSelect from "../../../hooks/useAsyncSelect";
import {
  addIngredients,
  addStock,
  changePrice,
  createProduct,
  localBrands,
  localCategories,
  uploadImage,
} from "../../../services/products";
import { labels } from "../../../labels";
import { MEASURES } from "../../../constants";

const getCommerceID = () => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  return commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal;
};
const MB_SIZE = 1024 * 1024;
const MAX_MB_IMAGE = 2;
const ingredientsKey = "ingredients";
const initialForm: ICreateProductForm = {
  productName: "",
  fullDescription: "",
  price: "",
  productBrandID: "",
  productCategoryID: "",
  productImage: "",
  productMeasureUnit: MEASURES[0].label,
  shortDescription: "",
  stock: "",
  isFood: true,
  weight: "",
};

export default function useIndividualUpload(initialData?: ICreateProductForm) {
  const {
    open: openRespnseOK,
    handleClose: handleCloseResponseOk,
    handleOpen: handleOpenResponseOK,
  } = useDisclosure();
  const {
    open: openResponseError,
    handleClose: handleCloseResponseError,
    handleOpen: handleOpenResponseError,
  } = useDisclosure();
  const loadingContext = useLoadingContext();
  const [errors, setError] = useState<ICreateProductFormErrors | null>(null);
  const [form, setForm] = useState<ICreateProductForm>(
    initialData || initialForm
  );
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageBase64, setImageBase64] = useState<string>("");
  const { handleOptionSelect: handleSelectBrand, optionID: brandID } =
    useAsyncSelect();
  const { handleOptionSelect: handleSelectCategory, optionID: categoryID } =
    useAsyncSelect();
  const [isFood, setIsFood] = useState<boolean>(true);
  const brands = brandsToOption(localBrands());
  const categories = categoriesToOption(localCategories());
  const formRef = useRef<HTMLFormElement>(null);
  const [ingredients, setIngredients] = useState<IOptions | undefined>();
  const handleIngredients = (newIngredients: IOptions | undefined) => {
    setIngredients(newIngredients);
  };
  const [showError, setShowErrors] = useState<boolean>(false);

  const validateIngredients = () => {
    let ingredientsValid = true;
    if (ingredients) {
      if (ingredients.length > 0) {
        ingredients.forEach((element) => {
          if (!element.maximumQuantity) {
            ingredientsValid = false;
          }
          if (!element.name) {
            ingredientsValid = false;
          }
          element.options.forEach((item) => {
            if (!item.name) {
              ingredientsValid = false;
            }
            if (!item.price) {
              ingredientsValid = false;
            }
          });
          handleError(ingredientsKey, labels.ingredientsError);
        });
      } else {
        handleError(ingredientsKey, labels.ingredientsEmpty);
        ingredientsValid = false;
      }
    }
    if (ingredientsValid) {
      setError(null);
    }
    return ingredientsValid;
  };

  const validateIds = () => {
    let isValid = true;
    if (!brandID) {
      handleError(createProductFormIds.productBrandID, labels.required);
      isValid = false;
    } else if (!brands.find((element) => element.id === brandID)) {
      handleError(createProductFormIds.productBrandID, labels.noValidOption);
      isValid = false;
    }
    if (!categoryID) {
      handleError(createProductFormIds.productCategoryID, labels.required);
      isValid = false;
    } else if (!categories.find((element) => element.id === categoryID)) {
      handleError(createProductFormIds.productCategoryID, labels.noValidOption);
      isValid = false;
    }
    return isValid;
  };

  const validateImage = () => {
    let isValid = true;
    if (!image) {
      isValid = false;
    }
    if (image && image?.size / MB_SIZE > MAX_MB_IMAGE) {
      handleError(createProductFormIds.productImage, labels.imageSizeError);
      isValid = false;
    }
    return isValid;
  };

  const validateFullForm = () => {
    let formKey: keyof ICreateProductForm;
    let isValid = true;
    for (formKey in form) {
      if (
        ![
          createProductFormIds.productBrandID,
          createProductFormIds.productCategoryID,
          createProductFormIds.productImage,
          createProductFormIds.productMeasureUnit,
          createProductFormIds.isFood,
        ].includes(formKey) &&
        !form[formKey]
      ) {
        handleError(formKey as string, labels.required);
        isValid = false;
      }
    }
    const validIds = validateIds();
    const validImage = validateImage();
    isValid = validIds && validImage && isValid;
    return isValid;
  };
  const onCategoryChange = (newValue: string | null) => {
    handleSelectCategory(newValue);
  };
  const onBrandChange = (newValue: string | null) => {
    handleSelectBrand(newValue);
  };
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleUnit = (element: ChangeEvent<HTMLSelectElement>) => {
    setForm((prevState) => ({
      ...prevState,
      ["productMeasureUnit"]: element.target.value,
    }));
  };
  const handleFood = (value: boolean) => {
    setIsFood(value);
  };
  const handleError = (key: string, label: string) => {
    setError((prevState) => ({ ...prevState, [key]: label }));
    setIsValidForm(false);
  };

  const validate = () => {
    const validForm = validateFullForm();
    const validIngredients = validateIngredients();
    const isValid = validForm && validIngredients;
    if (!isValid) {
      setShowErrors(true);
      setIsValidForm(false);
    } else {
      setShowErrors(false);
      setIsValidForm(true);
    }

    return isValid;
  };

  const handleImage = (base64: string) => {
    setImageBase64(base64);
  };
  const onError = () => {
    loadingContext?.onClose();
    handleOpenResponseError();
  };
  const handleSubmit = async () => {
    const formatedData: ICreateProductForm = {
      ...form,
      productBrandID: brandID ? brandID : "0",
      productCategoryID: categoryID ? categoryID : "0",
      productImage: imageBase64,
      isFood: isFood,
    };
    if (formatedData.productImage) {
      loadingContext?.onOpen();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      try {
        await uploadImage(imageBase64).then(async (res: string) => {
          createProduct({
            ...formatedData,
            productImage: res,
          })
            .then(async (res: ILiquidateCreated) => {
              addStock(
                formatedData.stock,
                res.id,
                formatedData.weight,
                formatedData.productMeasureUnit
              )
                .then(async () => {
                  changePrice(formatedData.price || "", res.id, 1).then(() => {
                    loadingContext?.onClose();
                    handleOpenResponseOK();
                  });
                })
                .then(() => {
                  if (ingredients) {
                    const formatIngredients = ingredients.reduce(
                      (acc: AddIngredient[], el): AddIngredient[] => {
                        const typeIngredients: Ingredient[] = el.options.map(
                          (item): Ingredient => {
                            return {
                              name: item.name,
                              price: +item.price,
                              maxAmount: +el.maximumQuantity,
                            };
                          }
                        );
                        return [
                          ...acc,
                          {
                            ingredients: typeIngredients,
                            mandatory: el.required,
                            maxAmount: +el.maximumQuantity,
                            multichoice: false,
                            name: el.name,
                            productID: res.id,
                            internalCommerceID: +getCommerceID(),
                          },
                        ];
                      },
                      []
                    );
                    addIngredients(res.id, formatIngredients);
                  }
                });
            })
            .catch(() => {
              onError();
            });
        });
      } catch (e) {
        onError();
      }
    }
  };

  const handleFile = (e: FormEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0] || null;
    setImage(file);
    setError(null);
    validateFullForm();
  };

  useEffect(() => {
    imageToBase64(image, handleImage);
  }, [image]);

  useEffect(() => {
    setError(null);
    const ingredientsValid = validateIngredients();
    const fullValid = validateFullForm();
    setIsValidForm(ingredientsValid && fullValid);
  }, [ingredients, categoryID, brandID, image, form]);

  const handleCloseResponse = () => {
    handleCloseResponseOk();
    location.reload();
  };
  return {
    formRef,
    errors,
    isValidForm,
    openRespnseOK,
    showError,
    openResponseError,
    handleSelectBrand: onBrandChange,
    handleSelectCategory: onCategoryChange,
    handleUnit,
    handleChange,
    validate,
    handleSubmit,
    handleFile,
    handleCloseResponseOk: handleCloseResponse,
    handleCloseResponseError,
    handleFood,
    handleIngredients,
    form,
  };
}
