import { useRef, useState, useEffect, UIEvent, useCallback } from "react";

export const PAGE_CHUNK = 10;

const useLazyLoader = (
  callback: (
    commerceId: string,
    status: number,
    PAGE_CHUNK: number,
    page: number
  ) => Promise<any>,
  referenceId: string,
  status: number
) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const listRef = useRef<HTMLDivElement | null>();
  const lastItemRef = useRef<HTMLDivElement | null>();
  const [fetch, setFetch] = useState<boolean>(false);
  const [noData, setNoData] = useState<boolean>(false);
  const [data, setData] = useState<TypeHistory[]>([]);
  const [page, setPage] = useState<number>(1);
  const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal;

  const refresh = useCallback(
    (newPage: number) => {
      setPage(newPage);
      callback(commerceId, status, PAGE_CHUNK, newPage).then((response) => {
        setData((prevState) =>
          newPage == 1 ? [...response] : [...prevState, ...response]
        );
        if (response.length === 0) {
          setNoData(true);
        }
        setFetch(false);
      });
    },
    [commerceId]
  );
  const refreshWithChunk = useCallback(
    (newChunk: number) => {
      callback(commerceId, status, newChunk, 1).then((response) => {
        setData([...response]);
        if (response.length === 0) {
          setNoData(true);
        }
        setFetch(false);
      });
    },
    [commerceId]
  );

  useEffect(() => {
    refresh(1);
  }, []);

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const last = document
        .getElementById(referenceId)
        ?.getBoundingClientRect();
      if (
        last &&
        last?.top < 747 &&
        !fetch &&
        !noData &&
        data.length === PAGE_CHUNK * page
      ) {
        event.preventDefault();
        event.stopPropagation();
        setFetch(true);
        refresh(page + 1);
      }
    },
    [data, noData, fetch, refresh, referenceId, page]
  );
  return {
    data,
    onScroll,
    listRef,
    lastItemRef,
    refresh,
    page,
    refreshWithChunk,
  };
};
export default useLazyLoader;
