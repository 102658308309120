import React from "react";
import "./RedirectCard.css";
import "../text/Text.css";

interface RedirectProps {
  iconName: string;
  title: string;
  subtitle: string;
  handleOnPress: () => void;
}

export default function RedirectCard({
  iconName,
  title,
  subtitle,
  handleOnPress,
}: RedirectProps) {
  return (
    <div className={"landing"} onClick={handleOnPress}>
      <img src={iconName} className={"iconStyle"} />

      <div className="titleStyle">{title}</div>
      <div className="centeredSubtitle">{subtitle}</div>
    </div>
  );
}
