import React, { useState, useEffect, useMemo, useContext } from 'react'
import styles from './SalesOfMonth.module.css'
import Text from '../../components/text/Text'
import { TransactionResponse, saleDateFormat } from '../../services/commerce'
import { format, startOfMonth, subMonths } from 'date-fns'
import SvgIcon, { iconNames } from '../../components/svgIcon/SvgIcon'
import {
    addSales,
    currencyFormat,
    getIncrease,
    sortSales,
} from '../../formatters'
import { labels } from '../../labels'
import { getTransaccionesLocalPorFechasTipoPago } from '../../services/commerce'
import Button from '../../components/buttons/ButtonUI'
import { useNavigate } from 'react-router-dom'
import routes from '../../router/routes'
import SaleComponent from '../../components/saleComponent/SaleComponent'
import { FeatureContext } from '../../context/useFeatureFlagContext'
import ScrollComponent from '../../components/ScrollComponent'

export default function SalesOfMonth() {
    const { features } = useContext(FeatureContext)
    const currentUser = localStorage.getItem('currentUser')
    const user = currentUser && JSON.parse(currentUser)
    const [sales, setSales] = useState<TransactionResponse[]>([])
    const navigate = useNavigate()
    const [prevSales, setPrevSales] = useState<TransactionResponse[]>([])
    const getSales = async (startDate: string, endDate: string) => {
        return await getTransaccionesLocalPorFechasTipoPago(
            user.sEstablecimiento,
            user.cLocal[0].sLocal,
            startDate,
            endDate
        )
    }
    const getActualSales = async () => {
        await getSales(
            format(startOfMonth(new Date()), saleDateFormat),
            format(new Date(), saleDateFormat)
        ).then((data) => {
            setSales(data)
        })
    }
    const getPrevSales = async () => {
        const prevMonth = subMonths(new Date(), 1)
        const start = startOfMonth(prevMonth)
        await getSales(
            format(start, saleDateFormat),
            format(prevMonth, saleDateFormat)
        ).then((data) => {
            setPrevSales(data)
        })
    }
    const goToSaleDetails = () => {
        navigate(routes.salesDetails)
    }
    const actualSalesAmmount = useMemo(() => {
        return sales.reduce(addSales, 0)
    }, [sales])

    const incress = useMemo(() => {
        const prevTotal = prevSales.reduce(addSales, 0)
        return getIncrease(prevTotal, actualSalesAmmount)
    }, [actualSalesAmmount, prevSales])

    const lastMovements = useMemo(() => {
        const sorted = sortSales(sales)
        return sorted.slice(0, 15)
    }, [sales])

    useEffect(() => {
        getActualSales()
        getPrevSales()
    }, [])

    return (
        <div className={styles.view}>
            <div className={styles.container}>
                <Text label={labels.monthSales} variant="H4 bold" />
                <div className={styles.indicators}>
                    {!!actualSalesAmmount && (
                        <>
                            <div className={styles.row}>
                                <Text
                                    label={`${incress}${labels.incressPercentage}`}
                                    variant={`subtitle2 ${styles.incress}`}
                                />
                                <div>
                                    <SvgIcon
                                        iconName={
                                            incress > 0
                                                ? iconNames.arrowUp
                                                : iconNames.arrowDown
                                        }
                                    />
                                </div>
                            </div>
                            <div className={styles.row}>
                                <Text
                                    label={`${currencyFormat(
                                        actualSalesAmmount
                                    )}`}
                                    variant={styles.greenTitle}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.container}>
                <div
                    className={`${styles.indicators} ${styles.lastMovementsRadius}`}
                >
                    <Text label={labels.lastMovements} variant="H6" />
                    <div className={styles.scrollColumn}>
                        <ScrollComponent>
                            {lastMovements.map((element) => (
                                <div
                                    className={`${styles.rowSale} ${styles.saleCard}`}
                                    key={element.idTransaccion}
                                >
                                    <SaleComponent sale={element} />
                                </div>
                            ))}
                        </ScrollComponent>
                    </div>
                </div>
                {features.bottomBar.sales && (
                    <div className={styles.buttonContainer}>
                        <Button
                            variant="successDark"
                            label={labels.goToSaleDetails}
                            onClick={goToSaleDetails}
                            type="button"
                            fullWidth
                            textVariant="md14"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
