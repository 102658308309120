import React from "react";
import Text from "../../components/text/Text";

interface INoOrdersProps {
  icon: string;
  label: string;
}

const NoOrders = ({ icon, label }: INoOrdersProps) => {
  return (
    <div className="no-products-container">
      <Text label={label} variant={"H5 no-orders-text-style"} />
      <img src={icon} className="no-products-icon-container" />
    </div>
  );
};

export default NoOrders;
