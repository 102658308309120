import React, { useState, FormEvent, useEffect } from "react";
import { formatOptionLabel } from "../../formatters";
import useDisclosure from "../../hooks/useDisclosure";
import { labels } from "../../labels";
import OutlinedInput from "../outlinedInput/OutlinedInput";
import { iconNames } from "../svgIcon/SvgIcon";
import styles from "./AsyncSelect.module.css";

interface IAsyncSelectProps {
  options: Option[];
  floatingLabel: string;
  placeholder?: string;
  formId?: string;
  errorMessage?: string;
  initValue?: string;
  onChange: (newValue: string | null) => void;
  disabled? :boolean;
}

interface IMenuProps {
  options: Option[];
  onSelect: (option: Option) => void;
}

function Menu(props: IMenuProps) {
  return (
    <div className={styles.menuContainer}>
      {props.options.map((element) => (
        <div
          key={element.id}
          onClick={() => props.onSelect(element)}
          className={styles.menuItem}
        >
          {element.label}
        </div>
      ))}
      {props.options.length === 0 && (
        <div className={styles.menuItem}>{labels.optionNotFound}</div>
      )}
    </div>
  );
}

export default function AsyncSelect(props: IAsyncSelectProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [foundedOptions, setFoundedOptions] = useState<Option[]>(props.options);
  const [optionSelected, setOptionSelected] = useState<Option | null>(null);
  const {
    open: focused,
    handleOpen: onFocus,
    handleClose: onBlur,
  } = useDisclosure();
  const {
    open: openMenu,
    handleOpen: handleOpenMenu,
    handleClose: handleCloseMenu,
  } = useDisclosure();

  const handleChangeSearch = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setSearchValue(newValue);
    setOptionSelected(null);
  };

  const handleSelect = (option: Option) => {
    setSearchValue("");
    setOptionSelected(option);
    handleCloseMenu();
    onBlur();
  };

  const handleSearch = () => {
    const founded = props.options.filter((element) =>
      formatOptionLabel(element.label).includes(formatOptionLabel(searchValue))
    );

    if (founded) {
      setFoundedOptions(founded);
    }
  };
  const handleBlur = () => {
    setTimeout(() => {
      onBlur();
    }, 200);
  };
  useEffect(() => {
    if (focused && searchValue) {
      handleOpenMenu();
    } else {
      handleCloseMenu();
    }
  }, [searchValue, focused]);

  useEffect(() => {
    props.onChange(optionSelected?.id || null);
  }, [optionSelected]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (searchValue) {
      timeout = setTimeout(() => {
        handleSearch();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);

  const showNoValid =
    searchValue && !optionSelected ? labels.noValidOption : "";

  useEffect(() => {
    const optionFounded = props.options.find(
      (element) => element.id === props.initValue
    );
    if (props.initValue && optionFounded) {
      setOptionSelected(optionFounded);
    }
  }, [props.initValue]);

  return (
    <div onClick={onFocus} onBlur={handleBlur}>
      <OutlinedInput
        formId={props.formId}
        onChange={handleChangeSearch}
        floatingLabel={props.floatingLabel}
        placeholder={props.placeholder}
        trailingIcon={iconNames.search}
        value={optionSelected ? optionSelected.label : searchValue}
        error={
          !optionSelected && !searchValue ? props.errorMessage : showNoValid
        }
        disabled={props.disabled}
      />
      {openMenu && <Menu options={foundedOptions} onSelect={handleSelect} />}
    </div>
  );
}
