import React, { useState, useEffect, useContext } from 'react'
import './Home.css'
import { useNavigate } from 'react-router-dom'
import uploadProducts from '../../assets/icons/icons-upload-products.svg'
import products from '../../assets/icons/icons-products.svg'
import orders from '../../assets/icons/icons-orders.svg'
import RedirectCard from '../../components/redirectCard/RedirectCard'
import '../../components/text/Text.css'
import Text from '../../components/text/Text'
import { clevertapEvents, labels } from '../../labels'
import routes from '../../router/routes'
import { track } from '../../ClevertapWrapper'
import { onMessageListener } from '../../firebase'
import { format } from 'date-fns'
import qrSvg from '../../assets/icons/icons-qr.svg'
import { FeatureContext } from '../../context/useFeatureFlagContext'

export default function Home() {
    const { features } = useContext(FeatureContext)
    const navigation = useNavigate()
    const [lat, setLat] = useState<number>(0)
    const [lng, setLng] = useState<number>(0)
    const currentUser = localStorage.getItem('currentUser')
    const user = currentUser && JSON.parse(currentUser)
    const clevertapData = {
        geoLocationLat: lat,
        geoLocationLong: lng,
        date: format(new Date(), 'yyyy/MM/dd'),
        hour: format(new Date(), 'kk:mm:ss'),
        // ID: commerceId
    }
    const pageLoadData = {
        pageName: 'Home',
        pageURL: routes.home,
        geoLocationLat: lat,
        geoLocationLong: lng,
        date: format(new Date(), 'yyyy/MM/dd'),
        hour: format(new Date(), 'kk:mm:ss'),
        codComercio: user?.sEstablecimiento,
        codLocal: user?.cLocal[0].sLocal,
    }

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude)
            setLng(position.coords.longitude)
        })
    }

    const navigateToProducts = () => {
        track(clevertapEvents.homeLandingProducts, clevertapData)
        navigation(`/${routes.products}`)
    }
    const navigateToInventory = () => {
        track(clevertapEvents.homeLandingInventory, clevertapData)
        navigation(`/${routes.inventory}`)
    }

    onMessageListener()
        .then((payload) => {
            console.log(
                'Web Push Payload',
                payload.notification.title,
                payload.notification.body
            )

            // new Notification(payload.notification.title, {
            //   body: payload.notification.body,
            // });
        })
        .catch((err) => console.log('failed: ', err))

    const navigateToOrders = () => {
        track(clevertapEvents.homeLandingOrders, clevertapData)
        navigation(`/${routes.orders}`)
    }
    const navigateToPayment = () => {
        navigation(`/${routes.payment}`)
    }

    const navigateToSales = () => {
        navigation(`/${routes.sales}`)
    }

    useEffect(() => {
        getLocation()
    }, [lat, lng])
    useEffect(() => {
        track(clevertapEvents.pageLoadnl, pageLoadData)
    }, [])
    return (
        <div className="container-hm" style={{ minWidth: '50%' }}>
            <div className="container-home">
                <div style={{ width: '100%' }}>
                    <div className="titleContainer-home">
                        <Text
                            label={labels.homeTitle}
                            variant="H5 noLineHeight-home"
                        />
                        <Text label={labels.homeSubtitle} variant="H5 v" />
                    </div>
                    {features.amimarket && (
                        <div className="twolandingContainer">
                            <div className="landingContainer-home">
                                <RedirectCard
                                    iconName={uploadProducts}
                                    title={labels.uploadProductsTitle}
                                    subtitle={labels.uploadProductsSubtitle}
                                    handleOnPress={navigateToProducts}
                                />
                            </div>
                            {features.amimarket &&
                                features.bottomBar.inventory && (
                                    <div className="landingContainer-home">
                                        <RedirectCard
                                            iconName={products}
                                            title={labels.myProductsTitle}
                                            subtitle={labels.myProductsSubtitle}
                                            handleOnPress={navigateToInventory}
                                        />
                                    </div>
                                )}
                            {features.amimarket &&
                                features.amimarket &&
                                features.bottomBar.orders && (
                                    <div className="landingContainer-home">
                                        <RedirectCard
                                            iconName={orders}
                                            title={labels.myOrdersTitle}
                                            subtitle={labels.myOrdersSubtitle}
                                            handleOnPress={navigateToOrders}
                                        />
                                    </div>
                                )}
                        </div>
                    )}
                </div>
            </div>
            <div className="buttonContainer-home">
                <div className="greenButton-home" onClick={navigateToPayment}>
                    <img src={qrSvg} className="left-icon-home" />
                    <Text
                        label={labels.paidWithAmipass}
                        variant="H6  buttonText-home"
                    />
                </div>
                <div className="blueButton" onClick={navigateToSales}>
                    <Text
                        label={labels.goToMonthSells}
                        variant="H6  buttonText-home"
                    />
                </div>
            </div>
        </div>
    )
}
