import React, { useEffect, useState } from "react";
import OrdersHeaderComponent from "../../components/ordersHeader/OrdersHeaderComponent";
import "./Orders.css";
import { clevertapEvents } from "../../labels";
import { track } from "../../ClevertapWrapper";
import routes from "../../router/routes";
import { format } from "date-fns";
import NewOrders from "./tabs/NewOrders";
import Preparing from "./tabs/Preparing";
import ToDeliver from "./tabs/ToDeliver";
import Record from "./tabs/record/Record";

export default function Orders() {
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [activeTag, setActiveTag] = useState<number>(0);

  const pageLoadData = {
    pageName: "Mis pedidos",
    pageURL: routes.orders,
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    codComercio: user?.sEstablecimiento,
    codLocal: user?.cLocal[0].sLocal,
  };

  const handleOnChangeTab = (tab: number) => {
    setActiveTag(tab);
  };

  useEffect(() => {
    track(clevertapEvents.pageLoamped, pageLoadData);
  }, []);

  return (
    <div className="background-orders">
      <div className="all-orders-container">
        <OrdersHeaderComponent
          onChangeTab={handleOnChangeTab}
          activeTab={activeTag}
        />
        {/* <div className="scroll-orders-container"> */}
        {activeTag === 0 && <NewOrders />}
        {activeTag === 1 && <Preparing />}
        {activeTag === 2 && <ToDeliver />}
        {activeTag === 3 && <Record />}
        {/* </div> */}
      </div>
    </div>
  );
}
