import React, {
  useState,
  ChangeEvent,
  FormEvent,
  useRef,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import SquareCheckBox from "../../components/checkBox/SquareCheckBox";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import plus from "../../assets/icons/icons-plus-yellow.svg";
import routes from "../../router/routes";
import { labels, onlineConfig } from "../../labels";
import "./OnlineStoreInformationScreen.css";
import OutlinedInput from "../../components/outlinedInput/OutlinedInput";
import StepIndicator from "../../components/stepIndicator/StepIndicator";
import {
  ConfigState,
  getStateData,
  prepareForNavigation,
} from "./configurationsUtils";
import { getCommerceInfo, onlineStoreStepOne } from "../../services/commerce";
import { uploadImage } from "../../services/products";
import { useLoadingContext } from "../../context/spinnerContext";

const viewStep = 1;

const valueOrEmpty = (value: string) => value ?? "";
const valueOrFalse = (value: boolean) => value ?? false;
export default function OnlineStoreInformationScreen() {
  const navigation = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const loadingContext = useLoadingContext();

  const { state, data } = getStateData("storeConfig", {});
  const storeConfig = data as ConfigState;

  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal;
  const currentUserJSON = JSON.parse(currentUser ?? "{}");
  const [logo, setLogo] = useState<string>(storeConfig.logo ?? "");
  const [storeDescription, setStoreDescription] = useState<string>(() =>
    valueOrEmpty(storeConfig.storeDescription)
  );

  const [sEmailContacto, setSEmailContacto] = useState<string>(() =>
    valueOrEmpty(storeConfig.sEmailContacto)
  );
  const [minAmmount, setMinAmmount] = useState<string>(() =>
    valueOrEmpty(storeConfig.minAmmount)
  );
  const [emailError, setEmailError] = useState<string>("");

  const [image, setImage] = useState<File | null>(null);
  const [base64Img, setBase64Img] = useState<
    ArrayBuffer | string | null | undefined
  >(storeConfig.base64Img ?? null);
  const [allDayDel, setAllDayDel] = useState<boolean>(() =>
    valueOrFalse(storeConfig.allDayDel)
  );
  const [receptionDesde, setReceptionDesde] = useState<string>(() =>
    valueOrEmpty(storeConfig.receptionDesde)
  );
  const [receptionHasta, setReceptionHasta] = useState<string>(() =>
    valueOrEmpty(storeConfig.receptionHasta)
  );
  const [lunReceptionHour, setLunReceptionHour] = useState<boolean>(() =>
    valueOrFalse(storeConfig.lunReceptionHour)
  );
  const [marReceptionHour, setMarReceptionHour] = useState<boolean>(() =>
    valueOrFalse(storeConfig.marReceptionHour)
  );
  const [mierReceptionHour, setMierReceptionHour] = useState<boolean>(() =>
    valueOrFalse(storeConfig.mierReceptionHour)
  );
  const [jueReceptionHour, setJueReceptionHour] = useState<boolean>(() =>
    valueOrFalse(storeConfig.jueReceptionHour)
  );
  const [vieReceptionHour, setVieReceptionHour] = useState<boolean>(() =>
    valueOrFalse(storeConfig.vieReceptionHour)
  );
  const [sabReceptionHour, setSabReceptionHour] = useState<boolean>(() =>
    valueOrFalse(storeConfig.sabReceptionHour)
  );
  const [domReceptionHour, setDomReceptionHour] = useState<boolean>(() =>
    valueOrFalse(storeConfig.domReceptionHour)
  );

  const handleContinue = () => {
    prepareForNavigation(state, {
      storeConfig: {
        base64Img,
        logo,
        storeDescription,
        sEmailContacto,
        minAmmount,
        receptionDesde,
        receptionHasta,
        lunReceptionHour,
        marReceptionHour,
        mierReceptionHour,
        jueReceptionHour,
        vieReceptionHour,
        sabReceptionHour,
        domReceptionHour,
        allDayDel,
      },
    });

    navigation(`/${routes.optionOnlineStore}`);
  };

  const handleSendData = async () => {
    const logoUrl = await uploadImage(
      (base64Img as string).replace(/^data:image.+;base64,/, "")
    );
    const daysData = allDayDel
      ? {
          startTimeAttend: "00:00",
          endtimeAttend: "23:59",
          mondayAttend: true,
          tuesdayAttend: true,
          wednesdayAttend: true,
          thursdayAttend: true,
          fridayAttend: true,
          saturdayAttend: true,
          sundayAttent: true,
        }
      : {
          startTimeAttend: receptionDesde,
          endtimeAttend: receptionHasta,
          mondayAttend: lunReceptionHour,
          tuesdayAttend: marReceptionHour,
          wednesdayAttend: mierReceptionHour,
          thursdayAttend: jueReceptionHour,
          fridayAttend: vieReceptionHour,
          saturdayAttend: sabReceptionHour,
          sundayAttent: domReceptionHour,
        };
    loadingContext?.onOpen();
    const toNextStep = await onlineStoreStepOne({
      commerceInternalID: `${currentUserJSON?.sEstablecimiento ?? ""}${
        currentUserJSON?.cLocal?.[0]?.sLocal ?? ""
      }`,
      logo: base64Img ? base64Img : null,
      businessDescription: storeDescription,
      orderReceiptMail: sEmailContacto,
      minimalOrderAmount: +minAmmount,
      allDay: allDayDel,
      ...daysData,
    });
    loadingContext?.onClose();
    if (toNextStep) {
      handleContinue();
    }
  };

  const dateReceptionSelection = [
    {
      dayLetter: onlineConfig.daySelectL,
      day: onlineConfig.daySelectMonday,
      setDaySelected: () => setLunReceptionHour(!lunReceptionHour),
      isSelected: lunReceptionHour,
      dateDesde: receptionDesde,
      dateHasta: receptionHasta,
    },
    {
      dayLetter: onlineConfig.daySelectM,
      day: onlineConfig.daySelectTuesday,
      setDaySelected: () => setMarReceptionHour(!marReceptionHour),
      isSelected: marReceptionHour,
      dateDesde: receptionDesde,
      dateHasta: receptionHasta,
    },
    {
      dayLetter: onlineConfig.daySelectM,
      day: onlineConfig.daySelectWednesday,
      setDaySelected: () => setMierReceptionHour(!mierReceptionHour),
      isSelected: mierReceptionHour,
      dateDesde: receptionDesde,
      dateHasta: receptionHasta,
    },
    {
      dayLetter: onlineConfig.daySelectJ,
      day: onlineConfig.daySelectThursday,
      setDaySelected: () => setJueReceptionHour(!jueReceptionHour),
      isSelected: jueReceptionHour,
      dateDesde: receptionDesde,
      dateHasta: receptionHasta,
    },
    {
      dayLetter: onlineConfig.daySelectV,
      day: onlineConfig.dayselectFriday,
      setDaySelected: () => setVieReceptionHour(!vieReceptionHour),
      isSelected: vieReceptionHour,
      dateDesde: receptionDesde,
      dateHasta: receptionHasta,
    },
    {
      dayLetter: onlineConfig.daySelectS,
      day: onlineConfig.daySelectSaturday,
      setDaySelected: () => setSabReceptionHour(!sabReceptionHour),
      isSelected: sabReceptionHour,
      dateDesde: receptionDesde,
      dateHasta: receptionHasta,
    },
    {
      dayLetter: onlineConfig.daySelectD,
      day: onlineConfig.daySelectSunday,
      setDaySelected: () => setDomReceptionHour(!domReceptionHour),
      isSelected: domReceptionHour,
      dateDesde: receptionDesde,
      dateHasta: receptionHasta,
    },
  ];
  const dateReception = (dateData: any) => {
    return (
      <>
        <div>
          <div className="daysContainer-OSConfig receptionTitle-OSConfig">
            {dateData.map((day: any) => (
              <>
                <div
                  onClick={day.setDaySelected}
                  className={
                    day.isSelected
                      ? "dayContainerBlue-OSConfig"
                      : "dayContainerGrey-sConfig"
                  }
                >
                  <Text label={day.dayLetter} variant="Subtitle2" />
                </div>
              </>
            ))}
          </div>
          {lunReceptionHour ||
          marReceptionHour ||
          mierReceptionHour ||
          jueReceptionHour ||
          vieReceptionHour ||
          sabReceptionHour ||
          domReceptionHour ? (
            <>
              <Text label={labels.schedule} variant="Small-2" />
              <div>
                <div className="commonContainer-OSConfig spaceBetween-OSConfig">
                  <div className="commonContainer-OSConfig centered-sConfig spaceBetween-OSConfig commonWidth-OSConfig">
                    <Text
                      label={labels.sConfigDesde}
                      variant="Small-2 grey900-OSConfig "
                    />
                    <input
                      placeholder={""}
                      type="time"
                      value={receptionDesde}
                      onChange={(value: ChangeEvent<HTMLInputElement>) =>
                        setReceptionDesde(value.target.value)
                      }
                      className={
                        "Body1-16 grey900-OSConfig timeInputContainer-OSConfig"
                      }
                    />
                  </div>

                  <div className="commonContainer-OSConfig centered-OSConfig spaceBetween-OSConfig commonWidth-OSConfig">
                    <Text
                      label={labels.sConfigHasta}
                      variant="Small-2 grey900-OSConfig"
                    />
                    <input
                      placeholder={""}
                      type="time"
                      value={receptionHasta}
                      onChange={(value: ChangeEvent<HTMLInputElement>) =>
                        setReceptionHasta(value.target.value)
                      }
                      className={
                        "Body1-16 grey900-OSConfig timeInputContainer-OSConfig"
                      }
                    />
                  </div>
                </div>
                <div className="commonContainer-OSConfig spaceBetween-OSConfig ">
                  <div className="divider-OSConfig" />
                  <div className="divider-OSConfig" />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  };
  const handleFile = (e: FormEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0] || null;
    setImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => {
      const baseURL = reader.result;
      const tmp = baseURL?.slice(23);
      setBase64Img(tmp);
    };
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setLogo(objectUrl);
    }
  };
  const handleClick = () => {
    if (ref.current != null) {
      ref?.current?.click();
    }
  };
  const navigateBack = () => {
    navigation(`/${routes.storeConfigurations}`);
  };
  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChangeEmail = (value: any) => {
    if (!isValidEmail(value.currentTarget.value)) {
      setEmailError("Email is invalid");
    } else {
      setEmailError("");
    }
    setSEmailContacto(value.currentTarget.value);
  };
  const handleDescriptionInput = (value: any) => {
    setStoreDescription(value.target.value);
  };
  const handleChangeMinAmount = (value: any) => {
    setMinAmmount(value.target.value);
  };
  const getECommerce = async () => {
    loadingContext?.onOpen();
    try {
      await getCommerceInfo(commerceId).then((resp) => {
        loadingContext?.onClose();
        const logoRefresh = `${resp.logo}?time=${new Date()}`;
        setLogo(logoRefresh);
      });
    } catch (err) {
      loadingContext?.onClose();
      console.log("Error al cargar datos del comercio.");
    }
  };
  useEffect(() => {
    if (commerceId) {
      getECommerce();
    }
  }, []);
  return (
    <div className="background-OSConfig">
      <div className="innerContainer-OSConfig">
        <div className="commonContainer-OSConfig">
          <img
            src={arrowBack}
            className="iconContainer-OSConfig "
            onClick={navigateBack}
          />
        </div>
        <StepIndicator view={viewStep} />
        <div className="contentContainer">
          <div className="storeNameContainer-sConfig">
            <div>
              <Text
                label={labels.storeLogo}
                variant="subtitle1 textStyle-OSConfig logoTitleStyle-OSConfig "
              />
              <img src={logo} className="logo-img-OSConfig" />
              <div className="plusIconContainer-OSConfig" onClick={handleClick}>
                <img src={plus} />
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  ref={ref}
                  onChange={handleFile}
                />
              </div>
            </div>
            <div className="textContainer-OSConfig">
              <Text
                label={labels.uploadLogo}
                variant="subtitle1 textStyle-OSConfig "
              />
            </div>
          </div>

          <OutlinedInput
            value={storeDescription}
            onChange={handleDescriptionInput}
            maxLength={117}
            floatingLabel={labels.storeDescription}
            placeholder={labels.storeDescriptionPlaceholder}
          />
          <div>
            <Text
              label={storeDescription.length + "/117"}
              variant="Body3-12 grey900-OSConfig lenghtCounter-OSConfig"
            />
          </div>

          <div className="emailContainer-OSConfig">
            <OutlinedInput
              value={sEmailContacto}
              onChange={handleChangeEmail}
              maxLength={117}
              floatingLabel={labels.emailReception}
              placeholder={labels.emailReceptionPlaceholder}
            />
          </div>
          <Text
            label={"Horario recepción de pedidos para tienda online."}
            variant="subtitle1 grey900-OSConfig"
          />
          <div className="commonRow-OSConfig">
            <div className="receptionContainer-OSConfig">
              {dateReception(dateReceptionSelection)}
              <div className="receptionTitle-OSConfig">
                <SquareCheckBox
                  checkId="reception24-7"
                  labelClassName="Body3-12 "
                  label={labels.allDayReception}
                  onClick={() => setAllDayDel(!allDayDel)}
                  checked={allDayDel}
                />
              </div>
            </div>
            <div className="minAmmountContainer-OSConfig">
              <OutlinedInput
                value={minAmmount}
                onChange={handleChangeMinAmount}
                maxLength={117}
                floatingLabel={labels.sConfigMinAmount}
                placeholder={labels.minAmmountPlaceholder}
              />
              <Text
                label={labels.minimumAmmountForOnlineStore}
                variant="Body1-16 italicText-OSConfig "
              />
            </div>
          </div>
        </div>
      </div>
      <button className={"buttonContinue-OSConfig"} onClick={handleSendData}>
        <a className={"ButtonMd-14"}>{labels.continue}</a>
      </button>
    </div>
  );
}
