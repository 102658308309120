import React, { useEffect } from "react";
import { labels } from "../../../labels";
import SvgIcon, { iconNames } from "../../svgIcon/SvgIcon";
import styles from "./massiveUploadConfirm.module.css";

interface IMassiveUploadConfirmProps {
  onContinue: () => void;
  onClose: () => void;
  haveAllFiles: boolean;
}

const WithoutAllFiles = () => {
  return (
    <p className="subtitle1">
      {labels.suggest} <b>{labels.attachImages}</b>
      <br />
      {labels.publishWithoutImages}{" "}
      <b>
        <i>{labels.amipass}</i>
      </b>{" "}
      {labels.bussinessHours}
    </p>
  );
};

export default function MassiveUploadConfirm({
  onContinue,
  onClose,
  haveAllFiles,
}: IMassiveUploadConfirmProps) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <SvgIcon
          iconName={
            haveAllFiles ? iconNames.circleClock : iconNames.warningImages
          }
        />
      </div>
      {haveAllFiles ? (
        <p className="Body1-16">
          {labels.team}{" "}
          <b>
            <i>{labels.amipass}</i>
          </b>{" "}
          {labels.massiveConfirm}
        </p>
      ) : (
        <WithoutAllFiles />
      )}
      <b className="Body1-16 bold">{labels.wantContinue}</b>
      <div className={styles.buttons}>
        <button onClick={onClose} className={styles.buttonBack}>
          {labels.back}
        </button>
        <button
          onClick={onContinue}
          className={
            haveAllFiles
              ? styles.buttonContinue
              : `${styles.buttonContinue} ${styles.withoutImagesButton}`
          }
        >
          {haveAllFiles ? labels.continue : labels.buttonPublishWithoutImages}
        </button>
      </div>
    </div>
  );
}
