import React, { useState, useEffect } from "react";
import "./OrderHistoryComponent.css";
import Text from "../text/Text";
import OrderDetailModal from "../modal/OrderDetailModal";
import approvedIcon from "../../assets/icons/icons-green-approved.svg";
import rejectedIcon from "../../assets/icons/icons-red-rejected.svg";
import moment from "moment";
import "moment/locale/es";
import { labels } from "../../labels";

interface OProps {
  statusID: number;
  orderTypeID: number;
  id: string;
  client: string;
  orderNumber: string;
  dateCreated: string;
  ammount: string;
  acceptOrder?: () => void;
  rejectOrder?: () => void;
}

export default function OrderComponent(props: OProps): React.ReactElement {
  const time = moment(props.dateCreated).fromNow();
  const [minWidth, setMinWidth] = useState<boolean>(
    window.matchMedia("(min-width: 642px)").matches
  );
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [showRejectModal, setshowRejectModal] = useState<boolean>(false);

  useEffect(() => {
    window
      .matchMedia("(min-width: 642px)")
      .addEventListener("change", (e) => setMinWidth(e.matches));
  }, []);

  const handleCloseDetailModal = () => {
    setShowDetail(false);
  };
  const handleOpenDetailModal = () => {
    setShowDetail(true);
  };
  const handleCloseRejectModal = () => {
    setshowRejectModal(false);
  };
  const handleOpenRejectModal = () => {
    setshowRejectModal(true);
  };

  return (
    <>
      <div className={"order-container-OHC"}>
        <div className="first-row-container-OHC">
          {minWidth && (
            <div className="title-container-OHC">
              <div className="data-container-OHC">
                <Text
                  label={labels.orderComponentClient}
                  variant="Small text-style-OHC"
                />
                <Text
                  label={props.client}
                  variant="Caption3-13 text-style-OHC"
                />
              </div>
              <div className="data-container-OHC">
                <Text
                  label={labels.orderComponentOrderID}
                  variant="Small text-style-OHC"
                />
                <Text
                  label={props.orderNumber}
                  variant="Caption3-13 text-style-OHC"
                />
              </div>
              <div className="data-container-OHC">
                <Text
                  label={labels.orderComponentTime}
                  variant="Small text-style-OHC"
                />
                <Text label={time} variant="Caption3-13 text-style-OHC" />
              </div>
              <div className="data-container-OHC">
                <Text
                  label={labels.orderComponentAmount}
                  variant="Small text-style-OHC"
                />
                <Text
                  label={"$" + props.ammount}
                  variant="Caption3-13 text-style-OHC"
                />
              </div>
            </div>
          )}
          {!minWidth && (
            <div className="mobile-data-container-OHC">
              <div className="mobile-data-column-container-OHC">
                <Text
                  label={props.client}
                  variant="Caption3-13 text-style-OHC"
                />
                <Text
                  label={"N°" + props.orderNumber}
                  variant="Caption3-13 text-style-OHC"
                />
                <Text label={time} variant="Small text-style-OHC" />
              </div>
            </div>
          )}
          {props.statusID === 4 && (
            <div className="icon-text-container-OHC">
              <img src={approvedIcon} className="icon-style-OHC" />
              <Text
                variant={"Subtitle2 delivered-text-style-OCH"}
                label={labels.orderComponentDelivered}
              />
            </div>
          )}
          {props.statusID === -1 && (
            <div className="icon-text-container-OHC">
              <img src={rejectedIcon} className="icon-style-OHC" />
              <Text
                variant={"Subtitle2 rejected-text-style-OCH"}
                label={labels.orderComponentRejectedStore}
              />
            </div>
          )}
          {props.statusID === -2 && (
            <div className="icon-text-container-OHC">
              <img src={rejectedIcon} className="icon-style-OHC" />
              <Text
                variant={"Subtitle2 rejected-text-style-OCH"}
                label={labels.orderComponentRejectedClient}
              />
            </div>
          )}
        </div>
        <div className="detail-container-OHC" onClick={handleOpenDetailModal}>
          <Text
            label={labels.orderComponentDetail}
            variant={
              minWidth
                ? "subtitle1 text-style-big-OHC"
                : "Subtitle3 text-style-OHC"
            }
          />
        </div>
      </div>
      <OrderDetailModal
        id={props.id}
        show={!!showDetail}
        handleClose={handleCloseDetailModal}
      />
    </>
  );
}
