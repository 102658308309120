import { init_feature_context_value } from '../context/useFeatureFlagContext'
import { BASEAPI } from './constants'

const getCommerceInfo = async (commerceId: string) => {
    const res = await fetch(`${BASEAPI}commerces/info/${commerceId}`, {
        method: 'GET',
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.error(error)
            return null
        })
    return res
}

const getAllOrdersByStatus = async (
    commerce_id: string,
    status: number,
    chunk: number,
    page: number
) => {
    const res = await fetch(
        `${BASEAPI}apimac/api/v1/mac/order/orders/${commerce_id}/${status}?chunk=${chunk}&page=${page}`,
        {
            method: 'GET',
        }
    )
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.error(error)
            return null
        })
    return res
}
export const saleDateFormat = 'yyyy-MM-dd'
export interface TransactionResponse {
    idTransaccion: string
    sTipoTransaccion: string
    dTransaccion: string
    nMonto: string
    sRutPantalla: string
    sRut: string
    sNombreCliente: string
    sEstadoTx: string
    sFacturada: string
    sToken: string
}
const API_COMMERCE = 'wsAppComercios/'
async function getTransaccionesByComercioLocal(
    idEcommerce: string,
    idLocal: string,
    dateFrom: string,
    dateTo: string,
    top: number
) {
    let message = ''
    let resStatus = 200
    let responseService = {
        message: '',
        responseJson: [] as TransactionResponse[],
        status: resStatus,
    }

    await fetch(
        `${BASEAPI}${API_COMMERCE}TransaccionesLocal/${idEcommerce}/${idLocal}/${dateFrom}/${top}/${dateTo}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            resStatus = response.status

            switch (response.status) {
                case 200:
                    message = 'success'
                    break
                case 201:
                    message = 'created'
                    break
                case 409:
                    message = 'Error 409'
                    break
                case 400:
                    message = 'Solicitud invalida'
                    break
                case 500:
                    message = 'Error en el servidor, intente nuevamente'
                    break
                default:
                    message = 'Error no controlado'
                    break
            }

            const contentType = response.headers.get('content-type')

            if (contentType && contentType.indexOf('application/json') !== -1) {
                return response.text().then(function (text) {
                    return text ? JSON.parse(text) : {}
                })
            } else {
                return response.text()
            }
        })
        .then((responseJson: TransactionResponse[]) => {
            if (responseJson !== null) {
                responseService = { message, responseJson, status: resStatus }
            } else {
                responseService = {
                    message,
                    responseJson: [] as TransactionResponse[],
                    status: resStatus,
                }
            }
        })
        .catch((error) => {
            console.error(error)
        })

    return responseService
}
async function getTransaccionesLocalPorFechasTipoPago(
    idEcommerce: string,
    idLocal: string,
    dateFrom: string,
    dateTo: string
) {
    const resultServices = await getTransaccionesByComercioLocal(
        idEcommerce,
        idLocal,
        dateFrom,
        dateTo,
        10000
    )
    if (resultServices.status === 200) {
        return resultServices.responseJson.reduce(
            (acc, el): TransactionResponse[] => {
                if (
                    el.sTipoTransaccion !== 'RV' &&
                    el.sEstadoTx !== 'RECHAZADA' &&
                    el.sEstadoTx !== 'REVERSADA'
                ) {
                    return [...acc, el]
                }
                return acc
            },
            [] as TransactionResponse[]
        )
    }
    return resultServices.responseJson
}
export interface Token {
    idtoken: string
    descripcion: string
    stexto1: string
    stexto2: string
}

const getInfoTokens = async () => {
    let message = ''
    let resStatus = 200
    const responseJson = [] as Token[]
    let responseService = {
        message: '',
        responseJson: [] as Token[],
        status: resStatus,
    }

    await fetch(`${BASEAPI}infotoken/tokens`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            resStatus = response.status

            switch (response.status) {
                case 200:
                    message = 'success'
                    break
                case 201:
                    message = 'created'
                    break
                case 409:
                    message = 'Error 409'

                    break
                case 400:
                    message = 'Solicitud invalida'
                    break
                case 426:
                    message = 'Force update'
                    resStatus = 426
                    break
                case 500:
                    message = 'Error en el servidor, intente nuevamente'
                    break
                default:
                    message = 'Error no controlado'
                    break
            }
            resStatus = response.status
            responseService = { message, responseJson, status: resStatus }

            const contentType = response.headers.get('content-type')

            if (contentType && contentType.indexOf('application/json') !== -1) {
                return response.text().then(function (text) {
                    return text ? JSON.parse(text) : {}
                })
            } else {
                return response.text()
            }
        })
        .then((responseJson) => {
            if (responseJson !== null) {
                responseService = { message, responseJson, status: resStatus }
            } else {
                responseService = {
                    message,
                    responseJson: [],
                    status: resStatus,
                }
            }
        })
        .catch((error) => {
            console.error(error)
        })

    return responseService
}

interface IOnlineStoreStepOneBody {
    commerceInternalID: string
    businessDescription: string
    orderReceiptMail: string
    minimalOrderAmount: number
    mondayAttend: boolean
    tuesdayAttend: boolean
    wednesdayAttend: boolean
    thursdayAttend: boolean
    fridayAttend: boolean
    saturdayAttend: boolean
    sundayAttent: boolean
    startTimeAttend: string | null
    endtimeAttend: string | null
    logo: ArrayBuffer | string | null
    allDay: boolean
}
const onlineStoreStepOne = async (data: IOnlineStoreStepOneBody) => {
    const res = await fetch(
        `${BASEAPI}APIOnBoardingMerchant/merchantOnline/step1`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    )
        .then((response) => {
            return response.status === 200
        })
        .catch((error) => {
            console.error(error)
            return false
        })
    return res
}

export interface CommuneStepTwo {
    commerceInternalID: string
    communeDelivery: string
    amountDelivery: number
    sameDayDelivery: true
    timeDelivery: string
    typeDelivery: number | null //formaDespacho (id 1:hora id 2:dias)
    hourRange: string | null //tramoHoras (24487296120)
    hourLimit: string | null //horaTope (6:00PM) 18:00:00
    dayReception: number | null // 1: 1 día antes2:2 dias antes 0:Mismo dia
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
    sunday: boolean
}
export interface IOnlineStoreStepTwoBody {
    commerceInternalID: string
    hasDelivery: boolean
    hasWithdrawal: boolean
    hasFreeDelivery: boolean
    minimalAmountFreeDelivery: string
    commune: CommuneStepTwo[]
}

const onlineStoreStepTwo = async (data: IOnlineStoreStepTwoBody) => {
    const res = await fetch(
        `${BASEAPI}APIOnBoardingMerchant/merchantOnline/step2`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    )
        .then((response) => {
            return response.status === 200
        })
        .catch((error) => {
            console.error(error)
            return false
        })
    return res
}
const getConditions = async (commerceInternalID: string) => {
    return await fetch(
        `${BASEAPI}APIOnBoardingMerchant/merchantOnline/conditions?commerceInternalID=${commerceInternalID}`,
        {
            method: 'GET',
        }
    )
        .then((response) => {
            return response.json()
        })
        .then((responseJson) => {
            if (responseJson != null) {
                return responseJson
            }
        })
        .catch((error) => {
            console.error(error)
        })
}
const postStep4 = async (data: any) => {
    return await fetch(`${BASEAPI}APIOnBoardingMerchant/merchantOnline/step4`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.status === 200) {
                return true
            }
            return false
        })
        .catch((error) => {
            console.error(error)
            return false
        })
}

const postStep3 = async (data: any) => {
    return await fetch(`${BASEAPI}APIOnBoardingMerchant/merchantOnline/step3`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.status === 200) {
                return true
            }
            return false
        })
        .catch((error) => {
            console.error(error)
            return false
        })
}
const getFeatureFlags = async () => {
    const currentUser = localStorage.getItem('currentUser')

    if (currentUser) {
        const user = JSON.parse(currentUser)

        const local =
            Array.isArray(user?.cLocal) && user?.cLocal.length > 0
                ? user.cLocal[0].sLocal
                : ''

        const commerceId = (user?.sEstablecimiento || '') + local

        return await fetch(
            `${BASEAPI}apimac/api/v1/mac/features/commerce/${commerceId}`,
            {
                method: 'GET',
            }
        )
            .then((response) => {
                return response.json()
            })

            .catch((error) => {
                console.error(error)
                return init_feature_context_value
            })
    } else return init_feature_context_value
}
export {
    getInfoTokens,
    getCommerceInfo,
    getAllOrdersByStatus,
    getTransaccionesByComercioLocal,
    getTransaccionesLocalPorFechasTipoPago,
    onlineStoreStepOne,
    onlineStoreStepTwo,
    getConditions,
    postStep3,
    postStep4,
    getFeatureFlags,
}
