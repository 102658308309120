import React, { useEffect } from "react";
import OrderComponent from "../../../components/orderComponent/OrderComponent";
import useLazyLoader, { PAGE_CHUNK } from "../../../hooks/useLazyLoader";
import { getAllOrdersByStatus } from "../../../services/orders";
import NoOrders from "../NoOrders";
import orderInProgress from "../../../assets/icons/icons-order-progress.svg";
import ScrollComponent from "../../../components/ScrollComponent";

import { labels } from "../../../labels";
const preparationId = "preparationId";

export default function Preparing() {
  const { data, lastItemRef, onScroll, refreshWithChunk, page } = useLazyLoader(
    getAllOrdersByStatus,
    preparationId,
    2
  );
  const handleRefreshProcess = () => {
    refreshWithChunk(page * PAGE_CHUNK);
  };
  useEffect(() => {
    const refreshNew = setInterval(() => {
      refreshWithChunk(page * PAGE_CHUNK);
    }, 7000);
    return () => {
      clearInterval(refreshNew);
    };
  }, [page]);

  return (
    <ScrollComponent onScroll={onScroll}>
      {data.length !== 0 && (
        <div className="centered-orders-container">
          {data.map((item: TypeHistory, index: number) => (
            <div
              key={item.id}
              className="top-margin"
              id={index === data.length - 1 ? preparationId : ""}
              ref={(ref) => {
                if (index === data.length - 1) {
                  lastItemRef.current = ref;
                }
              }}
            >
              <OrderComponent
                orderTypeID={item.orderHeader.orderTypeID}
                statusID={item.orderHeader.statusID}
                id={item.id}
                client={`${item.consumer.firstName} ${item.consumer.lastName}`}
                orderNumber={item.orderHeader.id}
                dateCreated={item.orderHeader.creationDate}
                ammount={item.orderHeader.finalAmount}
                hasScheduledPurchase={item.orderSchedule}
                hasDelivery={item.orderDelivery}
                acceptOrder={handleRefreshProcess}
                rejectOrder={handleRefreshProcess}
              />
            </div>
          ))}
        </div>
      )}
      {data.length === 0 && (
        <NoOrders icon={orderInProgress} label={labels.noOrdesInProgress} />
      )}
    </ScrollComponent>
  );
}
