import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./successfulPayment.module.css";
import "../../components/text/Text.css";
import Text from "../../components/text/Text";
import routes from "../../router/routes";
import success from "../../assets/icons/icons-success.svg";
import { labels } from "../../labels";
import Moment from "moment";
export default function SuccessfulPayment() {
  const state = useLocation();

  const navigation = useNavigate();
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const navigateToHome = () => {
    navigation(`${routes.home}`);
  };
  const navigateToPayment = () => {
    navigation(`/${routes.payment}`);
  };
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <img src={success} className={styles.iconStyle} />
      </div>
      <div className={styles.textContainer}>
        <Text
          label={labels.paymentVoucher}
          variant={`H6 ${styles.commontTextLineHeight} `}
        />
        <Text
          label={user?.cLocal[0]?.sNombreLocal}
          variant={`H4 ${styles.commontTextLineHeight}`}
        />
        <Text label={user.sDireccion} variant="md-medium" />
        <Text
          label={`$` + state.state.data[0].nMonto}
          variant={`H2 ${styles.commontTextLineHeight} `}
        />
        <div className={styles.rowContainer}>
          <Text
            label={labels.paymentAuthorizationCode}
            variant={`Body2-14 bold  ${styles.commontTextLineHeight} `}
          />
          <Text
            label={state.state.data[0].CodAutorizador}
            variant={`Body2-14 bold  ${styles.commontTextLineHeight} ${styles.blue}`}
          />
        </div>
        {/* <div className={styles.rowContainer}>
          <Text
            label={labels.paymentClient}
            variant={`Body2-14 bold  ${styles.commontTextLineHeight} `}
          />
          <Text
            label="Cherie Lourdes Gonzales D."
            variant={`Body2-14 bold  ${styles.commontTextLineHeight}`}
          />
        </div> */}
        <div className={styles.rowContainer}>
          <Text
            label={labels.paymentDate}
            variant={`Body2-14 bold  ${styles.commontTextLineHeight} `}
          />
          <Text
            label={Moment(state.state.data[0].Fecha).format(
              "DD/MM/YYYY - HH:MM"
            )}
            variant={`Body2-14 bold  ${styles.commontTextLineHeight}  ${styles.blue}`}
          />
        </div>
        <Text label="FDD Innovación y crecimiento S.A" variant={`Small-2`} />
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.yellowButtonContainer} onClick={navigateToHome}>
          <Text label={labels.paymentBackHome} variant={"ButtonLg-16"} />
        </div>
        <div className={styles.blueButtonContainer} onClick={navigateToPayment}>
          <Text label={labels.backPayment} variant={"ButtonLg-16"} />
        </div>
      </div>
    </div>
  );
}
