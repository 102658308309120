import React from "react";
import "./SvgIcon.css";
import IconShow from "../../assets/icons/icons-show.svg";
import IconHide from "../../assets/icons/icons-hide.svg";
import IconError from "../../assets/icons/icons-error.svg";
import IconWarning from "../../assets/icons/icons-warning.svg";
import Next from "../../assets/icons/Next.svg";
import Back from "../../assets/icons/Back.svg";
import ResponseOK from "../../assets/icons/icons-response-ok.svg";
import CheckCircle from "../../assets/icons/icons-check-circle.svg";
import IconSearch from "../../assets/icons/icons-search.svg";
import Plus from "../../assets/icons/icons-plus.svg";
import Trash from "../../assets/icons/icons-trash.svg";
import Remove from "../../assets/icons/icons-remove-item.svg";
import Download from "../../assets/icons/icons-cloud-download.svg";
import CircleClock from "../../assets/icons/check-circle-clock-icon.svg";
import WarningRed from "../../assets/icons/icons-error-red.svg";
import WarningImages from "../../assets/icons/icons-warning-images.svg";
import ArrowUp from "../../assets/icons/arrow-up.svg";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import Sale from "../../assets/icons/icons-currency.svg";

interface SvgIconProps {
  iconName: IconName;
  width?: string;
}
interface IconNames {
  [name: string]: IconName;
}
export const iconNames: IconNames = {
  show: "show",
  hide: "hide",
  search: "search",
  error: "error",
  warning: "warning",
  shortArrowNext: "shortArrowNext",
  shortArrowBack: "shortArrowBack",
  responseOK: "responseOK",
  checkCircle: "checkCircle",
  plus: "plus",
  trash: "trash",
  remove: "remove",
  download: "download",
  circleClock: "circleClock",
  warningRed: "warningRed",
  warningImages: "warningImages",
  arrowUp: "arrowUp",
  arrowDown: "arrowDown",
  sale: "sale",
};
const icons = {
  show: IconShow,
  hide: IconHide,
  search: IconSearch,
  error: IconError,
  warning: IconWarning,
  shortArrowNext: Next,
  shortArrowBack: Back,
  responseOK: ResponseOK,
  checkCircle: CheckCircle,
  plus: Plus,
  trash: Trash,
  remove: Remove,
  download: Download,
  circleClock: CircleClock,
  warningRed: WarningRed,
  warningImages: WarningImages,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  sale: Sale,
};

export default function SvgIcon(props: SvgIconProps) {
  const Component = icons[props.iconName];
  return <img className="icon" src={Component} />;
}
