import React from "react";
import Text from "../../components/text/Text";
import ecoFriend from "../../assets/icons/icons-eco-friendly.svg";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import "./EcoFriendCommerce.css";
import ecoFriendlyIcon from "../../assets/icons/icons-eco-friend.svg";
import { ecofriendly } from "../../labels";
import { useNavigate } from "react-router";
import routes from "../../router/routes";

export default function EcoFriendCommerce() {
  const navigate = useNavigate();
  const handleNavigationBack = () => {
    navigate(`/${routes.configurations}`);
  };
  return (
    <div className="container-commerce">
      <div className="ecoFriendContainer-commerce">
        <div className="configTitleIconContainer-commerce">
          <img src={arrowBack} onClick={handleNavigationBack} />
          <Text
            variant="Subtitle2 configTextStyle-commerce"
            label={ecofriendly.ecoFriendTitle}
          />
          <img src={ecoFriend} className="iconContainer-commerce" />
        </div>
        <div className="subtitleMargin-commerce">
          <Text
            label={ecofriendly.hasEcoBadge}
            variant="Body3-12 subtitleTextStyle"
          />
        </div>
        <div className="ecofriendIconContainer">
          <img src={ecoFriendlyIcon} className="" />
        </div>
        <Text
          label={ecofriendly.hasEcoBadgeSubtitle}
          variant="subtitle1 grey-color-commerce"
        />
      </div>
    </div>
  );
}
