import { labels } from "./labels";

export const MEASURES: Option[] = [
  { label: "Kg", id: "k" },
  { label: "G", id: "g" },
  { label: "Ml", id: "ml" },
  { label: "Lt", id: "lt" },
  { label: "Un", id: "un" },
];

export const menuItems = [
  {
    label: labels.allOrders,
    value: 0,
  },
  {
    label: labels.deliveredOrdes,
    value: 4,
  },
  {
    label: labels.rejectedOrders,
    value: -1,
  },
  {
    label: labels.rejectedByClient,
    value: -2,
  },
];

export const menuDates = [
  {
    label: labels.selectADate,
    value: 0,
  },
  {
    label: labels.today,
    value: 4,
  },
  {
    label: labels.thisWeek,
    value: 1,
  },
  {
    label: labels.thisMonth,
    value: 2,
  },
  {
    label: labels.threeMonthsAgo,
    value: 3,
  },
];
