import React, { MouseEvent } from "react";
import styles from "./Modal.module.css";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, title }) => {
  const handleClose = () => {
    onClose();
  };

  const handleBackdropClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`${styles.modal} ${isOpen ? styles.open : ""}`}
      onClick={handleBackdropClick}
    >
      <div className={styles.modalContent}>
        <div className={styles.header}>
          {title && <p className={styles.modalTitle}>{title}</p>}
          <button className={styles.closeButton} onClick={handleClose}>
            <span className={styles.closeIcon}>&times;</span>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
