import React, { ChangeEvent, useState } from "react";
import Text from "../../components/text/Text";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import "./EcoFriendSurvey.css";
import { labels, ecofriendly } from "../../labels";
import { useNavigate } from "react-router";
import routes from "../../router/routes";
import ModalComponent, {
  VARIANTS,
  VariantsType,
} from "../../components/modal/ModalComponent";
import ErrorModal from "../../components/modal/modalVariants/ErrorModal";
import EcoFriendSuccess from "../../components/modal/modalVariants/EcoFriendSuccess";
import { guardaEncuestaLocal, putEcoSurvey } from "../../services/ecoSurvey";
import { useLoadingContext } from "../../context/spinnerContext";

import moment from "moment";
import Question from "../../components/question/Question";

const questionsOrdered = [
  ecofriendly.surveyQuestion0,

  ecofriendly.surveyQuestion1,
  ecofriendly.surveyQuestion2,
  ecofriendly.surveyQuestion3,
  ecofriendly.surveyQuestion4,
  ecofriendly.surveyQuestion5,
];

export default function EcoFriendSurvey() {
  const navigate = useNavigate();
  const loadingContext = useLoadingContext();

  const currentUser = localStorage.getItem("currentUser");

  const user = currentUser && JSON.parse(currentUser);
  const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal;
  const sEstablecimiento = user?.sEstablecimiento;
  const sLocal = user?.cLocal[0]?.sLocal;
  const sNombreLocal = user?.cLocal[0]?.sNombreLocal;
  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);

  const [answers, setAnswers] = useState<(boolean | null)[]>(
    questionsOrdered.map(() => null)
  );
  const handleChangeAnswers = (event: ChangeEvent<HTMLFormElement>) => {
    const [questionIndex, value] = event.target.id.split("-");
    setAnswers((prevState) => {
      return [
        ...prevState.map((element, index) => {
          if (index === +questionIndex) {
            return value === "1";
          }
          return element;
        }),
      ];
    });
  };
  const validForm = !answers.includes(null);

  const handleNavigationBack = () => {
    navigate(`/${routes.configurations}`);
  };
  const handleCloseErrorModal = () => {
    setModalContentType(null);
  };

  const booleanToString = (value: boolean | null) => {
    return value ? labels.yes : labels.no;
  };
  const handleContinue = async () => {
    const responses = answers.map((element) => {
      return { respuesta: booleanToString(element) };
    });
    const newQuestions = responses.map((element, index) => {
      return {
        id: index,
        question: questionsOrdered[index],
        sRespuesta: element.respuesta,
      };
    });
    try {
      const bodyRespuestas = {
        dEncuesta: moment().format(),
        sNombreEncuesta: `${ecofriendly.surveyFor} ${sNombreLocal}`,
        sEstablecimiento: sEstablecimiento,
        sLocal: sLocal,
        nNroPreguntas: `${newQuestions.length}`,
        sObservacion: "",
        nNota: "",
        lDetalle: newQuestions,
      };
      loadingContext?.onOpen();
      await guardaEncuestaLocal(bodyRespuestas);
      await guardaEncuestaLocal(bodyRespuestas);
      await putEcoSurvey(commerceId, responses).then(async (resp: any) => {
        loadingContext?.onClose();
        if (resp.eco) {
          setModalContentType(VARIANTS.SUCCESS);
        } else {
          setModalContentType(VARIANTS.ERROR);
        }
      });
    } catch (err) {
      console.error(err);
      setModalContentType(VARIANTS.ERROR);
    }
  };
  return (
    <div className="container-ecoFr">
      <div className="surveyContainer-ecoFr">
        <div className="titleContainer-ecoFr">
          <img src={arrowBack} onClick={handleNavigationBack} />
          <Text label={ecofriendly.surveyTitle} variant="H5 ecoFriendTitle" />
        </div>
        <form className="container" onChange={handleChangeAnswers}>
          {questionsOrdered.map((element, index) => (
            <div key={element} className="questionColumnContainer">
              <Question title={element} index={index} />
            </div>
          ))}
        </form>
        <button
          className={
            !validForm ? "buttonContinueDeactive-ecoFr" : "buttonContinue-ecoFr"
          }
          onClick={handleContinue}
          disabled={!validForm}
        >
          <a className={"ButtonMd-14"}>{labels.continue}</a>
        </button>
      </div>
      <ModalComponent
        show={!!modalContentType}
        icon={false}
        handleClose={handleCloseErrorModal}
        modalStyle={
          modalContentType === VARIANTS.SUCCESS
            ? "modalsmallSurvey"
            : "modalMediumSurvey"
        }
      >
        {modalContentType === VARIANTS.ERROR && (
          <ErrorModal
            text={ecofriendly.errorModal}
            buttonText={labels.continue}
            whatsappButton={false}
            closeModal={handleCloseErrorModal}
          />
        )}
        {modalContentType === VARIANTS.SUCCESS && (
          <EcoFriendSuccess
            text={ecofriendly.congrats}
            subtitle={ecofriendly.successModal}
            buttonText={labels.continue}
            handleOnPress={handleNavigationBack}
          />
        )}
      </ModalComponent>
    </div>
  );
}
