import React, { useState } from "react";
import Text from "../text/Text";
import "./SelectComponent.css";
import selectUp from "../../assets/icons/icons-select-up.svg";
import selectDown from "../../assets/icons/icons-select-down.svg";
interface SProps {
  item: SelectOption[];
  onChange?: (event: SelectOption) => void;
  initValue?: SelectOption;
}
export interface SelectOption {
  label: string;
  value: number;
}

export default function SelectComponent(props: SProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [menu, setMenu] = useState<any>(props.item);
  const [selectedOption, setSelectedOption] = useState<SelectOption>(
    props.initValue || props.item[0]
  );

  const toggling = () => {
    setIsOpen(!isOpen);
  };
  const onOptionClicked = (value: SelectOption) => {
    props.onChange?.(value);
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <div className="select-first-container">
      <div onClick={toggling} className="option-container-SC">
        <Text
          label={selectedOption.label}
          variant={"Body3-12 select-text-style"}
        />
        <img src={selectDown} />
      </div>
      {isOpen && (
        <div className="select-container">
          {menu.map((item: any, index: number) => (
            <div
              key={index}
              onClick={() => onOptionClicked(item)}
              className="option-container-SC"
            >
              <Text label={item.label} variant={"Body3-12 select-text-style"} />
              {index === 0 && <img src={selectUp} />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
