import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import Router from './router/Router'
import { init } from './ClevertapWrapper'
import { initWorkers } from './notifications-workers'
import { AuthProvider } from './context/loginContext/loginContext'
import { FeatureProvider } from './context/useFeatureFlagContext'
import SpinnerProvider from './context/spinnerContext'
import { BrowserRouter } from 'react-router-dom'

init()
initWorkers()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <AuthProvider>
        <FeatureProvider>
            <SpinnerProvider>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </SpinnerProvider>
        </FeatureProvider>
    </AuthProvider>
)
