import { compareDesc, parse } from "date-fns";
import { TransactionResponse } from "./services/commerce";

interface LiquidateForm {
  [key: string]: string;
}

export const formIds: LiquidateForm = {
  name: "name",
  category: "category",
  description: "description",
  shortDescription: "shortDescription",
  productBrand: "productBrand",
  classification: "classification",
  productImage: "productImage",
  unit: "unit",
  quantity: "quantity",
  price: "price",
  liquidatePrice: "liquidatePrice",
  stock: "stock",
};

export const createProductFormIds = {
  productName: "productName",
  fullDescription: "fullDescription",
  shortDescription: "shortDescription",
  productBrandID: "productBrandID",
  productCategoryID: "productCategoryID",
  productImage: "productImage",
  productMeasureUnit: "productMeasureUnit",
  weight: "weight",
  price: "price",
  stock: "stock",
  isFood: "isFood",
};

export const formToData = (data: FormData): ILiquidateForm => {
  return Object.keys(formIds).reduce(
    (acc, key) => {
      return { ...acc, [key]: data.get(key) };
    },
    {
      name: "",
      description: "",
      shortDescription: "",
      productBrand: "",
      classification: "",
      productImage: new File([], ""),
      unit: "",
      quantity: "",
      price: "",
      liquidatePrice: "",
      stock: "",
      date: new Date(),
    }
  );
};
export const createProductFormToData = (data: FormData): ICreateProductForm => {
  return Object.keys(createProductFormIds).reduce(
    (acc, key) => {
      return { ...acc, [key]: data.get(key) };
    },
    {
      productName: "",
      fullDescription: "",
      shortDescription: "",
      productBrandID: "0",
      productCategoryID: "0",
      productImage: new File([], ""),
      productMeasureUnit: "",
      weight: "",
      price: "",
      stock: "",
      isFood: false,
    }
  );
};
export const getBase64 = (
  file: File,
  callBack: (data: string | ArrayBuffer | null) => void
) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callBack(reader.result);
  };
};

export const brandsToOption = (brands: IBrand[]): Option[] => {
  return brands.map<Option>(({ id, name }) => ({
    id: `${id}`,
    label: name,
  }));
};
export const categoriesToOption = (brands: ICategory[]): Option[] => {
  return brands.map<Option>(({ id, name, ...rest }) => ({
    ...rest,
    id: `${id}`,
    label: name,
  }));
};
export const formatOptionLabel = (label: string) => {
  return label
    .toLowerCase()
    .replace("á", "a")
    .replace("é", "e")
    .replace("í", "i")
    .replace("ó", "o")
    .replace("ú", "u")
    .replace("ü", "u");
};

export const decimalFormat = (value: string) => {
  let number = value;
  let decimals = "";
  const isDecimal = value.includes(",");
  if (isDecimal) {
    number = value.substring(0, value.indexOf(","));
    decimals = value.substring(value.indexOf(",") + 1, value.length);
  }

  number = number?.match(/\d/g)?.join("") || "";

  decimals = decimals?.match(/\d/g)?.join("") || "";
  const style = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    useGrouping: true,
  };

  const preFormatNumber = isDecimal
    ? +`${number}${decimals ? `.${decimals}` : ""}`
    : +number;
  const formatter = new Intl.NumberFormat(["es"], style);
  const formatNumber = formatter.format(preFormatNumber);
  return `${formatNumber}`;
};

export const numberFormat = (value: string) => {
  if (!value) return "";
  let number = value;

  if (value.includes(",")) {
    number = value.substring(0, value.indexOf(","));
  }
  const formatValue = number?.match(/\d/g)?.join("") || 0;
  return `${Number(formatValue) || 0}`;
};

export const imageToBase64 = (
  file: File | null,
  handler: (base64: string) => void
) => {
  if (file) {
    getBase64(file, (result) => {
      handler((result as string).replace(/^data:image.+;base64,/, ""));
    });
  }
};

export const currencyFormat = (num: number) => {
  return Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(num);
};
export const REJECTED = "RECHAZADA";
export const addSales = (acc: number, el: TransactionResponse): number => {
  if (el.sEstadoTx === REJECTED) {
    return acc - Number(el.nMonto);
  }
  return acc + Number(el.nMonto);
};

export const getIncrease = (prev: number, actual: number) => {
  if (prev > 0 && actual > 0) {
    return Math.round((Math.abs(actual - prev) / Math.abs(prev)) * 100);
  }
  return 0;
};

export const parseStringDate = (stringDate: string) => {
  return parse(
    stringDate,
    stringDate.includes(".")
      ? "yyyy-MM-dd'T'HH:mm:ss.SSS"
      : "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );
};

export const sortSales = (sales: TransactionResponse[]) => {
  return sales.sort((a, b) => {
    const dateA = parseStringDate(a.dTransaccion);
    const dateB = parseStringDate(b.dTransaccion);
    return compareDesc(dateA, dateB);
  });
};
