import React, { useState, FormEvent, useCallback, useEffect } from "react";
import { useTextInput } from "../../hooks/useTextInput";
import { labels } from "../../labels";
import CheckBox from "../checkBox/CheckBox";
import OutlinedInput from "../outlinedInput/OutlinedInput";
import Text from "../text/Text";
import styles from "./ingredients.module.css";
import OptionsForEdit from "./OptionsForEdit";

interface IIngredientsProps {
  handleIngredients: (ingredients: IEditIngredients[]) => void;
  haveError: string;
  initialData?: IEditIngredients[];
}

export const initState: IEditIngredients = {
  id: null,
  ingredientTypeID: null,
  name: "",
  productID: 0,
  internalCommerceID: 0,
  mandatory: false,
  deactive: false,
  multichoice: false,
  maxAmount: 0,
  ingredients: [],
};

export default function IngredientsForEdit({
  handleIngredients,
  haveError,
  initialData,
}: IIngredientsProps) {
  const [haveIngredients, setHaveIngredients] = useState(
    initialData ? true : false
  );
  const {
    text: typeName,
    handleChangeText: handleChangeTypeName,
    handleChangeString: handleChangeTypeString,
  } = useTextInput();
  const { text: inputError, handleChangeString: handleChangeInputError } =
    useTextInput();
  const [options, setOptions] = useState<IEditIngredients[]>(initialData || []);

  const handleNameChange = (event: FormEvent<HTMLInputElement>) => {
    handleChangeTypeName(event);
    handleChangeInputError("");
  };

  const toogleIngredients = () => {
    setHaveIngredients((prevState) => !prevState);
  };
  const validateCreation = () => {
    let isValid = true;
    options.forEach((element) => {
      if (!element.deactive && element.name === typeName) {
        isValid = false;
        handleChangeInputError(labels.duplicateOptionError);
        return false;
      }
    });
    return isValid;
  };

  const onCreateOptionType = () => {
    if (typeName && validateCreation()) {
      setOptions((prevState) => [
        ...prevState,
        { ...initState, name: typeName },
      ]);
      handleChangeTypeString("");
    }
  };

  const onOptionTypeDelete = (index: number) => {
    setOptions((prevState) => {
      const copyState = [...prevState];
      copyState[index].deactive = true;
      return copyState;
    });
  };

  const onChangeRequired = (index: number, required: boolean) => {
    setOptions((prevState) => {
      const prevOptions = prevState.slice(0, index);
      const lastOptions = prevState.slice(index + 1, prevState.length);

      return [
        ...prevOptions,
        { ...prevState[index], mandatory: required },
        ...lastOptions,
      ];
    });
  };

  const onChangeMaximum = useCallback((index: number, maximum: string) => {
    setOptions((prevState) => {
      const prevOptions = prevState.slice(0, index);
      const lastOptions = prevState.slice(index + 1, prevState.length);

      return [
        ...prevOptions,
        { ...prevState[index], maxAmount: Number(maximum) },
        ...lastOptions,
      ];
    });
  }, []);

  const onChangeOptions = useCallback(
    (index: number, productOptions: IEditIngredientOption[]) => {
      setOptions((prevState) => {
        const prevOptions = prevState.slice(0, index);
        const lastOptions = prevState.slice(index + 1, prevState.length);
        return [
          ...prevOptions,
          { ...prevState[index], ingredients: productOptions },
          ...lastOptions,
        ];
      });
    },
    []
  );

  const onToogleIngredients = () => {
    const newOptions = options.map((element) => {
      return { ...element, deactive: true };
    });
    if (!haveIngredients) {
      toogleIngredients();
      setOptions(newOptions);
    } else {
      toogleIngredients();

      setOptions(newOptions);
      handleIngredients(newOptions);
    }
  };

  useEffect(() => {
    if (haveIngredients) {
      handleIngredients(options);
    }
  }, [options, haveIngredients]);
  return (
    <div className={styles.container}>
      <div className={styles.checkBoxContainer}>
        <CheckBox
          checkId="haveIngredient"
          labelClassName="subtitle2"
          label={labels.productHaveOptions}
          onClick={onToogleIngredients}
          checked={haveIngredients}
        />
      </div>
      {haveIngredients && (
        <>
          <div className={styles.titleWrapper}>
            <Text
              label={labels.productOptions}
              variant={`subtitle2 ${styles.textWidth} ${styles.bold}`}
            />
            <div className={styles.divider} />
          </div>
          <OutlinedInput
            value={typeName}
            onChange={handleNameChange}
            trailingIcon="plus"
            maxLength={20}
            floatingLabel={labels.optionType}
            placeholder={labels.optionTypePlaceholder}
            trailingIconClick={onCreateOptionType}
            error={inputError}
          />
        </>
      )}

      {haveIngredients &&
        options.map(
          (element, index) =>
            !element.deactive && (
              <OptionsForEdit
                index={index}
                ingredientType={element.id || null}
                maxAmount={`${element.maxAmount}`}
                mandatory={element.mandatory}
                onDeleteType={() => onOptionTypeDelete(index)}
                key={element.name}
                haveError={haveError}
                name={element.name}
                ingredients={element.ingredients}
                getMaximum={(maximum: string) =>
                  onChangeMaximum(index, maximum)
                }
                getRequired={(required: boolean) =>
                  onChangeRequired(index, required)
                }
                getOptions={(options: IEditIngredientOption[]) =>
                  onChangeOptions(index, options)
                }
              />
            )
        )}
    </div>
  );
}
