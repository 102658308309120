import { useState } from "react";

const useBooleanValue = (initialState?: boolean | null) => {
  const [open, setOpen] = useState<boolean | null>(
    initialState || initialState === null ? initialState : false
  );

  const handleToggle2 = () => {
    setOpen((prevState) => {
      if (prevState === null) return true;
      return !prevState;
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue: boolean | null) => {
    setOpen(newValue);
  };

  return { open, handleToggle2, handleChange, handleClose, handleOpen };
};

export default useBooleanValue;
