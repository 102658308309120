import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cloud from "../../assets/icons/icons-cloud-product.svg";
import dish from "../../assets/icons/icons-dish-products.svg";
import discount from "../../assets/icons/icons-discounts-products.svg";
import "../../components/text/Text.css";
import Text from "../../components/text/Text";
import "./Products.css";
import RedirectCard from "../../components/redirectCard/RedirectCard";
import { labels, clevertapEvents } from "../../labels";
import routes from "../../router/routes";
import { track } from "../../ClevertapWrapper";
import { format } from "date-fns";

export default function Products() {
  const navigation = useNavigate();
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const pageLoadData = {
    pageName: "Cargar productos",
    pageURL: routes.products,
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    codComercio: user?.sEstablecimiento,
    codLocal: user?.cLocal[0].sLocal,
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };

  const navigateToLiquidate = () => {
    track(clevertapEvents.productsLandingLiquidate, clevertapData);
    navigation(`/${routes.products}/${routes.liquidate}`);
  };
  const navigateToIndividual = () => {
    track(clevertapEvents.productsLandingIndividual, clevertapData);
    navigation(`/${routes.products}/${routes.individualUpload}`);
  };
  const navigateToMassive = () => {
    track(clevertapEvents.productsLandingMassive, clevertapData);
    navigation(`/${routes.products}/${routes.massiveUpload}`);
  };
  useEffect(() => {
    getLocation();
  }, [lat, lng]);
  useEffect(() => {
    track(clevertapEvents.pageLoadnl, pageLoadData);
  }, []);
  return (
    <div className="container-products">
      <div>
        <div className="titleContainer-products">
          <Text label={labels.productsTitle} variant="H5" />
        </div>
        <div className="twolandingContainer-products">
          <div className="landingContainer-products">
            <RedirectCard
              iconName={cloud}
              title={labels.productsMassiveCardTitle}
              subtitle={labels.productsMassiveCardSubtitle}
              handleOnPress={navigateToMassive}
            />
          </div>
          <div className="landingContainer-products">
            <RedirectCard
              iconName={dish}
              title={labels.productsIndividualUploadTitle}
              subtitle={labels.productsIndividualUploadSubtitle}
              handleOnPress={navigateToIndividual}
            />
          </div>
        </div>
      </div>

      <div className="thirdlandingPadding-products">
        <div className="landingContainer-products">
          <RedirectCard
            iconName={discount}
            title={labels.productsDiscountTitle}
            subtitle={labels.productsDiscountSubtitle}
            handleOnPress={navigateToLiquidate}
          />
        </div>
      </div>
    </div>
  );
}
