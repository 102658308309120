import React from "react";
import iconX from "../../assets/icons/icons-x.svg";
import "./modalComponentStyles.css";
import Text from "../text/Text";

interface ModalProps {
  children?: React.ReactNode;
  show: boolean;
  handleClose?: () => void;
  icon?: boolean;
  backDropStyle?: string;
  modalStyle?: string;
  title?: string;
}
interface VariantsModal {
  [name: string]: VariantsType;
}

export const VARIANTS: VariantsModal = {
  ERROR: "error",
  SUCCESS: "success",
  GREEN: "green",
  ACCEPTED: "accepted",
  ALERT: "alert",
  PHOTO: "photo",
  CALENDAR: "calendar",
};

export type VariantsType =
  | "success"
  | "error"
  | "accepted"
  | "green"
  | "alert"
  | "photo"
  | "calendar"
  | null;

export default function ModalComponent({
  handleClose,
  show,
  children,
  icon = true,
  backDropStyle,
  modalStyle,
  title,
}: ModalProps) {
  return show ? (
    <div className={backDropStyle ? backDropStyle : "backdrop"}>
      <div className={"modal display-block"}>
        <div className={modalStyle ? modalStyle : "modal-main"}>
          <div className={title ? "titleIconContainer" : ""}>
            {title && (
              <Text label={title} variant="H5 greyTitleModal leftTitleMargin" />
            )}
            {icon && (
              <div className="IconContainer">
                <div
                  onClick={handleClose}
                  className={
                    title
                      ? " xIconContainer hastitleIconContainer"
                      : "xIconContainer"
                  }
                >
                  <img src={iconX} />
                </div>
              </div>
            )}
          </div>

          {children}
        </div>
      </div>
    </div>
  ) : null;
}
