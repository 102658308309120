import React, { useEffect } from "react";
import OrderComponent from "../../../components/orderComponent/OrderComponent";
import { track } from "../../../ClevertapWrapper";
import { clevertapEvents, labels } from "../../../labels";
import useLazyLoader, { PAGE_CHUNK } from "../../../hooks/useLazyLoader";
import { getAllOrdersByStatus } from "../../../services/orders";
import uploadProducts from "../../../assets/icons/icons-upload-products.svg";
import ScrollComponent from "../../../components/ScrollComponent";

import NoOrders from "../NoOrders";

const newId = "newId";
export default function NewOrders() {
  const {
    data: newData,
    lastItemRef: newLastItemRef,
    onScroll: newOnScroll,
    refreshWithChunk,
    page,
  } = useLazyLoader(getAllOrdersByStatus, newId, 1);
  const handleAcceptOrder = (id: string) => {
    track(clevertapEvents.ordersButtonAccept, { orderId: id });
    refreshWithChunk(page * PAGE_CHUNK);
  };

  useEffect(() => {
    const refreshNew = setInterval(() => {
      refreshWithChunk(page * PAGE_CHUNK);
    }, 7000);
    return () => {
      clearInterval(refreshNew);
    };
  }, [page]);

  return (
    <ScrollComponent onScroll={newOnScroll}>
      {newData.length !== 0 && (
        <div className="centered-orders-container">
          {newData
            .slice()
            .sort((a, b) => Number(a.orderHeader.id) - Number(b.orderHeader.id))
            .map((item: TypeHistory, index: number) => (
              <div
                key={item.id}
                className="top-margin"
                id={index === newData.length - 1 ? newId : ""}
                ref={(ref) => {
                  if (index === newData.length - 1) {
                    newLastItemRef.current = ref;
                  }
                }}
              >
                <OrderComponent
                  orderTypeID={item.orderHeader.orderTypeID}
                  statusID={item.orderHeader.statusID}
                  id={item.id}
                  client={`${item.consumer.firstName} ${item.consumer.lastName}`}
                  orderNumber={item.orderHeader.id}
                  dateCreated={item.orderHeader.creationDate}
                  ammount={item.orderHeader.finalAmount}
                  hasScheduledPurchase={item.orderSchedule}
                  hasDelivery={item.orderDelivery}
                  acceptOrder={() => handleAcceptOrder(item.id)}
                />
              </div>
            ))}
        </div>
      )}
      {newData.length === 0 && (
        <NoOrders icon={uploadProducts} label={labels.noNewOrders} />
      )}{" "}
    </ScrollComponent>
  );
}
