const routes = {
    home: '/',
    appLinks: 'app/:module/:section/:subsection',
    login: 'login',
    passwordRecovery: 'recover-password',
    products: 'products',
    edit: 'edit',
    massiveUpload: 'massive',
    individualUpload: 'individual',
    liquidate: 'liquidate',
    inventory: 'inventory',
    orders: 'orders',
    payment: 'payment',
    qr: 'qr',
    successPayment: 'successPayment',
    sales: 'sales',
    salesDetails: 'details',
    voucherScreen: 'voucherScreen',
    hasVoucherScreen: 'hasVoucherScreen',
    configurations: 'configurations',
    ecofriendSurvey: 'ecoFriendSurvey',
    ecofriendCommerce: 'ecoFriendCommerce',
    storeConfigurations: 'store-configurations',
    scheduled: 'scheduled',
    onlineStoreInformationScreen: 'onlineStoreInformationScreen',
    optionOnlineStore: 'optionOnlineStoreScreen',
    scheduledOnlineStore: 'scheduledPurchaseOnlineStoreScreen',
    termsAndConditions: 'TermsAndConditions',
    onlineStoreActivated: 'onlineStoreActivated',
    urlDashboard: 'reportes',
}

export default routes
