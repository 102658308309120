import React, { useState, useEffect, useMemo, ChangeEvent } from "react";
import styles from "./SalesDetails.module.css";
import {
  TransactionResponse,
  getTransaccionesLocalPorFechasTipoPago,
  saleDateFormat,
} from "../../services/commerce";
import { format, startOfMonth, subMonths } from "date-fns";
import SaleComponent, {
  amiRutToken,
  codAppToken,
  codWebToken,
  qrToken,
} from "../../components/saleComponent/SaleComponent";
import OutlinedSelect from "../../components/outlinedSelect/OutlinedSelect";
import { labels } from "../../labels";
import { addSales, currencyFormat, getIncrease } from "../../formatters";
import Text from "../../components/text/Text";
import SvgIcon, { iconNames } from "../../components/svgIcon/SvgIcon";
const payments = [
  { id: "", label: labels.allPayments },
  { id: amiRutToken, label: labels.amiRut },
  { id: codWebToken, label: labels.codWeb },
  { id: codAppToken, label: labels.codApp },
  { id: qrToken, label: labels.qr },
];

const datesOption = [
  { id: format(new Date(), saleDateFormat), label: labels.today },
  {
    id: format(startOfMonth(new Date()), saleDateFormat),
    label: labels.thisMonth,
  },
  {
    id: format(startOfMonth(subMonths(new Date(), 3)), saleDateFormat),
    label: labels.threeMonthsAgo,
  },
  {
    id: format(startOfMonth(subMonths(new Date(), 6)), saleDateFormat),
    label: labels.sixMonthsAgo,
  },
];

export default function SalesDetails() {
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const [data, setData] = useState<TransactionResponse[]>([]);
  const [actualMonthSales, setActualMonthSales] = useState<
    TransactionResponse[]
  >([]);
  const [prevSales, setPrevSales] = useState<TransactionResponse[]>([]);
  const [filterType, setFilterType] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<string>(
    format(startOfMonth(new Date()), saleDateFormat)
  );
  const handleFilterTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = payments.find(
      (element) => element.label === event.target.value
    );
    setFilterType(selectedOption?.id || payments[0].id);
  };
  const handleDateFromChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = datesOption.find(
      (element) => element.label === event.target.value
    );
    setDateFrom(selectedOption?.id || datesOption[0].id);
  };
  const getSales = async (startDate: string, endDate: string) => {
    return await getTransaccionesLocalPorFechasTipoPago(
      user.sEstablecimiento,
      user.cLocal[0].sLocal,
      startDate,
      endDate
    );
  };
  const getPrevSales = async () => {
    const prevMonth = subMonths(new Date(), 1);
    const start = startOfMonth(prevMonth);
    await getSales(
      format(start, saleDateFormat),
      format(prevMonth, saleDateFormat)
    ).then((data) => {
      setPrevSales(data);
    });
  };
  const filterData = useMemo(() => {
    return filterType
      ? data?.filter((element) => element.sToken === filterType)
      : data;
  }, [data, filterType]);

  const actualSalesAmmount = useMemo(() => {
    return actualMonthSales.reduce(addSales, 0);
  }, [actualMonthSales]);

  const incress = useMemo(() => {
    const prevTotal = prevSales.reduce(addSales, 0);
    return getIncrease(prevTotal, actualSalesAmmount);
  }, [actualSalesAmmount, prevSales]);

  useEffect(() => {
    getSales(dateFrom, format(new Date(), saleDateFormat)).then((res) => {
      setData(res.reverse());
      if (dateFrom === datesOption[1].id) {
        setActualMonthSales(res);
      }
    });
  }, [dateFrom]);
  useEffect(() => {
    getPrevSales();
  }, []);
  return (
    <div className={styles.view}>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.header}>
            <div className={`${styles.rowHeader} ${styles.spacedX}`}>
              <div>Este mes has vendido</div>
              <div className={styles.rowFilters}>
                <div>
                  <OutlinedSelect
                    options={payments}
                    onChange={handleFilterTypeChange}
                  />
                </div>
                <div>
                  <OutlinedSelect
                    options={datesOption}
                    onChange={handleDateFromChange}
                    defaultValue={datesOption[1].id}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.spacedX} ${styles.wrap}`}>
              <Text
                label={currencyFormat(actualSalesAmmount)}
                variant={`H2 gray10c ${styles.totalAmmount}`}
              />
              <div className={styles.rowPercentage}>
                <Text
                  label={`${incress}${labels.incressPercentage}`}
                  variant={`subtitle2 ${styles.incress}`}
                />
                <div>
                  <SvgIcon
                    iconName={
                      incress > 0 ? iconNames.arrowUp : iconNames.arrowDown
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.salesList}>
            {filterData.length
              ? filterData.map((element) => (
                  <div key={element.idTransaccion} className={styles.rowSale}>
                    <SaleComponent sale={element} />
                  </div>
                ))
              : labels.noDataForFilter}
          </div>
        </div>
      </div>
    </div>
  );
}
