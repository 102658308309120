import React from "react";
import success from "../../../assets/icons/icons-success.svg";
import Button from "../../buttons/ButtonUI";
import Text from "../../text/Text";
import styles from "./SuccessModal.module.css";

export interface ISuccessModalProps {
  text: string;
  buttonText: string;
  handleOnPress: () => void;
}

export default function SuccessModal({
  text,
  buttonText,
  handleOnPress,
}: ISuccessModalProps) {
  return (
    <div className={styles.successContainer}>
      <div className={styles.IconContainer}>
        <img src={success} />
      </div>

      <Text label={text} variant="Body1-16" />
      <div className={styles.buttonContainer} onClick={handleOnPress}>
        <Button
          label={buttonText}
          variant={"success"}
          type="button"
          textVariant="md14"
        />
      </div>
    </div>
  );
}
