import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { decimalFormat, numberFormat } from "../../formatters";
import SvgIcon from "../svgIcon/SvgIcon";
import "./OutlinedInput.css";
export type InputType = "text" | "password" | "file";
export interface InputTypes {
  [name: string]: InputType;
}

export const inputTypes: InputTypes = {
  text: "text",
  password: "password",
  file: "file",
};
interface IOutlineInputProps {
  charCounter?: boolean;
  formId?: string;
  error?: string;
  isNumber?: boolean;
  floatingLabel: string;
  helperText?: string;
  max?: string;
  maxLength?: number;
  min?: string;
  minLength?: number;
  placeholder?: string;
  accept?: string;
  trailingIcon?: IconName;
  type?: InputType;
  value?: string;
  onChange?: (data: React.FormEvent<HTMLInputElement>) => void;
  isDecimal?: boolean;
  trailingIconClick?: () => void;
  disabled?: boolean;
}
interface IHintContainerProps {
  helperText?: string;
  errorMessage?: string | null;
  value?: string;
  charCounter?: boolean;
  valid?: boolean;
  maxLength?: number;
}
function HintContainer({
  helperText,
  errorMessage,
  value,
  charCounter,
  valid,
  maxLength,
}: IHintContainerProps) {
  const showHints = errorMessage || helperText || charCounter;

  return showHints ? (
    <div className="hints">
      <div className="messages">
        {(errorMessage || helperText) && (
          <>
            {!valid && errorMessage && (
              <div className="errorInput">{errorMessage}</div>
            )}
            {valid && helperText && (
              <div className="helper Caption3">{helperText}</div>
            )}
          </>
        )}
      </div>
      {!!maxLength && (
        <div className="char-counter">
          {value ? value.length : 0}/{maxLength}
        </div>
      )}
    </div>
  ) : null;
}

function OutlinedInput({
  charCounter,
  formId,
  error,
  floatingLabel,
  helperText,
  max,
  maxLength,
  min,
  minLength,
  placeholder,
  trailingIcon,
  accept,
  type,
  value,
  onChange,
  isNumber = false,
  isDecimal,
  trailingIconClick,
  disabled ,
}: IOutlineInputProps) {
  const isPassword = type === "password";
  const [focused, setFocused] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(!isPassword);
  const [inputValue, setValue] = useState<string>(value || "");
  const [errorMessage, setErrorMessage] = useState<string | null>(error || "");

  const inputField = useRef<HTMLInputElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const floatingLabelRef = useRef<HTMLLabelElement>(null);
  const focusField = () => {
    if (!inputField.current) {
      return;
    }
    inputField.current.focus();
  };

  const setLabelWidthStyleProperty = () => {
    if (floatingLabelRef.current) {
      const dims = floatingLabelRef.current.getBoundingClientRect();
      container.current?.style.setProperty(
        "--label-active-width",
        `${dims.width}px`
      );
    } else {
      container.current?.style.setProperty("--label-active-width", "0");
    }
  };

  const passwordIcon: IconName = show ? "hide" : "show";

  const onFocus = () => {
    setFocused(true);
    setLabelWidthStyleProperty();
  };

  const onBlur = () => {
    if (type !== "file") {
      setFocused(false);
    }

    if (isNumber) {
      const formatNumber = numberFormat(inputValue || "");
      setValue(formatNumber);
    }
    if (isDecimal) {
      const formatDecimal = decimalFormat(inputValue || "");
      setValue(formatDecimal);
    }
  };

  const tooglePassword = () => {
    setShow((prevState) => !prevState);
  };

  useEffect(() => {
    if (!inputValue) {
      onFocus();
    }
  }, [inputValue]);

  const setError = (errorMessage: string) => {
    if (!inputField.current) {
      return;
    }
    inputField.current.setCustomValidity(errorMessage);
    setValid(false);
    setErrorMessage(errorMessage);
  };

  useLayoutEffect(() => {
    if (error) {
      setError(error);
    } else {
      setError("");
      setValid(true);
    }
  }, [error, type]);

  const placeholderLabel = () => {
    if (floatingLabel) {
      return focused ? placeholder : undefined;
    }
    return placeholder;
  };

  const updateValidity = () => {
    if (!inputField.current) {
      return;
    }
    setValid(inputField.current?.checkValidity());
  };

  const updateValue = (e: React.FormEvent<HTMLInputElement>) => {
    onChange?.(e);
    const newValue = e.currentTarget.value;
    setValue(newValue);

    updateValidity();
  };

  useEffect(() => {
    if (
      (!isDecimal && !isNumber && value && value !== inputValue) ||
      value === ""
    ) {
      setValue(value);
    }
  }, [value, inputValue]);

  const getStyleClasses = () => {
    const classes = [];
    if (floatingLabel) {
      classes.push("_floating-label");
    }
    if (!valid) {
      classes.push("_invalid");
    }
    if (inputValue) {
      classes.push("_has-value");
    }
    if (focused) {
      classes.push("_focused");
    }
    return classes.join(" ");
  };

  const isShowingText = show ? "text" : "password";
  const textTypes = !isPassword ? type : isShowingText;
  return (
    <div className="wrapper">
      <div className={disabled ? "outline-input disabled-outline-input": "outline-input"} ref={container}>
        <div
          className={`field-wrapper ${getStyleClasses()}`}
          onClick={focusField}
        >
          {floatingLabel && <label className="floating">{floatingLabel}</label>}
          {floatingLabel && (
            <label
              ref={floatingLabelRef}
              className="floating reference floatinglabel"
            >
              {floatingLabel}
            </label>
          )}
          <input
            ref={inputField}
            id={formId}
            name={formId}
            minLength={minLength}
            type={textTypes}
            maxLength={maxLength}
            accept={accept}
            min={min}
            disabled={disabled}
            max={max}
            placeholder={placeholderLabel()}
            value={inputValue}
            onChange={updateValue}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {trailingIcon && (
            <div
              onClick={isPassword ? tooglePassword : trailingIconClick}
              className="trailingWrapper"
            >
              <SvgIcon iconName={isPassword ? passwordIcon : trailingIcon} />
            </div>
          )}
          <div className="borders">
            <div className="border left" />
            <div className="middle">
              <div className="top-borders">
                <div className="border start" />
                <div className="border end" />
              </div>
              <div className="border bottom" />
            </div>
            <div className="border right" />
          </div>
        </div>
        <HintContainer
          charCounter={!!charCounter}
          errorMessage={errorMessage}
          maxLength={maxLength}
          helperText={helperText}
          valid={valid}
          value={inputValue}
        />
      </div>
    </div>
  );
}

export default memo(OutlinedInput);
