import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./passwordRecoveryStyles.css";
import leftArrow from "../../assets/icons/icons-left-arrow.svg";
import OutlinedInput from "../../components/outlinedInput/OutlinedInput";
import ModalComponent from "../../components/modal/ModalComponent";
import SuccessModal from "../../components/modal/modalVariants/SuccessModal";
import ErrorModal from "../../components/modal/modalVariants/ErrorModal";
import { VARIANTS, VariantsType } from "../../components/modal/ModalComponent";
import { labels, clevertapEvents } from "../../labels";
import { postRestaurarClave } from "../../services/authentication";
import { track } from "../../ClevertapWrapper";
import routes from "../../router/routes";
import { format } from "date-fns";
import { useLoadingContext } from "../../context/spinnerContext";

export default function PasswordRecovery() {
  const navigation = useNavigate();
  const loadingContext = useLoadingContext();

  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const noLoginData = {
    pageName: "Login",
    pageURL: routes.passwordRecovery,
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  const data1 = {
    success: true,
  };
  const data2 = {
    error: true,
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    track(clevertapEvents.restorePasswordButton, data);
    loadingContext?.onOpen();
    await postRestaurarClave(
      data.get("commerceUser")?.toString() || "",
      data.get("commerceEmail")?.toString() || ""
    ).then((resp) => {
      loadingContext?.onClose();
      if (resp === 200) {
        track("web- Éxito al restablecer contraseña", data1);
        setModalContentType(VARIANTS.SUCCESS);
      } else {
        track("web- Error al restablecer contraseña", data2);
        setModalContentType(VARIANTS.ERROR);
      }
    });
  };

  const handleOpenErrorModal = (type: ContentType) => {
    setModalContentType(type);
  };
  const handleCloseErrorModal = () => {
    setModalContentType(null);
  };
  const navigateToLogin = () => {
    navigation("/login");
  };

  useEffect(() => {
    getLocation();
  }, [lat, lng]);

  useEffect(() => {
    track(clevertapEvents.pageLoadNoLogin, noLoginData);
  }, []);

  return (
    <div className="background-pw">
      <div className="passwordContainer">
        <div className="password">
          <form className="passwordform" onSubmit={handleSubmit} noValidate>
            <div className="cardTitle-pw">
              <a className="H4">{labels.recoverPassword}</a>
            </div>
            <OutlinedInput
              floatingLabel={labels.enterUser}
              placeholder="000000"
              formId="commerceUser"
            />
            <OutlinedInput
              floatingLabel={labels.enterEmail}
              placeholder="mail@correo.cl"
              formId="commerceEmail"
            />
            <button className="buttonStyle-pw" type="submit">
              <a className="ButtonLg-16">{labels.recoverPassword}</a>
            </button>
          </form>
          <section className="bottomLinks">
            <button className="buttonBack-pw" onClick={navigateToLogin}>
              <img src={leftArrow} className="leftIcon-pw" />
              <a className="ButtonMd-14">{labels.backToLogin}</a>
            </button>
          </section>
        </div>

        <ModalComponent
          show={!!modalContentType}
          handleClose={handleCloseErrorModal}
        >
          {modalContentType === VARIANTS.ERROR && (
            <ErrorModal
              text={labels.couldntReset}
              buttonText={labels.contactSupport}
            />
          )}
          {modalContentType === VARIANTS.SUCCESS && (
            <SuccessModal
              text={labels.weSendEmail}
              buttonText={labels.accept}
              handleOnPress={navigateToLogin}
            />
          )}
        </ModalComponent>
      </div>
    </div>
  );
}
