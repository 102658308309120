import React from "react";
import styles from "./uploadLine.module.css";
import { labels } from "./../../labels";
interface IUploadLineProps {
  percentage: number;
  error?: boolean;
}
export default function UploadLine({ percentage, error }: IUploadLineProps) {
  const percentageBar = styles[`bar-${percentage}`];

  const notZero = percentage !== 0;
  const basicStyle = notZero ? `${styles.bar} ${percentageBar}` : styles.bar;
  const withError = notZero
    ? `${styles.bar} ${percentageBar} ${styles.error}`
    : `${styles.bar} ${styles.error}`;

  return (
    <div className={styles.container}>
      <div>{labels.zeropercent}</div>
      <div className={styles.progress}>
        <div className={error ? withError : basicStyle}></div>
      </div>

      <div>{labels.hundredPercent}</div>
    </div>
  );
}
