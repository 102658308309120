/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Text from "../text/Text";
import OutlinedInput from "../outlinedInput/OutlinedInput";
import SelectComponent from "../../components/selectComponent/SelectComponent";
import iconX from "../../assets/icons/icons-x.svg";
import circleAlert from "../../assets/icons/icon-info-circle-white.svg";
import location from "../../assets/icons/icon-location-grey.svg";
import { getComunaForOnlineStore } from "../../services/OnlineStore";
import "./ComuneModal.css";
import { labels, onlineConfig } from "../../labels";
import { eachHourOfInterval, format, setHours } from "date-fns";

interface ModalProps {
  show: boolean;
  regionSelected: string;
  handleClose?: () => void;
  accept?: () => void;
  onSelect: (item: ComuneType[]) => void;
  itemRegion?: RegionType;
  comuneData?: ComuneType;
}
const deliveryTime = [
  { label: onlineConfig.deliveryTime15to30, value: 0 },
  { label: onlineConfig.deliveryTime30to45, value: 1 },
  { label: onlineConfig.delivertyTime45to60, value: 2 },
  { label: onlineConfig.deliveryTime60to75, value: 3 },
  { label: onlineConfig.deliveryTime75to90, value: 4 },
];
export default function ComuneModal({
  handleClose,
  accept,
  show,
  onSelect,
  regionSelected,
  itemRegion,
  comuneData,
}: ModalProps) {
  const [iRegion] = useState<RegionType | undefined>(itemRegion);
  const [comune, setComune] = useState([] as any);
  const [comuneList, setComuneList] = useState([] as any);
  const [listFiltered, setListFiltered] = React.useState([] as any);
  const [addedItems, setAddedItems] = React.useState<CommuneTypeDTO[]>(
    comuneData?.comunaList || []
  );
  const [costValue, setCostValue] = useState(comuneData?.costValue || "");
  const [intheDayDelivery, setIntheDayDelivery] = useState(
    comuneData?.intheDayDelivery || false
  );
  const [moreThanTwentyFourHr, setMoreThanTwentyFourHr] = useState(
    comuneData?.moreThanTwentyFourHr || false
  );
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<string>(
    comuneData?.deliveryTime || ""
  );
  const [timeSlot, setTimeSlot] = useState<boolean>(
    comuneData?.optionSelected ? true : false || false
  );
  const [isSelected, setIsSelected] = useState<number | null>(() => {
    const option = comuneData?.optionSelected?.setOption;
    if (option || option === 0) {
      return option;
    }
    return null;
  });
  const [specificDays, setSpecificDays] = useState<boolean>(
    comuneData?.specificDays || false
  );
  const [selectedReceptionDays, setSelectedReceptionDays] = useState<string>(
    comuneData?.pickerValueReception || ""
  );
  const [lunAttentionHour, setLunAttentionHour] = useState<boolean>(false);
  const [marAttentionHour, setMarAttentionHour] = useState<boolean>(false);
  const [mierAttentionHour, setMierAttentionHour] = useState<boolean>(false);
  const [jueAttentionHour, setJueAttentionHour] = useState<boolean>(false);
  const [vieAttentionHour, setVieAttentionHour] = useState<boolean>(false);
  const [sabAttentionHour, setSabAttentionHour] = useState<boolean>(false);
  const [domAttentionHour, setDomAttentionHour] = useState<boolean>(false);
  const [pickerValueReception, setPickerValueReception] = useState(
    comuneData?.selectedReceptionDays || ""
  );
  const [pickerValue, setPickerValue] = useState<string>(
    comuneData?.pickerValue || ""
  );
  const [withinTheSameDay, setWithinTheSameDay] = useState<number>(
    deliveryTime.find(
      (element) => element.label === comuneData?.withinTheSameDayPickerValue
    )?.value || deliveryTime[0].value
  );
  const [withinTheSameDayPickerValue, setWithinTheSameDayPickerValue] =
    useState<string>(
      comuneData?.withinTheSameDayPickerValue || deliveryTime[0].label
    );
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const receptionDays = [
    { label: onlineConfig.receptionSelect, value: 0 },
    { label: onlineConfig.receptionSelect1, value: 1 },
    { label: onlineConfig.receptionSelect2, value: 2 },
    { label: onlineConfig.receptionSelect3, value: 3 },
  ];
  const hours = eachHourOfInterval({
    start: setHours(new Date(), 0),
    end: setHours(new Date(), 23),
  }).map((item, index) => {
    return { value: index, label: format(item, "HH:mm a") };
  });

  const timeSlotOption = [
    {
      title: onlineConfig.timeSlotSelect,
      setOption: 0,
      hourRange: 24,
    },
    {
      title: onlineConfig.timeSlotSelect1,
      setOption: 1,
      hourRange: 48,
    },
    {
      title: onlineConfig.timeslotSelect2,

      setOption: 2,
      hourRange: 72,
    },
    {
      title: onlineConfig.timeSlotSelect3,
      setOption: 3,
      hourRange: 96,
    },
    {
      title: onlineConfig.timeSlotSelect4,

      setOption: 4,

      hourRange: 120,
    },
  ];
  const daysSelection = [
    {
      dayLetter: onlineConfig.daySelectL,
      day: onlineConfig.daySelectMonday,
      setDaySelected: () => setLunAttentionHour(!lunAttentionHour),
      isSelected: lunAttentionHour,
    },
    {
      dayLetter: onlineConfig.daySelectM,
      day: onlineConfig.daySelectTuesday,
      setDaySelected: () => setMarAttentionHour(!marAttentionHour),
      isSelected: marAttentionHour,
    },
    {
      dayLetter: onlineConfig.daySelectM,
      day: onlineConfig.daySelectWednesday,
      setDaySelected: () => setMierAttentionHour(!mierAttentionHour),
      isSelected: mierAttentionHour,
    },
    {
      dayLetter: onlineConfig.daySelectJ,
      day: onlineConfig.daySelectThursday,
      setDaySelected: () => setJueAttentionHour(!jueAttentionHour),
      isSelected: jueAttentionHour,
    },
    {
      dayLetter: onlineConfig.daySelectV,
      day: onlineConfig.dayselectFriday,
      setDaySelected: () => setVieAttentionHour(!vieAttentionHour),
      isSelected: vieAttentionHour,
    },
    {
      dayLetter: onlineConfig.daySelectS,
      day: onlineConfig.daySelectSaturday,
      setDaySelected: () => setSabAttentionHour(!sabAttentionHour),
      isSelected: sabAttentionHour,
    },
    {
      dayLetter: onlineConfig.daySelectD,
      day: onlineConfig.daySelectSunday,
      setDaySelected: () => setDomAttentionHour(!domAttentionHour),
      isSelected: domAttentionHour,
    },
  ];
  const handleFilterByStatus = (newStatus: any) => {
    const hourNoA = newStatus.label.slice(0, -3);
    setSelectedDeliveryTime(hourNoA);
    setPickerValue(newStatus.value);
  };
  const handleReceptionDays = (newStatus: any) => {
    setSelectedReceptionDays(newStatus.label);
    setPickerValueReception(newStatus.value);
  };
  const onChangeWithinTheSameDayDelivery = (newStatus: any) => {
    setWithinTheSameDay(newStatus.value);
    setWithinTheSameDayPickerValue(newStatus.label);
  };
  const handleOnPressSpecificDays = () => {
    setTimeSlot(false);
    setSpecificDays(!specificDays);
  };
  const optionSelected =
    timeSlotOption.find((tslo) => tslo.setOption === isSelected) || null;

  const renderTimeSlotOptions = () => {
    return (
      <div>
        <Text label={labels.comuneModalTimeSlot} variant="H5" />
        <Text label={labels.comuneModalAlert7} variant="Subtitle3" />

        {timeSlotOption.map((item, index) => (
          <div key={index}>
            <div className="iconRegionContainer">
              <input
                name="cssCheckboxSq"
                type="checkbox"
                className={
                  isSelected === index ? "cssCheckbox-com" : "noChecked-com"
                }
                onChange={() => setIsSelected(item.setOption)}
                checked={isSelected === index}
              />
              <Text label={item.title} variant="Body2-14 grey900-com" />
            </div>
          </div>
        ))}
      </div>
    );
  };
  const dateConfig = (dateData: any) => {
    return (
      <div className="daysContainer-sConfig">
        {dateData.map((day: any, index: number) => (
          <div key={index}>
            <div
              onClick={day.setDaySelected}
              className={
                day.isSelected
                  ? "dayContainerBlue-sConfig"
                  : "dayContainerGrey-sConfig"
              }
            >
              <Text label={day.dayLetter} variant="Subtitle2" />
            </div>
          </div>
        ))}
      </div>
    );
  };
  const renderAlert = (description: string) => {
    return (
      <div className="alertTextContainer-com">
        <img src={circleAlert} className="iconAlertStyle-com" />
        <Text variant="Subtitle3 textAlertStyle-com" label={description} />
      </div>
    );
  };
  const renderCommonTitle = (title: string) => {
    return <Text label={title} variant="Subtitle2 grey900-com" />;
  };
  const getComune = async () => {
    try {
      await getComunaForOnlineStore(regionSelected).then((response: any) => {
        const resp = response.reduce(
          (acc: any, { id, name, codeRegion }: any) => {
            return [
              ...acc,
              {
                NOMBRE: name,
                ID: id,
                ID_REGION: codeRegion,
                regID: iRegion?.regID,
              },
            ];
          },
          []
        );
        setComuneList(resp);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnChangeText = (text: FormEvent<HTMLInputElement>) => {
    setComune(text.currentTarget.value);
    if (text.currentTarget.value === "") {
      setListFiltered([]);
      return;
    }
    if (text.currentTarget.value.length > 1) {
      const listFilteredTmp = [
        ...comuneList.filter((it: any) =>
          it.NOMBRE.toString()
            .toLocaleLowerCase()
            .includes(text.currentTarget.value.toLocaleLowerCase())
        ),
      ];
      const listFiltered = listFilteredTmp.filter(
        (it: any) =>
          !addedItems.find(
            (ai: any) => ai.NOMBRE === it.NOMBRE && !ai.isDeleted
          )
      );
      setListFiltered(listFiltered);
    }
  };
  const ShowList = (listData: any) => {
    return listData.map((item: any) => {
      return (
        <div
          key={item}
          className="iconRegionContainer"
          onClick={() => {
            const listTmp = addedItems;
            const filteredL = listTmp.filter((lt: any) => lt.ID !== item.ID);
            item.isDeleted = false;
            filteredL.push(item);
            setAddedItems(filteredL);
            setListFiltered([]);
            setComune("");
          }}
        >
          <img src={location} className="iconLocationContainer-com" />
          <Text label={item.NOMBRE} variant="Small-2" />
        </div>
      );
    });
  };
  const onDelete = (NOMBRE: string) => {
    const deleteItem = addedItems.find((element) => element.NOMBRE === NOMBRE);
    const filteredList = addedItems.filter((it: any) => it.NOMBRE !== NOMBRE);
    if (deleteItem) {
      deleteItem.isDeleted = true;
      filteredList.push(deleteItem);
      setAddedItems(filteredList);
      setDisableInput(false);
    }
  };
  const isConfirmButtonDisable = () => {
    if (addedItems.length === 0) return true;
    if (!costValue) return true;
    if (!timeSlot && !intheDayDelivery && !specificDays) return true;
    if (timeSlot && (!optionSelected || !pickerValue)) return true;
    if (intheDayDelivery && !intheDayDelivery) return true;
    if (specificDays) {
      if (!pickerValueReception || !pickerValue) return true;
      const hasSelectedADay = daysSelection.find((days) => days.isSelected);
      if (!hasSelectedADay) return true;
    }

    return false;
  };
  const showSelectedCommune = () => {
    return addedItems.map(
      (selected) =>
        !selected.isDeleted && (
          <div key={selected.ID} className="selectedItemContainer-com">
            <div className="innerSelectedItemContainer-com">
              <Text label={selected.NOMBRE} variant="Body3-12" />
              <img
                src={iconX}
                className="iconXStyle-com"
                onClick={() => onDelete(selected.NOMBRE)}
              />
            </div>
          </div>
        )
    );
  };
  const handleContinue = () => {
    const data: ComuneType[] = [];
    addedItems.forEach((value) => {
      data.push({
        comunaList: [value],
        pickerValue: intheDayDelivery ? null : pickerValue,
        deliveryTime: selectedDeliveryTime,
        pickerValueReception: selectedReceptionDays,
        costValue: costValue,
        optionSelected: intheDayDelivery ? null : optionSelected,
        withinTheSameDayPickerValue: withinTheSameDayPickerValue,
        moreThanTwentyFourHr: moreThanTwentyFourHr,
        specificDays: specificDays,
        intheDayDelivery: intheDayDelivery,
        selectedReceptionDays: pickerValueReception,
        daysSelection: daysSelection,
        isEdit: !!comuneData,
        timeSlot: timeSlot,
      });
    });
    onSelect(data);
    accept?.();
  };
  const handleCostValueInput = (value: ChangeEvent<HTMLInputElement>) => {
    const regexOnlyNumbers = "^[0-9]+$";
    if (value.currentTarget.value.length === 0) {
      setCostValue("");
      return true;
    }
    if (value.currentTarget.value.match(regexOnlyNumbers)) {
      setCostValue(value.currentTarget.value);
    }
    return false;
  };
  useEffect(() => {
    if (comuneData?.daysSelection && comuneData.daysSelection.length > 0) {
      comuneData.daysSelection.forEach((dsp) => {
        const founded = daysSelection.find(
          (ds) => dsp.isSelected && ds.day === dsp.day
        );
        if (founded) founded.setDaySelected();
      });
    }
  }, []);
  useEffect(() => {
    getComune();
  }, []);
  if (!show) {
    return null;
  } else {
    return (
      <div className="backdrop-com">
        <div className={"modal display-block-com"}>
          <div
            className={
              addedItems.length > 0
                ? "modal-main-comuneSelected"
                : "modal-main-com"
            }
          >
            <div className="IconContainer-com">
              <div onClick={handleClose} className={"xIconContainer-com"}>
                <img src={iconX} />
              </div>
            </div>
            <div className="alertTextContainer-com">
              <img src={circleAlert} className="iconAlertStyle-com" />
              <Text
                variant="Subtitle3 textAlertStyle-com"
                label={labels.comuneModalAlert}
              />
            </div>
            <div className="searchInputStyle-com">
              <OutlinedInput
                formId={"search-name"}
                floatingLabel={""}
                placeholder={labels.comuneModalInput}
                charCounter
                trailingIcon="search"
                onChange={(value) => handleOnChangeText(value)}
                value={comune}
                disabled={disableInput}
              />
              {listFiltered.length >= 1 && (
                <div className="listContainer-com">
                  {ShowList(listFiltered)}
                </div>
              )}
              {addedItems.some((element) => !element.isDeleted) && (
                <>
                  <div>{showSelectedCommune()}</div>
                  <div className="innerSelectedItemContainer-com commonSpaceBetween">
                    <Text
                      label={labels.comuneModalAmountInput}
                      variant="Subtitle2 grey900"
                    />
                    <input
                      placeholder={""}
                      value={costValue}
                      onChange={(value) => handleCostValueInput(value)}
                      className={"Body1-16 grey900 costContainer-com"}
                    />
                  </div>
                  <div>
                    <Text label={labels.comuneModalDeliverType} variant="H5" />
                    <div
                      className={
                        !moreThanTwentyFourHr
                          ? "typeDeliver-com"
                          : "typeDeliverSelected-com"
                      }
                      onClick={() => {
                        setMoreThanTwentyFourHr(!moreThanTwentyFourHr);
                        setIntheDayDelivery(false);
                        setTimeSlot(false);
                        setIsSelected(null);
                      }}
                    >
                      <Text
                        label={labels.comuneModalMoreThan24hs}
                        variant="ButtonMd-14"
                      />
                    </div>
                    <div
                      className={
                        !intheDayDelivery
                          ? "typeDeliver-com"
                          : "typeDeliverSelected-com"
                      }
                      onClick={() => {
                        setIntheDayDelivery(!intheDayDelivery);
                        setMoreThanTwentyFourHr(false);

                        setTimeSlot(false);
                        setSpecificDays(false);
                        setSelectedDeliveryTime("");
                        setTimeSlot(false);
                      }}
                    >
                      <Text
                        label={labels.comuneModalInTheDay}
                        variant="ButtonMd-14"
                      />
                    </div>
                  </div>
                  {intheDayDelivery && (
                    <div>
                      <Text
                        label={labels.comuneModalTimeToDeliver}
                        variant="Subtitle2 grey900-com "
                      />
                      <div className="selectorContainer-com">
                        {/* {!intheDayDelivery && ( */}
                        <SelectComponent
                          item={deliveryTime}
                          onChange={onChangeWithinTheSameDayDelivery}
                          initValue={{
                            value: +withinTheSameDay,
                            label: withinTheSameDayPickerValue,
                          }}
                        />
                        {/* )} */}
                      </div>
                    </div>
                  )}
                  {moreThanTwentyFourHr && (
                    <div>
                      <Text
                        label={labels.comuneModalDeliverOptionPick}
                        variant="H5  "
                      />
                      <div
                        className={
                          !timeSlot
                            ? "typeDeliver-com"
                            : "typeDeliverSelected-com"
                        }
                        onClick={() => {
                          setTimeSlot(!timeSlot);
                          setSpecificDays(false);
                        }}
                      >
                        <Text
                          label={labels.comuneModalTimeSlot}
                          variant="ButtonMd-14"
                        />
                      </div>
                      <div
                        className={
                          !specificDays
                            ? "typeDeliver-com"
                            : "typeDeliverSelected-com"
                        }
                        onClick={handleOnPressSpecificDays}
                      >
                        <Text
                          label={labels.comuneModalSpecificDays}
                          variant="ButtonMd-14"
                        />
                      </div>
                    </div>
                  )}
                  {timeSlot && (
                    <div>
                      {renderTimeSlotOptions()}
                      {renderAlert(labels.comuneModalAlert1)}
                      {renderCommonTitle(labels.comuneModalAlert2)}
                      <div className="selectorContainer-com">
                        <SelectComponent
                          item={hours}
                          onChange={handleFilterByStatus}
                          initValue={{
                            label: selectedDeliveryTime,
                            value: +pickerValue,
                          }}
                        />
                      </div>
                      {
                        <div className="commonContainer-com">
                          {renderAlert(labels.comuneModalAlert3)}
                        </div>
                      }
                    </div>
                  )}
                  {specificDays && moreThanTwentyFourHr && (
                    <div>
                      <Text
                        label={labels.comuneModalDeliverDays}
                        variant="H5"
                      />
                      {dateConfig(daysSelection)}
                      {renderAlert(labels.comuneModalAlert4)}
                      {renderCommonTitle(labels.comuneModalReceptionDays)}
                      <div className="selectorContainer-com">
                        <SelectComponent
                          item={receptionDays}
                          onChange={handleReceptionDays}
                          initValue={{
                            label: selectedReceptionDays,
                            value: +pickerValueReception,
                          }}
                        />
                      </div>
                      {renderAlert(labels.comuneModalAlert5)}
                      {renderCommonTitle(labels.comuneModalHourLimitTitle)}
                      <div className="selectorContainer-com">
                        <SelectComponent
                          item={hours}
                          onChange={handleFilterByStatus}
                          initValue={{
                            label: selectedDeliveryTime,
                            value: +pickerValue,
                          }}
                        />
                      </div>
                      {
                        <div className="commonContainer-com">
                          {renderAlert(labels.comuneModalAlert6)}
                        </div>
                      }
                    </div>
                  )}
                </>
              )}
            </div>
            <button
              type="button"
              className={
                isConfirmButtonDisable()
                  ? "confirmButtonDeactive-com"
                  : "confirmButton-com "
              }
              onClick={handleContinue}
              disabled={isConfirmButtonDisable()}
            >
              <Text label={labels.confirm} variant="Subtitle2" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
