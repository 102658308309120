import React from "react";
import whatsapp from "../../../assets/icons/icons-whatsapp.svg";
import error from "../../../assets/icons/icons-error.svg";
import Text from "../../text/Text";
import "./ErrorModal.css";
import openWhatsapp from "../../../utils";

export interface IErrorModalProps {
  text: string;
  buttonText: string;
  subtitle?: string;
  handleOnPress?: () => void;
  whatsappButton?: boolean;
  closeModal?: () => void;
}

export default function ErrorModal({
  text,
  buttonText,
  subtitle,
  handleOnPress,
  whatsappButton = true,
  closeModal,
}: IErrorModalProps) {
  return (
    <div>
      <div className={"centeredIcon"}>
        <img src={error} />
      </div>
      <div className={"title"}>
        <Text label={text} variant="Body1-16 centeredTitle" />
      </div>
      {subtitle && <Text label={subtitle} variant="Body2-14" />}
      <div className={"centered"}>
        {whatsappButton ? (
          <button className={"buttonHelp"} onClick={openWhatsapp}>
            <a className={"ButtonMd-14"}>{buttonText}</a>
            <img src={whatsapp} className={"whatsappIcon"} />
          </button>
        ) : (
          <button className={"buttonHelp"} onClick={closeModal}>
            <a className={"ButtonMd-14"}>{buttonText}</a>
          </button>
        )}
      </div>
    </div>
  );
}
