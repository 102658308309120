import { BASEAPI } from "./constants";

const processPaymentOrder = async (
  idLocal: string,
  idVenta: string,
  data: any,
  token: string
) => {
  const res = await fetch(
    `${BASEAPI}locales/${idLocal}/ventas/${idVenta}/pagar`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  )
    .then(async (response) => {
      const data = JSON.parse(await response.text());
      return [data, response.status];
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};
const createPaymentOrder = async (
  idLocal: string,
  token: string,
  data: any
) => {
  const res = await fetch(`${BASEAPI}locales/${idLocal}/ventas`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      const data = JSON.parse(await response.text());
      return [data, response.status];
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};

export { processPaymentOrder, createPaymentOrder };
