import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { MEASURES } from "../../../constants";
import { useAuthState } from "../../../context/loginContext/loginContext";
import { useLoadingContext } from "../../../context/spinnerContext";
import {
  brandsToOption,
  categoriesToOption,
  createProductFormIds,
  imageToBase64,
} from "../../../formatters";
import useAsyncSelect from "../../../hooks/useAsyncSelect";
import useDisclosure from "../../../hooks/useDisclosure";
import { labels } from "../../../labels";
import {
  addIngredients,
  localBrands,
  localCategories,
  updateIngredients,
  updatePrice,
  updateProduct,
  updateStock,
  uploadImage,
  deleteProduct,
  getProduct,
} from "../../../services/products";
import AsyncSelect from "../../asyncSelect/AsyncSelect";
import IngredientsForEdit from "../../ingredients/IngredientsForEdit";
import OutlinedInput from "../../outlinedInput/OutlinedInput";
import OutlinedSelect from "../../outlinedSelect/OutlinedSelect";
import ProductType from "../../productType/ProductType";
import SvgIcon from "../../svgIcon/SvgIcon";
import Text from "../../text/Text";
import ModalComponent from "../ModalComponent";
import DeleteProductModal from "./DeleteProductModal";
import styles from "./EditProduct.module.css";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { useLocation, useNavigate } from "react-router-dom";
import WarningUI from "../../warning/WarningUI";

const MB_SIZE = 1024 * 1024;
const MAX_MB_IMAGE = 2;

const ingredientsKey = "ingredients";
const EditProduct = () => {
  const location = useLocation();
  const data = location.state as IProductObject;
  const [ingredients, setIngredients] = useState<IEditIngredients[] | null>(
    null
  );
  const handleIngredients = (newIngredients: IEditIngredients[]) => {
    setIngredients(newIngredients);
  };
  const navigate = useNavigate();
  const brands = brandsToOption(localBrands());
  const categories = categoriesToOption(localCategories());
  const [editData, setEditData] = useState<IProduct>({} as IProduct);
  const [editUnit, setEditUnit] = useState<ICommerceStock>(
    {} as ICommerceStock
  );
  const [unit, setUnit] = useState<string>("");
  const [isValidForm, setIsValidForm] = useState<boolean>(true);
  const [price, setPrice] = useState<number>(0);
  const [isPublic, setIsPublic] = useState<boolean>(data.product.public);
  const [deleteProductMessage, setDeleteProductMessage] =
    useState<boolean>(false);
  const loadingContext = useLoadingContext();
  const { handleOptionSelect: handleSelectBrand, optionID: brandID } =
    useAsyncSelect();
  const { handleOptionSelect: handleSelectCategory, optionID: categoryID } =
    useAsyncSelect();
  const { open: loading, handleClose: handleCloseLoading } =
    useDisclosure(true);
  const [image, setImage] = useState<File | null>(null);
  const [imageBase64, setImageBase64] = useState<string>("");
  const [errors, setError] = useState<ICreateProductFormErrors | null>(null);
  const [stock, setStock] = useState<number>(0);
  const [showError, setShowErrors] = useState<boolean>(false);
  const internalCommerceID = data.commerceStock.commerceInternalID;

  const {
    open: openConfirm,
    handleClose: onCloseConfirm,
    handleOpen: handleOpenConfirm,
  } = useDisclosure();
  const {
    open: openRespnseOK,
    handleClose: handleCloseResponseOk,
    handleOpen: handleOpenResponseOk,
  } = useDisclosure();
  const {
    open: openResponseError,
    handleClose: handleCloseResponseError,
    handleOpen: handleOpenResponseError,
  } = useDisclosure();
  const userData = useAuthState();
  const getData = async () => {
    const productData = await getProduct(
      data.product.id,
      data.commerceStock.commerceInternalID
    );
    setEditData({
      ...productData.product,
      weight: productData.commerceStock.weight,
    });
    setEditUnit({
      ...productData.commerceStock,
      weightUnit: productData.commerceStock.weightUnit,
    });
    setPrice(
      productData.productPrices.find((element) => element.priceTypeID === 1)
        ?.saleAmount ?? 0
    );
    setStock(productData.commerceStock.stock);
    setIngredients(productData.ingredientTypes);
    handleCloseLoading();
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const getBase64FromUrl = async () => {
      const url = data.product.photoURL;
      const resp = await fetch(url);
      const blob = await resp.blob();
      return new Promise((resolve) => {
        const file = new File([blob], "image.jpg", { type: blob.type });
        setImage(file);
      });
    };
    getBase64FromUrl();
  }, []);

  const handleFood = () => {
    setEditData((prevState) => ({ ...prevState, food: !prevState.food }));
  };
  const handleError = (key: string, label: string) => {
    setError((prevState) => ({ ...prevState, [key]: label }));
    setIsValidForm(false);
  };
  const handleImage = (base64: string) => {
    setImageBase64(base64);
  };

  useEffect(() => {
    if(image)
      imageToBase64(image, handleImage);
    else
      setImageBase64("");
  }, [image]);

  const validateIngredients = () => {
    let ingredientsValid = true;
    if (ingredients && ingredients.length > 0) {
      ingredients.forEach((element) => {
        if (!element.deactive && (!element.maxAmount || !element.name)) {
          ingredientsValid = false;
        }
        element.ingredients.forEach((item) => {
          if (!item.deactive && !item.name) {
            ingredientsValid = false;
          }
        });
        handleError(ingredientsKey, labels.ingredientsError);
      });
    }
    if (ingredientsValid) {
      setError(null);
    }
    return ingredientsValid;
  };

  const validateIds = () => {
    let isValid = true;
    if (!brandID) {
      handleError(createProductFormIds.productBrandID, labels.required);
      isValid = false;
    } else if (!brands.find((element) => element.id === brandID)) {
      handleError(createProductFormIds.productBrandID, labels.noValidOption);
      isValid = false;
    }
    if (!categoryID) {
      handleError(createProductFormIds.productCategoryID, labels.required);
      isValid = false;
    } else if (!categories.find((element) => element.id === categoryID)) {
      handleError(createProductFormIds.productCategoryID, labels.noValidOption);
      isValid = false;
    }

    return isValid;
  };
  const validateImage = () => {
    let isValid = true;
    if (image && image?.size / MB_SIZE > MAX_MB_IMAGE) {
      handleError(createProductFormIds.productImage, labels.imageSizeError);
      isValid = false;
    }
    if (!isValid) {
      setShowErrors(true);
      setIsValidForm(false);
    } else {
      setShowErrors(false);
      setIsValidForm(true);
    }
    return isValid;
  };

  const validateFullForm = () => {
    setIsValidForm(true);
    let formKey: keyof IProduct;
    let isValid = true;
    for (formKey in editData) {
      if (
        [
          createProductFormIds.productName,
          createProductFormIds.fullDescription,
          createProductFormIds.shortDescription,
          createProductFormIds.weight,
          createProductFormIds.stock,
        ].includes(formKey) &&
        !editData[formKey]
      ) {
        handleError(formKey as string, labels.required);
        isValid = false;
      }
    }
    if (!price || !stock) {
      isValid = false;
    }
    const validIds = validateIds();
    const isValidImage = validateImage();
    const isValidIngredients = validateIngredients();
    isValid = validIds && isValid && isValidImage && isValidIngredients;
    setIsValidForm(isValid);
    if (isValid) {
      setError(null);
    }
    return isValid;
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    setEditData((prevState) => ({ ...prevState, [name]: value }));
    validateFullForm();
    setError(null);
  };
  const handleChangePrice = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPrice(Number(value));
    validateFullForm();
    setError(null);
  };
  const handleChangeStock = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setStock(Number(value));
    validateFullForm();
    setError(null);
  };

  const handleFile = (e: FormEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0] ?? null;
    setImage(file);
    validateFullForm();
    setError(null);
  };

  const handleUnitChange = (element: ChangeEvent<HTMLSelectElement>) => {
    setUnit(element.target.value);
  };
  const formatIngredient = (
    ingredientType: IEditIngredients,
    forCreate: boolean,
    prodId: number
  ) => {
    const ingredients = ingredientType.ingredients.map((element) => {
      return {
        ...element,
        ingredientType: element.id ? element.ingredientType : null,
      };
    });
    return {
      ingredientTypeID: ingredientType ? ingredientType.id : null,
      name: ingredientType.name,
      productID: prodId,
      internalCommerceID,
      mandatory: ingredientType.mandatory,
      deactive: forCreate ? false : ingredientType.deactive,
      multichoice: false,
      maxAmount: ingredientType.maxAmount,
      ingredients: ingredients,
      id: ingredientType.id,
    };
  };
  const handleUpdateIngredients = async (prodId: number) => {
    const ingredientsForUpdate = ingredients
      ?.filter((element) => element.id)
      .map((element) => formatIngredient(element, false, prodId));
    const ingredientsForCreate = ingredients
      ?.filter((element) => element.id === null || element.id === undefined)
      .map((element) => formatIngredient(element, true, prodId));

    await updateIngredients(prodId, ingredientsForUpdate ?? []).then((resp) => {
      if (resp.status === 200) {
        handleOpenResponseOk();
      } else {
        handleOpenResponseError();
      }
    });

    if (ingredientsForCreate && ingredientsForCreate.length > 0) {
      const data = {
        ingredientTypes: ingredientsForCreate,
      };
      await addIngredients(
        prodId,
        data.ingredientTypes.map((element) => {
          return {
            ...element,
            ingredients: element.ingredients.map((item): Ingredient => {
              return {
                name: item.name,
                price: item.price,
                maxAmount: item?.maxAmount ?? 0,
              };
            }),
          };
        })
      );
    }
    getData();
  };

  const addPrice = async (prodId: number) => {
    setDeleteProductMessage(false);
    const dataPrecio = {
      productID: prodId,
      commerceInternalID: +(
        userData?.commerceDetails?.sEstablecimiento ??
        "" + userData?.commerceDetails?.cLocal[0].idLocal
      ),
      active: true,
      code: null,
      cost: 0,
      datePriceEnd: null,
      datePriceStart: null,
      discount: 0,
      dueDate: null,
      priceTypeID: 1,
      saleAmount: price,
      tax: 0,
    };
    try {
      loadingContext?.onOpen();
      await updatePrice(prodId, dataPrecio).then((response) => {
        loadingContext?.onClose();
        if (ingredients && ingredients.length > 0) {
          handleUpdateIngredients(prodId);
        } else if (response.status === 200) {
          handleOpenResponseOk();
        } else {
          handleOpenResponseError();
        }
      });
    } catch (err) {
      loadingContext?.onClose();
      console.log(err);
    }
  };

  const addStock = async (prodId: number) => {
    const dataStock: IEditStock = {
      minimunStock: 200,
      maximunStock: 50,
      minimunStockForEcommerce: 123,
      stock: stock,
      weight: editData.weight,
      weightUnit: unit,
      entryDate: null,
      expirationDate: null,
      outOfStockDate: null,
      sellingByEcommerce: true,
      sellingByPickandgo: true,
    };
    try {
      loadingContext?.onOpen();

      await updateStock(prodId, dataStock).then((response) => {
        loadingContext?.onClose();
        if (response.status === 200) {
          addPrice(prodId);
        }
      });
    } catch (err) {
      loadingContext?.onClose();
      handleOpenResponseError();
      console.log(err);
    }
  };

  const handleUpdateProduct = async () => {
    let error = true;
    const isValid = validateFullForm();
    if (!isValid) return;
    loadingContext?.onOpen();
    const photo = imageBase64
      ? await uploadImage(imageBase64)
      : data.product.photoURL;
    const params: IEditProduct = {
      sku: data.product.sku,
      productName: editData.productName,
      fullDescription: editData.fullDescription,
      shortDescription: editData.shortDescription,
      photoURL: photo,
      productCategoryID: Number(categoryID),
      productBrandID: Number(brandID),
      isFood: editData.food,
    };
    try {
      await updateProduct(params)
        .then((resp) => {
          loadingContext?.onClose();
          if (resp.status === 200) {
            addStock(data.product.id);
            error = false;
          } else {
            handleOpenResponseError();
          }
        })
        .finally(() => {
          loadingContext?.onClose();
          if (error) {
            handleOpenResponseError();
          }
        });
    } catch (err) {
      loadingContext?.onClose();
      handleOpenResponseError();
    }
  };

  const handleClick = () => {
    if (validateFullForm()) {
      const myDiv = document.getElementById("liquidate-form");
      if (myDiv) {
        myDiv.scrollIntoView({ behavior: "smooth" });
      }
      handleUpdateProduct();
    }
  };

  const clickOnDelete = () => {
    const myDiv = document.getElementById("liquidate-form");
    if (myDiv) {
      myDiv.scrollIntoView({ behavior: "smooth" });
    }
    handleOpenConfirm();
  };
  const handleDelete = async () => {
    setDeleteProductMessage(true);
    const idProduct = data.product.id;
    const internalIdCommerce = data.commerceStock.commerceInternalID;
    loadingContext?.onOpen();
    if (validateFullForm()) {
      try {
        await deleteProduct(idProduct, internalIdCommerce, data).then(
          (resp) => {
            loadingContext?.onClose();
            onCloseConfirm();
            if (resp.status === 200) {
              setTimeout(() => {
                handleOpenResponseOk();
              }, 400);
            } else {
              setTimeout(() => {
                handleOpenResponseError();
              }, 400);
            }
          }
        );
      } catch (err) {
        onCloseConfirm();
        setTimeout(() => {
          handleOpenResponseError();
        }, 400);
      }
    }
  };
  const closeConfirm = openConfirm && onCloseConfirm;
  const closeResponseOk = openRespnseOK && handleCloseResponseOk;
  const closeResponseError = openResponseError && handleCloseResponseError;
  const closeFunction = closeConfirm || closeResponseOk || closeResponseError;

  const handleCloseResponseOKModal = () => {
    handleCloseResponseOk();
    setTimeout(() => {
      navigate(-1);
    }, 400);
  };
  useEffect(() => {
    validateFullForm();
  }, [editData, ingredients, image, price, stock]);
  return (
    <div className={styles.container}>
      {!loading && (
        <div className={styles.wrapper}>
          <div className={styles.titleTextWrapper}>
            <Text label={labels.editProductTitle} variant="H5 black" />
          </div>
          <div>
            <WarningUI />
          </div>
          <form id="liquidate-form" className={styles.form}>
            <div className={styles.container}>
              <ModalComponent
                show={openConfirm || openRespnseOK || openResponseError}
                handleClose={closeFunction ? closeFunction : () => null}
                backDropStyle={styles.backdrop}
                modalStyle={styles.modalsmall}
              >
                {openConfirm && (
                  <DeleteProductModal
                    onClick={handleDelete}
                    onBack={onCloseConfirm}
                  />
                )}
                {openRespnseOK && (
                  <SuccessModal
                    buttonText={labels.continue}
                    text={
                      deleteProductMessage
                        ? labels.editProductDeleted
                        : labels.editProductUpdated
                    }
                    handleOnPress={handleCloseResponseOKModal}
                  />
                )}
                {openResponseError && (
                  <ErrorModal
                    buttonText={labels.accept}
                    handleOnPress={handleCloseResponseError}
                    text={
                      deleteProductMessage
                        ? labels.editProductDeleteError
                        : labels.editProductUpdateError
                    }
                  />
                )}
              </ModalComponent>
              <div className={styles.formWrapper}>
                <div className={styles.titleWrapper}>
                  <Text
                    label={labels.productInformation}
                    variant={`subtitle1 ${styles.textWidth} ${styles.textColor}`}
                  />
                  <div className={styles.divider} />
                </div>
                <Text
                  label={"Tipo"}
                  variant={`subtitle1 ${styles.leftAlign}`}
                />
                <ProductType isFood={!!editData.food} onChange={handleFood} />

                <div className={styles.formWrapper}>
                  <div className={styles.inputContainer}>
                    <OutlinedInput
                      formId={createProductFormIds.productName}
                      floatingLabel={labels.name}
                      onChange={handleChange}
                      value={editData.productName}
                      placeholder={labels.productNamePlaceholder}
                      minLength={0}
                      maxLength={42}
                      charCounter
                      min="0"
                      max="42"
                      disabled={isPublic}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <OutlinedInput
                      formId={createProductFormIds.fullDescription}
                      floatingLabel={labels.description}
                      onChange={handleChange}
                      value={editData.fullDescription}
                      placeholder={labels.descriptionPlaceHolder}
                      minLength={0}
                      maxLength={82}
                      charCounter
                      disabled={isPublic}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <OutlinedInput
                      formId={createProductFormIds.shortDescription}
                      floatingLabel={labels.shortDescription}
                      onChange={handleChange}
                      value={editData.shortDescription}
                      placeholder={labels.productNamePlaceholder}
                      minLength={0}
                      maxLength={64}
                      charCounter
                      disabled={isPublic}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <AsyncSelect
                      options={brands}
                      floatingLabel={labels.productBrand}
                      placeholder={labels.productBrandPlaceholder}
                      initValue={`${data.productBrand.id}`}
                      onChange={handleSelectBrand}
                      disabled={isPublic}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <AsyncSelect
                      options={categories}
                      floatingLabel={labels.classification}
                      initValue={`${data.productCategories.id}`}
                      placeholder={labels.classificationPlaceholder}
                      onChange={handleSelectCategory}
                      disabled={isPublic}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <OutlinedInput
                      formId={createProductFormIds.productImage}
                      floatingLabel={labels.productImage}
                      placeholder={labels.productImagePlaceholder}
                      type="file"
                      accept="image/*;capture=camera"
                      onChange={handleFile}
                      helperText={`${
                        labels.fileSize
                      } \n Archivo actual (${data.product.photoURL.slice(
                        data.product.photoURL.lastIndexOf("/") + 1,
                        data.product.photoURL.length
                      )})`}
                      disabled={isPublic}
                      error={
                        showError
                          ? `${
                              labels.imageSizeError
                            }  Archivo actual (${data.product.photoURL.slice(
                              data.product.photoURL.lastIndexOf("/") + 1,
                              data.product.photoURL.length
                            )})`
                          : ""
                      }
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.rowItem1}>
                      <OutlinedSelect
                        formId={createProductFormIds.productMeasureUnit}
                        floatingLabel={labels.unit}
                        options={MEASURES}
                        defaultValue={
                          editUnit?.weightUnit?.toLowerCase() || "0"
                        }
                        onChange={handleUnitChange}
                      />
                    </div>
                    <div className={styles.rowItem2}>
                      <OutlinedInput
                        formId={createProductFormIds.weight}
                        floatingLabel={labels.quantity}
                        type="text"
                        onChange={handleChange}
                        value={
                          editData.weight === null ? "0" : `${editData.weight}`
                        }
                        isNumber={unit !== "lt" && unit !== "kg"}
                        isDecimal={unit === "lt" || unit === "kg"}
                        min="0"
                        placeholder={labels.quantityPlaceholder}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.rowItem1}>
                      <OutlinedInput
                        onChange={handleChangePrice}
                        value={`${price}`}
                        floatingLabel={labels.price}
                        isNumber
                        placeholder={labels.normalPlaceholder}
                      />
                    </div>
                    <div className={styles.rowItem2}>
                      <OutlinedInput
                        floatingLabel={labels.stock}
                        onChange={handleChangeStock}
                        value={`${stock}`}
                        isNumber
                        placeholder={labels.quantityPlaceholder}
                      />
                    </div>
                  </div>
                  {ingredients && (
                    <IngredientsForEdit
                      handleIngredients={handleIngredients}
                      haveError={""}
                      initialData={
                        ingredients && ingredients?.length > 0
                          ? ingredients.map((element) => {
                              return { ...element, creationDate: null };
                            })
                          : undefined
                      }
                    />
                  )}
                  {errors?.ingredients && (
                    <div className={styles.individualError}>
                      <div className={styles.warningIcon}>
                        <SvgIcon iconName="warning" />
                      </div>
                      <p className="subtitle3">{errors?.ingredients}</p>
                    </div>
                  )}
                  <div className={styles.buttonWrapper}>
                    <button
                      type="button"
                      className={` ButtonLg-16  ${styles.buttonDelete}`}
                      onClick={clickOnDelete}
                    >
                      {labels.deleteProduct}
                    </button>
                    <button
                      type="button"
                      className={` ButtonLg-16 ${styles.button} ${
                        !isValidForm ? styles.IsButtonDisabled : ""
                      }`}
                      onClick={handleClick}
                    >
                      {labels.saveChanges}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditProduct;
