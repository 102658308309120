import { BASEAPI } from "./constants";

const BASE_API = BASEAPI;
const API_OB_FORM = BASE_API + "APIOnBoardingMerchant/";

const getRegionForOnlineStore = async () => {
  let res: RegionTypeDTO[] = [];
  await fetch(`${API_OB_FORM}merchantOnline/region`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .then((responseJson) => {
      if (responseJson != null) {
        res = responseJson as RegionTypeDTO[];
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return res;
};
const getComunaForOnlineStore = async (codeRegion: string) => {
  let res = null;
  await fetch(`${API_OB_FORM}merchantOnline/commune?region=${codeRegion}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .then((responseJson) => {
      if (responseJson != null) {
        res = responseJson;
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return res;
};
const getWithdrawalHourSlot = async () => {
  let res = null;
  await fetch(`${BASE_API}apimac/api/v1/mac/common/hour-limit`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .then((responseJson) => {
      if (responseJson != null) {
        res = responseJson;
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return res;
};
const closeStore = async (internalCommerceId: string, data: any) => {
  const res = await fetch(
    `${BASE_API}apimac/api/v1/mac/commerces/close/${internalCommerceId}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
    .then(async (response) => {
      const data = JSON.parse(await response.text());
      return [data, response.status];
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};
const putCommerceInfo = async (data: any, sEcommerceId: string) => {
  const res = await fetch(
    `${BASE_API}apimac/api/v1/mac/commerces/info?commerceInternalID=${sEcommerceId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
    .then(async (response) => {
      return response.status;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};
const setComunesToRegion = (region: RegionType[], comune: ComuneType[]) => {

    const tmp = region;
    comune.forEach(value => {
      const foundedIndex = tmp.findIndex(
        (element) => element.regID === value.comunaList[0].regID
      );
      if (value.isEdit) {
        const editIndex = tmp[foundedIndex].comunas.findIndex(
          (item) => item.comunaList[0].ID === value.comunaList[0].ID
        );
        if (editIndex > -1) {
          tmp[foundedIndex].comunas[editIndex] = value;
        }
      } else {
        tmp[foundedIndex].comunas = [...tmp[foundedIndex].comunas, value];
      }
    })
    return tmp;

};

export {
  getRegionForOnlineStore,
  getComunaForOnlineStore,
  getWithdrawalHourSlot,
  closeStore,
  putCommerceInfo,
  setComunesToRegion,
};
