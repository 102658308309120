import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import routes from "../../router/routes";
import { labels } from "../../labels";
import "./OptionOnlineStoreScreen.css";
import location from "../../assets/icons/icon-location.svg";
import RegionModal from "../../components/modal/RegionModal";
import useDisclosure from "../../hooks/useDisclosure";
import Trash from "../../assets/icons/icons-trash.svg";
import editPencil from "../../assets/icons/icon-edit-pencil.svg";
import ComuneModal from "../../components/modal/ComuneModal";
import StepIndicator from "../../components/stepIndicator/StepIndicator";
import { getStateData, prepareForNavigation } from "./configurationsUtils";
import { onlineStoreStepTwo } from "../../services/commerce";
import OutlinedInput from "../../components/outlinedInput/OutlinedInput";
import { useLoadingContext } from "../../context/spinnerContext";
import {
  setComunesToRegion,
} from "../../services/OnlineStore";

interface OptionsState {
  selectedList: RegionType[];
  noFreeDelivery: boolean;
  withDrawal: boolean;
  deliverySelected: boolean;
  freeDelivery: boolean;
  minAmountDelivery: boolean;
}
const viewStep = 2;
export default function OptionOnlineStoreScreen() {
  const navigation = useNavigate();
  const { state, data } = getStateData("optionsState", {});
  const loadingContext = useLoadingContext();
  const currentUser = localStorage.getItem("currentUser");
  const currentUserJSON = JSON.parse(currentUser ?? "{}");
  const optionsState = data as OptionsState;

  const { open, handleClose, handleOpen } = useDisclosure();

  const [showRegionModal, setshowRegionModal] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<RegionType[]>(
    optionsState.selectedList ?? []
  );
  const [deliverySelected, setDeliverySelected] = useState<boolean>(
    optionsState.deliverySelected ?? false
  );
  const [withDrawal, setWithdrawal] = useState<boolean>(
    optionsState.withDrawal ?? false
  );
  const [noFreeDelivery, setNoFreeDelivery] = useState<boolean>(
    optionsState.noFreeDelivery ?? false
  );
  const [minAmountDelivery, setMinAmountDelivery] = useState<boolean>(
    optionsState.minAmountDelivery ?? false
  );
  const [freeDelivery, setFreeDelivery] = useState<boolean>(
    optionsState.freeDelivery ?? false
  );
  const [regionSelected, setRegionSelected] = useState<RegionType>();
  const [comuneSelected, setComuneSelected] = useState<
    ComuneType | undefined
  >();
  const [regionID, setRegionID] = useState<string>("");
  const [minAmmountDeliverPrice, setMinAmmountDeliverPrice] =
    useState<string>("");
  const [minAmmountError, setMinAmmountError] = useState<string>("");
  const prepare = () => {
    let newState = {};
    if (withDrawal) {
      newState = {
        optionsState: {
          withDrawal,
        },
      };
    } else {
      newState = {
        optionsState: {
          selectedList,
          noFreeDelivery,
          withDrawal,
          deliverySelected,
          freeDelivery,
          minAmountDelivery,
        },
      };
    }
    prepareForNavigation(state, newState);
  };
  const navigateBack = () => {
    prepare();
    navigation(`/${routes.onlineStoreInformationScreen}`);
  };
  const handleSendData = async () => {
    let allRegionsHasComune = true;
    selectedList.forEach((element) => {
      if (element.comunas.length === 0) {
        allRegionsHasComune = false;
      }
    });
    if (!allRegionsHasComune) {
      return;
    }
    const comuneListTmp: any[] = [];
    selectedList?.forEach((reg: RegionType) =>
      reg.comunas.forEach((com) => {
        const daysSelectedTmp =
          com.daysSelection?.filter((dss) => dss.isSelected) || [];
        com.comunaList.forEach((co) => {
          const comuToAdd = com.intheDayDelivery
            ? {
                regionDelivery: deliverySelected ? co.ID_REGION : "",
                communeDelivery: co.NOMBRE,
                amountDelivery: com.costValue,
                hourLimit: null,
                sameDayDelivery: com.intheDayDelivery,
                timeDelivery: com.withinTheSameDayPickerValue,
                typeDelivery: 0,
                hourRange: null,
                dayReception: null,
              }
            : {
                regionDelivery: deliverySelected ? co.ID_REGION : "",
                communeDelivery: co.NOMBRE,
                amountDelivery: com.costValue,
                hourLimit: com.deliveryTime,
                sameDayDelivery: com.intheDayDelivery,
                timeDelivery: com.withinTheSameDayPickerValue,
                typeDelivery: com.specificDays ? 2 : 1,
                hourRange: com.specificDays
                  ? null
                  : com.optionSelected?.hourRange,
                dayReception: com.specificDays
                  ? com.selectedReceptionDays
                  : null,
                monday: daysSelectedTmp.find((dsst) => dsst.day === "Lunes")
                  ? true
                  : undefined,
                tuesday: daysSelectedTmp.find((dsst) => dsst.day === "Martes")
                  ? true
                  : undefined,
                wednesday: daysSelectedTmp.find(
                  (dsst) => dsst.day === "Miércoles"
                )
                  ? true
                  : undefined,
                thursday: daysSelectedTmp.find((dsst) => dsst.day === "Jueves")
                  ? true
                  : undefined,
                friday: daysSelectedTmp.find((dsst) => dsst.day === "Viernes")
                  ? true
                  : undefined,
                saturday: daysSelectedTmp.find((dsst) => dsst.day === "Sábado")
                  ? true
                  : undefined,
                sunday: daysSelectedTmp.find((dsst) => dsst.day === "Domingo")
                  ? true
                  : undefined,
              };
          comuneListTmp.push(comuToAdd);
        });
      })
    );

    const data = {
      hasDelivery: deliverySelected,
      hasWithdrawal: withDrawal,
      hasFreeDelivery: freeDelivery,
      minimalAmountFreeDelivery: minAmmountDeliverPrice,
      commerceInternalID: `${currentUserJSON?.sEstablecimiento ?? ""}${
        currentUserJSON?.cLocal?.[0]?.sLocal ?? ""
      }`,
      commune: comuneListTmp,
    };
    loadingContext?.onOpen();
    const toNextStep = await onlineStoreStepTwo(data);
    loadingContext?.onClose();
    if (toNextStep) {
      handleContinue();
    }
  };
  const handleContinue = () => {
    prepare();
    navigation(`/${routes.scheduledOnlineStore}`);
  };

  const handleCloseRegionModal = () => {
    setshowRegionModal(false);
  };
  const addRegion = (region: RegionType) => {
    setSelectedList((prevState) => {
      const tmp = prevState;
      const foundedIndex = tmp.find(
        (element) => element.regID === region.regID
      );

      if (foundedIndex) {
        return [...prevState];
      } else {
        return [...prevState, { ...region, comunas: [] }];
      }
    });
  };

  const handleOpenComuneModal = (region: RegionType) => {
    const tmp = region.ID;
    setRegionSelected(region);
    setRegionID(tmp);
    handleOpen();
  };
  const handleOpenEditComuneModal = (
    region: RegionType,
    comune: ComuneType
  ) => {
    const tmp = region.ID;
    setRegionSelected(region);
    setComuneSelected(comune);
    setRegionID(tmp);
    handleOpen();
  };
  const renderDay = (daysSelection: DaySelectionType[]) => {
    const daysSelected =
      daysSelection.filter((ds: DaySelectionType) => ds.isSelected) || [];
    let days = "";
    daysSelected.forEach((dss: DaySelectionType, index: number) => {
      days += dss.day;
      if (index !== daysSelected.length - 1) days += ", ";
    });

    return (
      <Text label={days} variant="Body2-14 grey900 communeTextAlign-sConfig" />
    );
  };
  const handleCloseComuneModal = () => {
    setRegionSelected(undefined);
    setComuneSelected(undefined);
    handleClose();
  };
  const addComuneToRegion = (comune: ComuneType[]) => {
    setSelectedList(setComunesToRegion(selectedList, comune));
  };
  const returnLocationInfoCard = () => {
    return selectedList.map((reg: RegionType) => (
      <div className="regionSelectedContainer-OOSConfig " key={reg.regID}>
        <div className="regionTitleStyle-sConfig ">
          <img
            src={Trash}
            className="trashIconContainer-sConfig"
            onClick={() => {
              const filteredList = selectedList.filter(
                (sl: RegionType) => sl.ID !== reg.ID
              );
              setSelectedList(filteredList);
            }}
          />
          <Text
            label={reg.NOMBRE ? reg.NOMBRE.toLowerCase() : reg.NOMBRE}
            variant="ButtonLg-16"
          />
        </div>
        <div className="containerCommune-sConfig ">
          {reg.comunas.map((co: ComuneType) =>
            co.comunaList.map((com: CommuneTypeDTO) => {
              return (
                !com.isDeleted && (
                  <div className="communesContainer" key={com.ID}>
                    <div>
                      <div className="commonContainer-sConfig spaceBetween-sConfig">
                        <Text
                          label={com.NOMBRE.toLowerCase()}
                          variant="Subtitle2"
                        />
                        <img
                          src={editPencil}
                          className="trashIconContainer-sConfig"
                          onClick={() => handleOpenEditComuneModal(reg, co)}
                        />
                      </div>

                      {co.moreThanTwentyFourHr && (
                        <>
                          <Text
                            label={labels.sConfigAproxDeliverTime}
                            variant="Subtitle2 grey900"
                          />
                          <Text
                            label={labels.comuneModalMoreThan24hs}
                            variant="Body2-14 grey900 communeTextAlign-sConfig"
                          />
                        </>
                      )}

                      <Text
                        label={labels.sConfigCost}
                        variant="Subtitle2 grey900"
                      />
                      <Text
                        label={`$ ${co.costValue}`}
                        variant="Body2-14 grey900 communeTextAlign-sConfig"
                      />
                      {co.specificDays && (
                        <div>
                          <Text
                            label={labels.sConfigDeliveryDays}
                            variant="Subtitle2 grey900"
                          />

                          {renderDay(co.daysSelection)}

                          <Text
                            label={labels.sConfigReceptionDays}
                            variant="Subtitle2 grey900"
                          />
                          <Text
                            label={co.pickerValueReception}
                            variant="Body2-14 grey900 communeTextAlign-sConfig"
                          />

                          <Text
                            label={labels.sConfigMaxHour}
                            variant="Subtitle2 grey900"
                          />
                          <Text
                            label={
                              Number(co.deliveryTime) >= 12
                                ? co.deliveryTime + " PM"
                                : co.deliveryTime + " AM"
                            }
                            variant="Body2-14 grey900 communeTextAlign-sConfig"
                          />
                        </div>
                      )}
                      {!co.moreThanTwentyFourHr &&
                        co.withinTheSameDayPickerValue && (
                          <>
                            <Text
                              label={labels.sConfigAproxDeliverTime}
                              variant="Subtitle2 grey900"
                            />
                            <Text
                              label={co.withinTheSameDayPickerValue}
                              variant="Body2-14 grey900 communeTextAlign-sConfig"
                            />
                          </>
                        )}

                      {co.optionSelected?.title && (
                        <>
                          <Text
                            label={labels.sConfigTimeSlog}
                            variant="Subtitle2 grey900"
                          />
                          <Text
                            label={co.optionSelected?.title}
                            variant="Body2-14 grey900 communeTextAlign-sConfig"
                          />
                          <Text
                            label={labels.sConfigMaxHour}
                            variant="Subtitle2 grey900"
                          />
                          <Text
                            label={
                              Number(co.deliveryTime) >= 12
                                ? co.deliveryTime + " PM"
                                : co.deliveryTime + " AM"
                            }
                            variant="Body2-14 grey900 communeTextAlign-sConfig"
                          />
                        </>
                      )}
                    </div>
                    <div className="lineCommuneStyle-sConfig" />
                  </div>
                )
              );
            })
          )}
        </div>

        <div className="comunneButtonContainer">
          <button
            type="button"
            className={"comuneButton-sConfig"}
            onClick={() => handleOpenComuneModal(reg)}
          >
            <img src={location} className="iconContainer-sConfig" />
            <Text
              label={labels.sConfigAddComune}
              variant="Subtitle2 comuneButtonTextStyle-sConfig"
            />
          </button>
        </div>
      </div>
    ));
  };
  const handleSelectDelivery = () => {
    setDeliverySelected(!deliverySelected);
  };
  const handleSelectWithdrawal = () => {
    setWithdrawal(!withDrawal);
  };
  const handleSelectNoFreeDelivery = () => {
    setNoFreeDelivery(!noFreeDelivery);
    setMinAmountDelivery(false);
    setFreeDelivery(false);
  };
  const handleSelectMinAmountDelivery = () => {
    setMinAmountDelivery(!minAmountDelivery);
    setNoFreeDelivery(false);
    setFreeDelivery(false);
  };
  const handleSelectFreeDelivery = () => {
    setFreeDelivery(!freeDelivery);
    setNoFreeDelivery(false);
    setMinAmountDelivery(false);
  };
  const handleDisabledButton = () => {
    let allRegionsHasComune = undefined;
    selectedList.forEach((element) => {
      if (element.comunas.length === 0) {
        allRegionsHasComune = false;
      } else {
        allRegionsHasComune = true;
      }
    });
    if (deliverySelected || withDrawal) {
      if (
        noFreeDelivery &&
        !minAmountDelivery &&
        !freeDelivery &&
        allRegionsHasComune
      )
        return false;
      if (
        !noFreeDelivery &&
        minAmountDelivery &&
        !freeDelivery &&
        minAmmountDeliverPrice !== "" &&
        Number(minAmmountDeliverPrice) > state.storeConfig.minAmmount &&
        allRegionsHasComune
      )
        return false;
      if (
        !noFreeDelivery &&
        !minAmountDelivery &&
        freeDelivery &&
        allRegionsHasComune
      )
        return false;
    }
    return true;
  };

  const handleChangeMinAmountPrice = (value: FormEvent<HTMLInputElement>) => {
    const regexOnlyNumbers = "^[0-9]+$";
    if (value.currentTarget.value.length === 0) {
      setMinAmmountDeliverPrice("");
      setMinAmmountError(labels.mustFill);
      return true;
    }
    if (Number(value.currentTarget.value) < state.storeConfig.minAmmount) {
      setMinAmmountError(
        `${labels.minAmmountBiggerThan} ${state.storeConfig.minAmmount}`
      );
      setMinAmmountDeliverPrice(value.currentTarget.value);
      return true;
    }
    if (value.currentTarget.value.match(regexOnlyNumbers)) {
      setMinAmmountDeliverPrice(value.currentTarget.value);
      setMinAmmountError("");
    }
    return false;
  };
  return (
    <div className="background-OOSConfig">
      <div className="innerContainer-OOSConfig">
        <div className="commonContainer-OOSConfig">
          <img
            src={arrowBack}
            className="iconContainer-OOSConfig "
            onClick={navigateBack}
          />
        </div>
        <StepIndicator view={viewStep} />
        <div>
          <div className="optionsContainer-OOSConfig">
            <Text label={labels.selectOptionForYourBussines} variant="H5" />
            <div className="optionsContainer-OOSConfig-row">
              <div
                className={
                  deliverySelected
                    ? "typeDeliverSelected-OOSConfig"
                    : "typeDeliver-OOSConfig "
                }
                onClick={handleSelectDelivery}
              >
                <Text label={labels.delivery} variant="ButtonMd-14" />
              </div>
              <div
                className={
                  withDrawal
                    ? "typeDeliverSelected-OOSConfig"
                    : "typeDeliver-OOSConfig "
                }
                onClick={handleSelectWithdrawal}
              >
                <Text label={labels.localPickup} variant="ButtonMd-14" />
              </div>
            </div>
          </div>
          {deliverySelected && (
            <>
              <div className="optionsContainer-OOSConfig">
                <Text label={labels.freeDelivery} variant="H5" />
                <div className="optionsContainer-OOSConfig-row">
                  <div
                    className={
                      noFreeDelivery
                        ? "typeDeliverSelected-OOSConfig"
                        : "typeDeliver-OOSConfig "
                    }
                    onClick={handleSelectNoFreeDelivery}
                  >
                    <Text label={labels.noFreeDelivery} variant="ButtonMd-14" />
                  </div>
                  <div
                    className={
                      minAmountDelivery
                        ? "typeDeliverSelected-OOSConfig"
                        : "typeDeliver-OOSConfig "
                    }
                    onClick={handleSelectMinAmountDelivery}
                  >
                    <Text
                      label={labels.paidFreeDelivery}
                      variant="ButtonMd-14"
                    />
                  </div>
                </div>
                <div className="alwaysDeliveryWidth">
                  <div
                    className={
                      freeDelivery
                        ? "typeDeliverSelected-OOSConfig"
                        : "typeDeliver-OOSConfig "
                    }
                    onClick={handleSelectFreeDelivery}
                  >
                    <Text
                      label={labels.alwaysFreeDelivery}
                      variant="ButtonMd-14"
                    />
                  </div>
                </div>
              </div>
              {minAmountDelivery && (
                <div className="minAmountInputContainer-OOSConfig">
                  <Text
                    label={labels.freeDeliverCondition}
                    variant="subtitle1 commonTextAlignt-OOSConfig"
                  />
                  <div className="minAmountInputWidth">
                    <OutlinedInput
                      value={minAmmountDeliverPrice}
                      onChange={handleChangeMinAmountPrice}
                      floatingLabel={labels.minAmmountPriceFreeDelivery}
                      placeholder={labels.examplePriceFreeDelivery}
                      error={minAmmountError}
                    />
                  </div>
                  <Text
                    label={`${labels.storeMinAmmount} ${state.storeConfig.minAmmount}`}
                    variant="minAmountDescription-text-style commonTextAlignt-OOSConfig"
                  />
                </div>
              )}
              <div className="optionsContainer-OOSConfig">
                <Text label={labels.deliveryPlaces} variant="md-medium " />
                <button
                  type="button"
                  className={"regionButton-sConfig"}
                  onClick={() => setshowRegionModal(true)}
                >
                  <img src={location} className="iconContainer-sConfig" />
                  <Text label={labels.sConfigAddRegion} variant="Subtitle2" />
                </button>
                {returnLocationInfoCard()}
              </div>
            </>
          )}
        </div>
      </div>
      <button
        className={
          handleDisabledButton()
            ? "buttonContinueDisabled-OOSConfig"
            : "buttonContinue-OOSConfig"
        }
        onClick={handleSendData}
      >
        <a className={"ButtonMd-14"}>{labels.continue}</a>
      </button>
      <RegionModal
        show={!!showRegionModal}
        handleClose={handleCloseRegionModal}
        onSelect={addRegion}
        accept={() => setshowRegionModal(false)}
      />
      {regionSelected && (
        <ComuneModal
          show={open}
          handleClose={handleCloseComuneModal}
          onSelect={addComuneToRegion}
          accept={handleCloseComuneModal}
          regionSelected={regionID}
          itemRegion={regionSelected}
          comuneData={comuneSelected}
        />
      )}
    </div>
  );
}
