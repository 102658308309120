import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import routes from "../../router/routes";
import "./OnlineStoreScheduledPurchaseScreen.css";
import ecoFriendlyIcon from "../../assets/icons/icons-eco-friend.svg";
import StepIndicator from "../../components/stepIndicator/StepIndicator";
import { labels } from "../../labels";
import { getStateData, prepareForNavigation } from "./configurationsUtils";
import { postStep3 } from "../../services/commerce";
import { useLoadingContext } from "../../context/spinnerContext";

const viewStep = 3;
export default function OnlineStoreScheduledPurchaseScreen() {
  const navigation = useNavigate();
  const loadingContext = useLoadingContext();
  const ref = useRef<HTMLInputElement>(null);
  const { state } = getStateData("optionsState", false);
  const currentUser = localStorage.getItem("currentUser");

  const navigateBack = () => {
    navigation(`/${routes.optionOnlineStore}`);
  };

  const navigateToTerms = (accept: boolean) => {
    prepareForNavigation(state, {
      programmed: {
        accept,
      },
    });
    // if (accept) {
    navigation(`/${routes.termsAndConditions}`);
    // }
  };
  const sendData = async (accept: boolean) => {
    const commerce = JSON.parse(currentUser ?? "{}");
    const data = {
      commerceInternalID: `${commerce?.sEstablecimiento ?? ""}${
        commerce?.cLocal?.[0]?.sLocal ?? ""
      }`,
      commerceSchedule: accept,
    };
    loadingContext?.onOpen();
    await postStep3(data).then((resp) => {
      loadingContext?.onClose();
      navigateToTerms(accept);
    });
  };
  return (
    <div className="background-SPConfig">
      <div className="innerContainer-SPConfig">
        <div className="commonContainer-SPConfig">
          <img
            src={arrowBack}
            className="iconContainer-SPConfig "
            onClick={navigateBack}
          />
        </div>
        <StepIndicator view={viewStep} />
        <div className="optionsContainer-SPConfig innerMargin-SPCongig">
          <img src={ecoFriendlyIcon} />
          <Text label={labels.configScheduled1} variant="Body1-16" />
          <Text label={labels.configScheduled2} variant="Body1-16" />
          <Text label={labels.configScheduled3} variant="Body1-16" />
          <Text label={labels.configScheduled4} variant="Body1-16 bold" />
          <Text label={labels.configScheduled5} variant="Body1-16" />
          <button
            className={"greyButton-SPConfig "}
            onClick={() => sendData(false)}
          >
            <a className={"ButtonMd-14"}>{labels.configScheduledDecline}</a>
          </button>
          <button
            className={"buttonContinue-SPConfig"}
            onClick={() => sendData(true)}
          >
            <a className={"ButtonMd-14"}>{labels.configScheduledAccept}</a>
          </button>
        </div>
      </div>
    </div>
  );
}
