import React from "react";
import Text from "../../text/Text";
import ecoFriendlyIcon from "../../../assets/icons/icons-eco-friend.svg";

import "../../../views/configurations/EcoFriendSurvey.css";

export interface ISuccessModalProps {
  text: string;
  subtitle: string;
  buttonText: string;
  handleOnPress: () => void;
}

export default function EcoFriendSuccess(props: ISuccessModalProps) {
  return (
    <div>
      <div className={"centeredIcon-survey"}>
        <img src={ecoFriendlyIcon} />
      </div>
      <div className={"title"}>
        <Text label={props.text} variant="Body1-16 bold centeredText-survey" />
        <Text label={props.subtitle} variant="centeredText-survey" />
      </div>
      <div className={"centered"}>
        <div className={"buttonContinue-survey "} onClick={props.handleOnPress}>
          <a className={"ButtonMd-14"}>{props.buttonText}</a>
        </div>
      </div>
    </div>
  );
}
