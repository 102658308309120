import React from "react";
import useBooleanValue from "../../hooks/useBooleanValue";
import RoundCheckbox from "../roundCheckBox/RoundCheckbox";
import Text from "../text/Text";
import { ecofriendly } from "../../labels";

interface IQuestionProps {
  title: string;
  index: number;
}
const Question = ({ title, index }: IQuestionProps) => {
  const {
    open: answer,
    handleOpen: handleCheckAnswer,
    handleClose: handleUnCheckAnswer,
  } = useBooleanValue(null);
  return (
    <>
      <Text label={title} variant="Subtitle3 commonMarginTop " />
      <RoundCheckbox
        checkId={`${index}-1`}
        label={ecofriendly.yes}
        onClick={handleCheckAnswer}
        checked={answer === true}
      />
      <RoundCheckbox
        checkId={`${index}-0`}
        label={ecofriendly.no}
        onClick={handleUnCheckAnswer}
        checked={answer === false}
      />
    </>
  );
};
export default Question;
