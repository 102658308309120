import React from "react";

import Text from "../text/Text";
import Pagination from "../pagination/Pagination";

import "./TableInventory.css";
import { labels } from "../../labels";

interface ITableInventory {
  listProducts: IProductObject[];
  listTitles: string[];
  liquidate: (item: IProductObject) => void;
  stopLiquidating: (item: IProduct) => void;
  onPageChange: (page: number) => void;
  onSort: (item: string) => void;
  pageSize: number;
  totalPage: number;
  currentPage: number;
  onUpdateClick: (data: IProductObject) => void;
}

function TableInventory({
  listProducts,
  listTitles,
  liquidate,
  stopLiquidating,
  onPageChange,
  onSort,
  pageSize,
  totalPage,
  currentPage,
  onUpdateClick,
}: ITableInventory) {
  return (
    <div className="container-table">
      <table>
        <thead style={{ height: "35px" }}>
          <tr>
            {listTitles.map((item) => {
              return (
                <th
                  key={item}
                  className={
                    item === "Descripción"
                      ? "thDescripcion"
                      : item === "Precio"
                      ? "thPrecio"
                      : ""
                  }
                  onClick={() => onSort(item)}
                >
                  <Text label={item} variant="text-title-table" />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {listProducts.map((item) => {
            return (
              <tr key={item.product.id} className="trList">
                <td>
                  <img
                    src={item.product.photoURL}
                    width={"58px"}
                    height={"58px"}
                  />
                </td>
                <td>
                  <Text
                    label={item.product.productName}
                    variant="text-info-table"
                  />
                </td>
                <td>
                  <Text
                    label={
                      item.product.settlement
                        ? item.productPrices[0].priceOriginal.toString()
                        : item.productPrices[0].saleAmount.toString()
                    }
                    variant="text-info-table"
                  />
                </td>
                <td>
                  <Text
                    label={
                      item.product.settlement
                        ? item.productPrices[0].saleAmount.toString()
                        : ""
                    }
                    variant="text-info-table"
                  />
                </td>
                <td>
                  <Text
                    label={item.commerceStock.stock.toString()}
                    variant="text-info-table"
                  />
                </td>
                <td>
                  {!item.product.settlement ? (
                    <div className="actions">
                      <button
                        className="button-lidation"
                        onClick={() => liquidate(item)}
                      >
                        {"Liquidar"}
                      </button>
                      <button
                        onClick={() => onUpdateClick(item)}
                        className="edit-product"
                      >
                        {labels.editProduct}
                      </button>
                    </div>
                  ) : (
                    <button
                      className="button-stop-lidation"
                      onClick={() => stopLiquidating(item.product)}
                    >
                      {"Dejar de Liquidar"}
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalPage}
        pageSize={pageSize}
        onPageChange={onPageChange}
      />
    </div>
  );
}

export default TableInventory;
