import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
  UIEvent,
} from "react";

import OutlinedInput from "../../components/outlinedInput/OutlinedInput";
import Text from "../../components/text/Text";
import ModalComponent from "../../components/modal/ModalComponent";
import SuccessModal from "../../components/modal/modalVariants/SuccessModal";
import ErrorModal from "../../components/modal/modalVariants/ErrorModal";
import { VARIANTS, VariantsType } from "../../components/modal/ModalComponent";
import { clevertapEvents, labels } from "../../labels";
import imgProducts from "../../assets/icons/icons-inventory-empty.svg";
import {
  localCategoriesCommerce,
  localProducts,
  getProducts,
  liquidateFromStock,
  stopLiquidatedProduct,
} from "../../services/products";
import "./Inventory.css";
import NavBarCategories from "../../components/navigation/NavBarCategories";
import TableInventory from "../../components/tables/TableInventory";
import useDate from "../../hooks/useDate";
import Calendar from "../../components/calendar/Calendar";
import CardProduct, { lastId } from "../../components/cards/CardProduct";
import iconError from "../../assets/icons/icons-error-msj.svg";
import LiquidateError from "../../components/modal/modalVariants/LiquidateError";
import { track } from "../../ClevertapWrapper";
import { format } from "date-fns";
import routes from "../../router/routes";
import { useNavigate } from "react-router-dom";

export default function Inventory() {
  const navigate = useNavigate();
  const [category, setCategory] = useState<string>("");
  const [nameProduct, setNameProduct] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>("");
  const [listProducts, setListProducts] = useState<IProductsWithPage>();
  const [listProductsFiltered, setListProductsFiltered] =
    useState<IProductsWithPage>();
  const [listCategories, setListCategories] = useState<ICategory[]>([]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [sortName, setSortName] = useState<string>("asc");
  const [sortPrice, setSortPrice] = useState<string>("");

  const [fetch, setFetch] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState<string>("ALL");

  const [showModalLiquidate, setShowModalLiquidate] = useState<boolean>(false);
  const [productLiq, setProductLiq] = useState<IProductObject>();

  const [modalContentType, setModalContentType] = useState<VariantsType>(null);
  const [modalContentTypeStopLiq, setModalContentTypeStopLiq] =
    useState<VariantsType>(null);
  const [noData, setNoData] = useState<boolean>(false);

  const [inputPrice, setInputPrice] = useState<number>(0);
  const [inputStock, setInputStock] = useState<number>(0);

  const [showErrorPrice, setShowErrorPrice] = useState<boolean>(false);
  const [showErrorStock, setShowErrorStock] = useState<boolean>(false);
  const [showErrorDate, setShowErrorDate] = useState<boolean>(false);
  const [minWidth, setMinWidth] = useState(
    window.matchMedia("(min-width: 749px)").matches
  );
  const [liquidar, setLiquidar] = useState<boolean>(false);
  const pageSize = 6;

  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);

  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const pageLoadData = {
    pageName: "Inventario",
    pageURL: routes.inventory,
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    codComercio: user?.sEstablecimiento,
    codLocal: user?.cLocal[0].sLocal,
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };

  const fillData = (newData: IProductObject) => {
    navigate("edit", { state: newData });
  };

  const handleCloseModalStopLiq = () => {
    setModalContentTypeStopLiq(null);
    setRefresh(true);
  };

  const handleCloseModalLiquidate = () => {
    setShowModalLiquidate(false);
  };

  const getFilterProducts = async () => {
    await getProducts(
      filter,
      page,
      pageSize,
      category,
      nameProduct,
      sortName,
      sortPrice
    );
    setFetch(false);
  };

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const last = document.getElementById(lastId)?.getBoundingClientRect();
      if (document.body.getBoundingClientRect().width < 769) {
        if (last && last?.top < 1300 && !fetch && !noData) {
          setFetch(true);
          event.preventDefault();
          event.stopPropagation();

          setPage(page + 1);
        }
      }
    },
    [fetch, page]
  );

  const handleSearchChange = (data: React.FormEvent<HTMLInputElement>) => {
    if (data.currentTarget.value != null) {
      track(clevertapEvents.inventoryClickSearch, clevertapData);
      setNameProduct(data.currentTarget.value);
      setPage(1);
      setNoData(false);
    }
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const handleClickItemNav = (data: string) => {
    if (data === "ALL") {
      setCategory("");
      setFilter("");
    } else if (data.includes("liquidacion")) {
      setCategory("");
      setFilter("SETTLEMENT");
    } else {
      setCategory(data);
      setFilter("");
    }
    setCurrentCategory(data);

    setListProductsFiltered(undefined);
    setPage(1);
    setNoData(false);
  };

  const onLiquidate = async (product: IProductObject) => {
    const data = {
      geoLocationLat: lat,
      geoLocationLong: lng,
      date: format(new Date(), "yyyy/MM/dd"),
      hour: format(new Date(), "kk:mm:ss"),
      productName: product.product.productName,
    };
    track(clevertapEvents.inventoryLiquidateProductButton, data);
    setProductLiq(product);
    setLiquidar(false);
    setShowErrorDate(false);
    setShowErrorPrice(false);
    setShowErrorStock(false);
    setInputPrice(0);
    setInputStock(0);
    setShowModalLiquidate(true);
  };

  const onStopLiquidating = async (product: IProduct) => {
    const res = await stopLiquidatedProduct(
      product.id,
      product.commerceCreatedBy
    );
    if (res.status === 200) {
      setModalContentTypeStopLiq(VARIANTS.SUCCESS);
    } else {
      setModalContentTypeStopLiq(VARIANTS.ERROR);
    }
  };

  const { handleDate, selectedDate } = useDate(new Date());

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const price = Number(data.get("liquidatePrice"));
    const stock = Number(data.get("liquidateStock"));

    const res = await liquidateFromStock({
      commerceInternalID: productLiq ? productLiq.product.commerceCreatedBy : 0,
      dateVoidSettlement: selectedDate,
      saleAmount: price,
      sku: productLiq ? productLiq.product.sku : "",
      stock: stock,
    });

    if (res.status === 200) {
      setModalContentType(VARIANTS.SUCCESS);
    } else {
      setModalContentType(VARIANTS.ERROR);
    }
  };

  const handleChangePrice = (data: React.FormEvent<HTMLInputElement>) => {
    const price = data.currentTarget.value;
    setInputPrice(Number(data.currentTarget.value));
    const priceProduct = productLiq?.productPrices[0]
      ? productLiq?.productPrices[0].saleAmount
      : 0;

    if (Number(price) > 0) {
      if (Number(price) < priceProduct) {
        setShowErrorPrice(false);
      } else if (Number(price) >= priceProduct) {
        setShowErrorPrice(true);
      }
    }
  };

  const handleChangeStock = (data: React.FormEvent<HTMLInputElement>) => {
    const stock = data.currentTarget.value;
    setInputStock(Number(data.currentTarget.value));
    const stockProduct = productLiq?.commerceStock
      ? productLiq?.commerceStock.stock
      : 0;

    if (Number(stock) > 0) {
      if (Number(stock) <= stockProduct) {
        setShowErrorStock(false);
      } else if (Number(stock) > stockProduct) {
        setShowErrorStock(true);
      }
    }
  };

  const onSort = (item: string) => {
    if (item === "Descripción") {
      setSortPrice("");
      setSortName(sortName === "asc" ? "desc" : "asc");
      if (page == 1) {
        setRefresh(true);
      } else {
        setPage(1);
      }
    }
    if (item === "Precio") {
      setSortName("");
      setSortPrice(sortPrice === "asc" ? "desc" : "asc");
      if (page == 1) {
        setRefresh(true);
      } else {
        setPage(1);
      }
    }
  };

  const titles = [
    "Imagen",
    "Nombre del producto",
    "Precio",
    "Precio Oferta",
    "Stock",
    "Acciones",
  ];

  useEffect(() => {
    window
      .matchMedia("(min-width: 749px)")
      .addEventListener("change", (e) => setMinWidth(e.matches));
  }, []);

  useLayoutEffect(() => {
    const resize = () => {
      if (document.body.getBoundingClientRect().width < 769) {
        setPage(1);

        setNoData(false);
      }
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  useEffect(() => {
    const init = async () => {
      await getFilterProducts();
      setListProducts(localProducts);
      setListCategories(localCategoriesCommerce);
    };

    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await getFilterProducts();
      setListProductsFiltered(localProducts);
    };

    if (refresh) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const filter = async () => {
      await getFilterProducts().then(() => {
        setListProductsFiltered((prevState) => {
          const newProducts = localProducts();

          if (newProducts.products.length > 0) {
            if (page === 1 || minWidth) {
              return newProducts;
            }
            if (prevState) {
              return {
                pages: newProducts.pages,
                products: [...prevState.products, ...newProducts.products],
              };
            }
          } else {
            if (nameProduct.length > 0) {
              setNoData(false);
              return newProducts;
            } else {
              setNoData(true);
              return prevState;
            }
          }
        });
        setFetch(false);
      });
    };
    if (!noData) {
      filter();
    }
  }, [category, nameProduct, page, filter, noData]);
  useEffect(() => {
    const validate = () => {
      let validPrice = true;
      let validStock = true;

      const normalPrice = Number(productLiq?.productPrices[0].saleAmount);
      const normalStock = Number(productLiq?.commerceStock.stock);

      validPrice =
        inputPrice === 0 || (inputPrice && normalPrice < inputPrice)
          ? false
          : true;
      validStock =
        inputStock === 0 || (inputStock && normalStock < inputStock)
          ? false
          : true;

      setLiquidar(validPrice && validStock);
    };

    validate();
  }, [inputPrice, inputStock]);

  useEffect(() => {
    getLocation();
  }, [lat, lng]);

  useEffect(() => {
    track(clevertapEvents.pageLoadmp, pageLoadData);
  }, []);
  useEffect(() => {
    track(clevertapEvents.pageLoadmp, pageLoadData);
  }, []);
  const handleCloseModal = () => {
    setModalContentType(null);
    setShowModalLiquidate(false);
    setRefresh(true);
  };

  return (
    <>
      <ModalComponent
        show={showModalLiquidate}
        handleClose={handleCloseModalLiquidate}
      >
        <div className="container-body-liquidate">
          <div className="container-title-liquidate">
            <div className="image-product-liquidate">
              <img
                src={productLiq ? productLiq.product.photoURL : ""}
                width={"84px"}
                height={"84px"}
              />
            </div>
            <div className="name-product-liquidate">
              <div>
                <Text
                  label={productLiq ? productLiq.product.shortDescription : ""}
                  variant="text-description-short"
                />
              </div>
              <div>
                <Text
                  label={productLiq ? productLiq.product.fullDescription : ""}
                  variant="text-description-full"
                />
              </div>
            </div>
          </div>
          <div>
            <form className="form-liquidate" onSubmit={handleSubmit} noValidate>
              <div className="form-inputs">
                <div className="input-price">
                  <OutlinedInput
                    formId="liquidatePrice"
                    floatingLabel={labels.inventoryModalPrice}
                    placeholder="Ej.: $2990"
                    helperText={`Precio Normal: ${
                      productLiq ? productLiq.productPrices[0].saleAmount : ""
                    }`}
                    onChange={handleChangePrice}
                  />
                </div>
                <div className="input-stock">
                  <OutlinedInput
                    formId="liquidateStock"
                    floatingLabel={labels.inventoryModalStock}
                    placeholder="Ej.: 1"
                    helperText={`Stock disponible: ${
                      productLiq ? productLiq.commerceStock.stock : ""
                    }`}
                    onChange={handleChangeStock}
                  />
                </div>
              </div>
              {showErrorPrice && (
                <div className="error-message">
                  <img src={iconError} width={"28px"} height={"28px"} />
                  <Text
                    label={labels.inventoryModalMsjErrorPrice}
                    variant="text-error-modal"
                  />
                </div>
              )}
              {showErrorStock && (
                <div className="error-message">
                  <img src={iconError} width={"28px"} height={"28px"} />
                  <Text
                    label={labels.inventoryModalMsjErrorStock}
                    variant="text-error-modal"
                  />
                </div>
              )}
              <div>
                <div className={"dateContainer"}>
                  <p className="Subtitle2 leftAlign greyColorInv">
                    {labels.inventoryModalDate}
                  </p>
                  <Calendar onChange={handleDate} minDate={new Date()} />
                </div>
              </div>
              {showErrorDate && (
                <div className="error-message">
                  <img src={iconError} width={"28px"} height={"28px"} />
                  <Text
                    label={labels.inventoryModalMsjErrorDate}
                    variant="text-error-modal"
                  />
                </div>
              )}
              <button
                type="submit"
                className="button-submit-liquidation"
                disabled={!liquidar}
              >
                {labels.productsDiscountTitle}
              </button>
            </form>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        show={!!modalContentType}
        handleClose={handleCloseModal}
        modalStyle={"modalsmallInv"}
      >
        {modalContentType === VARIANTS.ERROR && (
          <ErrorModal
            text={labels.inventoryErrorLiquidacion}
            buttonText={labels.continue}
          />
        )}
        {modalContentType === VARIANTS.SUCCESS && (
          <SuccessModal
            text={labels.inventorySuccesLiquidacion}
            buttonText={labels.continue}
            handleOnPress={handleCloseModal}
          />
        )}
      </ModalComponent>

      <ModalComponent
        show={!!modalContentTypeStopLiq}
        handleClose={handleCloseModalStopLiq}
        modalStyle={"modalsmallInv"}
      >
        {modalContentTypeStopLiq === VARIANTS.ERROR && (
          <LiquidateError onClick={handleCloseModalStopLiq} />
        )}
        {modalContentTypeStopLiq === VARIANTS.SUCCESS && (
          <SuccessModal
            text={labels.inventorySuccesStopLiquidacion}
            buttonText={labels.continue}
            handleOnPress={handleCloseModalStopLiq}
          />
        )}
      </ModalComponent>
      <div className="background-inventory">
        {listProducts?.products && listProducts?.products.length <= 0 ? (
          <div className="container-productsempty">
            <div className="container-leyendimage">
              <div className="box-text">
                <Text
                  label={labels.inventoryEmpty}
                  variant="text-productempty"
                />
              </div>
              <div className="box-image">
                <img src={imgProducts} alt="icons-inventory-empty" />
              </div>
            </div>
            <div className="container-leyendfooter">
              <div className="box-textfooter">
                <Text
                  label={labels.inventoryEmptyFooter}
                  variant="text-footerempty"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-body">
            <div className="container-search-name">
              <OutlinedInput
                formId={"search-name"}
                floatingLabel={labels.inventorySearch}
                placeholder={labels.inventorySearchHolder}
                charCounter
                trailingIcon="search"
                onChange={handleSearchChange}
              />
            </div>
            <div className="container-navbar">
              <NavBarCategories
                listCategories={listCategories}
                onClickItem={handleClickItemNav}
                currentCategory={currentCategory}
              />
            </div>
            {minWidth ? (
              <div className="container-inventory">
                <div className="container-table-inventory">
                  <TableInventory
                    listProducts={
                      listProductsFiltered ? listProductsFiltered?.products : []
                    }
                    listTitles={titles}
                    liquidate={onLiquidate}
                    stopLiquidating={onStopLiquidating}
                    pageSize={pageSize}
                    totalPage={
                      listProductsFiltered ? listProductsFiltered.pages : 1
                    }
                    onUpdateClick={fillData}
                    onPageChange={onChangePage}
                    onSort={onSort}
                    currentPage={page}
                  />
                </div>
              </div>
            ) : (
              <div className="container-cards-inventory" onScroll={onScroll}>
                {listProductsFiltered?.products.map((item, index) => {
                  return (
                    <CardProduct
                      isLast={
                        index === listProductsFiltered.products.length - 1
                      }
                      liquidate={onLiquidate}
                      stopLiquidating={onStopLiquidating}
                      key={item.product.id}
                      productObject={item}
                      onUpdateClick={fillData}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
