import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./payment.module.css";
import "../../components/text/Text.css";
import Text from "../../components/text/Text";
import qrSvg from "../../assets/icons/icons-qr-payment.svg";
import qrDisabled from "../../assets/icons/icons-disabled-qr.svg";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import routes from "../../router/routes";
import { createPaymentOrder } from "../../services/payment";
import { labels } from "../../labels";
import { useLoadingContext } from "../../context/spinnerContext";

export default function Payment() {
  const navigation = useNavigate();
  const loadingContext = useLoadingContext();
  const [price, setPrice] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);

  const handleChangePrice = (data: React.FormEvent<HTMLInputElement>) => {
    const re = /[0-9\b]+$/;
    if (!re.test(data.currentTarget.value)) {
      setPrice("");
      setDisabled(true);
    } else {
      const value = "$" + data.currentTarget.value.replace("$", "");
      setPrice(value);
      setDisabled(false);
    }
  };
  const navigateBack = () => {
    navigation(`${routes.home}`);
  };
  const navigateToQRPayment = async () => {
    const idLocal = user?.cLocal[0].idLocal;
    const token = currentUser && JSON.parse(currentUser).token;
    const data = {
      sTipoPedido: "TR",
      itemsVenta: [
        {
          nMontoUnitario: price.replace("$", ""),
          nCantidad: 1,
          sDescripcion: "Venta Tradicional",
          sTipo: "Venta Tradicional",
        },
      ],
    };
    loadingContext?.onOpen();
    await createPaymentOrder(idLocal, token, data)
      .then(async (resp: any) => {
        loadingContext?.onClose();
        if (resp[1] === 201) {
          navigation(`/${routes.qr}`, {
            state: { price: price.replace("$", ""), data: resp[0] },
          });
        }
      })
      .catch((error) => {
        loadingContext?.onClose();
        console.error(error);
      });
  };

  return (
    <div className={styles.containertop}>
      <div className={styles.container}>
        <div className={styles.backIconContainer} onClick={navigateBack}>
          <img src={arrowBack} className={styles.arrowStyle} />
          <Text label={labels.back} variant="md-medium titleStyle " />
        </div>
        <div className={styles.commonMargin}>
          <Text label={labels.paymentTotalAmount} variant="H6 titleStyle" />
          <input
            placeholder={"$0"}
            value={price}
            onChange={handleChangePrice}
            className={styles.inputStyle}
            id={styles.inputID}
            style={{ fontSize: 36, borderColor: "#898989", paddingLeft: 15}}
          />
          <Text label={labels.paymentMethod} variant="subtitle1 titleStyle" />
          <div
            className={
              disabled ? styles.buttonContainerDisabled : styles.buttonContainer
            }
            onClick={navigateToQRPayment}
            aria-disabled={disabled ? true : false}
          >
            {disabled ? (
              <img
                src={qrDisabled}
                className={styles.buttonTextStyleDisabled}
              />
            ) : (
              <img src={qrSvg} className={styles.buttonTextStyle} />
            )}
            <Text
              label={labels.paymentAmipass}
              variant={
                disabled
                  ? `subtitle1 ${styles.buttonTextStyleDisabled} ${styles.textMargin}`
                  : `subtitle1 ${styles.buttonTextStyle} ${styles.textMargin}`
              }
            />
            <Text
              label={labels.paymentQR}
              variant={
                disabled
                  ? `subtitle1 ${styles.buttonTextStyleDisabled} ${styles.textMargin}`
                  : `subtitle1 ${styles.buttonTextStyle} ${styles.blue}`
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
