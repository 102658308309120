import React, { useState, useEffect } from "react";
import { labels, clevertapEvents } from "../../labels";
import SvgIcon, { iconNames } from "../svgIcon/SvgIcon";
import styles from "./ProductType.module.css";
import { track } from "../../ClevertapWrapper";
import { format } from "date-fns";

interface IProductTypeProps {
  onChange: (value: boolean) => void;
  isFood: boolean;
}

export default function ProductType({ onChange, isFood }: IProductTypeProps) {
  const [typeSelected, setTypesSelected] = useState<boolean>(isFood);

  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  useEffect(() => {
    getLocation();
  }, [lat, lng]);

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  const handleNoFood = () => {
    const data = {
      geoLocationLat: lat,
      geoLocationLong: lng,
      date: format(new Date(), "yyyy/MM/dd"),
      hour: format(new Date(), "kk:mm:ss"),
      food: "No food",
    };
    track(clevertapEvents.individualNotFood, data);
    setTypesSelected(false);
    onChange(false);
  };
  const handleFood = () => {
    const data = {
      geoLocationLat: lat,
      geoLocationLong: lng,
      date: format(new Date(), "yyyy/MM/dd"),
      hour: format(new Date(), "kk:mm:ss"),
      food: "Food",
    };
    track(clevertapEvents.individualFood, data);
    setTypesSelected(true);
    onChange(true);
  };

  return (
    <div className={styles.container}>
      <div
        onClick={handleFood}
        className={`${styles.button} ${typeSelected ? styles.selected : ""}`}
      >
        <div className={`${styles.item1}`}>
          <p className="subtitle3">{labels.food}</p>
        </div>
        <div className={styles.icon}>
          {typeSelected && <SvgIcon iconName={iconNames.checkCircle} />}
        </div>
      </div>

      <div
        onClick={handleNoFood}
        className={`${styles.button} ${!typeSelected ? styles.selected : ""}`}
      >
        <div className={`${styles.item2}`}>
          <p className="subtitle3">{labels.notFood}</p>
        </div>
        <div className={styles.icon}>
          {!typeSelected && <SvgIcon iconName={iconNames.checkCircle} />}
        </div>
      </div>
    </div>
  );
}
