import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import SquareCheckBox from "../../components/checkBox/SquareCheckBox";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import routes from "../../router/routes";
import "./TermsConditionsOnlineStore.css";
import report from "../../assets/icons/icons-report.svg";
import StepIndicator from "../../components/stepIndicator/StepIndicator";
import { labels } from "../../labels";
import { getStateData, prepareForNavigation } from "./configurationsUtils";
import { getConditions, postStep4 } from "../../services/commerce";
import { useLoadingContext } from "../../context/spinnerContext";

const viewStep = 4;

export default function TermsConditionsOnlineStore() {
  const navigation = useNavigate();
  const loadingContext = useLoadingContext();
  const ref = useRef<HTMLInputElement>(null);
  const { state, data } = getStateData("acceptTerms", false);

  const acceptTerms = data?.accept;
  const currentUser = localStorage.getItem("currentUser");
  const [conditions, setConditions] = useState<string[]>([]);
  const [accept, setAccept] = useState<boolean>(acceptTerms);

  const navigateBack = () => {
    prepareForNavigation(state, {
      acceptTerms: {
        accept,
      },
    });
    navigation(`/${routes.scheduledOnlineStore}`);
  };
  const navigateToActiveOnlineStore = () => {
    navigation(`/${routes.onlineStoreActivated}`);
  };
  const getTermsAndConditions = async () => {
    const commerce = JSON.parse(currentUser ?? "{}");
    const conditionsJSON = await getConditions(
      `${commerce?.sEstablecimiento}${commerce?.cLocal?.[0]?.sLocal}`
    );
    setConditions(conditionsJSON?.text ?? []);
  };
  const sendData = async () => {
    const commerce = JSON.parse(currentUser ?? "{}");

    const data = {
      commerceInternalID: `${commerce?.sEstablecimiento ?? ""}${
        commerce?.cLocal?.[0]?.sLocal ?? ""
      }`,
      acceptAgreement: accept,
    };
    loadingContext?.onOpen();
    await postStep4(data).then((resp) => {
      loadingContext?.onClose();
      navigateToActiveOnlineStore();
    });
  };
  useEffect(() => {
    getTermsAndConditions();
  }, []);
  return (
    <div className="background-TCConfig">
      <div className="innerContainer-TCConfig">
        <div className="commonContainer-TCConfig">
          <img
            src={arrowBack}
            className="iconContainer-TCConfig "
            onClick={navigateBack}
          />
        </div>
        <StepIndicator view={viewStep} />
        <div className="optionsContainer-TCConfig innerMargin-TCCongig">
          <div>
            <img src={report} />
          </div>
          <Text label={labels.exhibitTerms} variant="H5" />
          <div className="termsOverflow">
            {conditions.map((text, index) => (
              <Text variant="body1" label={text} key={index} />
            ))}
          </div>
          <div className="centeredCheckbox-TCConfig">
            <SquareCheckBox
              checkId="reception24-7"
              labelClassName="Body3-12 "
              label={labels.acceptTerms}
              onClick={() => setAccept(!accept)}
              checked={accept}
            />
          </div>
        </div>
      </div>
      <button
        className={
          accept
            ? "buttonContinue-TCConfig "
            : "buttonContinueDisabled-TCConfig"
        }
        onClick={sendData}
      >
        <a className={"ButtonMd-14"}>{labels.activeOnlineStore}</a>
      </button>
    </div>
  );
}
