import React, { ChangeEvent } from "react";
import FileUploader from "./FileUploader";
import styles from "./filesUpload.module.css";
import { labels } from "../../labels";
interface IFilesUpload {
  handleProducFile: (e: ChangeEvent<HTMLInputElement>) => void;
  handleImageFile: (e: ChangeEvent<HTMLInputElement>) => void;
  productFile?: File;
  imageFile?: File;
  error: boolean;
  uploadError: boolean;
  errorMessage: string;
}

export default function FilesUpload({
  handleProducFile,
  handleImageFile,
  productFile,
  uploadError,
  imageFile,
  error,
  errorMessage,
}: IFilesUpload) {
  return (
    <div className={styles.container}>
      <FileUploader
        error={error || uploadError}
        errorMessage={(uploadError && labels.fileUploadError) || ""}
        percentage={productFile ? 100 : 0}
        formId="files"
        handleFile={handleProducFile}
        title={labels.excel}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <FileUploader
        errorMessage={
          errorMessage || (uploadError && labels.fileUploadError) || ""
        }
        error={uploadError}
        percentage={imageFile ? 100 : 0}
        accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
        formId="images"
        handleFile={handleImageFile}
        title={labels.zipFile}
      />
    </div>
  );
}
