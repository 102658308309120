import React, { useState } from "react";
import Text from "../../components/text/Text";
import back from "../../assets/icons/icons-arrow-back.svg";
import "./VoucherScreen.css";
import { useLocation, useNavigate } from "react-router";
import routes from "../../router/routes";
import { labels, voucher } from "../../labels";

export default function HasVoucherScreen() {
  const navigation = useNavigate();
  const state = useLocation();

  const [urlImage, setUrlImage] = useState<string>(
    state.state.place ? state.state.place : ""
  );
  const [urlImage2, setUrlImage2] = useState<string>(
    state.state.voucher ? state.state.voucher : ""
  );
  const handleNavigateBack = () => {
    navigation(`/${routes.orders}`);
  };

  return (
    <div className="background-vs">
      <div className="containerVoucher-vs">
        <div className="titleContainer-vs" onClick={handleNavigateBack}>
          <img src={back} />
          <Text
            label={voucher.uploadVoucherTitle}
            variant="H6 titleIconStyle-vs"
          />
        </div>

        <div className="innerContainer-vs">
          <div>
            <Text
              label={voucher.voucherSubtitle}
              variant="subtitle1 titleStyle-vs "
            />

            <img src={urlImage} className="uploadContainer-vs" />

            <Text
              label={labels.fileSize}
              variant="Caption-Italic titleStyle-vs"
            />
          </div>
          <div>
            <Text
              label={voucher.placeSubtitle}
              variant="subtitle1 titleStyle-vs "
            />

            <img src={urlImage2} className="uploadContainer-vs" />

            <Text
              label={labels.fileSize}
              variant="Caption-Italic titleStyle-vs"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
