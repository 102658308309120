import React, { useEffect } from "react";
import { useParams } from "react-router";

export default function AppLink() {
  const { module, section, subsection } = useParams();
  useEffect(() => {
    const deeplink = `app/${module}/${section}/${subsection}`;
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (isChrome) {
      const chromeURL = `${window.location.host}/${deeplink}`;
      window.location.href = `intent://${chromeURL}#Intent;scheme=https;package=com.amipassPay.app;end`;
    } else {
      const appLink = `amipos.page.link://${deeplink}`;
      window.location.href = appLink;
    }
  }, []);
  return <div></div>;
}
