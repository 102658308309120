import React from "react";
import "./SwitchComponent.css";

interface ISwitchProps {
  onClick: () => void;
  isSelected?: boolean;
  id: string;
}
const SwitchComponent = ({ onClick, isSelected, id }: ISwitchProps) => {
  return (
    <>
      <input
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        onChange={onClick}
        checked={isSelected}
      />
      <label className="react-switch-label" htmlFor={id}>
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default SwitchComponent;
