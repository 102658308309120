import React from "react";
import alert from "../../../assets/icons/icons-alert.svg";
import Text from "../../text/Text";
import "./AlertModal.css";

export interface IAlertModalProps {
  text: string;
  buttonText: string;
  subtitle?: string;
  closeModal?: () => void;
}

export default function AlertModal({
  text,
  buttonText,
  subtitle,
  closeModal,
}: IAlertModalProps) {
  return (
    <div>
      <div className={"centeredIcon"}>
        <img src={alert} style={{ width: 118, height: 118 }} />
      </div>
      <div className={"title"}>
        <Text label={text} variant="Body1-16 bold" />
      </div>
      {subtitle && <Text label={subtitle} variant="Body2-14 title" />}
      <div className={"centered"}>
        <button className={"buttonHelp"} onClick={closeModal}>
          <a className={"ButtonMd-14"}>{buttonText}</a>
        </button>
      </div>
    </div>
  );
}
