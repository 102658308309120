import { labels } from "../../labels";
export interface StepConfiguration {
  label: string;
  value: number;
}
export const stepContent: StepConfiguration[] = [
  { label: labels.onlineStorInformation, value: 1 },
  { label: labels.deliveryConfiguration, value: 2 },
  { label: labels.scheduledPurtchases, value: 3 },
  { label: labels.termsAndConditions, value: 4 },
];
export interface ConfigState {
  logo: string;
  base64Img: ArrayBuffer | string | null;
  storeDescription: string;
  sEmailContacto: string;
  minAmmount: string;
  receptionDesde: string;
  receptionHasta: string;
  lunReceptionHour: boolean;
  marReceptionHour: boolean;
  mierReceptionHour: boolean;
  jueReceptionHour: boolean;
  vieReceptionHour: boolean;
  sabReceptionHour: boolean;
  domReceptionHour: boolean;
  allDayDel: boolean;
}
export const stateKey = "configState";
export const getStateData = (
  accessor: string,
  defaultValue: boolean | object
) => {
  const state = JSON.parse(localStorage.getItem(stateKey) ?? "{}");
  const data = state[accessor] ?? defaultValue;
  return { state, data };
};
export const prepareForNavigation = (state: any, updateState: any) => {
  const actualState = state || {};

  const newState = {
    ...actualState,
    ...updateState,
  };

  localStorage.setItem(stateKey, JSON.stringify(newState));
};
