
// export const app = initializeApp({
//   apiKey: "AIzaSyAWCr0PwL1IqPqsy-yD6tlJVoXsYjfnmVg",
//   authDomain: "amipasspay.firebaseapp.com",
//   databaseURL: "https://amipasspay.firebaseio.com",
//   projectId: "amipasspay",
//   storageBucket: "amipasspay.appspot.com",
//   messagingSenderId: "101828101532",
//   appId: "1:101828101532:web:360bf2143c240823693459"
// });

// export const messaging = getMessaging(app);

// getToken(messaging, {vapidKey: "BE5pbBKo98PIS088N-ULOYDUNRh63R0pivNgi8e0o6ka1eVlWtzDk3a7mEW5IN8vWFLiDxdJrTA8CZr5nyh3G2U"}).then((currentToken) => {
//   if (currentToken) {
//     console.log("currentToken",currentToken)
//   } else {
//     console.log('No registration token available. Request permission to generate one.');
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
// });

export const onMessageListener = () =>
  new Promise((resolve) => {
    // onMessage(messaging, (payload) => {
    //   resolve(payload);
    // });
  });
