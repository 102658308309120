import React from "react";
import styles from "./SquareCheckBox.module.css";
import Text from "../text/Text";

interface ICheckBoxProps {
  checkId: string;
  label: string;
  subtitle?: string;
  onClick: () => void;
  checked: boolean;
  labelClassName?: string;
}

export default function SquareCheckBox({
  checkId,
  label,
  subtitle,
  checked,
  onClick,
}: ICheckBoxProps) {
  return (
    <div className={styles.containerSq}>
      <div className={styles.backgroundCheckboxSq}>
        <input
          name="cssCheckboxSq"
          id={checkId}
          type="checkbox"
          className={checked ? styles.cssCheckboxSq : styles.noCheckedSq}
          onChange={onClick}
          checked={checked}
        />
      </div>
      <div className={subtitle ? styles.hasSubtitleSq : styles.noSubtitleSq}>
        <Text label={label} variant={`Subtitle2 ${styles.textStyleSq}`} />
        {subtitle && (
          <Text
            label={subtitle}
            variant={`Small-2 ${styles.subtitleTextStyleSq}`}
          />
        )}
      </div>
    </div>
  );
}
