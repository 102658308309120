import * as React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "../../hooks/usePagination";
import "./Pagination.css";
import { track } from "../../ClevertapWrapper";
import { clevertapEvents } from "../../labels";
import { format } from "date-fns";

interface IPagination {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className: string;
}

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: IPagination) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const [lat, setLat] = React.useState<number>(0);
  const [lng, setLng] = React.useState<number>(0);

  React.useEffect(() => {
    getLocation();
  }, [lat, lng]);
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    const page = currentPage + 1;
    const data = {
      geoLocationLat: lat,
      geoLocationLong: lng,
      date: format(new Date(), "yyyy/MM/dd"),
      hour: format(new Date(), "kk:mm:ss"),
      page: page,
    };
    if (currentPage < totalCount) {
      track(clevertapEvents.inventoryPaginationNext, data);
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    const page = currentPage - 1;
    const data = {
      geoLocationLat: lat,
      geoLocationLong: lng,
      date: format(new Date(), "yyyy/MM/dd"),
      hour: format(new Date(), "kk:mm:ss"),
      page: page,
    };
    if (currentPage > 1)
      track(clevertapEvents.inventoryPaginationPrevious, data);
    onPageChange(currentPage - 1);
  };

  const handleOnPageChange=(pageNumber:number)=>{
    const data = {
      geoLocationLat: lat,
      geoLocationLong: lng,
      date: format(new Date(), "yyyy/MM/dd"),
      hour: format(new Date(), "kk:mm:ss"),
      page: pageNumber,
    };
    track(clevertapEvents.inventoryPaginationNumber, data)
    onPageChange(pageNumber)
  }
  const lastPage = paginationRange
    ? paginationRange[paginationRange.length - 1]
    : 0;

  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange &&
        paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li key={`li-dots-`} className="pagination-item dots">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={pageNumber}
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => handleOnPageChange(+pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
