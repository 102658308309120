import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useRef,
    useContext,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Text from '../../components/text/Text'
import { getCommerceInfo, getAllOrdersByStatus } from '../../services/commerce'
import home from '../../assets/icons/icons-home.svg'
import myproducts from '../..//assets/icons/icons-my-products.svg'
import dish from '../../assets/icons/icons-dish.svg'
import discount from '../../assets/icons/icons-discount.svg'
import bike from '../../assets/icons/icons-bike.svg'
import logout from '../../assets/icons/icons-logout.svg'
import arrowDown from '../../assets/icons/icons-arrow-down.svg'
import badge from '../../assets/icons/icons-badge.svg'
import badgecount from '../../assets/icons/icons-badge-count.svg'
import arrowUp from '../../assets/icons/icons-arrow-up.svg'
import config from '../../assets/icons/icons-config.svg'
import sale from '../../assets/icons/icons-currency.svg'
import '../../components/text/Text.css'
import '../drawer/Drawer.css'
import { labels, clevertapEvents } from '../../labels'
import routes from '../../router/routes'
import { track } from '../../ClevertapWrapper'
import sound from '../../assets/sounds/soundamipos.wav'
import { FeatureContext } from '../../context/useFeatureFlagContext'
import report from '../../assets/icons/icon-reports.svg'

export default function Drawer() {
    const navigate = useNavigate()
    const location = useLocation()
    const { features } = useContext(FeatureContext)

    const currentUser = localStorage.getItem('currentUser')
    const commerceCode = localStorage.getItem('commerceCode')
    const user = currentUser && JSON.parse(currentUser)
    const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal
    const [logo, setLogo] = useState<string>('')
    const [minWidth, setMinWidth] = useState<boolean>(
        window.matchMedia('(min-width: 833px)').matches
    )
    const [showDrawer, setSohwDrawer] = useState<boolean>(false)
    const [count, setCount] = useState<number>(0)
    const [selectOpen, setSelectOpen] = useState<boolean>(false)
    const [lat, setLat] = useState<number>(0)
    const [lng, setLng] = useState<number>(0)

    const audioRef = useRef(new Audio(sound))
    const play = () => {
        audioRef.current.play()
    }
    const openSelect = () => {
        setSelectOpen(!selectOpen)
    }
    const handleSelector = () => {
        setSelectOpen(false)
    }
    const getECommerce = async () => {
        try {
            await getCommerceInfo(commerceId).then((response) => {
                const data = response?.logo || ''
                setLogo(data)
            })
        } catch (err) {
            console.log('Error al cargar datos del comercio.')
        }
    }

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude)
            setLng(position.coords.longitude)
        })
    }

    const badgeCount = async () => {
        try {
            await getAllOrdersByStatus(commerceId, 1, 200, 1).then((res) => {
                if (res !== null) {
                    setCount(res.length)
                }
            })
        } catch (error) {
            setCount(0)
        }
    }
    useEffect(() => {
        if (count >= 1) {
            const playSound = () => {
                play()
            }
            playSound()
            const interval = setInterval(playSound, 90000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [count])
    const navigateToHome = () => {
        track(clevertapEvents.drawerHome)
        handleSelector()
        navigate(`${routes.home}`)
    }
    const navigateToSales = () => {
        handleSelector()
        navigate(`${routes.sales}`)
    }
    const navigateToMyProducts = () => {
        track(clevertapEvents.drawerMyProducts)
        handleSelector()
        navigate(`/${routes.inventory}`)
    }
    const navigateToUploadProducts = () => {
        track(clevertapEvents.drawerUploadProducts)
        navigate(`/${routes.products}`)
        setSelectOpen(!selectOpen)
    }
    const navigateToLiquidation = () => {
        track(clevertapEvents.drawerLiquidate)
        handleSelector()
        navigate(`/${routes.products}/${routes.liquidate}`)
    }
    const navigateToOrders = () => {
        track(clevertapEvents.drawerOrders)
        handleSelector()
        navigate(`/${routes.orders}`)
    }
    const handleLogOut = () => {
        track(clevertapEvents.drawerLogout)
        localStorage.clear()
        setTimeout(() => {
            window.location.href = '/'
        }, 300)
    }
    const navigateToIndividual = () => {
        handleSelector()
        track(clevertapEvents.drawerIndividualUpload)
        navigate(`/${routes.products}/${routes.individualUpload}`)
    }
    const navigateToMassive = () => {
        handleSelector()
        track(clevertapEvents.drawerMassiveUpload)
        navigate(`/${routes.products}/${routes.massiveUpload}`)
    }
    const navigateToConfig = () => {
        navigate(`/${routes.configurations}`)
    }
    const handleOpenDrawer = () => {
        setSohwDrawer(!showDrawer)
    }

    const navigateToReport = () => {
        track(clevertapEvents.drawerReport)
        handleSelector()
        navigate(`${routes.urlDashboard}`)
    }

    useEffect(() => {
        if (commerceId) {
            getECommerce()
        }
    }, [])

    useEffect(() => {
        if (commerceId) {
            const refreshNew = setInterval(() => {
                badgeCount()
            }, 3000)
            return () => {
                clearInterval(refreshNew)
            }
        }
    }, [count])

    useEffect(() => {
        getLocation()
    }, [lat, lng])
    useLayoutEffect(() => {
        window
            .matchMedia('(min-width: 833px)')
            .addEventListener('change', (e) => setMinWidth(e.matches))
    })
    return (
        <>
            <div className={'audioEl'}>
                <audio src={sound} hidden ref={audioRef} />
            </div>
            <div
                className={
                    !minWidth && showDrawer ? 'backdrop' : 'backdrop-close'
                }
                onClick={handleOpenDrawer}
            >
                {!minWidth && !showDrawer && (
                    <div className="mobile-header">
                        <div
                            className="mobile-inner-header"
                            onClick={handleOpenDrawer}
                        >
                            {logo !== '' ? (
                                <img src={logo} className={'logo-img-header'} />
                            ) : (
                                <div className="Body3-12 header-img-container">
                                    50x50
                                </div>
                            )}
                            <Text
                                label={user?.cLocal[0]?.sNombreLocal}
                                variant="Body2-14  mobile-header-title"
                            />
                        </div>

                        <div
                            className="badge-icon-container"
                            onClick={navigateToOrders}
                        >
                            {count === 0 ? (
                                <img src={badge} className="icon-badge" />
                            ) : (
                                <div className={'badge-count-container'}>
                                    <img
                                        src={badgecount}
                                        className="icon-badge"
                                    />
                                    <Text
                                        label={count.toString()}
                                        variant="Badge-small text-badge"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className={showDrawer ? 'drawer' : 'drawer-closed'}>
                    <div className="header-dw">
                        <div className="logo-padding">
                            {logo !== '' ? (
                                <img src={logo} className={'logo-img'} />
                            ) : (
                                <div className="commerce-img">100x100</div>
                            )}
                        </div>
                        <Text
                            label={user?.cLocal[0]?.sNombreLocal}
                            variant="subtitle1 titleStyle-dw"
                        />
                        <Text
                            label={`${labels.drawerSubtitle} ${
                                commerceCode ?? ''
                            }`}
                            variant="Body2-14 titleStyle-dw"
                        />
                    </div>

                    <div
                        className={
                            location.pathname === `${routes.home}`
                                ? 'button-container-selected-dw'
                                : 'button-container-dw'
                        }
                        onClick={navigateToHome}
                    >
                        <img src={home} className="left-icon-dw" />
                        <Text
                            label={labels.drawerHome}
                            variant="Body2-14 buttonText-dw"
                        />
                    </div>

                    {features.reports && (
                        <>
                            <div className="line-dw" />
                            <div
                                className={
                                    location.pathname === `${routes.sales}`
                                        ? 'button-container-selected-dw'
                                        : 'button-container-dw'
                                }
                                onClick={navigateToReport}
                            >
                                <img src={report} className="left-icon-dw" />
                                <Text
                                    label={labels.drawerReport}
                                    variant="Body2-14 buttonText-dw"
                                />
                            </div>
                        </>
                    )}

                    {features.bottomBar.sales && (
                        <>
                            <div className="line-dw" />
                            <div
                                className={
                                    location.pathname === `${routes.sales}`
                                        ? 'button-container-selected-dw'
                                        : 'button-container-dw'
                                }
                                onClick={navigateToSales}
                            >
                                <img src={sale} className="left-icon-dw" />
                                <Text
                                    label={labels.drawerSales}
                                    variant="Body2-14 buttonText-dw"
                                />
                            </div>
                        </>
                    )}
                    {features.amimarket && (
                        <>
                            {features.bottomBar.inventory && (
                                <>
                                    <div className="line-dw" />
                                    <div
                                        className={
                                            location.pathname ===
                                            `/${routes.inventory}`
                                                ? 'button-container-selected-dw'
                                                : 'button-container-dw'
                                        }
                                        onClick={navigateToMyProducts}
                                    >
                                        <img
                                            src={myproducts}
                                            className="left-icon-dw"
                                        />
                                        <Text
                                            label={labels.drawerProducts}
                                            variant="Body2-14 buttonText-dw"
                                        />
                                    </div>
                                </>
                            )}
                            <div className="line-dw" />
                            <div
                                className={'button-container-dw'}
                                onClick={navigateToUploadProducts}
                            >
                                <img src={dish} className="left-icon-dw" />
                                <Text
                                    label={labels.drawerUploadProducts}
                                    variant="Body2-14 buttonText-dw"
                                />
                                {selectOpen && (
                                    <img
                                        src={arrowUp}
                                        className="arrow-icon-dw"
                                        onClick={openSelect}
                                    />
                                )}
                                {!selectOpen && (
                                    <img
                                        src={arrowDown}
                                        className="arrow-icon-dw"
                                        onClick={openSelect}
                                    />
                                )}
                            </div>
                            <div className="line-dw" />
                            {selectOpen && (
                                <>
                                    <div className="line-dropdown-dw" />
                                    <div
                                        className="button-dropdown-dw"
                                        onClick={navigateToIndividual}
                                    >
                                        <Text
                                            label={
                                                labels.productsIndividualUploadTitle
                                            }
                                            variant="Body2-14 buttonText-drawer-dropdown-dw"
                                        />
                                    </div>
                                    <div className="line-dropdown-dw" />
                                    <div
                                        className="button-dropdown-dw"
                                        onClick={navigateToMassive}
                                    >
                                        <Text
                                            label={
                                                labels.productsMassiveCardTitle
                                            }
                                            variant="Body2-14 buttonText-drawer-dropdown-dw"
                                        />
                                    </div>
                                </>
                            )}
                            <div
                                className={
                                    location.pathname ===
                                    `/${routes.products}/${routes.liquidate}`
                                        ? 'button-container-selected-dw'
                                        : 'button-container-dw'
                                }
                                onClick={navigateToLiquidation}
                            >
                                <img src={discount} className="left-icon-dw" />
                                <Text
                                    label={labels.drawerLiquidateProducts}
                                    variant="Body2-14 buttonText-dw"
                                />
                            </div>
                        </>
                    )}
                    {features.amimarket && features.bottomBar.orders && (
                        <>
                            <div className="line-dw" />
                            <div
                                className={
                                    location.pathname === `/${routes.orders}`
                                        ? 'button-container-selected-dw'
                                        : 'button-container-dw'
                                }
                                onClick={navigateToOrders}
                            >
                                <img src={bike} className="left-icon-dw" />
                                <Text
                                    label={labels.drawerMyOrders}
                                    variant="Body2-14 buttonText-dw"
                                />
                                <div className="badge-circle-container">
                                    {count !== 0 && (
                                        <div
                                            className={
                                                'badge-circle-count-container'
                                            }
                                        >
                                            <Text
                                                label={count.toString()}
                                                variant="subtitle1"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="line-dw" />
                    <div
                        className={
                            location.pathname === `/${routes.inventory}`
                                ? 'button-container-selected-dw'
                                : 'button-container-dw'
                        }
                        onClick={navigateToConfig}
                    >
                        <img src={config} className="left-icon-dw" />
                        <Text
                            label={labels.drawerConfig}
                            variant="Body2-14 buttonText-dw"
                        />
                    </div>
                    <div className="line-dw" />
                    <div className="button-container-dw" onClick={handleLogOut}>
                        <img src={logout} className="left-icon-dw" />
                        <Text
                            label={labels.drawerLogOut}
                            variant="Body2-14 buttonText-dw"
                        />
                    </div>
                    <div className="line-dw" />
                </div>
            </div>
        </>
    )
}
