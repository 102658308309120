import React from "react";
import SvgIcon, { iconNames } from "../../svgIcon/SvgIcon";
import styles from "./massiveResponseOk.module.css";
import { labels } from "./../../../labels";

interface IMassiveResponseOkProps {
  onClose: () => void;
  isError: boolean;
}

export default function MassiveResponseOk({
  onClose,
  isError,
}: IMassiveResponseOkProps) {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <SvgIcon iconName={isError ? iconNames.error : iconNames.responseOK} />
      </div>
      <p className="Body1-16">
        {isError ? labels.responseError : labels.responseOk}
      </p>
      <div className={styles.buttons}>
        <button
          onClick={onClose}
          className={isError ? styles.buttonError : styles.buttonContinue}
        >
          {labels.back}
        </button>
      </div>
    </div>
  );
}
