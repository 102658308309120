import React, { useState, useEffect, useContext } from 'react'
import Text from '../../components/text/Text'
import ecoFriend from '../../assets/icons/icons-eco-friendly.svg'
import chevronRight from '../../assets/icons/icons-arrow-right.svg'
import store from '../../assets/icons/icon-store.svg'
import './Configurations.css'
import routes from '../../router/routes'
import { useNavigate } from 'react-router'
import { ecofriendly, labels } from '../../labels'
import { FeatureContext } from '../../context/useFeatureFlagContext'
import { useLoadingContext } from '../../context/spinnerContext'
import { getCommerceInfo } from '../../services/commerce'

export default function Configurations() {
    const { features } = useContext(FeatureContext)
    const loadingContext = useLoadingContext()
    const currentUser = localStorage.getItem('currentUser')
    const user = currentUser && JSON.parse(currentUser)
    const commerceId = user?.sEstablecimiento + user?.cLocal[0]?.sLocal
    const [hasEco, setHasEco] = useState<boolean>(false)

    const navigate = useNavigate()
    const handleNavigationConfig = () => {
        navigate(`/${routes.storeConfigurations}`)
    }
    const getECommerce = async () => {
        loadingContext?.onOpen()
        try {
            await getCommerceInfo(commerceId).then((resp) => {
                loadingContext?.onClose()
                setHasEco(resp.eco)
            })
        } catch (err) {
            loadingContext?.onClose()
            console.log('Error al cargar datos del comercio.')
        }
    }
    const handleNavigation = () => {
        if (hasEco) {
            navigate(`/${routes.ecofriendCommerce}`)
        } else {
            navigate(`/${routes.ecofriendSurvey}`)
        }
    }
    useEffect(() => {
        if (commerceId) {
            getECommerce()
        }
    }, [commerceId])

    return (
        <div className="container-config">
            <Text label="Configuraciones" variant="H6" />
            <div className="buttonsContainer-config">
                <div
                    className="configContainer"
                    onClick={handleNavigationConfig}
                >
                    <div>
                        <div className="configTitleIconContainer">
                            <img src={store} className="" />
                            <Text
                                variant="Subtitle2 configTextStyle"
                                label={labels.configurationScreenTitle}
                            />
                        </div>
                        <div className="configTitleIconContainer">
                            <Text
                                label={labels.configurationScreenDescription}
                                variant="Body3-12 subtitleTextStyle"
                            />
                        </div>
                    </div>
                    <img src={chevronRight} className="iconStyleConfig" />
                </div>
                {features.drawer.config.eco_friendly && (
                    <div className="configContainer" onClick={handleNavigation}>
                        <div>
                            <div className="configTitleIconContainer">
                                <img src={ecoFriend} className="" />
                                <Text
                                    variant="Subtitle2 configTextStyle"
                                    label={ecofriendly.ecoFriendTitle}
                                />
                            </div>
                            <div className="configTitleIconContainer">
                                <Text
                                    label={ecofriendly.ecoFriendButtonText}
                                    variant="Body3-12 subtitleTextStyle"
                                />
                            </div>
                        </div>
                        <img src={chevronRight} className="iconStyleConfig" />
                    </div>
                )}
            </div>
        </div>
    )
}
