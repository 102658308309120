import React from "react";

import { useNavigate } from "react-router-dom";

import Text from "../../components/text/Text";
import arrowBack from "../../assets/icons/icons-arrow-back.svg";
import ecoFriendly from "../../assets/icons/icon-ecoFriendly-check.svg";
import { labels } from "../../labels";
import routes from "../../router/routes";
import "./Scheduled.css";
import ModalComponent, {
  VARIANTS,
  VariantsType,
} from "../../components/modal/ModalComponent";

export default function Scheduled() {
  const navigate = useNavigate();

  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);

  const handleCloseModal = () => {
    setModalContentType(null);
  };
  const handleGoBack = () => {
    navigate(`/${routes.storeConfigurations}`);
  };
  const handleAccept = () => {
    navigate(`/${routes.storeConfigurations}`, {
      state: { commerceScheduled: true },
    });
  };

  return (
    <div className="background-sch">
      <div className="commonContainer-sch">
        <img
          src={arrowBack}
          className="iconContainer-sch"
          onClick={handleGoBack}
        />
        <Text label={labels.scheduledBack} variant="H5" />
      </div>
      <div className="container-sch">
        <img src={ecoFriendly} className="iconEcoFriendly-sch" />
        <Text
          label={labels.scheduledDescription1}
          variant="Body1-16 neutralGray900-sch commonWeight"
        />
        <Text
          label={labels.scheduledDescription2}
          variant="Body1-16 neutralGray900-sch commonWeight"
        />
        <Text
          label={labels.scheduledDescription3}
          variant="Body1-16 neutralGray900-sch commonWeight"
        />
        <Text
          label={labels.scheduledDescription4}
          variant="Body1-16 italicBold-sch neutralGray900-sch "
        />
        <Text
          label={labels.scheduledDescription5}
          variant="Body1-16 neutralGray900-sch commonWeight"
        />
        <div
          className="greenButtonContainer-sch"
          onClick={() => setModalContentType(VARIANTS.SUCCESS)}
        >
          <div className="greenButton-sch">
            <Text label={labels.scheduledAccept} variant="ButtonLg-16" />
          </div>
        </div>
      </div>
      <ModalComponent
        show={!!modalContentType}
        handleClose={handleCloseModal}
        icon={false}
        modalStyle="modalStyle-sch"
      >
        <div className="modalContentStyle-sch">
          <img src={ecoFriendly} className="" />
          <Text
            label={labels.scheduledCongrats}
            variant="ButtonLg-16  bold greyFontColor-sch"
          />
          <Text
            label={labels.scheduledCongratsDescription}
            variant="Body1-16 greyFontColor-sch"
          />
          <button className="greenModalButton-sch " onClick={handleAccept}>
            <Text label={labels.continue} variant="Subtitle2" />
          </button>
        </div>
      </ModalComponent>
    </div>
  );
}
