import { BASEAPI } from './constants'

const loginUser = async (user: string, password: string) => {
    return fetch(`${BASEAPI}auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'user=' + user + '&password=' + password,
    })
}

const postRestaurarClave = async (user: string, email: string) => {
    let res = null

    await fetch(`${BASEAPI}auth/restaurar-clave`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'user=' + user + '&email=' + email,
    })
        .then((response) => {
            res = response.status
        })
        .catch((error) => {
            console.error(error)
        })

    return res
}

const getUrlDashboard = async (commerce_internal_id: string) => {
    const res = await fetch(
        `${BASEAPI}apimac/api/v1/mac/urldashboard/${commerce_internal_id}/response`,
        {
            method: 'GET',
        }
    )
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.error(error)
            return null
        })
    return res
}

export { loginUser, postRestaurarClave, getUrlDashboard }
