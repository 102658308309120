import { BASEAPI } from "./constants";

const getOrderDetail = async (id: string) => {
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/order/detail/${id}/ingredient`,
    {
      method: "GET",
    }
  )
    .then(async (response) => {
      return await response.json();
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};

const getAllOrders = async (commerce_id: string) => {
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/order/orders/${commerce_id}`,
    {
      method: "GET",
    }
  )
    .then(async (response) => {
      return await response.json();
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};

const getProductDetail = async (product_id: string, commerce_id: string) => {
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/${product_id}/${commerce_id}`,
    {
      method: "GET",
    }
  )
    .then(async (response) => {
      return await response.json();
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};

const getAllOrdersByStatus = async (
  commerce_id: string,
  status: number,
  chunk: number,
  page: number,
  dateFrom?: string,
  dateTo?: string
) => {
  const from = dateFrom ? `&dateFrom=${dateFrom}` : "";
  const to = dateTo ? `&dateTo=${dateTo}` : "";
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/order/orders/${commerce_id}/${status}?chunk=${chunk}&page=${page}${from}${to}`,
    {
      method: "GET",
    }
  )
    .then(async (response) => {
      return await response.json();
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};

const filterOrdersByStatusDate = async (
  commerce_id: string,
  status_id: string,
  dateFrom: string,
  dateTo: string
) => {
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/order/orders/${commerce_id}/${status_id}?chunk=100&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    {
      method: "GET",
    }
  )
    .then(async (response) => {
      return await response.json();
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};

const modifyOrderStatus = async (id: string, data: any) => {
  const res = await fetch(`${BASEAPI}apimac/api/v1/mac/order/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      return response.status;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};

function showFile(data: string) {
  const bufferFile = Buffer.from(data, "base64");
  const blob = new Blob([bufferFile], {
    type: "application/pdf",
  });
  const url = URL.createObjectURL(blob);
  window.open(url)?.print();
}

const getOrderComanda = async (orderId: number) => {
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/order/comanda/${orderId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const json = JSON.parse(await response.text());
      return json.url;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};
const postVoucher = async (data: any, orderID: string) => {
  const res = fetch(`${BASEAPI}APIUploadImages/order/voucher/${orderID}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      const newData = JSON.parse(await response.text());
      return [newData, response.status];
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};
const postPlace = async (data: any, orderID: string) => {
  const res = fetch(`${BASEAPI}APIUploadImages/order/place/${orderID}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      const newData = JSON.parse(await response.text());
      return [newData, response.status];
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
  return res;
};
const postBackUp = async (data: any, orderID: string) => {
  return fetch(`${BASEAPI}apimac/api/v1/mac/order/backup/${orderID}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export {
  getOrderDetail,
  getAllOrders,
  getProductDetail,
  getAllOrdersByStatus,
  filterOrdersByStatusDate,
  modifyOrderStatus,
  getOrderComanda,
  postVoucher,
  postPlace,
  postBackUp,
};
