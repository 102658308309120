import React, { useContext, useEffect } from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import Home from '../views/home/Home'
import Inventory from '../views/inventory/Inventory'
import Login from '../views/login/Login'
import Orders from '../views/Orders/Orders'
import PasswordRecovery from '../views/passwordRecovery/PasswordRecovery'
import Liquidate from '../views/liquidate/Liquidate'
import routes from './routes'
import ProtectedRoute from './ProtectedRoute'
import Layout from '../views/layout/Layout'
import { FeatureContext } from '../context/useFeatureFlagContext'
import MassiveUpload from '../views/product/masssiveUpload/MassiveUpload'
import IndividualUpload from '../views/product/individualUpload/IndividualUpload'
import Payment from '../views/payment/payment'
import QrPayment from '../views/payment/qrPayment'
import SuccessPayment from '../views/payment/successfulPayment'
import VoucherScreen from '../views/Orders/VoucherScreen'
import HasVoucherScreen from '../views/Orders/HasVoucherScreen'
import Configurations from '../views/configurations/Configurations'
import EcoFriendSurvey from '../views/configurations/EcoFriendSurvey'
import EcoFriendCommerce from '../views/configurations/EcoFriendCommerce'
import EditProduct from '../components/modal/modalVariants/EditProduct'
import SalesOfMonth from '../views/sales/SalesOfMonth'
import SalesDetails from '../views/salesDetails/SalesDetails'
import TokensProvider from '../context/tokensContext'
import StoreConfigurationScreen from '../views/configurations/StoreConfigurationScreen'
import Scheduled from '../views/configurations/Scheduled'
import AppLink from '../views/applinks/AppLink'
import OnlineStoreInformationScreen from '../views/configurations/OnlineStoreInformationScreen'
import OptionOnlineStoreScreen from '../views/configurations/OptionOnlineStoreScreen'
import OnlineStoreScheduledPurchaseScreen from '../views/configurations/OnlineStoreScheduledPurchaseScreen'
import TermsConditionsOnlineStore from '../views/configurations/TermsConditionsOnlineStore'
import OnlineStoreActivatedScreen from '../views/configurations/OnlineStoreActivatedScreen'
import UrlDashboardScreen from '../views/urldashboard/UrlDashboardScreen'

import Products from '../views/product/Products'
import { getFeatureFlags } from '../services/commerce'

export default function Router() {
    const { features, updateFeature } = useContext(FeatureContext)

    useEffect(() => {
        getFeatureFlags().then((res) => {
            updateFeature(res)
        })
    }, [])

    return (
        <Routes>
            <Route path={routes.login} element={<Login />} />
            <Route
                path={routes.passwordRecovery}
                element={<PasswordRecovery />}
            />
            <Route path={routes.appLinks} element={<AppLink />} />
            <Route
                path={routes.home}
                element={
                    <ProtectedRoute>
                        <Layout>
                            <Outlet />
                        </Layout>
                    </ProtectedRoute>
                }
            >
                <Route index element={<Home />} />
                {features.amimarket && (
                    <>
                        <Route path={routes.products} element={<Outlet />}>
                            <Route index element={<Products />} />
                            <Route
                                path={routes.massiveUpload}
                                element={<MassiveUpload />}
                            />
                            <Route
                                path={routes.individualUpload}
                                element={<IndividualUpload />}
                            />
                            <Route
                                path={routes.liquidate}
                                element={<Liquidate />}
                            />
                        </Route>
                        <Route path={routes.orders} element={<Orders />} />
                    </>
                )}
                <Route path={routes.payment} element={<Payment />} />
                <Route path={routes.qr} element={<QrPayment />} />
                <Route
                    path={routes.successPayment}
                    element={<SuccessPayment />}
                />
                <Route path={routes.sales} element={<Outlet />}>
                    <Route
                        index
                        element={
                            <TokensProvider>
                                <SalesOfMonth />
                            </TokensProvider>
                        }
                    />
                    <Route
                        path={routes.salesDetails}
                        element={
                            <TokensProvider>
                                <SalesDetails />
                            </TokensProvider>
                        }
                    />
                </Route>
                <Route path={routes.inventory} element={<Outlet />}>
                    <Route index element={<Inventory />} />
                    <Route path={routes.edit} element={<EditProduct />} />
                </Route>
                <Route
                    path={routes.voucherScreen}
                    element={<VoucherScreen />}
                />
                <Route
                    path={routes.hasVoucherScreen}
                    element={<HasVoucherScreen />}
                />
                <Route
                    path={routes.configurations}
                    element={<Configurations />}
                />
                <Route
                    path={routes.ecofriendSurvey}
                    element={<EcoFriendSurvey />}
                />
                <Route
                    path={routes.ecofriendCommerce}
                    element={<EcoFriendCommerce />}
                />
                <Route
                    path={routes.storeConfigurations}
                    element={<StoreConfigurationScreen />}
                />
                <Route path={routes.scheduled} element={<Scheduled />} />
                <Route
                    path={routes.onlineStoreInformationScreen}
                    element={<OnlineStoreInformationScreen />}
                />
                <Route
                    path={routes.optionOnlineStore}
                    element={<OptionOnlineStoreScreen />}
                />
                <Route
                    path={routes.scheduledOnlineStore}
                    element={<OnlineStoreScheduledPurchaseScreen />}
                />
                <Route
                    path={routes.termsAndConditions}
                    element={<TermsConditionsOnlineStore />}
                />
                <Route
                    path={routes.onlineStoreActivated}
                    element={<OnlineStoreActivatedScreen />}
                />
                {features.reports && (
                    <Route
                        path={routes.urlDashboard}
                        element={<UrlDashboardScreen />}
                    />
                )}
            </Route>
        </Routes>
    )
}
