import React from "react";
import { useNavigate } from "react-router-dom";
import { labels } from "../../../labels";
import SvgIcon from "../../svgIcon/SvgIcon";
import Text from "../../text/Text";
import styles from "./LiquidateCreateResponseOK.module.css";
interface ILiquidateCreateResponseOKProps {
  text: string;
  buttonText: string;
  onClose: () => void;
}
export default function LiquidateCreateResponseOK({
  onClose,
  buttonText,
  text,
}: ILiquidateCreateResponseOKProps) {
  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
    location.reload();
  };
  const goToInventory = () => {
    navigate("/inventory");
  };

  return (
    <div className={styles.successContainer}>
      <div className={styles.IconContainer} onClick={onClose}>
        <SvgIcon iconName="responseOK" />
      </div>
      <Text label={text} variant="Body1-16" />
      <div className={styles.buttonContainer}>
        <button
          className={`ButtonSm-13 ${styles.successButton} ${styles.buttonItem}`}
          onClick={handleClose}
          type="button"
        >
          {buttonText}
        </button>
        <button
          className={`ButtonSm-13 successBC ${styles.buttonItem}`}
          onClick={goToInventory}
          type="button"
        >
          {labels.goToProducts}
        </button>
      </div>
    </div>
  );
}
