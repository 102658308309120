import { useState } from "react";

export default function useDisclosure(initialState?: boolean) {
  const [open, setOpen] = useState<boolean>(initialState || false);
  const handleToogle = () => {
    setOpen((prevState) => !prevState);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return { open, handleToogle, handleOpen, handleClose };
}
