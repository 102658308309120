import React, { useEffect } from "react";
import OrderComponent from "../../../components/orderComponent/OrderComponent";
import useLazyLoader, { PAGE_CHUNK } from "../../../hooks/useLazyLoader";
import { getAllOrdersByStatus } from "../../../services/orders";
import deliverIcon from "../../../assets/icons/icons-orders.svg";
import ScrollComponent from "../../../components/ScrollComponent";

import NoOrders from "../NoOrders";
import { labels } from "../../../labels";
const toDeliverId = "toDeliverId";

export default function ToDeliver() {
  const {
    data: toDeliverData,
    lastItemRef: toDeliverLastItemRef,
    onScroll: toDeliverOnScroll,
    refreshWithChunk,
    page,
  } = useLazyLoader(getAllOrdersByStatus, toDeliverId, 3);
  const handleRefreshDeliver = () => {
    refreshWithChunk(page * PAGE_CHUNK);
  };
  useEffect(() => {
    const refreshNew = setInterval(() => {
      refreshWithChunk(page * PAGE_CHUNK);
    }, 7000);
    return () => {
      clearInterval(refreshNew);
    };
  }, [page]);
  return (
    <ScrollComponent onScroll={toDeliverOnScroll}>
      {toDeliverData.length !== 0 && (
        <div className="centered-orders-container">
          {toDeliverData.map((item: TypeHistory, index: number) => (
            <div
              key={item.id}
              className="top-margin"
              id={index === toDeliverData.length - 1 ? toDeliverId : ""}
              ref={(ref) => {
                if (index === toDeliverData.length - 1) {
                  toDeliverLastItemRef.current = ref;
                }
              }}
            >
              <OrderComponent
                orderTypeID={item.orderHeader.orderTypeID}
                statusID={item.orderHeader.statusID}
                id={item.id}
                client={`${item.consumer.firstName} ${item.consumer.lastName}`}
                orderNumber={item.orderHeader.id}
                dateCreated={item.orderHeader.creationDate}
                ammount={item.orderHeader.finalAmount}
                hasScheduledPurchase={item.orderSchedule}
                hasDelivery={item.orderDelivery}
                delivered={handleRefreshDeliver}
              />
            </div>
          ))}
        </div>
      )}
      {toDeliverData.length === 0 && (
        <NoOrders icon={deliverIcon} label={labels.noOrdersToDeliver} />
      )}
    </ScrollComponent>
  );
}
