import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ScrollComponent.css";

const ScrollComponent = ({
  children,
  ...props
}: React.ComponentPropsWithoutRef<"div">) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const [thumbHeight, setThumbHeight] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(
    null
  );
  const [initialScrollTop, setInitialScrollTop] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);

  function handleResize(ref: HTMLDivElement, trackSize: number) {
    const { clientHeight, scrollHeight } = ref;
    const maxThumbHeight = Math.max(
      (clientHeight / scrollHeight) * trackSize,
      20
    );
    setThumbHeight(Math.min(thumbHeight, maxThumbHeight));
  }

  const handleTrackClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const { current: trackCurrent } = scrollTrackRef;
      const { current: contentCurrent } = contentRef;
      if (trackCurrent && contentCurrent) {
        const { clientY } = e;
        const target = e.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const trackTop = rect.top;
        const thumbOffset = -(thumbHeight / 2);
        const clickRatio =
          (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
        const scrollAmount = Math.floor(
          clickRatio * contentCurrent.scrollHeight
        );

        contentCurrent.children[0].children[scrollAmount].scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    [thumbHeight]
  );

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }
    const { scrollTop: contentTop, scrollHeight: contentHeight } =
      contentRef.current;
    const { clientHeight: trackHeight } = scrollTrackRef.current;
    const maxThumbTop = trackHeight - thumbHeight;
    let newTop = (+contentTop / +contentHeight) * maxThumbTop;
    newTop = Math.min(newTop, maxThumbTop);
    const thumb = scrollThumbRef.current;
    thumb.style.top = `${newTop}px`;
  }, [thumbHeight]);

  const handleThumbMousedown = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollStartPosition(e.clientY);
    if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
    setIsDragging(true);
  }, []);
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (contentRef.current) {
        const { keyCode } = e;
        const scrollStep = 20;

        switch (keyCode) {
          case 38:
            contentRef.current.scrollTop -= scrollStep;
            break;
          case 40:
            contentRef.current.scrollTop += scrollStep;
            break;
          default:
            break;
        }
        handleThumbPosition();
      }
    },
    [contentRef, handleThumbPosition]
  );
  const handleThumbMouseup = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbMousemove = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging && contentRef.current && scrollTrackRef.current) {
        const {
          scrollHeight: contentScrollHeight,
          offsetHeight: contentOffsetHeight,
        } = contentRef.current;

        const newScrollStartPosition =
          scrollStartPosition !== null ? scrollStartPosition : 0;

        const deltaY =
          (e.clientY - newScrollStartPosition) *
          (contentOffsetHeight / thumbHeight) *
          0.1; // Adjust the sensitivity here

        const newScrollTop = Math.min(
          initialScrollTop + deltaY,
          contentScrollHeight - contentOffsetHeight
        );

        contentRef.current.scrollTop = newScrollTop;
        handleThumbPosition();
      }
    },
    [
      isDragging,
      scrollStartPosition,
      thumbHeight,
      contentRef,
      initialScrollTop,
      handleThumbPosition,
    ]
  );

  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientHeight: trackSize } = scrollTrackRef.current;
      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });
      observer.current.observe(ref);
      ref.addEventListener("scroll", handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener("scroll", handleThumbPosition);
      };
    }
  }, []);
  useEffect(() => {
    document.addEventListener("mousemove", handleThumbMousemove);
    document.addEventListener("mouseup", handleThumbMouseup);
    document.addEventListener("mouseleave", handleThumbMouseup);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousemove", handleThumbMousemove);
      document.removeEventListener("mouseup", handleThumbMouseup);
      document.removeEventListener("mouseleave", handleThumbMouseup);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleThumbMousemove, handleThumbMouseup, handleKeyDown]);

  return (
    <div className="custom-scrollbars__container">
      <div className="custom-scrollbars__content" ref={contentRef} {...props}>
        {children}
      </div>
      <div className="custom-scrollbars__scrollbar">
        <div className="custom-scrollbars__track-and-thumb">
          <div
            className="custom-scrollbars__track"
            ref={scrollTrackRef}
            onClick={handleTrackClick}
            style={{ cursor: isDragging ? "grabbing" : undefined }}
          ></div>
          <div
            className="custom-scrollbars__thumb"
            ref={scrollThumbRef}
            onMouseDown={handleThumbMousedown}
            style={{
              height: `${thumbHeight}px`,
              cursor: isDragging ? "grabbing" : "grab",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ScrollComponent;
