import { BASEAPI } from "./constants";

const putEcoSurvey = async (idLocal: string, body: any) => {
  let res = null;

  await fetch(`${BASEAPI}commerces/eco/${idLocal}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    res = response.json();
  });

  return res;
};
const guardaEncuestaLocal = async (body: any) => {
  let res = null;

  await fetch(`${BASEAPI}GuardaEncuestaLocal`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    res = response.json();
  });

  return res;
};
export { putEcoSurvey, guardaEncuestaLocal };
