import React from "react";
import styles from "./checkbox.module.css";

interface ICheckBoxProps {
  checkId: string;
  label: string;
  onClick: () => void;
  checked: boolean;
  labelClassName?: string;
}

export default function CheckBox({
  checkId,
  label,
  checked,
  onClick,
  labelClassName,
}: ICheckBoxProps) {
  return (
    <div id="inputPreview">
      <input
        name="cssCheckbox"
        id={checkId}
        type="checkbox"
        className={checked ? styles.cssCheckbox : styles.noChecked}
        onChange={onClick}
        checked={checked}
      />
      <label className={labelClassName} htmlFor={checkId}>
        {label}
      </label>
    </div>
  );
}
