import React, { useState, FormEvent, useCallback, useEffect } from "react";
import { track } from "../../ClevertapWrapper";
import { useTextInput } from "../../hooks/useTextInput";
import { clevertapEvents, labels } from "../../labels";
import CheckBox from "../checkBox/CheckBox";
import OutlinedInput from "../outlinedInput/OutlinedInput";
import Text from "../text/Text";
import styles from "./ingredients.module.css";
import { IOption } from "./Option";
import Options from "./Options";
import { format } from "date-fns";

export interface IOptionType {
  id?: number;
  name: string;
  options: IOption[];
  required: boolean;
  maximumQuantity: string;
}

export type IOptions = IOptionType[];

export const initState: IOptionType = {
  name: "",
  options: [],
  maximumQuantity: "0",
  required: false,
};

interface IIngredientsProps {
  handleIngredients: (ingredients: IOptions | undefined) => void;
  haveError: string;
  initialData?: IOptions;
}
export default function Ingredients({
  handleIngredients,
  haveError,
  initialData,
}: IIngredientsProps) {
  const [haveIngredients, setHaveIngredients] = useState(
    initialData ? true : false
  );
  const {
    text: typeName,
    handleChangeText: handleChangeTypeName,
    handleChangeString: handleChangeTypeString,
  } = useTextInput();
  const { text: inputError, handleChangeString: handleChangeInputError } =
    useTextInput();

  // const [options, setOptions] = useState<IOptions>([]);
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  const [options, setOptions] = useState<IOptions>(initialData || []);

  const handleNameChange = (event: FormEvent<HTMLInputElement>) => {
    handleChangeTypeName(event);
    handleChangeInputError("");
  };

  const toogleIngredients = () => {
    setHaveIngredients((prevState) => !prevState);
  };
  const validateCreation = () => {
    let isValid = true;
    options.forEach((element) => {
      if (element.name === typeName) {
        isValid = false;
        handleChangeInputError(labels.duplicateOptionError);
        return false;
      }
    });
    return isValid;
  };

  const onCreateOptionType = () => {
    track(clevertapEvents.createProductAddIngredients, clevertapData);
    if (typeName && validateCreation()) {
      setOptions((prevState) => [
        ...prevState,
        { ...initState, name: typeName },
      ]);
      handleChangeTypeString("");
    }
  };

  const onOptionTypeDelete = (index: number) => {
    track(clevertapEvents.createProductDeleteIngredient, clevertapData);
    setOptions((prevState) => {
      const prevOptions = prevState?.slice(0, index) || [];
      const lastOptions = prevState?.slice(index + 1, prevState.length) || [];

      const newOptions = [...prevOptions, ...lastOptions];
      return newOptions;
    });
  };

  const onChangeRequired = (index: number, required: boolean) => {
    setOptions((prevState) => {
      const prevOptions = prevState.slice(0, index);
      const lastOptions = prevState.slice(index + 1, prevState.length);

      return [
        ...prevOptions,
        { ...prevState[index], required },
        ...lastOptions,
      ];
    });
  };

  const onChangeMaximum = useCallback((index: number, maximum: string) => {
    setOptions((prevState) => {
      const prevOptions = prevState.slice(0, index);
      const lastOptions = prevState.slice(index + 1, prevState.length);

      return [
        ...prevOptions,
        { ...prevState[index], maximumQuantity: maximum },
        ...lastOptions,
      ];
    });
  }, []);

  const onChangeOptions = useCallback(
    (index: number, productOptions: IOption[]) => {
      setOptions((prevState) => {
        track(clevertapEvents.createProductAddOptions, clevertapData);
        const prevOptions = prevState.slice(0, index);
        const lastOptions = prevState.slice(index + 1, prevState.length);
        return [
          ...prevOptions,
          { ...prevState[index], options: productOptions },
          ...lastOptions,
        ];
      });
    },
    []
  );

  const onToogleIngredients = () => {
    if (!haveIngredients) {
      track(clevertapEvents.createProductIngredients, clevertapData);
      toogleIngredients();
      setOptions([]);
    } else {
      toogleIngredients();
      setOptions([]);
      handleIngredients(undefined);
    }
  };

  useEffect(() => {
    if (haveIngredients) {
      handleIngredients(options);
    }
  }, [options, haveIngredients]);

  useEffect(() => {
    getLocation();
  }, [lat, lng]);

  return (
    <div className={styles.container}>
      <div className={styles.checkBoxContainer}>
        <CheckBox
          checkId="haveIngredient"
          labelClassName="subtitle2"
          label={labels.productHaveOptions}
          onClick={onToogleIngredients}
          checked={haveIngredients}
        />
      </div>
      {haveIngredients && (
        <>
          <div className={styles.titleWrapper}>
            <Text
              label={labels.productOptions}
              variant={`subtitle2 ${styles.textWidth} ${styles.bold}`}
            />
            <div className={styles.divider} />
          </div>
          <OutlinedInput
            value={typeName}
            onChange={handleNameChange}
            trailingIcon="plus"
            maxLength={20}
            floatingLabel={labels.optionType}
            placeholder={labels.optionTypePlaceholder}
            trailingIconClick={onCreateOptionType}
            error={inputError}
          />
        </>
      )}

      {haveIngredients &&
        options.map((element, index) => (
          <Options
            index={index}
            maxAmount={element.maximumQuantity}
            mandatory={element.required}
            onDeleteType={() => onOptionTypeDelete(index)}
            key={element.name}
            haveError={haveError}
            name={element.name}
            ingredients={element.options}
            getMaximum={(maximum: string) => onChangeMaximum(index, maximum)}
            getRequired={(required: boolean) =>
              onChangeRequired(index, required)
            }
            getOptions={(options: IOption[]) => onChangeOptions(index, options)}
          />
        ))}
    </div>
  );
}
