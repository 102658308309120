import React from "react";
import error from "../../../assets/icons/icons-error.svg";
import Text from "../../text/Text";
import "./ErrorModal.css";
import { labels } from "../../../labels";

export interface ILiquidateErrorProps {
  onClick: () => void;
}

export default function LiquidateError(props: ILiquidateErrorProps) {
  return (
    <div>
      <div className={"centeredIcon"}>
        <img src={error} />
      </div>
      <div className={"title"}>
        <Text label={labels.liquidateError} variant="Body1-16" />
        <Text label={labels.problemPersist} variant="Body1-16" />
      </div>
      <div className={"centered"}>
        <button className={"buttonHelp"} onClick={props.onClick}>
          <a className={"ButtonMd-14"}>{labels.retry}</a>
        </button>
      </div>
    </div>
  );
}
