import React, { useEffect, useState, FormEvent } from "react";
import Text from "../../components/text/Text";
import upload from "../../assets/icons/icons-upload-img.svg";
import back from "../../assets/icons/icons-arrow-back.svg";
import "./VoucherScreen.css";
import ModalComponent, {
  VARIANTS,
  VariantsType,
} from "../../components/modal/ModalComponent";
import SuccessModal from "../../components/modal/modalVariants/SuccessModal";
import ErrorModal from "../../components/modal/modalVariants/ErrorModal";
import PhotoModal from "../../components/modal/modalVariants/PhotoModal";
import { useLocation, useNavigate } from "react-router";
import routes from "../../router/routes";
import { postVoucher, postPlace, postBackUp } from "../../services/orders";
import { labels, voucher } from "../../labels";
import { useLoadingContext } from "../../context/spinnerContext";
import useDisclosure from "../../hooks/useDisclosure";

export default function VoucherScreen() {
  const navigation = useNavigate();
  const loadingContext = useLoadingContext();

  const state = useLocation();

  const MB_SIZE = 1024 * 1024;
  const MAX_MB_IMAGE = 2;

  const [modalContentType, setModalContentType] =
    React.useState<VariantsType>(null);
  const [selected, setSelected] = useState<boolean>(false);
  const [secondSelected, setSecondSelected] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [urlImage, setUrlImage] = useState<string>("");
  const [image2, setImage2] = useState<File | null>(null);
  const [urlImage2, setUrlImage2] = useState<string>("");
  const orderId = state.state.orderId;
  const [showError, setShowErrors] = useState<boolean>(false);
  const [showError2, setShowErrors2] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [base64Img, setBase64Img] = useState<ArrayBuffer | string | null>();
  const [base64Img2, setBase64Img2] = useState<ArrayBuffer | string | null>();
  const { open: hasVoucher, handleOpen: handleHasVoucher } = useDisclosure();
  const handleCloseModal = () => {
    setModalContentType(null);
    setSelected(false);
    setSecondSelected(false);
  };

  const handleAcceptModal = () => {
    setModalContentType(null);
    navigation(`/${routes.orders}`);
  };
  const handleOpenPhotoModal = () => {
    setSelected(true);
    setSecondSelected(false);
    setModalContentType(VARIANTS.PHOTO);
  };

  const handleOpenSecondPhotoModal = () => {
    setSelected(false);
    setSecondSelected(true);
    setModalContentType(VARIANTS.PHOTO);
  };

  const handleFile = (e: FormEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0] ?? null;
    setImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => {
      const baseURL = reader.result;
      const tmp = baseURL?.slice(23);
      setBase64Img(tmp);
    };
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setUrlImage(objectUrl);
    }
    setModalContentType(null);
    setSelected(false);
  };

  const handleSecondFile = (e: FormEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0] ?? null;
    setImage2(file);
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => {
      const baseURL = reader.result;
      const tmp = baseURL?.slice(23);
      setBase64Img2(tmp);
    };
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setUrlImage2(objectUrl);
    }
    setModalContentType(null);
  };

  const handleNavigateBack = () => {
    navigation(`/${routes.orders}`);
  };

  const handleBackUp = async () => {
    try {
      loadingContext?.onOpen();

      const dataVoucher = {
        image: base64Img,
      };
      const dataPlace = {
        image: base64Img2,
      };
      const url1 = await postVoucher(dataVoucher, orderId).then(
        async (resp: any) => {
          return resp[0].url;
        }
      );
      const url2 = await postPlace(dataPlace, orderId).then(
        async (resp: any) => {
          return resp[0].url;
        }
      );
      const dataImg = {
        urlPlace: url1,
        urlVoucher: url2,
      };
      await postBackUp(dataImg, orderId).then((resp) => {
        loadingContext?.onClose();
        if (resp.status === 200) {
          handleHasVoucher();
          setModalContentType(VARIANTS.SUCCESS);
        } else {
          setModalContentType(VARIANTS.ERROR);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const validateImage = () => {
    if (image && image?.size / MB_SIZE > MAX_MB_IMAGE) {
      setShowErrors(true);
      return true;
    }
    setShowErrors(false);
    return false;
  };
  const validateSecImage = () => {
    if (image2 && image2?.size / MB_SIZE > MAX_MB_IMAGE) {
      setShowErrors2(true);
      return true;
    }
    setShowErrors2(false);
    return false;
  };

  const buttonDisabled = () => {
    if (urlImage === "" || urlImage2 === "" || showError || showError2) {
      setDisabled(true);
    }
    if (urlImage !== "" || urlImage2 !== "" || !showError || !showError2) {
      setDisabled(false);
    }
  };
  useEffect(() => {
    if (image) {
      validateImage();
    }
  }, [image]);
  useEffect(() => {
    if (image2) {
      validateSecImage();
    }
  }, [image2]);

  useEffect(() => {
    buttonDisabled();
  }, [disabled]);
  return (
    <div className="background-vs">
      <div className="containerVoucher-vs">
        <div className="titleContainer-vs" onClick={handleNavigateBack}>
          <img src={back} />
          <Text
            label={voucher.uploadVoucherTitle}
            variant="H6 titleIconStyle-vs"
          />
        </div>

        <div className="innerContainer-vs">
          <div>
            <Text
              label={voucher.voucherSubtitle}
              variant="subtitle1 titleStyle-vs "
            />
            {!urlImage ? (
              <>
                <div
                  className="uploadContainer-vs"
                  onClick={handleOpenPhotoModal}
                >
                  <img src={upload} />
                </div>
              </>
            ) : (
              urlImage && (
                <img
                  src={urlImage}
                  className="uploadContainer-vs"
                  onClick={handleOpenPhotoModal}
                />
              )
            )}
            {!showError ? (
              <Text
                label={labels.fileSize}
                variant="Caption-Italic titleStyle-vs"
              />
            ) : (
              <Text
                label={labels.imageSizeError}
                variant="Caption-Italic titleStyleError-vs"
              />
            )}
          </div>
          <div>
            <Text
              label={voucher.placeSubtitle}
              variant="subtitle1 titleStyle-vs "
            />
            {!urlImage2 ? (
              <>
                <div
                  className="uploadContainer-vs"
                  onClick={handleOpenSecondPhotoModal}
                >
                  <img src={upload} />
                </div>
              </>
            ) : (
              urlImage2 && (
                <img
                  src={urlImage2}
                  className="uploadContainer-vs"
                  onClick={handleOpenSecondPhotoModal}
                />
              )
            )}
            {!showError2 ? (
              <Text
                label={labels.fileSize}
                variant="Caption-Italic titleStyle-vs"
              />
            ) : (
              <Text
                label={labels.imageSizeError}
                variant="Caption-Italic titleStyleError-vs"
              />
            )}
          </div>
        </div>
      </div>
      {!hasVoucher && (
        <div
          role="button"
          className={
            urlImage === "" || urlImage2 === "" || showError || showError2
              ? "buttonContinueDisabled-vs"
              : "buttonContinue-vs"
          }
          aria-disabled={disabled}
          onClick={handleBackUp}
        >
          <Text label={voucher.save} variant="subtitle1" />
        </div>
      )}
      <ModalComponent
        show={!!modalContentType}
        handleClose={handleCloseModal}
        icon={true}
        modalStyle={
          modalContentType === VARIANTS.PHOTO
            ? "modalsmall-vs"
            : "modalstyle-vs"
        }
        title={voucher.uploadImage}
      >
        {modalContentType === VARIANTS.PHOTO && selected && (
          <PhotoModal
            subtitle={voucher.uploadImageSubtitle}
            handleOnPress={handleFile}
          />
        )}
        {modalContentType === VARIANTS.PHOTO && secondSelected && (
          <PhotoModal
            subtitle={voucher.uploadImageSubtitle}
            handleOnPress={handleSecondFile}
          />
        )}
        {modalContentType === VARIANTS.ERROR && (
          <ErrorModal
            text={voucher.uploadError}
            buttonText={labels.continue}
            whatsappButton={false}
            closeModal={handleCloseModal}
          />
        )}
        {modalContentType === VARIANTS.SUCCESS && (
          <SuccessModal
            text={voucher.uploadSuccess + orderId}
            buttonText={labels.continue}
            handleOnPress={handleAcceptModal}
          />
        )}
      </ModalComponent>
    </div>
  );
}
