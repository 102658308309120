import { FormEvent, useCallback, useState } from "react";

export const useTextInput = (initialState?: string) => {
  const [text, setText] = useState<string>(initialState || "");
  const handleChangeText = useCallback((event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setText(newValue);
  }, []);

  const handleChangeString = useCallback((newText: string) => {
    setText(newText);
  }, []);

  return { text, handleChangeText, handleChangeString };
};
