import React, { useRef } from "react";
import upload from "../../../assets/icons/icon-photo-phone-upload.svg";
import Text from "../../text/Text";
import "./PhotoModal.css";

export interface IPhotoModalProps {
  subtitle?: string;
  handleOnPress?: (data: React.FormEvent<HTMLInputElement>) => void;
}

export default function PhotoModal({
  subtitle,
  handleOnPress,
}: IPhotoModalProps) {
  const ref = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (ref.current != null) {
      ref?.current?.click();
    }
  };
  return (
    <>
      <div className={"centeredIcon-pm"} onClick={handleClick}>
        <img src={upload} width={36} />
      </div>
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        style={{ display: "none" }}
        ref={ref}
        onChange={handleOnPress}
      />
      {subtitle && (
        <div className="textContainer-pm">
          <Text label={subtitle} variant="Body3-12 text-pm textstyle-pm" />
        </div>
      )}
    </>
  );
}
