import React from "react";
import { numberFormat } from "../../formatters";
import { useTextInput } from "../../hooks/useTextInput";
import { labels } from "../../labels";
import OutlinedInput, { inputTypes } from "../outlinedInput/OutlinedInput";
import SvgIcon from "../svgIcon/SvgIcon";
import styles from "./ingredients.module.css";

export interface IOptionProps {
  handleInfo: (index: number, name: string, price: string) => void;
  index: number;
  optionName: string;
  optionPrice: string;
  deleteOption: () => void;
  haveError: string;
}

export interface IOption {
  name: string;
  price: string;
  id: string;
}

const Option = ({
  handleInfo,
  index,
  optionName,
  optionPrice,
  deleteOption,
  haveError,
}: IOptionProps) => {
  const { text: name, handleChangeText: handleChangeName } =
    useTextInput(optionName);

  const {
    text: price,
    handleChangeText: handleChangePrice,
    handleChangeString,
  } = useTextInput(optionPrice);

  const onChangeName = () => {
    const formatPrice = numberFormat(price);
    handleChangeString(formatPrice);
    handleInfo(index, name, price);
  };
  return (
    <div className={styles.column}>
      <div className={styles.row}>
        <div
          className={index === 0 ? styles.rowInput : styles.rowInputLow}
          onBlur={onChangeName}
        >
          <OutlinedInput
            formId={"name" + index}
            floatingLabel={labels.optionName}
            type={inputTypes.text}
            value={name}
            error={(!!haveError && !name && labels.required) || ""}
            onChange={handleChangeName}
          />
        </div>
        <div
          className={index === 0 ? styles.rowInput : styles.rowInputLow}
          onBlur={onChangeName}
        >
          <OutlinedInput
            formId={"price" + index}
            floatingLabel={labels.price}
            type={inputTypes.text}
            isNumber
            value={price}
            min="1"
            error={(!!haveError && !price && labels.required) || ""}
            onChange={handleChangePrice}
          />
        </div>
        {index > 0 && (
          <div className={styles.remove} onClick={deleteOption}>
            <SvgIcon iconName="remove" />
          </div>
        )}
      </div>
      <div className={styles.fullDivider} />
    </div>
  );
};

export default Option;
