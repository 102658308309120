import React from "react";
import { buttonTextVariants, buttonVariants } from "./Button";
import "./Button.css";

interface IButtonProps {
  type: ButtonType;
  label: string;
  variant: ButtonVariants;
  textVariant?: ButtonTextVariant;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

export default function Button({
  variant,
  textVariant,
  label,
  type,
  disabled,
  fullWidth,
  onClick,
}: IButtonProps): React.ReactElement {
  const getStyles = () => {
    const classes: string[] = [];
    if (variant) {
      classes.push(buttonVariants[variant]);
    }

    if (disabled) {
      classes.push("buttonDisabled");
    }

    if (textVariant) {
      classes.push(buttonTextVariants[textVariant]);
    }

    if (fullWidth) {
      classes.push("fullwidth");
    }
    return classes.join(" ");
  };

  return (
    <button className={getStyles()} onClick={onClick} type={type}>
      {label}
    </button>
  );
}
