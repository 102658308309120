import React, { useState, ChangeEvent, useEffect } from "react";
import AsyncSelect from "../../../components/asyncSelect/AsyncSelect";
import Ingredients from "../../../components/ingredients/Ingredients";
import ModalComponent from "../../../components/modal/ModalComponent";
import ConfirmModal from "../../../components/modal/modalVariants/ConfirmModal";
import LiquidateCreateResponseOK from "../../../components/modal/modalVariants/LiquidateCreateResponseOK";
import LiquidateError from "../../../components/modal/modalVariants/LiquidateError";
import OutlinedInput from "../../../components/outlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/outlinedSelect/OutlinedSelect";
import ProductType from "../../../components/productType/ProductType";
import SvgIcon from "../../../components/svgIcon/SvgIcon";
import Text from "../../../components/text/Text";
import WarningUI from "../../../components/warning/WarningUI";
import { MEASURES } from "../../../constants";
import {
  brandsToOption,
  categoriesToOption,
  createProductFormIds,
} from "../../../formatters";
import useDisclosure from "../../../hooks/useDisclosure";
import { labels, clevertapEvents } from "../../../labels";
import { localBrands, localCategories } from "../../../services/products";
import styles from "./individualUpload.module.css";
import useIndividualUpload from "./useIndividualUpload";
import { track } from "../../../ClevertapWrapper";
import { format } from "date-fns";
import routes from "../../../router/routes";

export default function IndividualUpload() {
  const {
    formRef,
    errors,
    isValidForm,
    openRespnseOK,
    openResponseError,
    showError,
    form,
    handleSelectBrand,
    handleSelectCategory,
    handleChange,
    handleSubmit,
    handleFile,
    validate,
    handleCloseResponseOk,
    handleCloseResponseError,
    handleFood,
    handleIngredients,
    handleUnit,
  } = useIndividualUpload();

  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const [unit, setUnit] = useState<string>("");
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const pageLoadData = {
    pageName: "Carga de producto individual",
    pageURL: routes.individualUpload,
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    codComercio: user?.sEstablecimiento,
    codLocal: user?.cLocal[0].sLocal,
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  const {
    open: openConfirm,
    handleClose: handleCloseConfirm,
    handleOpen: handleOpenConfirm,
  } = useDisclosure();
  const brands = brandsToOption(localBrands());
  const categories = categoriesToOption(localCategories());
  const handleUnitChange = (element: ChangeEvent<HTMLSelectElement>) => {
    handleUnit(element);
  };
  const handleClick = () => {
    track(clevertapEvents.individualCreateProduct, clevertapData);
    const isValid = validate();
    if (isValid) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      handleOpenConfirm();
    }
  };

  const onSubmit = () => {
    handleCloseConfirm();
    handleSubmit();
  };

  const onCloseConfirm = () => {
    handleCloseConfirm();
  };

  const closeConfirm = openConfirm && onCloseConfirm;
  const closeResponseOk = openRespnseOK && handleCloseResponseOk;
  const closeResponseError = openResponseError && handleCloseResponseError;
  const closeFunction = closeConfirm || closeResponseOk || closeResponseError;

  useEffect(() => {
    getLocation();
  }, [lat, lng]);
  useEffect(() => {
    track(clevertapEvents.pageLoacpi, pageLoadData);
  }, []);
  return (
    <div className={styles.container}>
      <ModalComponent
        show={openConfirm || openRespnseOK || openResponseError}
        handleClose={closeFunction ? closeFunction : () => null}
        modalStyle={
          openRespnseOK || openResponseError
            ? styles.modalSmallStyleContainer
            : styles.modalStyleContainer
        }
        icon={false}
      >
        {openConfirm && (
          <div style={{ marginTop: 10 }}>
            <ConfirmModal onClick={onSubmit} onBack={onCloseConfirm} />
          </div>
        )}
        {openRespnseOK && (
          <div style={{ marginTop: 30 }}>
            <LiquidateCreateResponseOK
              buttonText={labels.newProduct}
              text={labels.productSuccess}
              onClose={handleCloseResponseOk}
            />
          </div>
        )}
        {openResponseError && (
          <LiquidateError onClick={handleCloseResponseError} />
        )}
      </ModalComponent>
      <div className={styles.wrapper}>
        <Text label={labels.createNewProduct} variant="subtitle1 black" />
        <WarningUI />
        <form ref={formRef} id="liquidate-form" className={styles.form}>
          <div className={styles.formWrapper}>
            <ProductType isFood={!!form.isFood} onChange={handleFood} />
            <div className={styles.titleWrapper}>
              <Text
                label={labels.productInformation}
                variant={`subtitle1 ${styles.textWidth}`}
              />
              <div className={styles.divider} />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                formId={createProductFormIds.productName}
                floatingLabel={labels.name}
                onChange={handleChange}
                value={form.productName}
                placeholder={labels.productNamePlaceholder}
                minLength={0}
                error={showError ? errors?.productName && " " : ""}
                maxLength={350}
                charCounter
                min="0"
                max="42"
              />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                formId={createProductFormIds.fullDescription}
                floatingLabel={labels.description}
                onChange={handleChange}
                value={form.fullDescription}
                placeholder={labels.descriptionPlaceHolder}
                error={showError ? errors?.fullDescription && " " : ""}
                minLength={0}
                maxLength={350}
                charCounter
              />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                formId={createProductFormIds.shortDescription}
                floatingLabel={labels.shortDescription}
                onChange={handleChange}
                value={form.shortDescription}
                placeholder={labels.productNamePlaceholder}
                error={showError ? errors?.shortDescription && " " : ""}
                minLength={0}
                maxLength={350}
                charCounter
              />
            </div>
            <div className={styles.inputContainer}>
              <AsyncSelect
                options={brands}
                floatingLabel={labels.productBrand}
                placeholder={labels.productBrandPlaceholder}
                errorMessage={showError ? errors?.productBrandID && " " : ""}
                onChange={handleSelectBrand}
              />
            </div>
            <div className={styles.inputContainer}>
              <AsyncSelect
                options={categories}
                floatingLabel={labels.classification}
                errorMessage={showError ? errors?.productCategoryID && " " : ""}
                placeholder={labels.classificationPlaceholder}
                onChange={handleSelectCategory}
              />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                formId={createProductFormIds.productImage}
                floatingLabel={labels.productImage}
                placeholder={labels.productImagePlaceholder}
                type="file"
                accept="image/*;capture=camera"
                onChange={handleFile}
                helperText={labels.fileSize}
                error={!showError ? errors?.productImage : ""}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.rowItem1}>
                <OutlinedSelect
                  formId={createProductFormIds.productMeasureUnit}
                  floatingLabel={labels.unit}
                  options={MEASURES}
                  error={showError ? errors?.productMeasureUnit && " " : ""}
                  onChange={handleUnitChange}
                />
              </div>
              <div className={styles.rowItem2}>
                <OutlinedInput
                  formId={createProductFormIds.weight}
                  floatingLabel={labels.quantity}
                  type="text"
                  onChange={handleChange}
                  value={form.weight}
                  isNumber={unit !== "Lt" && unit !== "Kg"}
                  isDecimal={unit === "Lt" || unit === "Kg"}
                  min="0"
                  placeholder={labels.quantityPlaceholder}
                  error={showError ? errors?.weight && " " : ""}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowItem1}>
                <OutlinedInput
                  formId={createProductFormIds.price}
                  onChange={handleChange}
                  value={form.price}
                  floatingLabel={labels.price}
                  isNumber
                  placeholder={labels.normalPlaceholder}
                  error={showError ? errors?.price && " " : ""}
                />
              </div>
              <div className={styles.rowItem2}>
                <OutlinedInput
                  formId={createProductFormIds.stock}
                  floatingLabel={labels.stock}
                  onChange={handleChange}
                  value={form.stock}
                  isNumber
                  placeholder={labels.quantityPlaceholder}
                  error={showError ? errors?.stock && " " : ""}
                />
              </div>
            </div>
            <Ingredients
              handleIngredients={handleIngredients}
              haveError={showError ? errors?.ingredients || "" : ""}
            />
            {errors?.ingredients && (
              <div className={styles.individualError}>
                <div className={styles.warningIcon}>
                  <SvgIcon iconName="warning" />
                </div>
                <p className="subtitle3">{errors?.ingredients}</p>
              </div>
            )}
          </div>
          <button
            type="button"
            className={`successBC ButtonLg-16 fullwidth ${
              !isValidForm ? "buttonDisabled" : ""
            }`}
            onClick={handleClick}
          >
            {labels.createProduct}
          </button>
        </form>
      </div>
    </div>
  );
}
