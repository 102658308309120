import React from "react";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.css";
import es from "date-fns/locale/es";
registerLocale("es", es);

export default function Calendar(props: ReactDatePickerProps) {
  return (
    <div className="customDatePickerWidth">
      <DatePicker {...props} inline locale="es" />
    </div>
  );
}
