const labels = {
    manageBussiness: 'Administra tu negocio con tecnología ecofriendly',
    commerceCode: 'Código de comercio',
    password: 'Clave',
    login: 'Iniciar Sesión',
    forgetPassword: 'Olvidé mi clave',
    recoverPassword: 'Restablecer clave',
    backToLogin: 'Volver a login',
    back: 'Volver',
    enterEmail: 'Ingresa tu correo electrónico',
    enterUser: 'Ingresa tu usuario',
    couldntReset:
        'No pudimos restablecer tu clave. Por favor, comunícate con en el equipo amiPASS.',
    contactSupport: 'Contácta a soporte ahora',
    weSendEmail: 'Hemos enviado un correo para restablecer tu contraseña',
    accept: 'Aceptar',
    reject: 'Rechazar',
    continue: 'Continuar',
    incorrectCredentials: 'Crendenciales incorrectas',
    confirmLiquidate:
        'Asegúrate de que la información de tu producto a publicar cumpla con las políticas de uso de',
    amipass: 'amiPASS',
    confirmPublish: '¿Desea publicar su producto?',
    publish: 'Publicar',
    incorrectCredentialsModal:
        'Los datos ingresados no son válidos. Por favor intenta nuevamente.',
    accountBlockedModal:
        'Los datos ingresados no son válidos. Su clave ha sido bloqueada. Debe restaurarla para iniciar sesión',
    productsTitle: 'Selecciona una opción de carga para tus productos',
    productsMassiveCardTitle: 'Carga masiva de productos',
    productsMassiveCardSubtitle:
        'Recomendado si tienes más de 100 productos ordenados en un excel.',
    productsIndividualUploadTitle: 'Carga de productos individual',
    productsIndividualUploadSubtitle:
        'Recomendado si tienes platos preparados o menos de 50 productos.',
    productsDiscountTitle: 'Liquidar producto',
    productsDiscountSubtitle:
        'Publica un producto para líquidar a un precio y fecha límite de venta determinada.',
    homeTitle: 'Bienvenido.',
    homeSubtitle: '¿Que necesita hacer hoy?',
    uploadProductsTitle: 'Cargar productos',
    uploadProductsSubtitle:
        'Publica tus productos de forma individual o masiva.',
    myProductsTitle: 'Mis productos',
    myProductsSubtitle: 'Listado de productos publicados en tu tienda online.',
    myOrdersTitle: 'Mis Pedidos',
    myOrdersSubtitle: 'Gestiona tus pedidos desde tu navegador web.',
    liquidateNewProduct: 'Liquidar un producto nuevo',
    createNewProduct: 'Carga de productos individual',
    editProductTitle: 'Editar producto',
    editProductUpdated: 'Producto actualizado',
    editProductUpdateError:
        'Ocurrió un error al intentar actualizar el producto. Por favor, intente nuevamente. Si el problema persiste, contacta a soporte.',
    editProductDeleted: 'Producto eliminado con éxito',
    editProductDeleteError:
        'Ocurrió un error al intentar eliminar el producto. Por favor, intente nuevamente. Si el problema persiste, contacta a soporte.',
    saveChanges: 'Actualizar',
    deleteProduct: 'Eliminar',
    name: 'Nombre',
    productInformation: 'Información de producto',
    productNamePlaceholder: 'Ejemplo: Pizza del día',
    description: 'Descripción',
    descriptionPlaceHolder:
        'Ejemplo: Elíge una pizza distinta cada día con 4 ingredientes y salsa de elección.',
    shortDescription: 'Descripción corta',
    productBrand: 'Marca de producto',
    productBrandPlaceholder: 'Ejemplo: Marca propia',
    classification: 'Clasificación',
    classificationPlaceholder: 'Ejemplo: saludable',
    productImage: 'Imagen de producto',
    productImagePlaceholder: 'Sube la imagen de tu producto aquí',
    unit: 'Unidad de medida(UM)',
    quantity: 'Cantidad / UM',
    maxAmount: 'Cantidad Máxima',
    quantityPlaceholder: '1',
    normalPrice: 'Normal',
    liquidatePrice: 'Liquidación',
    normalPlaceholder: '2990',
    liquidatePlaceholder: '1990',
    fileSize: 'Límite de peso: 2 mb',
    priceToLiquidate: 'Precio de producto a liquidar',
    liquidateQuantity: 'Cantidad de productos a liquidar',
    liquidateStock: 'Stock a liquidar',
    stock: 'Stock',
    liquidatePriceError:
        'Precio de liquidación debe ser menor al precio normal',
    required: 'Requerido',
    ingredientsError:
        'Verifique que todos los campos de los ingredientes estén completos',
    ingredientsEmpty:
        'Si el producto tiene ingredientes debes ingresar al menos 1',
    imageSizeError: 'Límite requerido: 2MB',
    goToProducts: 'Ir a mis productos',
    beforePublish: 'Antes de ser publicados',
    warningProductInformation:
        ' asegúra que la información de tu producto cumpla con',
    usagePolicies: 'nuestra políticas de uso.',
    drawerHome: 'Inicio',
    drawerSales: 'Ventas del mes',
    drawerProducts: 'Mis productos',
    drawerUploadProducts: 'Cargar productos',
    drawerLiquidateProducts: 'Liquidar un producto',
    drawerMyOrders: 'Mis pedidos',
    drawerLogOut: 'Cerrar sesión',
    drawerConfig: 'Configuraciones',
    drawerSubtitle: 'Mi código de comercio:',
    optionNotFound: 'Opción no encontrada',
    noValidOption: 'Opción no valida',
    liquidateError:
        'Ocurrió un error al enviar la solicitud, intenta nuevamente.',
    problemPersist: 'Si el problema persiste, contacta a soporte.',
    retry: 'Volver a intentar',
    food: 'Alimentos',
    notFood: 'No alimentos',
    inventorySearch: 'Busca un producto',
    inventorySearchHolder: 'Escribe el nombre de un producto.',
    inventoryEmpty: 'No tienes productos publicados en tu tienda online.',
    inventoryEmptyFooter:
        'Sube tus productos de forma masiva o crea un producto nuevo.',
    inventoryNavBarTodos: 'Todos',
    inventoryNavBarEnLiquidacion: 'En Liquidación',
    inventorySuccesLiquidacion: 'Marcado como producto en liquidación',
    inventoryErrorLiquidacion:
        'Ocurrió un error al enviar la solicitud, intenta nuevamente. Si el problema persiste, contacta a soporte.',
    inventorySuccesStopLiquidacion: 'El producto dejó de estár en liquidación.',
    price: 'Precio',
    productHaveOptions: '¿El producto tiene distintas opciones?',
    ingredients: 'Ingredientes',
    requiredOption: 'Opción obligatoria',
    optionName: 'Nombre de opción',
    addOption: 'Agrega una opción',
    productOptions: 'Opciones de producto',
    optionType: 'Tipo de opción',
    optionTypePlaceholder: 'Ejemplo: INGREDIENTES',
    duplicateOptionError: 'Ya existe este tipo de opción',
    inventoryModalDate: 'Fecha límite de venta',
    inventoryModalStock: 'Cantidad a liquidar',
    inventoryModalPrice: 'Precio a liquidar',
    inventoryModalMsjErrorPrice:
        'Precio de liquidación debe ser menor al precio normal',
    inventoryModalMsjErrorStock: 'Cantidad ingresada excede stock disponible',
    inventoryModalMsjErrorDate: 'Fecha de consumo es menor a fecha actual',
    orderHeaderNew: 'Nuevos',
    orderHeaderProgress: 'En preparación',
    orderHeaderToDeliver: 'Por entregar',
    orderHeaderHistory: 'Historial',
    noNewOrders: 'Aún no tienes nuevos pedidos.',
    noOrdesInProgress: 'Aquí verás los pedidos una vez aceptados.',
    noOrdersToDeliver:
        'Aquí verás los pedidos que marques como listos para ser entregados.',
    ordersSearchInput: 'Ingresa un Número de pedido',
    ordersNoHistory: 'No se registran pedidos',
    orderComponentClient: 'Cliente',
    orderComponentOrderID: 'Número de pedido',
    orderComponentTime: 'Tiempo',
    orderComponentAmount: 'Monto',
    orderComponentDelivered: 'Entregado',
    orderComponentRejectedStore: 'Rechazado por el local',
    orderComponentRejectedClient: 'Rechazado por el cliente',
    orderComponentDetail: 'Ver detalle',
    orderComponentReadyDelivery: 'Listo para despacho',
    orderComponentReadyPickUp: 'Listo para retiro',
    orderComponentToDeliverClient: 'Por entregar a cliente',
    orderComponentReadyForPickUp: 'Por entregar a repartidor',
    modalErrorMessage:
        'Ocurrió un error al enviar la solicitud, intenta nuevamente. Si el problema persiste, contacta a soporte.',
    rejectOrder: 'Rechazar pedido',
    rejectOrderTitle: 'Se le devolverá el total del pedido al cliente.',
    rejectOrderSubtitle: '¿Está seguro que desea RECHAZAR el pedido Nº: ',
    rejectMotive: 'Selecciona un motivo',
    orderDetail: 'Detalle de pedido',
    orderDetailPickUpForm: 'Forma de entrega: ',
    orderDetailStoreDelivery: 'Delivery a cargo del local',
    orderDetailStorePickUp: 'Retiro en local',
    orderDetailScheduled: 'Pedido programado',
    orderDetailScheduledDate1: 'Día y hora de la compra:  ',
    orderDetailScheduledDate2: 'Día y hora de entrega:  ',
    orderDetailScheduledDate3: 'Tu local declara entregar en: ',
    orderDetailID: 'ID: #',
    orderDetailNumber: 'N° ',
    orderDetailAddress: 'Dirección: ',
    orderDetailTotal: 'Total',
    orderDetailComment: 'Comentario de compra',
    orderDetailNoComment: 'No hay comentarios en la compra',
    orderDetailprint: 'Imprimir comanda de pedido',
    orderDetailRejectMotive: 'Motivo del rechazo',
    orderDetailPickedUpByClient: 'Marcar como retirado por el cliente',
    orderDetailDelivered: 'Marcar como entregado',
    noOrdersHistory: 'No encontramos el pedido que buscas',
    ordersProgramedForDay: 'Programado para el ',
    ordersProgramedAtTime: ' a las ',
    ordersNumber: 'El pedido Nº: ',
    ordersRejected: ' ha sido RECHAZADO',
    ordersAccepted: ' ha sido ACEPTADO',
    allOrders: 'Todos los pedidos',
    deliveredOrdes: 'Entregados',
    rejectedOrders: 'Rechazado por local',
    rejectedByClient: 'Rechazado por cliente',
    selectADate: 'Selecciona una fecha',
    today: 'Hoy',
    thisWeek: 'Esta semana',
    thisMonth: 'Este mes',
    threeMonthsAgo: 'Hace 3 meses',
    sixMonthsAgo: 'Hace 6 meses',
    reason1: 'No estoy atendiendo',
    reason2: 'Urgencia que impide realizar el pedido',
    reason3: 'No cubro esa zona con delivery',
    reason4: 'Producto no disponible',
    orderDelivered1: 'Entregado el ',
    readyForPickUp: ' está listo para RETIRO',
    readyForDelivery: 'está listo para DESPACHO',
    pickedUpByClient: ' fue RETIRADO POR EL CLIENTE',
    delivered: ' fue marcado como ENTREGADO por el repartidor.',
    massiveUpload: 'Carga masiva de productos nuevos',
    massiveWarning:
        'Descarga la planilla excel y completa los campos con la información de tus productos antes de subirla al portal. Las imágenes de tus productos deben ser comprimidas en formato (ZIP) y subidas al portal. Puedes publicar tus productos sin imágenes, dejando vacío el campo ”Nombre del archivo de imagen” de la planilla.',
    important: 'Importante:',
    notInStock:
        'La carga masiva aplica sólo para la creación de productos que no se encuentren en el inventario.',
    newProducts:
        'La cantidad máxima de productos a cargar es de 15.000 cada vez. Si necesitas cargar 30.000, sepáralo en 2 archivos con 15.000 productos y cárgalos uno después de otro',
    notIngredients:
        'Sólo se puede aplicar a productos que no contengan ingredientes.',
    notEmpty:
        'La planilla debe estar sin campos vacíos. De lo contrario, no pasará la verificación y no podrás publicarlos.',
    excel: 'Planilla EXCEL',
    uploadFiles: 'Subir archivos',
    downloadExcel: 'Descarga de planilla excel',
    chooseFile: 'Elegir archivo',
    zeropercent: '0%',
    hundredPercent: '100%',
    responseError:
        'Ocurrió un error al enviar la solicitud, intenta nuevamente. Si el problema persiste, contacta a soporte.',
    responseOk: 'Solicitud envíada con éxito.',
    team: 'El equipo',
    massiveConfirm:
        ', revisará el contenido de la planilla y de las imágenes. En caso de no encontrarse errores, los productos serán publicados en 48 horas hábiles.',
    wantContinue: '¿Deseas continuar?',
    makeMassiveUpload: 'Realizar carga masiva',
    massiveFile: 'Formato_Llenado_Producto.xlsx',
    zipFile: 'Imágenes de producto (Archivo ZIP)',
    fileError: 'La planilla tiene campos incompletos.',
    fileUploadError: 'Error al subir archivos',
    fileSizeError: 'Las imágenes deben tener un tamaño máximo de 2MB.',
    suggest: 'Te sugerimos',
    attachImages: 'adjuntar las imágenes de producto (Archivo ZIP).',
    bussinessHours: 'en 48 horas hábiles',
    publishWithoutImages:
        'De lo contrario, tus productos serán publicados SIN IMÁGENES una vez revisados por el equipo',
    buttonPublishWithoutImages: 'Publicar sin imágenes',
    newProduct: 'Crear nuevo producto',
    productSuccess: 'Su producto ha sido publicado con éxito.',
    createProduct: 'Crear Producto',
    editProduct: 'Editar',
    deleteProductText:
        'Estás a punto de eliminar un producto de tu tienda. ¿Estás seguro?',
    deleteProductButtonText: 'ELIMINAR PRODUCTO',
    dontDeleteProductButtonText: 'NO HACER NADA',
    CardProductLiquidate: 'Liquidar',
    stopLiquidaqtion: 'Dejar de liquidar',
    productCardOfferPrice: 'Precio oferta',
    paymentTotalAmount: 'Total a cobrar:',
    paymentMethod: 'Selecciona un medio de pago',
    paymentAmipass: 'amiPASS',
    paymentQR: 'QR',
    paymentQRdigits: 'Ingresa los 8 dígitos del QR amiPASS',
    paymentQRdigitsButton: 'COBRAR ESTA VENTA',
    paymentErrorMessage: 'El cobro no fue realizado.',
    paymentRetry: 'Por favor, intenta nuevamente.',
    paymentContinue: 'CONTINUAR',
    paymentErrorSmallAmount:
        'El monto del QR generado es menor al valor de la venta.',
    paymentErrorInvalidQR: 'QR inválido',
    paymentErrorNewQR:
        'Solicítale al cliente que genere el código QR nuevamente.',
    paymentErrorQRUsed: 'QR ya fue utilizado',
    paymentErrorNewQR2: 'Solicítale al cliente que genere un nuevo código QR.',
    paymentErrorMoreDetails:
        'Infórmale a tu cliente que debe comunicarse con su empleador para más detalles.',
    paymentVoucher: 'Comprobante de venta',
    paymentAuthorizationCode: 'Cod. Autorización:',
    paymentClient: 'Cliente:',
    paymentDate: 'Fecha:',
    paymentMethodDetail: 'Medio de pago:',
    paymentBackHome: 'IR A INICIO',
    backPayment: 'HACER OTRO COBRO',
    yes: 'SI',
    no: 'NO',
    goToMonthSells: 'Ir a las ventas del mes',
    paidWithAmipass: 'Cobrar con AmiPASS',
    monthSales: 'Ventas del mes',
    incressPercentage: '% más que el mes pasado',
    lastMovements: 'Ultimos movimientos',
    goToSaleDetails: 'Ir a detalle de ventas',
    amiRut: 'amiPASS RUT',
    codWeb: 'Cód Comercio web',
    codApp: 'Cód Comercio APP',
    qr: 'QR',
    amiMarket: 'AmiMARKET',
    allPayments: 'Todos',
    noDataForFilter: 'No hay informacion para este filtro',
    comuneModalAlert:
        'Puedes agrupar varias comunas con las mismas condiciones de despacho y tiempo de entrega.',
    comuneModalInput: 'Escribe el nombre de una comuna',
    comuneModalAmountInput: 'Costo de despacho',
    comuneModalDeliverType: 'Tiempo de entrega',
    comuneModalMoreThan24hs: 'Entrega en más de 24 horas.',
    comuneModalInTheDay: 'Dentro del mismo día',
    comuneModalTimeToDeliver: 'Tiempo aproximado de entrega',
    comuneModalDeliverOptionPick: 'Elíge una forma de despacho',
    comuneModalTimeSlot: 'Tramos de horas',
    comuneModalSpecificDays: 'Días específicos de despacho',
    comuneModalAlert1:
        'Tiempo de entrega posterior a la aceptación del pedido.',
    comuneModalAlert2:
        'Hora tope de compra para despacho dentro del plazo indicado',
    comuneModalAlert3:
        'Tiempo límite que tendrá el cliente para realizar la compra y recibir su pedido en el tiempo indicado. ',
    comuneModalAlert4:
        'Estos días operan separado a los días de tu tienda online.',
    comuneModalAlert5:
        'Días que necesitas para planificar y realizar el despacho una vez que aceptes el pedido.',
    comuneModalAlert6:
        'Tiempo límite que tendrá el cliente para realizar la compra y recibir su pedido en el tiempo indicado. ',
    comuneModalAlert7:
        'Posterior a la compra del cliente dentro de los horarios declarados para tu tienda online.',
    comuneModalDeliverDays: 'Días de despacho',
    comuneModalReceptionDays:
        'Días de recepción de compra para preparar el despacho.',
    comuneModalHourLimitTitle:
        'Hora tope de compra para despacho dentro del plazo indicado.',
    confirm: 'Confirmar',
    regionModalAlert: 'Solo se puede agregar una región a la vez',
    regionModalInput: 'Escribe el nombre de una región',
    configurationDrawer: 'Configuraciones',
    configurationScreenTitle: 'Ajustes de mi negocio',
    configurationScreenDescription:
        'Cambia tu logo, descripción, horarios y otras configuraciones de tu local y tienda online',
    scheduledBack: 'Volver a Ajustes de mi negocio',
    scheduledDescription1:
        'Los pedidos programados te ayudarán a reducir la merma de tus productos y planificar la entrega en virtud de cuidar al planeta. Recibirás los pedidos programados en los horarios que declaraste para tu tienda online.',
    scheduledDescription2:
        'Ayuda al medio ambiente permitiendo que tus clientes puedan programar sus pedidos para una fecha en específico.',
    scheduledDescription3:
        'Los horarios que indiques estarán separados tanto por días en específico como por tramos de una hora dependiendo de la hora en que se realice el pedido programado.',
    scheduledDescription4: '(11:00 AM - 12:00 PM, 12:00 PM - 1:00 PM).',
    scheduledDescription5:
        'Esto dependerá de cómo configures los horarios de tu tienda online.',
    scheduledAccept: 'Aceptar recibir pedidos programados',
    scheduledCongrats: '¡Felicidades! ',
    scheduledCongratsDescription:
        'Ya puedes recibir pedidos programados en tu negocio y ayudar al medio ambiente',
    sConfigDesde: 'Desde',
    sConfigHasta: 'Hasta',
    sConfigCost: 'Costo de despacho',
    sConfigDeliveryDays: 'Días de despacho:',
    sConfigReceptionDays:
        'Días de recepción de compra para preparar el despacho:',
    sConfigMaxHour: 'Hora tope de compra:',
    sConfigAproxDeliverTime: 'Tiempo aproximado de entrega',
    sConfigTimeSlog: 'Tramos de horas:',
    sConfigAddComune: 'Agrega una comuna',
    sConfigTitle: 'Ajustes de mi negocio',
    sConfigDescription: 'Descripción',
    sConfigDeliveryType: 'Tipo de entrega',
    sConfigMinAmount: 'Monto mínimo de compra',
    sConfigFreeDeliver: 'Montos mínimos de compra para despacho gratis',
    sConfigDeliverPlaces: 'Zonas de despacho ',
    sConfigAddRegion: 'Agrega una región',
    sConfigDeactiveStore: 'Desactivar tienda temporalmente',
    sConfigProgrammed: 'Quiero recibir pedidos programados',
    sConfigProgramed2: 'Recibir pedidos programados ',
    sConfigDeactive: 'Desactivar mi tienda',
    sConfigDeactiveTitle: '¿Cuándo quieres reactivar tu tienda?',
    sConfigDeactiveHour: 'Reactivar tienda a las:',
    sConfigButtonDeactive: 'Desactivar temporalmente mi tienda',
    sConfigButtonDeactiveNoDate: 'Desactivar sin fecha de reactivación',
    sConfigStoreUpdated: 'Se actualizó la información de tu negocio.',
    sConfigErrorUpdate: 'No se guardó ningún cambio. ',
    sConfigPhone: 'Teléfono del local:',
    sConfigFisicalStore: 'Local Físico',
    sConfigOnlineStore: 'Tienda online',
    sConfigNoOnlineStore: 'Tu negocio no tiene una tienda online',
    sConfigUpdateStoreButton: 'ACTIVA TU TIENDA ONLINE AHORA',
    sConfigLink: 'Enlace de tienda online',
    sConfigEmail: 'Email recepción pedidos:',
    canceled: 'ANULADA',
    rejected: 'RECHAZADA',
    saleStatus: 'Estado de venta',
    saleClient: 'Cliente',
    saleDetail: 'Detalle',
    onlineStorInformation: 'Información de tienda online',
    deliveryConfiguration: 'Configuración de entrega',
    scheduledPurtchases: 'Compras programadas',
    termsAndConditions: 'Términos y condiciones',
    configScheduled1:
        'Los pedidos programados te ayudarán a reducir la merma de tus productos y planificar la entrega en virtud de cuidar al planeta. Recibirás los pedidos programados en los horarios que declaraste para tu tienda online.',
    configScheduled2:
        'Ayuda al medio ambiente permitiendo que tus clientes puedan programar sus pedidos para una fecha en específico.',
    configScheduled3:
        'Los horarios que indiques estarán separados tanto por días en específico como por tramos de una hora dependiendo de la hora en que se realice el pedido programado.',
    configScheduled4: '(11:00 AM - 12:00 PM, 12:00 PM - 1:00 PM).',
    configScheduled5:
        'Esto dependerá de cómo configures los horarios de tu tienda online.',
    configScheduledDecline: 'No estoy interesado por ahora',
    configScheduledAccept: 'Aceptar recibir pedidos programados',
    selectOptionForYourBussines:
        'Elige las opciones acorde a tu negocio para tu tienda online.',
    delivery: 'Despacho a domicilio',
    localPickup: 'Retiro en local',
    freeDelivery: '¿Tu negocio realiza despachos gratis?',
    noFreeDelivery: 'No realizo despachos gratis.',
    paidFreeDelivery: 'Si, Por monto mínimo de compra.',
    alwaysFreeDelivery: 'Siempre despacho gratis.',
    deliveryPlaces:
        'Agrega los lugares a los que realizas despacho a domicilio.  ',
    activeOnlineStore: 'Activar tienda online',
    acceptTerms: 'Acepto los términos y condiciones',
    exhibitTerms: 'Anexo acuerdo comercial para tienda online.',
    schedule: 'Horario',
    storeLogo: 'Logo de tu negocio',
    uploadLogo: 'Sube tu logo aquí',
    storeDescription: 'Descripción de tu negocio',
    storeDescriptionPlaceholder:
        'A pasos del metro San Miguel✨ 🥟🥗🍲 Empanadas, Almuerzos y Ensaladas 🍩🍰 Donuts y Pasteles 🎂 Desayunos a domicilio.',
    emailReception: 'Email recepción pedidos',
    emailReceptionPlaceholder: 'mail@minegocio.com',
    orderReception: 'Horarios de recepción de pedidos',
    allDayReception: 'Recepción de pedidos 24/7 ',
    minAmmountPlaceholder: '5000',
    minimumAmmountForOnlineStore:
        'Este monto corresponde a la cantidad mínima de compra para la tienda online.',
    storeActivated: 'Tu tienda fue activada',
    startCreatingProducts:
        'Comienza creando tus productos para recibir pedidos en tu aplicación amiPOS.',
    createFirstProduct: 'Crear mi primer producto',
    showDeliveryVoucher: 'Ver respaldo de entrega',
    attachDeliveryVoucher: 'Adjuntar respaldo de entrega de pedido',
    freeDeliverCondition: 'Condición para el despacho gratis.',
    minAmmountPriceFreeDelivery: 'Monto mínimo para despacho gratis',
    examplePriceFreeDelivery: '5000',
    storeMinAmmount: 'Monto mínimo para la tienda: $',
    mustFill: 'Campo obligatorio ',
    minAmmountBiggerThan: 'El monto no debe ser menor a $',
    drawerReport: 'Análisis de mi negocio',
    errorLoadReportBI:
        '¡Pronto podrás encontrar aquí el Análisis de tu negocio! Sólo falta que comiences a hacer tus primeras ventas.',
    reportBI: 'Reporte BI',
}

const voucher = {
    uploadVoucherTitle: 'Respaldo de entrega',
    voucherSubtitle: 'Adjunta una foto con firma de recepción en el voucher',
    placeSubtitle: 'Adjunta una Foto a la dirección del lugar físico',
    save: 'Guardar',
    uploadImage: 'Subir imagen',
    uploadImageSubtitle: 'Seleccionar imagen en tu dispositivo',
    uploadError:
        'Ocurrió un error al guardar el respaldo de entrega para este pedido. Por favor, intenta nuevamente.',
    uploadSuccess: 'Se guardó el respaldo de la entrega N°',
}

const ecofriendly = {
    ecoFriendTitle: 'Sello Eco Friendly',
    ecoFriendButtonText:
        'Obtén tu sello verde en representación a las acciones que realizas para reducir la huella de carbono.',
    hasEcoBadge:
        'Ayuda al planeta mejorando las prácticas dentro de tu negocio para así contribuir con el medio ambiente. Hoy en día, los consumidores prefieren comprar en comercios que generen menor impacto ambiental al momento de elaborar sus comidas favoritas. Por eso con este sello tendrás la preferencia ante otros locales de la red amiPASS.',
    hasEcoBadgeSubtitle: 'Ya tienes tu sello ecofriendly',
    surveyTitle: 'Elige las opciones acorde a tu negocio',
    surveyQuestion0:
        '¿Tienen contratado un servicio de Reciclaje de elementos de uso diario (papel, vidrio, cartón y/o latas?',
    surveyQuestion1:
        '¿Utilizan para los envíos empaques reciclables/biodegradables?',
    surveyQuestion2: '¿Liquidan los productos antes de vencer?',
    surveyQuestion3: '¿Cuentas con opciones veganas/vegetarianas?',
    surveyQuestion4:
        '¿Tienes un servicio contratado para el retiro y compostaje de sus residuos orgánicos? ',
    surveyQuestion5:
        '¿Realizas el 100% de despacho en bicicleta NO motorizada a combustibles fósiles? ',
    yes: 'Si',
    no: 'No',
    errorModal: 'Ocurrió un error al enviar la solicitud, intenta nuevamente.',
    successModal: 'Ya tienes tu sello ecofriendly',
    surveyFor: 'Encuesta EcoFriendly de',
    congrats: '¡Felicidades!',
}
const onlineConfig = {
    deliveryTime15to30: '15 a 30 minutos',
    deliveryTime30to45: '30 a 45 minutos',
    delivertyTime45to60: '45 a 60 minutos',
    deliveryTime60to75: '60 a 75 minutos',
    deliveryTime75to90: '75 a 90 minutos',
    receptionSelect: 'Seleccione un rango',
    receptionSelect1: '1 día antes',
    receptionSelect2: '2 días antes',
    receptionSelect3: 'Mismo día',
    timeSlotSelect: 'Máximo 24 horas (Entrega al día siguiente)',
    timeSlotSelect1: 'Máximo 48 horas (Entrega en 2 días después de la compra)',
    timeslotSelect2: 'Máximo 72 horas (Entrega en 3 días después de la compra)',
    timeSlotSelect3: 'Máximo 96 horas (Entrega en 4 días después de la compra)',
    timeSlotSelect4:
        'Máximo 120 horas (Entrega en 5 días después de la compra)',
    daySelectL: 'L',
    daySelectM: 'M',
    daySelectJ: 'J',
    daySelectV: 'V',
    daySelectS: 'S',
    daySelectD: 'D',
    daySelectMonday: 'Lunes',
    daySelectTuesday: 'Martes',
    daySelectWednesday: 'Miércoles',
    daySelectThursday: 'Jueves',
    dayselectFriday: 'Viernes',
    daySelectSaturday: 'Sábado',
    daySelectSunday: 'Domingo',
}
const clevertapEvents = {
    homeLandingProducts: 'web- nl- Landing cargar productos',
    homeLandingInventory: 'web- nl- Landing inventario',
    homeLandingOrders: 'web- nl- Landing mis pedidos',
    ordersButtonAccept: 'web- Click en botón "Aceptar"',
    restorePasswordButton: 'web- rc- Botón Restablecer Clave',
    backToLoginButton: 'web- li- Botón volver a Login',
    orderHeaderNew: 'web- mped- Click en tab "Nuevos"',
    orderHeaderProgress: 'web- mped- Click en tab "En preparación"',
    orderHeaderDelivery: 'web- mped- Click en tab "Por entregar"',
    orderHeaderHistory: 'web- mped- Click en tab "Historial"',
    productsLandingLiquidate: 'web- nl- Landing liquidar productos',
    productsLandingIndividual: 'web- nl- Landing carga de productos individual',
    productsLandingMassive: 'web- nl- Landing carga masiva de productos',
    individualNotFood: 'web- cpi- No Alimentos',
    individualFood: 'web- cpi- Alimentos',
    individualBrandSelection: 'web- Selección "Marca producto"',
    individualClasificationSelection: 'web- Selección "Clasificación"',
    individualCreateProduct: 'web- Click en botón "Crear producto"',
    detailAcceptOrder: 'web- gp- Click en Aceptar pedido',
    detailReadyDelivery: 'web- gp- Click en Listo para despacho',
    detailReadyPickUp: 'web- gp- Click en Listo para retiro',
    detailPickedUpByClient:
        'web- gp-  Click en Marcar como retirado por el cliente',
    detaildelivered: 'web- gp-  Click en Marcar como entregado',
    rejectOrderButton: 'web- gp-  Click en Rechazar Pedido',
    drawerHome: 'web- nl- Click en botón "Inicio"',
    drawerMyProducts: 'web- nl- Click en botón "Mis productos"',
    drawerUploadProducts: 'web- nl- Click en botón "Cargar productos"',
    drawerLiquidate: 'web- nl- Click en botón "Liquidar un producto"',
    drawerOrders: 'web- nl- Click en botón "Mis pedidos"',
    drawerLogout: 'web- nl- Click en botón "Cerrar sesión"',
    drawerMassiveUpload: 'web- nl- Click en botón "Carga masiva de productos"',
    drawerIndividualUpload:
        'web- nl- Click en botón "Carga individual de productos"',
    inventoryClickSearch: 'web- mp- Click en "Búsqueda de producto"',
    inventoryClickSearchIcon:
        'web- mp- Click en "Icono lupa búsqueda de producto"',
    inventoryTabAll: 'web- mp- Click en tab "TODOS"',
    inventoryTabLiquidation: 'web- mp- Click en tab "Liquidación"',
    inventoryTabListCategories: 'web- mp- Click en tab',
    inventoryLiquidateProductButton:
        'web- mp- Click en boton "Liquidar" producto',
    inventoryPaginationPrevious: 'web- mp- Click en paginación retroceder',
    inventoryPaginationNext: 'web- mp- Click en paginación próximo',
    inventoryPaginationNumber: 'web- mp- Click en número de paginacion',
    createProductIngredients: 'web- cpi- Click en "Agregar Opciones"',
    createProductAddIngredients: 'web- cpi- Click en "Agregar ingrediente',
    createProductDeleteIngredient: 'web- cpi- Click en "Eliminar ingrediente',
    createProductAddOptions: 'web- cpi- Click en "Agregar opción"',
    liquidateCreateProduct:
        'web- lpi- Click en "Crear Producto", liquidar producto nuevo',
    massiveUploadDownloadFile:
        'web- cpm- Click en "Descarga de planilla Excel"',
    massiveUploadFile: 'web- cpm- Click en boton "Elegir archivo" Excel',
    massiveUploadZip: 'web- cpm- Click en boton "Elegir archivo" Zip',
    massiveUploadConfirm: 'web- cpm- Click en boton "Continuar" carga masiva.',
    massiveContinueOK: 'web- pcp- Click en boton "Continuar"',
    massiveGoBack: 'web- pcp- Click en boton "volver"',
    closeDetailModal: 'web- cdp- Click en boton cerrar detalle de pedido',
    pageLoadNoLogin: 'web- nsl- Page load',
    pageLoadnl: 'web- nl- Page load',
    pageLoadmp: 'web- mp- Page load',
    pageLoacpi: 'web- cpi- Page load',
    pageLoalpi: 'web- lpi- Page load',
    pageLoamped: 'web- mped- Page load',
    pageLoadcpm: 'web- cpm- Page load',
    drawerReport: 'nl_click_menu_reportes',
}
export { labels, clevertapEvents, voucher, ecofriendly, onlineConfig }
