import { useState } from "react";
export default function useDate(initDate?: Date) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initDate || null
  );
  const handleDate = (newDate: Date | null) => {
    setSelectedDate(newDate);
  };
  return { handleDate, selectedDate };
}
