import React, { ReactNode } from "react";
import Drawer from "../drawer/Drawer";
import styles from "./Layout.module.css";

interface ILayoutProps {
  children: ReactNode;
}

export default function Layout({ children }: ILayoutProps) {
  return (
    <div className={styles.container}>
      <Drawer />
      <div className={styles.outletContainer}>{children}</div>
    </div>
  );
}
