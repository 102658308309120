import React, { useState, useContext, createContext, ReactNode } from "react";
import Spinner from "../components/spinner/Spinner";

interface ILoadingContext {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}
export const LoadingContext = createContext<ILoadingContext>({
  isOpen: false,
  onOpen: () => null,
  onClose: () => null,
});

export const useLoading = (): ILoadingContext => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return { isOpen, onClose, onOpen };
};
interface ISpinnerProviderProps {
  children: ReactNode;
}

export const useLoadingContext = (): ILoadingContext | null => {
  const context = useContext<ILoadingContext>(LoadingContext);
  return context;
};

export default function SpinnerProvider({ children }: ISpinnerProviderProps) {
  const context = useLoading();
  return (
    <LoadingContext.Provider value={context}>
      {context.isOpen && <Spinner />}
      {children}
    </LoadingContext.Provider>
  );
}
