import { format } from "date-fns";
import { BASEAPI } from "./constants";

const createProduct = async (data: ICreateProductForm) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  const res = await fetch(`${BASEAPI}apimac/api/v1/mac/product/ `, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      productCategoryID: Number(data.productCategoryID),
      productBrandID: Number(data.productBrandID),
      productMeasureUnit: data.productMeasureUnit,
      productStatusID: 1,
      productName: data.productName,
      fullDescription: data.fullDescription,
      shortDescription: data.shortDescription,
      weight: +data.weight.replace(",", "."),
      photoURL: data.productImage,
      commerceCreatedBy:
        commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal,
      isFood: data.isFood,
      isExemptFromIva: false,
      allowChange: false,
      isValid: false,
      isPack: false,
      isPublic: false,
      isExpirable: false,
      isHeavyProduct: false,
      isPreparable: false,
      isPartitionable: false,
      isCreatedByCommerce: false,
      ean: null,
    }),
  }).catch((error) => {
    throw new Error(error);
  });
  return JSON.parse(await res.text()) as ILiquidateCreated;
};

const liquidateFromCreate = async (data: ILiquidateCreate) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  const res = await fetch(`${BASEAPI}apimac/api/v1/mac/product/settlement/ `, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      productCategoryID: Number(data.category),
      productBrandID: Number(data.productBrand),
      productMeasureUnit: data.unit,
      productStatusID: 1,
      productName: data.name,
      fullDescription: data.description,
      shortDescription: data.shortDescription,
      weight: data.quantity,
      photoURL: data.productImage,
      commerceCreatedBy:
        commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal,
      dateVoidSettlement: data?.date ? format(data.date, "yyyy-MM-dd") : null,
      valid: false,
      createdByCommerce: true,
      isFood: data.isFood,
      public: false,
    }),
  });
  return JSON.parse(await res.text()) as ILiquidateCreated;
};

const addStock = (
  stock: string,
  productID: number,
  weight: string,
  weightUnit: string
) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  return fetch(
    `${BASEAPI}apimac/api/v1/mac/product/stock/${productID}/${
      commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stock: stock,
        sellingByEcommerce: true,
        sellingByPickandgo: true,
        weight: +weight.replace(",", "."),
        weightUnit,
      }),
    }
  );
};

const changePrice = (price: string, productID: number, priceTypeID: number) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  const internalID =
    commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal;
  return fetch(
    `${BASEAPI}apimac/api/v1/mac/product/price/${productID}/${internalID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productID: productID,
        commerceInternalID: internalID,
        active: true,
        discount: 0,
        priceTypeID: priceTypeID,
        saleAmount: Number(price),
      }),
    }
  );
};
const addPrice = (
  price: string,
  priceOriginal: string,
  productID: number,
  priceTypeID: number
) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  const internalID =
    commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal;
  return fetch(
    `${BASEAPI}apimac/api/v1/mac/product/price/${productID}/${internalID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productID: productID,
        commerceInternalID: internalID,
        active: true,
        discount: 0,
        priceTypeID: priceTypeID,
        saleAmount: Number(price),
        priceOriginal: priceOriginal,
      }),
    }
  );
};

const getBrands = async () => {
  const query = await fetch(`${BASEAPI}apimac/api/v1/mac/product/brands`);
  const data = await query.json();
  localStorage.setItem("brands", JSON.stringify(data));
};

const getCategories = async () => {
  const query = await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/categories?only_child=true`
  );
  const data = await query.json();
  localStorage.setItem("categories", JSON.stringify(data));
};

const localBrands = (): IBrand[] => {
  const brands = localStorage.getItem("brands") || "";
  return JSON.parse(brands);
};
const localCategories = (): ICategory[] => {
  const categories = localStorage.getItem("categories") || "";
  return JSON.parse(categories);
};

const uploadImage = async (image: string) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  return fetch(
    `${BASEAPI}APIUploadImages/product/${commerceData.sEstablecimiento}${commerceData.cLocal[0].sLocal}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ image }),
    }
  )
    .then(async (res) => {
      return JSON.parse(await res.text()).url;
    })
    .catch(() => {
      return false;
    });
};

const getProducts = async (
  filter: string,
  page: number,
  chunk: number,
  category: string,
  name: string,
  sortName: string,
  sortPrice: string
) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  const internalID =
    commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal;

  const query = await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/pages/ecommerce/${internalID}?filter=${filter}&page=${page}&chunk=${chunk}&category=${category}&product_name=${name.replaceAll(
      " ",
      ""
    )}&sortName=${sortName}&sortPrice=${sortPrice}`
  );
  const data = await query.json();
  localStorage.setItem("products", JSON.stringify(data));
  return data;
};

const localProducts = (): IProductsWithPage => {
  const products = localStorage.getItem("products") || "";
  return JSON.parse(products);
};

const getCategoriesByCommerce = async () => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  const internalID =
    commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal;

  const query = await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/categories/ecommerce/${internalID}`
  );
  const data = await query.json();
  localStorage.setItem("categoriesCommerce", JSON.stringify(data));
};

const localCategoriesCommerce = (): ICategory[] => {
  const categories = localStorage.getItem("categoriesCommerce") || "";
  return JSON.parse(categories);
};

const liquidateFromStock = async (data: ICreateLiquidateStock) => {
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/settlement/stock`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        commerceInternalID: data.commerceInternalID,
        sku: data.sku,
        dateVoidSettlement: data?.dateVoidSettlement
          ? format(data.dateVoidSettlement, "yyyy-MM-dd")
          : null,
        saleAmount: data.saleAmount,
        stock: data.stock,
      }),
    }
  );
  return res;
};

const stopLiquidatedProduct = async (
  productID: number,
  internalCommerceID: number
) => {
  const res = await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/settlement/${productID}/${internalCommerceID}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return res;
};

const addIngredients = async (productID: number, data: AddIngredient[]) => {
  await fetch(`${BASEAPI}apimac/api/v1/mac/product/${productID}/ingredient`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ingredientTypes: data }),
  });
};

const createMassiveProducts = async (products: File, images?: File) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;

  const data = new FormData();
  data.append("products", products);
  const emptyFile = new File([], "");

  data.append("images", images ? images : emptyFile);

  data.append(
    "commerceInternalID",
    `${commerceData.sEstablecimiento}${commerceData.cLocal[0].sLocal}`
  );
  return await fetch(`${BASEAPI}api-load-products/product/load`, {
    method: "POST",
    body: data,
  });
};

const updateProduct = async (data: IEditProduct) => {
  return await fetch(`${BASEAPI}apimac/api/v1/mac/product`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const updateStock = (productID: number, data: IEditStock) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  return fetch(
    `${BASEAPI}apimac/api/v1/mac/product/stock/${productID}/${
      commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};

const updatePrice = (productID: number, data: IEditPrice) => {
  const commerce = localStorage.getItem("currentUser");
  const commerceData: ICommerceBO = commerce ? JSON.parse(commerce) : null;
  const internalID =
    commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal;
  return fetch(
    `${BASEAPI}apimac/api/v1/mac/product/price/${productID}/${internalID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};
const updateIngredients = async (
  productID: number,
  data: IEditIngredients[]
) => {
  return await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/${productID}/ingredient`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};

const deleteProduct = async (
  idProducto: number,
  internalIdCommerce: number,
  data: IProductObject
) => {
  return await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/stock/deactive/${idProducto}/${internalIdCommerce}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};

const getProduct = async (
  idProducto: number,
  internalIdCommerce: number
): Promise<IProductObject> => {
  const query = await fetch(
    `${BASEAPI}apimac/api/v1/mac/product/${idProducto}/${internalIdCommerce}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  return (await query.json()) as IProductObject;
};
export {
  createProduct,
  liquidateFromCreate,
  uploadImage,
  addStock,
  changePrice,
  getBrands,
  getCategories,
  localBrands,
  localCategories,
  getProducts,
  localProducts,
  getCategoriesByCommerce,
  localCategoriesCommerce,
  liquidateFromStock,
  stopLiquidatedProduct,
  addIngredients,
  createMassiveProducts,
  updateProduct,
  updateStock,
  updatePrice,
  updateIngredients,
  deleteProduct,
  getProduct,
  addPrice,
};
