const MOBILE_NUMBER = "56933890580";

const openWhatsapp = () => {
  window.open(
    "https://wa.me/" +
      MOBILE_NUMBER +
      "/?text=" +
      "Hola Amipass, necesito ayuda"
  );
};

export default openWhatsapp;
