import { useEffect, useRef, useState } from "react";

export function useHorizontalScroll() {
  const elRef = useRef<HTMLDivElement>(null);
  const firstRef = useRef<HTMLLIElement>(null);
  const lastRef = useRef<HTMLLIElement>(null);
  const [showLeft, setShowLeft] = useState<boolean>(false);
  const [showRight, setShowRight] = useState<boolean>(true);

  const onClickLeft = () => {
    const el = elRef.current;

    const first = firstRef.current;
    if (el) {
      el.scrollTo({
        left: el.scrollLeft - 250,
        behavior: "smooth",
      });
      setShowRight(true);
      if (
        first &&
        first.getBoundingClientRect().left >= el.getBoundingClientRect().left
      ) {
        setShowLeft(false);
      }
    }
  };
  const onClickRight = () => {
    const el = elRef.current;
    const last = lastRef.current;
    if (el) {
      el.scrollTo({
        left: el.scrollLeft + 250,
        behavior: "smooth",
      });
      setShowLeft(true);
      if (
        last &&
        last.getBoundingClientRect().left <
          el.getBoundingClientRect().left + el.getBoundingClientRect().width
      ) {
        setShowRight(false);
      }
    }
  };
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth",
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return {
    showLeft,
    showRight,
    elRef,
    onClickLeft,
    onClickRight,
    lastRef,
    firstRef,
  };
}
