import React, { ChangeEvent, useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Text from "../../../components/text/Text";
import { clevertapEvents, labels } from "../../../labels";
import styles from "./massiveUpload.module.css";
import SvgIcon, { iconNames } from "../../../components/svgIcon/SvgIcon";
import FilesUpload from "../../../components/upload/FilesUpload";
import { createMassiveProducts } from "../../../services/products";
import useDisclosure from "../../../hooks/useDisclosure";
import ModalComponent from "../../../components/modal/ModalComponent";
import MassiveUploadConfirm from "../../../components/modal/modalVariants/MassiveUploadConfirm";
import MassiveResponseOk from "../../../components/modal/modalVariants/MassiveResponseOk";
import { useLoadingContext } from "../../../context/spinnerContext";
import { useNavigate } from "react-router";
import { track } from "../../../ClevertapWrapper";
import { format } from "date-fns";
import routes from "../../../router/routes";

interface Excel {
  [name: string]: string;
}

interface ExcelObject {
  "NOMBRE DEL PRODUCTO": string;
  "DESCRIPCIÓN DEL PRODUCTO": string;
  "DESCRIPCIÓN CORTA": string;
  EAN: string;
  MARCA: string;
  CATEGORÍA: string;
  "UNIDAD MEDIDA": string;
  "CANTIDAD MEDIDA": string;
  STOCK: string;
  PRECIO: string;
  "ES COMIDA?": string;
  "NOMBRE FOTO": string;
}
const productName = "NOMBRE DEL PRODUCTO";
const productDescription = "DESCRIPCIÓN DEL PRODUCTO";
const productShortDescription = "DESCRIPCIÓN CORTA";
const productUM = "UNIDAD MEDIDA";
const productQuantity = "CANTIDAD MEDIDA";
const productIsFood = "ES COMIDA?";
const productCategory = "CATEGORÍA";
const productBrand = "MARCA";
const productPrice = "PRECIO";
const productStock = "STOCK";
const MB_SIZE = 1024 * 1024;
const MAX_MB_IMAGE = 2;
export default function MassiveUpload() {
  const navigate = useNavigate();
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const [productsFile, setProductsFile] = useState<File>();
  const [imagesFile, setImagesFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState("");

  const [error, setError] = useState<boolean>(false);
  const context = useLoadingContext();

  const [responseOk, setResponseOk] = useState<boolean>();
  const [unit, setUnit] = useState<string>("Kg");
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const pageLoadData = {
    pageName: "Carga masiva de producto",
    pageURL: routes.massiveUpload,
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    codComercio: user?.sEstablecimiento,
    codLocal: user?.cLocal[0].sLocal,
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  const {
    open: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm,
  } = useDisclosure();
  const {
    open: openResponseOK,
    handleOpen: handleOpenResponseOK,
    handleClose: handleCloseResponseOK,
  } = useDisclosure();

  const handleclose = () => {
    window.location.reload();
  };

  const handleProductFile = (event: ChangeEvent<HTMLInputElement>) => {
    track(clevertapEvents.massiveUploadFile, clevertapData);
    if (event?.target?.files?.[0]) {
      setProductsFile(event?.target?.files?.[0]);
      setError(false);
      setResponseOk(undefined);
    }
  };

  const handleImageFile = (event: ChangeEvent<HTMLInputElement>) => {
    track(clevertapEvents.massiveUploadZip, clevertapData);
    if (event?.target?.files?.[0]) {
      setImagesFile(event?.target?.files?.[0]);
      setError(false);
      setErrorMessage("");
      setResponseOk(undefined);
    }
  };

  const handleSubmit = async () => {
    if (productsFile) {
      await createMassiveProducts(productsFile, imagesFile)
        .then((res) => {
          handleOpenResponseOK();
          setResponseOk(res.ok);
          context?.onClose();
        })
        .catch(() => {
          handleOpenResponseOK();
          setResponseOk(false);
          context?.onClose();
        });
    }
  };

  const goToProduct = () => {
    track(clevertapEvents.closeDetailModal, clevertapData);
    navigate("/products");
  };

  const validate = () => {
    track(clevertapEvents.massiveUploadConfirm, clevertapData);
    const reader = new FileReader();

    handleCloseConfirm();
    context?.onOpen();
    reader.onload = (evt) => {
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json<Excel & ExcelObject>(ws);
      let isValid = true;
      if (data.length > 0) {
        data.forEach((element) => {
          if (
            !element[productQuantity] ||
            !element[productCategory] ||
            !element[productShortDescription] ||
            !element[productDescription] ||
            !element[productIsFood] ||
            !element[productBrand] ||
            !element[productName] ||
            !element[productPrice] ||
            !element[productStock] ||
            !element[productUM]
          ) {
            isValid = false;
            setError(true);
          }
        });
        if (
          imagesFile &&
          data.length * MB_SIZE * MAX_MB_IMAGE < (imagesFile?.size || 0)
        ) {
          isValid = false;
          setError(true);
          setErrorMessage(labels.fileSizeError);
        }
      } else {
        isValid = false;
        setError(true);
      }

      if (isValid) {
        handleSubmit();
      } else {
        context?.onClose();
      }
    };
    reader.readAsBinaryString(productsFile as Blob);
  };
  useEffect(() => {
    getLocation();
  }, [lat, lng]);
  useEffect(() => {
    track(clevertapEvents.pageLoadcpm, pageLoadData);
  }, []);
  const handleClickDownloadFile = () => {
    track(clevertapEvents.massiveUploadDownloadFile, clevertapData);
  };
  return (
    <>
      <ModalComponent show={openConfirm} handleClose={handleCloseConfirm}>
        <MassiveUploadConfirm
          onContinue={validate}
          onClose={handleCloseConfirm}
          haveAllFiles={!!(productsFile && imagesFile)}
        />
      </ModalComponent>
      <ModalComponent
        show={openResponseOK}
        handleClose={responseOk ? handleclose : handleCloseResponseOK}
      >
        <MassiveResponseOk
          isError={!responseOk}
          onClose={responseOk ? goToProduct : handleCloseResponseOK}
        />
      </ModalComponent>
      <div className={styles.container}>
        <div className={styles.view}>
          <div className={styles.alignLeft}>
            <b>
              <Text label={labels.massiveUpload} variant="h5" />
            </b>
          </div>
          <div className={styles.warning}>
            <div className={styles.icon}>
              <SvgIcon iconName="warning" />
            </div>
            <p className="warning">{labels.massiveWarning}</p>
          </div>
          <b className={styles.alignLeft}>{labels.important}</b>
          <ul className={styles.alignLeft}>
            <li>{labels.newProducts}</li>
            <li>{labels.notInStock}</li>
            <li>{labels.notIngredients}</li>
            <li>{labels.notEmpty}</li>
          </ul>
          <div className={styles.downloaderFlex}>
            <div className={styles.flex}>
              <div
                className={styles.flexItem1}
                onClick={handleClickDownloadFile}
              >
                <p className="subtitle1">{labels.excel}</p>
                <a
                  href={require("../../../assets/files/Formato_Llenado_Producto.xlsx")}
                  download={labels.massiveFile}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.downloader}
                >
                  <div>
                    <SvgIcon iconName={iconNames.download} />
                  </div>
                  <p className="subtitle2">{labels.downloadExcel}</p>
                </a>
              </div>
              <div className={styles.flexItem2}>
                <p className="subtitle1">{labels.uploadFiles}</p>
                <FilesUpload
                  uploadError={responseOk === false}
                  error={error}
                  errorMessage={errorMessage}
                  productFile={productsFile}
                  imageFile={imagesFile}
                  handleImageFile={handleImageFile}
                  handleProducFile={handleProductFile}
                />
              </div>
            </div>
            <button
              onClick={productsFile && handleOpenConfirm}
              className={
                !productsFile
                  ? `${styles.button} ${styles.disabled}`
                  : styles.button
              }
            >
              {labels.makeMassiveUpload}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
