import React, { useEffect, useState } from 'react'
import Text from '../../components/text/Text'
import { labels } from '../../labels'
import { getUrlDashboard } from '../../services/authentication'
import { useLoadingContext } from '../../context/spinnerContext'
import './UrlDashboardScreen.css'

export default function UrlDashboardScreen() {
    const loadingContext = useLoadingContext()

    const [urlDashboard, setUrlDashboard] = useState<string>('')
    const [viewReport, setViewReport] = useState<boolean>()

    useEffect(() => {
        const init = () => {
            loadingContext?.onOpen()

            const commerce = localStorage.getItem('currentUser')
            const commerceData: ICommerceBO = commerce
                ? JSON.parse(commerce)
                : null
            const internalID =
                commerceData.sEstablecimiento + commerceData.cLocal[0].sLocal

            getUrlDashboard(internalID)
                .then((response) => {
                    if (response.urlDashboard) {
                        setUrlDashboard(response.urlDashboard)
                        setViewReport(true)
                    } else {
                        setViewReport(false)
                    }
                })
                .catch((err) => {
                    console.error(`Error: ${err}`)
                    setViewReport(false)
                })
                .finally(() => {
                    loadingContext?.onClose()
                })
        }
        init()
    }, [])

    return (
        <div>
            <Text label={labels.drawerReport} variant="H6" />
            {viewReport ? (
                <iframe
                    title={labels.reportBI}
                    src={urlDashboard}
                    className="conteiner conteiner-dashboard"
                />
            ) : (
                <div className="conteiner conteiner-dashboard">
                    {!loadingContext?.isOpen && viewReport !== undefined && (
                        <Text
                            label={labels.errorLoadReportBI}
                            variant="Body2-14"
                        />
                    )}
                </div>
            )}
        </div>
    )
}
