import React from "react";
import { labels } from "../../../labels";
import Text from "../../text/Text";
import styles from "./ConfirmModal.module.css";
import iconClock from "../../../assets/icons/icons-success-clock.svg";

interface IInconrrectCredentialsProps {
  onClick: () => void;
  onBack: () => void;
}

export default function ConfirmModal(props: IInconrrectCredentialsProps) {
  return (
    <div className={styles.confirmModal}>
      <div className={styles.labels}>
        <img src={iconClock} />
        <p className="H5-20 colorGray">
          {labels.confirmLiquidate} <b>{labels.amipass}</b>.
        </p>
        <Text label={labels.confirmPublish} variant="Body1-16 bold" />
      </div>
      <div className={styles.buttons}>
        <button
          className={`modalButtonBack sm13 ${styles.button}`}
          onClick={props.onBack}
          type="button"
        >
          {labels.back}
        </button>
        <button
          className={`modalButtonBack sm13 successBC ${styles.button}`}
          onClick={props.onClick}
          type="button"
        >
          {labels.publish}
        </button>
      </div>
    </div>
  );
}
