import * as React from "react";

import "./NavBarCategories.css";
import classnames from "classnames";
import Text from "../text/Text";
import { useHorizontalScroll } from "../../hooks/useHorizontalScroll";
import SvgIcon, { iconNames } from "../svgIcon/SvgIcon";
import { clevertapEvents } from "../../labels";
import { track } from "../../ClevertapWrapper";
import { format } from "date-fns";

interface INavBarCategories {
  listCategories: ICategory[];
  onClickItem: (id: string) => void;
  currentCategory: string;
}

function NavBarCategories({
  listCategories,
  onClickItem,
  currentCategory,
}: INavBarCategories) {
  const {
    elRef,
    onClickLeft,
    onClickRight,
    lastRef,
    firstRef,
    showLeft,
    showRight,
  } = useHorizontalScroll();
  const [lat, setLat] = React.useState<number>(0);
  const [lng, setLng] = React.useState<number>(0);
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };
  React.useEffect(() => {
    getLocation();
  }, [lat, lng]);

  const handleClickTab = (name: string, clevertapEvent: string) => {
    const clevertapData = {
      geoLocationLat: lat,
      geoLocationLong: lng,
      date: format(new Date(), "yyyy/MM/dd"),
      hour: format(new Date(), "kk:mm:ss"),
      tab: name,
    };
    onClickItem(name);
    track(clevertapEvent, clevertapData);
  };
  return (
    <>
      <div className="arrowLeftNavbar">
        {showLeft && (
          <div className="arrowNavbar" onClick={onClickLeft}>
            <SvgIcon iconName={iconNames.shortArrowBack} />
          </div>
        )}
      </div>
      <div className="container-navbar" ref={elRef}>
        <ul className="ul-navigation">
          <li
            onClick={() =>
              handleClickTab("ALL", clevertapEvents.inventoryTabLiquidation)
            }
            className={classnames("li-navigation", {
              selected: "ALL" === currentCategory,
            })}
            ref={firstRef}
          >
            <Text label="Todos" variant="textNavBar" />
          </li>
          <li
            onClick={() =>
              handleClickTab(
                "liquidacion",
                clevertapEvents.inventoryTabLiquidation
              )
            }
            className={classnames("li-navigation", {
              selected: "liquidacion" === currentCategory,
            })}
          >
            <Text label="En Liquidación" variant="textNavBar" />
          </li>
          {listCategories?.map((item, index) => {
            const listCategoriesName =
              clevertapEvents.inventoryTabListCategories + item.id.toString();
            return (
              <li
                onClick={() =>
                  handleClickTab(item.id.toString(), listCategoriesName)
                }
                key={item.id}
                className={classnames("li-navigation", {
                  selected: item.id.toString() === currentCategory,
                })}
                ref={index === listCategories.length - 1 ? lastRef : null}
              >
                <Text label={item.name} variant="textNavBar" />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="arrowRightNavbar">
        {showRight && (
          <div className="arrowNavbar" onClick={onClickRight}>
            <SvgIcon iconName={iconNames.shortArrowNext} />
          </div>
        )}
      </div>
    </>
  );
}

export default NavBarCategories;
