import React, { useState, ChangeEvent, useEffect } from "react";
import { track } from "../../ClevertapWrapper";
import AsyncSelect from "../../components/asyncSelect/AsyncSelect";
import Calendar from "../../components/calendar/Calendar";
import ModalComponent from "../../components/modal/ModalComponent";
import ConfirmModal from "../../components/modal/modalVariants/ConfirmModal";
import LiquidateCreateResponseOK from "../../components/modal/modalVariants/LiquidateCreateResponseOK";
import LiquidateError from "../../components/modal/modalVariants/LiquidateError";
import OutlinedInput from "../../components/outlinedInput/OutlinedInput";
import OutlinedSelect from "../../components/outlinedSelect/OutlinedSelect";
import ProductType from "../../components/productType/ProductType";
import SvgIcon from "../../components/svgIcon/SvgIcon";
import Text from "../../components/text/Text";
import WarningUI from "../../components/warning/WarningUI";
import { MEASURES } from "../../constants";
import { brandsToOption, categoriesToOption, formIds } from "../../formatters";
import useDisclosure from "../../hooks/useDisclosure";
import { clevertapEvents, labels } from "../../labels";
import { localBrands, localCategories } from "../../services/products";
import styles from "./liquidate.module.css";
import useLiquidate from "./useLiquidate";
import { format } from "date-fns";
import routes from "../../router/routes";

export default function Liquidate() {
  const {
    formRef,
    form,
    errors,
    isValidForm,
    openRespnseOK,
    openResponseError,
    showErrors,
    handleDate,
    handleSelectBrand,
    handleSelectCategory,
    handleChange,
    handleSubmit,
    handleFile,
    validate,
    handleCloseResponseOk,
    handleCloseResponseError,
    handleFood,
  } = useLiquidate();
  const [unit, setUnit] = useState<string>("Kg");

  const {
    open: openConfirm,
    handleClose: handleCloseConfirm,
    handleOpen: handleOpenConfirm,
  } = useDisclosure();
  const brands = brandsToOption(localBrands());
  const categories = categoriesToOption(localCategories());
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const currentUser = localStorage.getItem("currentUser");
  const user = currentUser && JSON.parse(currentUser);
  const clevertapData = {
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    // ID: commerceId
  };
  const pageLoadData = {
    pageName: "Liquidar producto",
    pageURL: routes.liquidate,
    geoLocationLat: lat,
    geoLocationLong: lng,
    date: format(new Date(), "yyyy/MM/dd"),
    hour: format(new Date(), "kk:mm:ss"),
    codComercio: user?.sEstablecimiento,
    codLocal: user?.cLocal[0].sLocal,
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  };

  const handleUnitChange = (element: ChangeEvent<HTMLSelectElement>) => {
    setUnit(element.target.value);
  };
  const handleClick = () => {
    track(clevertapEvents.liquidateCreateProduct, clevertapData);
    if (validate()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      handleOpenConfirm();
    }
  };

  const onSubmit = () => {
    handleCloseConfirm();
    handleSubmit();
  };

  const onCloseConfirm = () => {
    handleCloseConfirm();
  };

  const closeConfirm = openConfirm && onCloseConfirm;
  const closeResponseOk = openRespnseOK && handleCloseResponseOk;
  const closeResponseError = openResponseError && handleCloseResponseError;
  const closeFunction = closeConfirm || closeResponseOk || closeResponseError;
  useEffect(() => {
    getLocation();
  }, [lat, lng]);
  useEffect(() => {
    track(clevertapEvents.pageLoalpi, pageLoadData);
  }, []);
  return (
    <div className={styles.container}>
      <ModalComponent
        show={openConfirm || openRespnseOK || openResponseError}
        handleClose={closeFunction ? closeFunction : () => null}
      >
        {openConfirm && (
          <ConfirmModal onClick={onSubmit} onBack={onCloseConfirm} />
        )}
        {openRespnseOK && (
          <LiquidateCreateResponseOK
            buttonText="Crear nuevo producto"
            text="Su producto ha sido publicado con éxito."
            onClose={handleCloseResponseOk}
          />
        )}
        {openResponseError && (
          <LiquidateError onClick={handleCloseResponseError} />
        )}
      </ModalComponent>
      <div className={styles.wrapper}>
        <Text label={labels.liquidateNewProduct} variant="subtitle1 black" />
        <WarningUI />
        <form ref={formRef} id="liquidate-form" className={styles.form}>
          <div className={styles.formWrapper}>
            <ProductType isFood={!!form.isFood} onChange={handleFood} />
            <div className={styles.titleWrapper}>
              <Text
                label={labels.productInformation}
                variant={`subtitle1 ${styles.textWidth}`}
              />
              <div className={styles.divider} />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                onChange={handleChange}
                formId={formIds.name}
                floatingLabel={labels.name}
                placeholder={labels.productNamePlaceholder}
                minLength={0}
                error={showErrors ? errors?.name && " " : ""}
                maxLength={42}
                charCounter
                min="0"
                max="42"
              />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                onChange={handleChange}
                formId={formIds.description}
                floatingLabel={labels.description}
                placeholder={labels.descriptionPlaceHolder}
                error={showErrors ? errors?.description && " " : ""}
                minLength={0}
                maxLength={82}
                charCounter
              />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                onChange={handleChange}
                formId={formIds.shortDescription}
                floatingLabel={labels.shortDescription}
                placeholder={labels.productNamePlaceholder}
                error={showErrors ? errors?.shortDescription && " " : ""}
                minLength={0}
                maxLength={64}
                charCounter
              />
            </div>
            <div className={styles.inputContainer}>
              <AsyncSelect
                options={brands}
                floatingLabel={labels.productBrand}
                placeholder={labels.productBrandPlaceholder}
                errorMessage={showErrors ? errors?.productBrand && " " : ""}
                onChange={handleSelectBrand}
              />
            </div>
            <div className={styles.inputContainer}>
              <AsyncSelect
                options={categories}
                floatingLabel={labels.classification}
                errorMessage={showErrors ? errors?.classification && " " : ""}
                placeholder={labels.classificationPlaceholder}
                onChange={handleSelectCategory}
              />
            </div>
            <div className={styles.inputContainer}>
              <OutlinedInput
                formId={formIds.productImage}
                floatingLabel={labels.productImage}
                placeholder={labels.productImagePlaceholder}
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFile}
                helperText={labels.fileSize}
                error={!showErrors ? errors?.productImage : ""}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.rowItem1}>
                <OutlinedSelect
                  formId={formIds.unit}
                  floatingLabel={labels.unit}
                  options={MEASURES}
                  error={showErrors ? errors?.unit && " " : ""}
                  onChange={handleUnitChange}
                />
              </div>
              <div className={styles.rowItem2}>
                <OutlinedInput
                  onChange={handleChange}
                  formId={formIds.quantity}
                  floatingLabel={labels.quantity}
                  type="text"
                  isNumber={unit !== "Lt" && unit !== "Kg"}
                  isDecimal={unit === "Lt" || unit === "Kg"}
                  min="0"
                  placeholder={labels.quantityPlaceholder}
                  error={showErrors ? errors?.quantity && " " : ""}
                />
              </div>
            </div>
            <Text label={labels.priceToLiquidate} variant="Caption3" />
            <div className={styles.row}>
              <div className={styles.rowItem1}>
                <OutlinedInput
                  onChange={handleChange}
                  formId={formIds.price}
                  floatingLabel={labels.normalPrice}
                  isNumber
                  placeholder={labels.normalPlaceholder}
                  error={showErrors ? errors?.price && " " : ""}
                />
              </div>
              <div className={styles.rowItem2}>
                <OutlinedInput
                  onChange={handleChange}
                  formId={formIds.liquidatePrice}
                  floatingLabel={labels.liquidatePrice}
                  isNumber
                  placeholder={labels.liquidatePlaceholder}
                  error={showErrors ? errors?.liquidatePrice && " " : ""}
                />
              </div>
            </div>
            {showErrors && errors?.liquidatePrice && (
              <div className={styles.liquidateError}>
                <div className={styles.warningIcon}>
                  <SvgIcon iconName="warning" />
                </div>
                <p className="subtitle3">{errors?.liquidatePrice}</p>
              </div>
            )}
            <Text variant="Caption3" label={labels.liquidateQuantity} />
            <div className={[styles.inputContainer, styles.row].join(" ")}>
              <div className={styles.rowItem1}>
                <OutlinedInput
                  onChange={handleChange}
                  formId={formIds.stock}
                  floatingLabel={labels.liquidateStock}
                  isNumber
                  placeholder={labels.quantityPlaceholder}
                  error={showErrors ? errors?.stock && " " : ""}
                />
              </div>
              <div className={styles.rowItem2}></div>
            </div>
            <div className={styles.dateContainer}>
              <p className="subtitle2 leftAlign">Fecha límite de venta</p>
              <Calendar onChange={handleDate} minDate={new Date()} />
            </div>
          </div>
          <button
            onClick={handleClick}
            type="button"
            className={`successBC ButtonSm-13 fullwidth ${
              !isValidForm ? "buttonDisabled" : ""
            }`}
          >
            Crear Producto
          </button>
        </form>
      </div>
    </div>
  );
}
