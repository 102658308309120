import React, { useMemo } from "react";
import Text from "../text/Text";
import { Token, TransactionResponse } from "../../services/commerce";
import { format } from "date-fns";
import styles from "./SaleComponent.module.css";
import { currencyFormat, parseStringDate } from "../../formatters";
import { useTokensContext } from "../../context/tokensContext";
import { labels } from "../../labels";
import useDisclosure from "../../hooks/useDisclosure";
import Modal from "./Modal";

const getTokenName = (token: string, tokens: Token[] | null) => {
  let texto2 = labels.amiMarket;
  tokens?.forEach((item) => {
    if (item.idtoken == token) {
      if (item.idtoken === amiRutToken) {
        texto2 = labels.amiRut;
      } else if (item.idtoken === codWebToken) {
        texto2 = labels.codWeb;
      } else if (item.idtoken === codAppToken) {
        texto2 = labels.codApp;
      } else if (item.idtoken == qrToken) {
        texto2 = labels.qr;
      } else {
        texto2 = item.stexto2;
      }
    }
  });
  return texto2;
};

interface IModalInfoProps {
  title: string;
  label: string;
  customColor?: string;
}

const ModalInfo = ({ title, label, customColor }: IModalInfoProps) => {
  return (
    <div className={styles.modalRow}>
      <p className={`${styles.modalContentText} ${styles.gray}`}>{title}</p>
      <p
        className={`${styles.modalContentText} ${
          customColor ? customColor : styles.primary
        }`}
      >
        {label}
      </p>
    </div>
  );
};
export const dateFormat = "dd/MM/yyyy - HH:mm";
export const amiRutToken = "3487990009";
export const codWebToken = "3487990005";
export const codAppToken = "3487990007";
export const qrToken = "3487990010";
interface ISaleComponentProps {
  sale: TransactionResponse;
}

export default function SaleComponent({ sale }: ISaleComponentProps) {
  const { open, handleOpen, handleClose } = useDisclosure();
  const tokens = useTokensContext();

  const tokenName = useMemo(() => {
    return getTokenName(sale.sToken, tokens);
  }, [tokens]);
  return (
    <>
      <Modal isOpen={open} onClose={handleClose} title={labels.saleDetail}>
        <div className={styles.modalContainer}>
          <div className={styles.modalContent}>
            <ModalInfo
              title={labels.paymentAuthorizationCode}
              label={sale.idTransaccion}
            />
            <ModalInfo
              title={`${labels.saleClient}:`}
              label={sale.sNombreCliente}
            />
            <ModalInfo
              title={labels.paymentDate}
              label={format(parseStringDate(sale.dTransaccion), dateFormat)}
            />
            <ModalInfo title={labels.paymentMethodDetail} label={tokenName} />
            <ModalInfo
              title={`${labels.saleStatus}:`}
              label={sale.sEstadoTx}
              customColor={
                [labels.canceled, labels.rejected].includes(sale.sEstadoTx)
                  ? styles.error
                  : undefined
              }
            />
          </div>
        </div>
      </Modal>
      <div
        onClick={handleOpen}
        className={`${styles.rowSale} ${styles.saleCard}`}
        key={sale.idTransaccion}
      >
        <div className={styles.column}>
          <Text
            label={`${tokenName} / ${sale.sEstadoTx}`}
            variant="subtitle2 bold-600"
          />
          <Text
            label={format(parseStringDate(sale.dTransaccion), dateFormat)}
            variant="Body2-14"
          />
        </div>
        <div className={styles.columnId}>
          <Text label="N°" variant="subtitle2 bold-600" />
          <Text label={sale.idTransaccion} variant="Body2-14" />
        </div>
        <div className={styles.columnEnd}>
          <p
            className={`Body2-14 ${
              sale.nMonto.includes("-") ? styles.negative : ""
            }`}
          >
            {currencyFormat(+sale.nMonto)}
          </p>
        </div>
      </div>
    </>
  );
}
