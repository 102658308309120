import React from "react";
import { labels } from "../../labels";
import SvgIcon from "../svgIcon/SvgIcon";
import styles from "./warning.module.css";

function WarningUI() {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <SvgIcon iconName="warning" />
      </div>
      <p className="warning">
        <b>{labels.beforePublish}</b>, {labels.warningProductInformation}{" "}
        <b>{labels.usagePolicies}</b>
      </p>
    </div>
  );
}

export default WarningUI;
